import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ForumService } from 'src/app/shared/forum.service';
import { NotificationService } from '../../shared/notification.service'

@Component({
  selector: 'app-create-forum-component',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule
  ],
  templateUrl: './create-forum-component.component.html',
  styleUrl: './create-forum-component.component.scss'
})
export class CreateForumComponentComponent {
  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;

  mediaRecorder!: MediaRecorder;
  recordedChunks: Blob[] = [];
  recordedBlobUrl: string | null = null;
  isRecording = false;

  stream!: MediaStream;
  uploadForm!: FormGroup;
  submitted = false;
  selectedFile: File | null = null;
  fileUploadError: string = '';
  responseData: any;
  blob: any;

  constructor(public service:ForumService,private formBuilder: FormBuilder, private http: HttpClient,private location: Location,private notificationServ:NotificationService) {}

  ngOnInit(): void {
    this.initMediaStream();
     // Initialize the form with validation
     this.uploadForm = this.formBuilder.group({
      // user: ['', Validators.required],
      subject: ['', Validators.required],
      // description: ['', Validators.required]
    });
  }
  goBack(): void {
    this.location.back(); // Navigates back to the previous route
  }

  // Initialize the media stream from camera and microphone
  async initMediaStream() {
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true
      });

      // Set the stream to the video element
      this.videoElement.nativeElement.srcObject = this.stream;
    } catch (error) {
      console.error('Error accessing media devices:', error);
    }
  }

  // Start recording video and audio
  startRecording() {
    this.recordedChunks = [];
    this.isRecording = true;

    // Initialize MediaRecorder with the media stream
    this.mediaRecorder = new MediaRecorder(this.stream);

    // Event triggered when data is available (i.e., recording in progress)
    this.mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        this.recordedChunks.push(event.data);
      }
    };

    // Start recording
    this.mediaRecorder.start();
  }

  // Stop recording and create a blob from the recorded data
  stopRecording() {
    this.isRecording = false;

    this.mediaRecorder.stop();

    this.mediaRecorder.onstop = () => {
      // Create a Blob from the recorded chunks
      this.blob = new Blob(this.recordedChunks, { type: 'video/webm' });
    
      // Create a URL for the blob
      this.recordedBlobUrl = URL.createObjectURL(this.blob);
      let stream = this.mediaRecorder.stream; // Get the media stream
      let tracks = stream.getTracks(); // Get all tracks from the stream
  
      // Stop each track (video/audio)
      tracks.forEach(track => track.stop());

    };
  }
 // Getter for easy access to form fields
 get f() {
  return this.uploadForm.controls;
}

// Handle file selection
onFileSelect(event: Event): void {
  const input = event.target as HTMLInputElement;

  if (input.files && input.files.length > 0) {
    this.selectedFile = input.files[0];

    // Reset file upload error
    this.fileUploadError = '';

    // Optional: Validate file type and size here
    const allowedTypes = ['image/png', 'image/jpeg', 'application/pdf'];
    if (!allowedTypes.includes(this.selectedFile.type)) {
      this.fileUploadError = 'Invalid file type. Only PNG, JPEG, and PDF are allowed.';
      this.selectedFile = null;
    }
  }
}

discardRecording() {
  // this.recordedChunks = [];
  this.recordedBlobUrl = null;
  this.isRecording = false;
  this.initMediaStream();

  }
  

// Handle form submission
onSubmit(): void {
  console.log("here")
  this.submitted = true;

  // Validate form fields
  if (this.uploadForm.invalid || !this.blob) {
    if (!this.blob) {
      this.fileUploadError = 'File is required.';
    }
    return;
  }

  // Prepare form data for submission
  const formData = new FormData();
  // formData.append('user', this.uploadForm.get('user')?.value);
  formData.append('subject', this.uploadForm.get('subject')?.value);
  // formData.append('description', this.uploadForm.get('description')?.value);
  formData.append('file', this.blob, "recording.webm");
  console.log('Form submission successful:', formData);
    this.service.postQuestion(formData,this.uploadForm.get('subject')?.value).subscribe(res=>{
      this.notificationServ.ShowInfo('Successfully saved!');
      
    })
  
  }
}
