<li routerLink="/admindashboard/" routerLinkActive="active">
  <i class="fa fa-th-large"></i>
  <span>Dashboard</span>
</li>

<li data-bs-toggle="collapse" data-bs-target="#functionsDropdown"
  [ngClass]="{'active': (questionActive.isActive || analysisActive.isActive || surveyActive.isActive || assignmentActive.isActive || eventActive.isActive ),'collapsed':!(questionActive.isActive || analysisActive.isActive || surveyActive.isActive || assignmentActive.isActive || eventActive.isActive ) }">
  <i class="fa fa-sliders-h" aria-hidden="true"></i><span class="nav-label">Functions</span>
  <!-- <span class="fa fa-chevron-left float-right"></span> -->
  <ul class="sub-menu collapse menu_outer" id="functionsDropdown"
    [ngClass]="{'show': (questionActive.isActive || analysisActive.isActive || surveyActive.isActive || assignmentActive.isActive || eventActive.isActive ) }">
    <li><a routerLink="/question/" routerLinkActive="active" #questionActive="routerLinkActive">Assessments</a></li>
    <li><a routerLink="/analysis/" routerLinkActive="active" #analysisActive="routerLinkActive">Analysis</a></li>
    <li><a routerLink="/survey/" routerLinkActive="active" #surveyActive="routerLinkActive">Survey</a></li>
    <li><a routerLink="/assignment/" routerLinkActive="active" #assignmentActive="routerLinkActive">Assignments</a>
    </li>
    <li><a routerLink="/event/" routerLinkActive="active" #eventActive="routerLinkActive">Interactive Live Classes</a>
    </li>
  </ul>
</li>

<li data-bs-toggle="collapse" data-bs-target="#registersDropdown"
  [ngClass]="{'active': (contactlistActive.isActive || partnerlistActive.isActive || registrationlistActive.isActive),'collapsed':!(contactlistActive.isActive || partnerlistActive.isActive || registrationlistActive.isActive) }">
  <i class="fa fa-registered" aria-hidden="true"></i> <span class="nav-label">Registers</span>
  <!-- <span class="fa fa-chevron-left float-right"></span> -->
  <ul class="sub-menu collapse" id="registersDropdown"
    [ngClass]="{'show': (contactlistActive.isActive || partnerlistActive.isActive || registrationlistActive.isActive ) }">
    <li><a routerLink="/contact-list/" routerLinkActive="active" #contactlistActive="routerLinkActive">Contact</a>
    </li>
    <li><a routerLink="/partner-list/" routerLinkActive="active" #partnerlistActive="routerLinkActive">Partner</a>
    </li>
    <li><a routerLink="/registration-list/" routerLinkActive="active"
        #registrationlistActive="routerLinkActive">Registration</a></li>

  </ul>
</li>
<li routerLink="/school/" routerLinkActive="active"> <i class="fa fa-graduation-cap"></i> <span
    class="nav-label">School</span></li>
<li routerLink="/user/" routerLinkActive="active"><i class="fa fa-users"></i> <span class="nav-label">User</span></li>
<li routerLink="/chat/" routerLinkActive="active"><i class="fa fa-comments"></i> <span class="nav-label">Chat</span>
</li>
<li routerLink="/attendence/" routerLinkActive="active"><i class="fa fa-clock"></i> <span
    class="nav-label">Attendance</span> </li>

<li data-bs-toggle="collapse" data-bs-target="#paymentsDropdown"
  [ngClass]="{'active': (termsActive.isActive || paymentsActive.isActive ),'collapsed':!(termsActive.isActive || paymentsActive.isActive ) }">
  <fa-icon [icon]="currencyIcon"></fa-icon><span class="nav-label">Payments</span>
  <!-- <span class="fa fa-chevron-left float-right"></span> -->
  <ul class="sub-menu collapse" id="paymentsDropdown"
    [ngClass]="{'show': termsActive.isActive || paymentsActive.isActive }">
    <li class="active"><a routerLink="/terms/" routerLinkActive="active" #termsActive="routerLinkActive">Terms</a>
    </li>
    <li><a routerLink="/payments/" routerLinkActive="active" #paymentsActive="routerLinkActive">Payments</a></li>
  </ul>
</li>
<li data-bs-toggle="collapse" data-bs-target="#filesDropdown"
  [ngClass]="{'active': (unusedfilesActive.isActive || filemanagementActive.isActive || owndocumentsActive.isActive || SchoolSharedDocActive.isActive),'collapsed':!(unusedfilesActive.isActive || filemanagementActive.isActive || owndocumentsActive.isActive || SchoolSharedDocActive.isActive) }">
  <i class="fa fa-folder-open"></i> <span class="nav-label">Files</span>
  <span class="fa fa-chevron-left float-right"></span>
  <ul class="sub-menu collapse" id="filesDropdown" data-bs-target="#filesDropdown"
    [ngClass]="{'show': unusedfilesActive.isActive || filemanagementActive.isActive || owndocumentsActive.isActive || SchoolSharedDocActive.isActive}">
    <li class="active"><a routerLink="/unusedfiles/" routerLinkActive="active"
        #unusedfilesActive="routerLinkActive">Unused Files - {{unusedfilesActive.isActive ||
        filemanagementActive.isActive || owndocumentsActive.isActive}}</a></li>
    <li><a routerLink="/file-management/" routerLinkActive="active" #filemanagementActive="routerLinkActive">File
        Management</a></li>
    <li><a routerLink="/own-documents/" routerLinkActive="active" #owndocumentsActive="routerLinkActive">Own
        Documents</a></li>
    <li><a routerLink="/school-shared-doc/" routerLinkActive="active" #SchoolSharedDocActive="routerLinkActive">School
        Shared Doc</a></li>
  </ul>
</li>

<li routerLink="/qrcode/" routerLinkActive="active"> <fa-icon [icon]="qrcodeIcon"></fa-icon> <span
    class="nav-label">QRCode Lisr</span></li>