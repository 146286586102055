<div class="dashboardInnerPage inner-page-outer flex-column">
    <div class="title w-100">
        <span (click)="goBack()" title="Back" data-bs-toggle="tooltip" data-bs-placement="bottom"
            class="bi bi-arrow-left"></span>
        <h3>Chat</h3>
        <a class="add_button" routerLink="#" (click)="addNewRequest(0)">Add New
            Request</a>
    </div>
    <div class="custom-table">
        <!-- <pre>{{ filteredrowData | json }}</pre> Debugging purpose -->

        <table class="table table-striped">
            <thead>
                <tr>
                    <th>User Name</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Created On</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody *ngIf="chatsRowData.length === 0">
                <tr>
                    <td colspan="5">No data available</td>
                </tr>
            </tbody>
            <tbody *ngIf="chatsRowData.length > 0">
                <tr *ngFor="let data of chatsRowData">
                    <td>{{data.UserName}}</td>
                    <td>{{data.Name}}</td>
                    <td>{{data.Description}}</td>
                    <td>{{data.CreatedOn}}</td>

                    <td>
                        <div class="table-action">
                            <span data-bs-toggle="tooltip" data-bs-placement="bottom" class=" bi bi-eye"
                                title="View Chat" (click)="openChatModal(data.MessageMasterId)"></span>

                            <span data-bs-toggle="tooltip" data-bs-placement="bottom" class="bi bi-trash3"
                                title="Delete"></span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- <div class="row">
        <div class="col-12">
            <div style="width: calc(100%);" class="bg-white shadow p-2 mb-0 mt-3 mb-3">
                <ag-grid-angular #agGrid style="width: 100%; height:calc(100vh - 150px);min-height: 200px;"
                    class="ag-theme-alpine" id="chatGrid" [gridOptions]="chatGridOptions" [columnDefs]="chatColumnDefs"
                    [defaultColDef]="chatDefaultColDef" [rowSelection]="chatRowSelection" [rowData]="chatsRowData"
                    (gridReady)="onChatReady($event)" [frameworkComponents]="frameworkComponents"
                    (gridReload)="onChatReload($event)"></ag-grid-angular>
            </div>
        </div>
    </div> -->
</div>