<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1">Student Offer</h3>
        </div>
        <form novalidate #form="ngForm" autocomplete="off">
            <div class="breackout_body p-2">
                <div>
                    <div class="row form-row m-0">
                        <div class="col-6 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>Discount</label>
                                <input class="form-control form-control-sm " type="text" placeholder="Discount"
                                    name="Discount" #Discount="ngModel" [(ngModel)]="service.stdDiscountInfo.Discount"
                                    required class.invalid="Discount.invalid && Discount.touched" />
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div class="modal-footer p-1">
                <button type="button" (click)="addOfferInfo()" class="btn btn-success" type="button">Save</button>
                <button type="button" class="btn btn-danger" (click)="modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL)"
                    aria-label="Close">Close</button>
            </div>
        </form>
    </div>
</div>