<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1">Term</h3>
        </div>
        <form novalidate #form="ngForm" autocomplete="off">
            <div class="breackout_body p-2">
                <div>
                    <div class="row form-row m-0">
                        <div class="col-6 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>Name</label>
                                <input class="form-control form-control-sm " type="text" placeholder="Name" name="Name"
                                    #Name="ngModel" [(ngModel)]="service.termView.TermInfo.Name" required
                                    class.invalid="Name.invalid && Name.touched" />
                            </div>
                        </div>


                        <div class="col-6 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>{{'StartDate' | translate}}</label>
                                <input type="text" class="form-control form-control-sm"
                                    placeholder="{{'StartDate' | translate}}" name="StartDate" #StartDate="ngModel"
                                    [(ngModel)]="service.termView.TermInfo.StartDate" [bsConfig]="datepickerConfig"
                                    bsDatepicker timepicker>
                            </div>

                        </div>
                        <div class="col-6 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>{{'EndDate' | translate}}</label>
                                <input type="text" class="form-control form-control-sm"
                                    placeholder="{{'EndDate' | translate}}" name="EndDate" #EndDate="ngModel"
                                    [(ngModel)]="service.termView.TermInfo.EndDate" [bsConfig]="datepickerConfig"
                                    bsDatepicker timepicker>
                            </div>

                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>Academic year</label>
                                <select class="form-control form-control-sm appearance-auto" name="AcademicYearId"
                                    #AcademicYearId="ngModel" [(ngModel)]="service.termView.TermInfo.AcademicYearId">
                                    <option [value]="0">Select Academic year</option>
                                    <option *ngFor="let year of yearList" [value]="year.Id">{{year.Name}}</option>
                                </select>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer p-1">
                <button type="button" (click)="addTermInfo()" class="btn btn-success" type="button">Save</button>
                <button type="button" class="btn btn-danger" (click)="modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL)"
                    aria-label="Close">Close</button>
            </div>
        </form>
    </div>
</div>