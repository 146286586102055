<div class="dashboardInnerPage inner-page-outer flex-column">
    <div class="title w-100">
        <span (click)="goBack()" title="Back" data-bs-toggle="tooltip" data-bs-placement="bottom"
            class="bi bi-arrow-left"></span>
        <h3>Assessments</h3>
        <!-- <a class="add_button" routerLink="#" (click)="openquestionmodal(0)">Add Assignments</a> -->
    </div>

    <mat-tab-group animationDuration="1000ms" (selectedTabChange)="activeTabChange($event)">
        <mat-tab label="Assessments">
            <ng-template mat-tab-label>
                <label>Assessments</label>
            </ng-template>
            <div class="assingment-box-outer">
                <div class="assingment-heading">
                    <h3>Assessments</h3>
                </div>

                <div *ngFor="let assessment of assessmentData" class="class-col-outer w-100 mb-3">
                    <div class="class-date-time">
                        <span class="bi bi-calendar-week"> {{assessment.StartDate | date:'d MMMM, y'}}</span>
                        <span class="bi bi-calendar-week"> {{assessment.EndDate | date:'d MMMM, y'}}</span>
                    </div>
                    <div class="classes-col">
                        <div class="class-icon">{{getIcon(assessment.Name)}}</div>
                        <div class="class-data">
                            <h3>{{assessment.Name}}</h3>
                            <span>{{assessment.Description}}</span>
                            <span>Start Time: {{assessment.StartTime | date:'h:mm a'}} ~ End Time: {{assessment.EndTime
                                | date:'h:mm a'}}</span>
                            <div class="calling-btn action-links">
                                <span class="start_btn" data-bs-toggle="tooltip" data-bs-placement="bottom"
                                    title="Start Assessments" *ngIf="assessment.Status==='New'"
                                    (click)="startAssessment(assessment.QuestionMasterId)">Start</span>

                                <span class="start_btn expired_btn" data-bs-toggle="tooltip" data-bs-placement="bottom"
                                    title="Expired Assessments" *ngIf="assessment.Status==='Expired'"
                                    (click)="startExam(assessment.QuestionMasterId)">Expired</span>
                                <span class="start_btn completed_btn" data-bs-toggle="tooltip"
                                    data-bs-placement="bottom" title="Completed Assessments"
                                    *ngIf="assessment.Status==='Completed'"
                                    (click)="startExam(assessment.QuestionMasterId)">Completed</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </mat-tab>
        <mat-tab label="Feedback">
            <ng-template mat-tab-label>
                <div class="m-0">
                    <div class="d-flex align-items-center">
                        <label class="m-0">Feedback</label>

                    </div>
                </div>
            </ng-template>
            <div class="rounded mt-4">
                <div class="tab-content">
                    <div class="assesments">

                        <div class="row">
                            <div class="col-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 pb-4 ng-star-inserted"
                                *ngFor="let feedback of feedbackList">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="card-title d-flex justify-content-between">
                                            <h3 class="m-0">{{feedback.CreatedByName}}</h3>
                                            <span>{{feedback.CreatedOn}}</span>
                                        </div>
                                        <div class="card-body p-0">
                                            <p>{{feedback.Feedback}}</p>
                                            <span class="card-btn cursor-pointer"
                                                (click)="openReadmoreModel(feedback)">Read More<i
                                                    class="fa fa-solid fa-angle-right ml-2"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </mat-tab>

    </mat-tab-group>