import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { CommonService } from "../shared/common.service";
import { FeedbackData } from "../shared/feedback.model";
import { FeedbackService } from "../shared/feedback.service";
import { NotificationService } from "../shared/notification.service";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "app-edit-feedback",
  templateUrl: "./edit-feedback.component.html",
  styleUrls: ["./edit-feedback.component.scss"],
  standalone: true,
  imports: [FormsModule, TranslateModule],
})
export class EditFeedbackComponent implements OnInit {
  @Output() gridReload = new EventEmitter();
  list: any[] = [];

  constructor(
    public feedbackServ: FeedbackService,
    public bsModalRef: BsModalRef,
    private notificationServ: NotificationService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.feedbackServ.formData = new FeedbackData();
    this.feedbackServ.getfeedback(this.list[1].config).subscribe((res: any) => {
      this.feedbackServ.formData = res;
    });
  }
  addFeedbackInfo(form) {
    this.feedbackServ.formData.UserId = this.list[0].id;
    this.feedbackServ.formData.IsActive = true;
    this.feedbackServ.savefeedback().subscribe((res: any) => {
      if (res.IsSaved) {
        this.notificationServ.ShowInfo("Successfully saved!");
        this.commonService.CallOnRefreshGrid();
        this.bsModalRef.hide();
      } else {
        this.notificationServ.ShowError(res.ErrorMessage);
      }
    });
  }
}
