import { Component, OnInit, TemplateRef } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { UsereditComponent } from "./useredit.component";
import { UserService } from "src/app/shared/user.service";
import { CUSTOMCONST } from "src/app/shared/Const";
import { User } from "../shared/user.model";
import { CustommenuComponent } from "../custommenu/custommenu.component";
// import { GridOption } from 'ag-grid-angular';
import { CustomHeader } from "../custommenu/custom-header.component";

//import 'rxjs/add/operator/take';
import { CommonService } from "../shared/common.service";
import { formatDate, NgFor } from "@angular/common";
import { SearchUserSharedIdComponent } from "./search-user-shared-id.component";
import { AgGridModule } from "ag-grid-angular";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
  standalone: true,
  imports: [FormsModule, NgFor, AgGridModule],
})
export class UserComponent {
  gridApi;
  gridColumnApi;
  frameworkComponents;
  columnDefs;
  defaultColDef;
  rowSelection;
  rowData: User[];
  bsModalRef: BsModalRef;
  modalRef: BsModalRef;
  gridOptions: any;

  selectedYear: string;
  startDate: string;
  endDate: string;
  yearList: any;
  config = {
    params: {
      startDate: "",
      endDate: "",
    },
  };
  constructor(
    public service: UserService,
    private modalService: BsModalService,
    public commonServ: CommonService
  ) {
    var header_template =
      '<span class="text-danger" style="height:30px;">Some Value </span>';
    this.columnDefs = [
      {
        headerName: "Action",
        field: "UserId",
        filter: false,
        cellRendererFramework: CustommenuComponent,
        cellRendererParams: {
          MenuList: [
            { Name: "Edit", Click: this.MenuEditClick, that: this },
            { Name: "Delete", Click: this.deleteUser, that: this },
          ],
        },
        headerComponent: "customHeader",
        headerComponentParams: {
          button: "click",
          enableMenu: true,
          menuIcon: "fa-plus",
          enableSorting: false,
        },
      },
      {
        field: "FullName",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
      },
      {
        field: "Address",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        maxWidth: 120,
        minWidth: 120,
      },
      {
        field: "Street",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        maxWidth: 120,
        minWidth: 120,
      },
      {
        field: "City",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        maxWidth: 120,
        minWidth: 120,
      },
      {
        field: "State",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        maxWidth: 120,
        minWidth: 120,
      },
      {
        field: "PostalNo",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        maxWidth: 120,
        minWidth: 120,
      },
      {
        field: "Mobile",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        maxWidth: 120,
        minWidth: 120,
      },
      {
        field: "SMobile",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        maxWidth: 120,
        minWidth: 120,
      },
      {
        field: "Role",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        maxWidth: 120,
        minWidth: 120,
      },
      {
        field: "Branch",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        maxWidth: 120,
        minWidth: 120,
      },
      {
        field: "Email",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 220,
      },
    ];
    this.frameworkComponents = {
      customHeader: CustomHeader,
    };
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      floatingFilter: true,
    };
    this.rowSelection = "single";
    // this.gridOptions = {
    //   context:{
    //     componentParent:this
    //   }
    // }

    this.commonServ.RefreshGrid$.subscribe((item) => {
      this.getUserList();
      // setTimeout(() => {
      //   this.service.getUserList().subscribe((res : User[]) => {
      //     this.rowData =  res;
      //   });
      // }, 2000);
    });
  }

  ngOnInit(): void {
    this.commonServ.getMlListInfo("MLAcademicYear").subscribe((res: any) => {
      res = res.slice().reverse();
      this.yearList = res;
      this.selectedYear = res[0].Code;
      this.config = {
        params: {
          startDate: res[0].Code,
          endDate:
            "30-04-" +
            (parseInt(formatDate(new Date(res[0].Code), "yyyy", "en")) + 1),
        },
      };
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    setTimeout(() => {
      this.getUserList();
    }, 300);
  }
  getUserList() {
    this.service.getUserListByParams(this.config).subscribe((res: any) => {
      const rowData = res;
      this.gridApi.setRowData(rowData);
    });
  }
  deleteUser(UserId, that) {}
  MenuEditClick(UserId, that) {
    const initialState = {
      list: [{ id: UserId }],
    };
    that.bsModalRef = that.modalService.show(UsereditComponent, {
      id: CUSTOMCONST.USER_EDIT_MODAL,
      initialState,
      class: "modal-dialog modal-dialog-centered",
      ignoreBackdropClick: true,
    });
    that.bsModalRef.content.saved.take(1).subscribe(that.UserSaved.bind(that));
  }
  UserSaved(someData: any) {
    this.getUserList();
  }

  /*
  openusermodal(userid) {
    const initialState = {
      list: [
        {id:userid}
      ]
    };
    this.bsModalRef = this.modalService.show(UsereditComponent,{initialState, class: 'modal-dialog modal-dialog-centered'});
    this.bsModalRef.content.event.subscribe(res => {
    });

  }
  */
  onSelectionChanged($event) {
    var selectedRows = this.gridApi.getSelectedRows();
  }
  changeYear() {
    this.config = {
      params: {
        startDate: this.selectedYear,
        endDate:
          "30-04-" +
          (parseInt(formatDate(new Date(this.selectedYear), "yyyy", "en")) + 1),
      },
    };
  }
  serchStudent() {
    this.getUserList();
  }
  openusermodal() {
    const initialState = {
      list: [{ id: 0 }],
    };
    this.bsModalRef = this.modalService.show(SearchUserSharedIdComponent, {
      id: CUSTOMCONST.USER_EDIT_MODAL,
      initialState,
      class: "modal-dialog modal-dialog-centered",
      ignoreBackdropClick: true,
    });
  }
}
