import { formatDate, NgClass, NgIf, NgFor, DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Assignemnt } from "../shared/assignemnt.model";
import { AssignemntService } from "../shared/assignemnt.service";
import { NotificationService } from "../shared/notification.service";
import { ViewdocumentComponent } from "./viewdocument.component";
import { CUSTOMCONST } from "../shared/Const";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { map } from "rxjs";
import { TooltipPosition, MatTooltipModule } from "@angular/material/tooltip";
import { ThinkComponent } from "./think.component";
import { MatButtonModule } from "@angular/material/button";
import { MatTabsModule } from "@angular/material/tabs";
@Component({
  selector: "app-student-assignments",
  templateUrl: "./student-assignments.component.html",
  styleUrls: ["./student-assignments.component.scss"],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    MatTabsModule,
    NgFor,
    MatButtonModule,
    MatTooltipModule,
    DatePipe,
  ],
})
export class StudentAssignmentsComponent implements OnInit {
  assignmentList: Assignemnt[];
  watchList: any[] = [];
  listenList: any[] = [];
  readList: any[] = [];
  doList: any[] = [];
  writeList: any[] = [];
  homeWorkList: any[] = [];
  date: any;
  positionOptions: TooltipPosition[] = [
    "after",
    "before",
    "above",
    "below",
    "left",
    "right",
  ];
  position = "below";
  accAssignmentCount: number;
  selAssignmentCount: number;
  lifesAssignmentCount: number;
  outBoxAssignmentCount: number;

  assignmentListByDate: Assignemnt[];
  activeTab: string = "Academics";
  activeLearningType: string = "SchoolBL";
  bsModalRef: BsModalRef;
  isExplore: boolean = false;
  constructor(
    public assignedService: AssignemntService,
    private notificationServ: NotificationService,
    public modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.date = new Date();
    this.getassignments();
  }

  toTime(dateString, timeString) {
    // let timeTokens = timeString.split(':');
    // debugger;
    // return new Date(1970,0,1, timeTokens[0], timeTokens[1]);
    return new Date(dateString + " " + timeString);
  }
  getassignments() {
    this.activeTab = "WatchAssingment";
    // debugger;
    // var strDate = this.date.length == 10 ? this.date : formatDate(new Date(), 'dd-MM-yyyy', 'en');
    let strDate = formatDate(new Date(), "dd-MM-yyyy", "en");
    // debugger;
    this.assignedService
      .getassignedassignmentsbydate(strDate)
      .pipe(
        map((res: any, index: number) => {
          let data = [];
          res.forEach((item) => {
            item.StartTime = this.toTime(
              item.StartDate.split("-").reverse().join(","),
              item.StartTime
            );
            item.EndTime = this.toTime(
              item.EndDate.split("-").reverse().join(","),
              item.EndTime
            );
            item.StartDate = new Date(
              item.StartDate.split("-").reverse().join("-")
            );
            // item.IsWhatch = false;
            data.push(item);
          });
          return data;
        })
      )
      .subscribe((res: any) => {
        // let pendingAssignment:any[] ;
        // console.log("getassignments");
        // console.log(res);

        // this.accAssignmentCount = res.filter(item => item.IsAcademics == true).length;
        // this.selAssignmentCount = res.filter(item => item.IsHumanityLife == true).length;
        // this.lifesAssignmentCount = res.filter(item => item.IsSkills == true).length;
        // this.outBoxAssignmentCount = res.filter(item => item.IsLearnOutOfBox == true).length;
        if (this.activeLearningType === "SchoolBL") {
          // pendingAssignment = res.filter(item => (item.Status == 'New' && item.IsAcademics == true))

          this.assignmentListByDate = res.filter(
            (item) => item.IsAcademics == true
          );
        } else if (this.activeLearningType === "HomeBL") {
          // pendingAssignment = res.filter(item => (item.Status == 'New' && item.IsHumanityLife == true))

          this.assignmentListByDate = res.filter(
            (item) => item.IsHumanityLife == true
          );
        } else if (this.activeLearningType === "CommunityBL") {
          // pendingAssignment = res.filter(item => (item.Status == 'New' && item.IsSkills == true))

          this.assignmentListByDate = res.filter(
            (item) => item.IsSkills == true
          );
        } else if (this.activeLearningType === "SocietyBL") {
          // pendingAssignment = res.filter(item => (item.Status == 'New' && item.IsLearnOutOfBox == true))

          this.assignmentListByDate = res.filter(
            (item) => item.IsLearnOutOfBox == true
          );
        }

        this.watchList = this.assignmentListByDate.filter(
          (item) => item.Type == "Watch"
        );
        this.listenList = this.assignmentListByDate.filter(
          (item) => item.Type == "Listen"
        );
        this.readList = this.assignmentListByDate.filter(
          (item) => item.Type == "Read"
        );
        this.doList = this.assignmentListByDate.filter(
          (item) => item.Type == "Do"
        );
        this.homeWorkList = this.assignmentListByDate.filter(
          (item) => item.Type == "Home work"
        );

        // if (pendingAssignment && pendingAssignment.length > 0) {
        //   if (pendingAssignment.length == 1) {
        //     this.notificationServ.ShowWarning('You have ' + pendingAssignment.length.toString() + ' pending assignment');
        //   }
        //   else {
        //     this.notificationServ.ShowWarning('You have ' + pendingAssignment.length.toString() + ' pending assignments');
        //   }
        // }
      });
  }

  activeTabChange(event) {
    this.activeTab = event.tab.textLabel;

    if (this.activeTab == "WatchAssingment") {
      this.watchList = this.assignmentListByDate.filter(
        (item) => item.Type == "Watch"
      );
    } else if (this.activeTab == "ListenAssingments") {
      this.listenList = this.assignmentListByDate.filter(
        (item) => item.Type == "Listen"
      );
    } else if (this.activeTab == "ReadAssingments") {
      this.readList = this.assignmentListByDate.filter(
        (item) => item.Type == "Read"
      );
    } else if (this.activeTab == "WriteAssingments") {
      this.writeList = this.assignmentListByDate.filter(
        (item) => item.Type == "Write"
      );
    } else if (this.activeTab == "DoAssingments") {
      this.doList = this.assignmentListByDate.filter(
        (item) => item.Type == "Do"
      );
    } else if (this.activeTab == "Homework") {
      this.homeWorkList = this.assignmentListByDate.filter(
        (item) => item.Type == "Home work"
      );
    }
  }
  changeLearningType(type: string) {
    this.activeLearningType = type;
    this.getassignments();
  }
  savestudentassignmentstartInfo(data) {
    const payload = {
      AssignmentInfo: {
        AssignmentId: data.AssignmentId,
        TypeId: 1,
        Type: data.Type,
        Name: data.Name,
        Description: "",
        StartDate: data.StartDate,
        StartTime: data.StartTime,
        EndDate: data.EndDate,
        EndTime: data.EndTime,
        IsActive: false,
        Url: data.Url,
        QuestionMasterId: data.QuestionMasterId,
        ExplorDescription: "",
        DiscussionPoints: "",
        FileId: data.FileId,
        FileInfo: null,
        IsQuestionir: true,
        UrlType: data.UrlType,
        ClassIdList: null,
        Status: "Expired",
        ResponseFileId: 0,
        Result: null,
        Comments: null,
        IsHumanityLife: false,
        IsSkills: true,
        IsAcademics: true,
        IsLearnOutOfBox: false,
        ResponseFileIds: null,
        ClassNames: null,
        AcademicYearId: data.AcademicYearId,
        TotalDuration: 0.0,
        CompletedTime: 0.0,
        IsWatch: false,
        Duration: 0,
      },
      ErrorMessage: "",
      IsSaved: false,
    };
    this.assignedService
      .savestudentassignmentstartInfo(payload)
      .subscribe((res: any) => {});
  }
  openModel(data: any) {
    // var sd = data.StartDate.split('-');
    // var ed = data.EndDate.split('-');
    // var examStartTime = new Date(sd[1] + '-' + sd[0] + '-' + sd[2] + ' ' + data.StartTime);
    // var examEndTime = new Date(ed[1] + '-' + ed[0] + '-' + ed[2] + ' ' + data.EndTime);
    if (data.Type == "Watch") {
      this.watchList.forEach((item, index) => {
        if (item.AssignmentId === data.AssignmentId) {
          this.watchList[index].IsWhatch = true;
        }
      });
    }
    if (data.Type == "Listen") {
      this.listenList.forEach((item, index) => {
        if (item.AssignmentId === data.AssignmentId) {
          this.listenList[index].IsWhatch = true;
        }
      });
    }
    if (data.Type == "Read") {
      this.readList.forEach((item, index) => {
        if (item.AssignmentId === data.AssignmentId) {
          this.readList[index].IsWhatch = true;
        }
      });
    }
    if (data.Type == "Do") {
      this.doList.forEach((item, index) => {
        if (item.AssignmentId === data.AssignmentId) {
          this.doList[index].IsWhatch = true;
        }
      });
    }
    if (data.Type == "Home work") {
      this.homeWorkList.forEach((item, index) => {
        if (item.AssignmentId === data.AssignmentId) {
          this.homeWorkList[index].IsWhatch = true;
        }
      });
    }
    this.date = new Date();
    this.date = formatDate(new Date(), "MM-dd-yyyy HH:mm a", "en", "+0530");
    var currentTime = new Date(); //new Date(this.date);
    if (currentTime <= data.StartTime) {
      this.notificationServ.ShowError(
        "Assignment will start at " + data.StartDate + " " + data.StartTime
      );
    } else {
      const initialState = {
        list: [{ data: data }],
      };
      this.bsModalRef = this.modalService.show(ViewdocumentComponent, {
        id: CUSTOMCONST.STDASSIGNMENT_MODAL,
        initialState,
        class: "modal-dialog modal-dialog-centered",
        ignoreBackdropClick: true,
      });
    }
    this.savestudentassignmentstartInfo(data);
  }

  openThinkModel(data: any) {
    console.log(
      "here",
      data,
      currentTime <= data.StartTime,
      CUSTOMCONST.STDASSIGNMENT_MODAL
    );

    this.date = new Date();
    this.date = formatDate(new Date(), "MM-dd-yyyy HH:mm a", "en", "+0530");
    var currentTime = new Date(); //new Date(this.date);
    if (currentTime <= data.StartTime) {
      this.notificationServ.ShowError(
        "Assignment will start at " + data.StartDate + " " + data.StartTime
      );
    } else {
      const initialState = {
        list: [{ data: data }],
      };
      this.bsModalRef = this.modalService.show(ThinkComponent, {
        id: CUSTOMCONST.STDASSIGNMENT_MODAL,
        initialState,
        class: "modal-dialog modal-dialog-centered",
        ignoreBackdropClick: true,
      });
    }
  }
  updateIsExplore() {
    this.isExplore = this.isExplore ? false : true;
  }
}
