import { Component, ElementRef, ViewChild } from '@angular/core';
import { AnswerListComponentComponent } from './../answer-list-component/answer-list-component.component';
import { AnswerModalComponentComponent } from './../answer-modal-component/answer-modal-component.component';
import { CommonModule, Location } from '@angular/common';
import { ForumService } from 'src/app/shared/forum.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-question-details-component',
  standalone: true,
  imports: [CommonModule,AnswerListComponentComponent,AnswerModalComponentComponent],
  templateUrl: './question-details-component.component.html',
  styleUrls: ['./question-details-component.component.scss']
})
export class QuestionDetailsComponentComponent {
  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
  question: any;
  public bsModalRef: BsModalRef;
  isShowAnswer: boolean=false;
  questionId:number
  reloadAnswer: number = 0;
  constructor(
    private location: Location,
    public service:ForumService,
    private modalService: BsModalService,
    private _route: ActivatedRoute,
 ) {}
  ngOnInit(): void {
   
    // this.question = {
    //   subject: "test subject",
    //   video: "https://hybridlearning.in/95/File/192d6206-4099-4678-a0b1-ea191bad1081recording.webm",
    //   userName: "test",
    //   questionId: "1"
    // };
  // }
  // ngOnChanges(){
    this.getQuestioDetails()
  }
  getQuestioDetails(){
    this.questionId = parseInt(this._route.snapshot.paramMap.get('id'));
    this.service.getQuestion(this.questionId).subscribe(res=>{
      this.question=  res["QuestionInfo"]
    })
  }
  getAnswers(): void{
    this.reloadAnswer = this.reloadAnswer+1
  }
  goBack(): void {
    this.location.back(); // Navigates back to the previous route
  }
  addAnswer() {
    this.isShowAnswer=!this.isShowAnswer
  }
    
}
