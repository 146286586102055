import { Component, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";
import { EventService } from "src/app/shared/event.service";
import { CommonModule } from "@angular/common";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { CommonService } from "src/app/shared/common.service";

declare const getActiveParticipants: any;
declare const sendMessage: any;

@Component({
  selector: "app-split-room",
  standalone: true,
  imports: [TranslateModule, FormsModule, CommonModule, MatCheckboxModule],
  templateUrl: "./split-room.component.html",
  styleUrl: "./split-room.component.scss",
})
export class SplitRoomComponent implements OnInit {
  list: any[] = [];
  activeList: any[] = [];
  tGroups: number = 2;
  selectedGroup: any;
  selectedItem: any;
  selectedList: any[] = [];
  constructor(
    public service: EventService,
    public bsModalRef: BsModalRef,
    private commonService: CommonService
  ) {}
  ngOnInit(): void {
    const msg = JSON.stringify({
      type: "creatingGroup",
    });
    this.activeList = getActiveParticipants();
    sendMessage(msg);
    this.list = this.list.map((v) => ({ ...v, groupId: "" }));
  }
  get isGroupSelected(): boolean {
    return (
      typeof this.selectedGroup === "undefined" || this.selectedGroup === "0"
    );
  }
  range(): number[] {
    var n = this.tGroups;
    return Array.from({ length: n }, (_, i) => i + 1);
  }
  update(act: any, id: any) {
    console.log(this.tGroups);

    this.list = this.list.map((itm) => {
      if (act && itm.Id === id) {
        itm.groupId = this.selectedGroup;
      } else if (!act && itm.Id === id) {
        itm.groupId = "";
      }
      return { ...itm };
    });
  }
  isActive(item: any) {
    var idx = this.activeList.findIndex(itm=>item.Id===itm)
    // console.log(item, typeof this.selectedGroup === "undefined",this.selectedGroup === "0",idx == -1);
    
    return (
      typeof this.selectedGroup === "undefined" ||
      this.selectedGroup === "0" ||
      idx == -1
    );
    // return false;
  }
  submit() {
    const roomids = [];
    for (var i = 1; i <= this.tGroups; i++) {
      roomids[i] = this.commonService.generateRandomString(10);
    }
    const filteredList = this.list
      .filter((item) => item.groupId && item.groupId.trim() !== "")
      .map(({ Id, groupId }) => {
        var roomId = roomids[groupId];
        return { UserId: Id, groupId: `group_${groupId}`, roomId };
      });
    const msg = JSON.stringify({
      type: "splitGroupList",
      list: filteredList,
    });
    sendMessage(msg);
    this.bsModalRef.hide();
  }
  closeModal(){
    const msg = JSON.stringify({
      type: "splitGroupList",
      list: [],
    });
    sendMessage(msg);
    this.bsModalRef.hide();
  }
}
