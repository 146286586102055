<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title h3 m-1">{{'StudentAnswerList' | translate}}</h3>
        <span class="bi bi-x" (click)="bsModalRef.hide()" title="Close Module" data-bs-toggle="tooltip"></span>
    </div>
    <div class="breackout_body">
        <div class="field-row filter-form" id="collapseExample">
            <div class="select_field">
                <label>Class Name</label>
                <input type="text" class="field-control" name="ClassName" (keyup)="filter($event)" />
            </div>
            <div class="select_field">
                <label>Name</label>
                <input type="text" class="field-control" name="Name" (keyup)="filter($event)" />
            </div>
            <div class="select_field">
                <label>Status</label>
                <input type="text" class="field-control" name="Status" (keyup)="filter($event)" />
            </div>
        </div>

        <div class="custom-table">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Class Name</th>
                        <th>Name</th>
                        <th>No. Of Quections</th>
                        <th>No. Of Correct Answer</th>
                        <th>Total Time</th>
                        <th>Total Marks</th>
                        <th>Scored</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody *ngIf="filteredrowData.length === 0">
                    <tr>
                        <td colspan="8">No data available</td>
                    </tr>
                </tbody>
                <tbody *ngIf="filteredrowData.length > 0">
                    <tr *ngFor="let data of filteredrowData">
                        <td>{{data.ClassName}}</td>
                        <td (click)="viewAtudentProfile(data.UserId, this)">{{data.Name}}</td>
                        <td>{{data.NoOfQuections}}</td>
                        <td>{{data.NoOfCorrectAnswers}}</td>
                        <td>{{data.TotalTime}}</td>
                        <td>{{data.TotalPoints}}</td>
                        <td>{{data.Points}}</td>
                        <td><span class="assignmentStatus" (click)="answerPreview(data.StdQuestionMasterId, this)"
                                [ngClass]="{'completed': data.Status == 'Completed'}">{{data.Status}}</span>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- <section>
            <div class="row justify-content-c m-0  bg-white p-2">
                <div class="col-md-12 p-0">
                    <ag-grid-angular #agGrid style="width: 100%; height:calc(100vh - 192px)" class="ag-theme-alpine"
                        id="answerList" [gridOptions]="answerListOptions" [columnDefs]="columnDefs"
                        [defaultColDef]="defaultColDef" [rowSelection]="rowSelection" [rowData]="filteredrowData"
                        (cellValueChanged)="onCellValueChanged($event)" (gridReady)="onGridReady($event)">
                    </ag-grid-angular>
                </div>
            </div>
        </section> -->

        <div class="modal-footer">

            <button type="button" class="btn btn-danger" (click)="bsModalRef.hide()" aria-label="Close">Close</button>
        </div>
    </div>

</div>