<div class="modal-content">

    <div class="modal-header">
        <h3 class="modal-title h3 m-1">{{editimageof === 'profilePic'?'Upload Profile Pic':'Upload Background Cover'}}
        </h3>
        <span class="bi bi-x" (click)="modalService.hide(CUSTOMCONST.LABEL_TWO_MODAL)" title="Close Module"
            data-bs-toggle="tooltip"></span>
    </div>
    <form novalidate #form="ngForm" (submit)="onSave(form)">
        <div class="breackout_body">

            <div class="field-row">

                <div class="select_field" *ngIf="editimageof === 'profilePic'">
                    <label for="">Upload Profile Pic</label>
                    <input type="file" class="field-control" (change)="fileChangeEvent($event)" />
                    <div class="row" style="margin-top: 15px;">
                        <div class="text-center col-md-8 w-100">
                            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                                [aspectRatio]="4 / 4" [resizeToWidth]="256" format="png"
                                (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)"
                                (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"
                                [roundCropper]=true></image-cropper>
                        </div>
                        <div class="text-center col-md-4">
                            <img [src]="croppedImage" class="img-circle" />
                        </div>
                    </div>
                </div>

                <div class="select_field" *ngIf="editimageof === 'coverImage'">
                    <label for="">Upload Background Cover</label>
                    <input type="file" class="field-control" (change)="fileChangeEvent($event)" />
                    <div class="row" style="margin-top: 15px;">
                        <div class="text-center col-md-8 w-100">
                            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                                [aspectRatio]="10 / 3.75" [resizeToWidth]="256" format="png"
                                (imageCropped)="coverImageCropped($event)" (imageLoaded)="coverImageLoaded($event)"
                                (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"
                                [roundCropper]=false></image-cropper>
                        </div>
                        <div class="text-center col-md-4">
                            <img [src]="croppedImage" class="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button type="submit" class="custom-primary-btn">Save</button>
                <button type="button" class="btn btn-danger" (click)="modalService.hide(CUSTOMCONST.LABEL_TWO_MODAL)"
                    aria-label="Close">Close</button>
            </div>
        </div>
    </form>
</div>