<!-- <div class="container-fluid bg-light">
    <div class="row">
        <div class="col-12">
           <div class="mt-3 mb-3">
            <div class="modal-header p-1 bg-theam">
                <h3 class="modal-title h3 m-1" >Student files</h3>
              </div>
              
            
           </div>
        </div>
    </div>
</div>
  -->





<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1">User Log History</h3>
        </div>
        <div class="breackout_body p-0 model-max-window-h">
            <section class="pt-0">
                <div class="row justify-content-c m-0  bg-white shadow p-2">
                    <div class="col-md-12 p-0">
                        <form novalidate #form="ngForm" (submit)="onSearch(form)" method="POST">
                            <div class="row border m-0 py-2 mb-2 justify-content-between">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                                    <div class="form-group mb-0">
                                        <label>Start Date</label>
                                        <input type="text" class="form-control form-control-sm" placeholder="Start Date"
                                            name="StartDate" #StartDate="ngModel" [(ngModel)]="startDate"
                                            [bsConfig]="datepickerConfig" bsDatepicker [bsValue]="bsValue" required
                                            class.invalid="StartDate.invalid && StartDate.touched" autocomplete="off">
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                                    <div class="form-group mb-0">
                                        <label>End Date</label>
                                        <input type="text" class="form-control form-control-sm" placeholder="End Date"
                                            name="EndDate" #EndDate="ngModel" [(ngModel)]="endDate"
                                            [bsConfig]="datepickerConfig" bsDatepicker [bsValue]="bsValue" required
                                            class.invalid="EndDate.invalid && EndDate.touched" autocomplete="off">
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                                    <div class="form-group mb-0">
                                        <label>&nbsp;</label>
                                        <div class="form-control form-control-sm p-0 border-0">
                                            <button class="btn btn-warning btn-sm float-right" type="submit"
                                                [disabled]="form.invalid">Search</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </form>
                        <ag-grid-angular #agGrid style="width: 100%; height:calc(100vh - 263px);min-height: 200px;"
                            class="ag-theme-alpine" id="fileList" [ensureDomOrder]="true"
                            [enableCellTextSelection]="true" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
                            [rowSelection]="rowSelection" [rowData]="logListData" (gridReady)="onGridReady($event)">
                        </ag-grid-angular>
                    </div>
                </div>
            </section>
        </div>
        <div class="modal-footer p-1">
            <button type="button" class="btn btn-danger" (click)="modalService.hide(CUSTOMCONST.LABEL_TWO_MODAL)"
                aria-label="Close">Close</button>
        </div>
    </div>
</div>