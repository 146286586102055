<div class="modal-content">
  <div class="modal-header">
    <h3 class="modal-title h3 m-1" *ngIf="service.formData.UserInfo.UserId > 0">{{'UserEdit' | translate}}</h3>
    <h3 class="modal-title h3 m-1" *ngIf="service.formData.UserInfo.UserId <= 0">Add User</h3>
    <span class="bi bi-x" (click)="modalService.hide(CUSTOMCONST.USER_EDIT_MODAL)" title="Close Module" data-bs-toggle="tooltip"></span>
  </div>
  <!-- <div class="modal-header bg-theam p-1">
    <h3 class="modal-title h3 m-1" *ngIf="service.formData.UserInfo.UserId > 0">{{'UserEdit' | translate}}</h3>
    <h3 class="modal-title h3 m-1" *ngIf="service.formData.UserInfo.UserId <= 0">Add User</h3>
  </div> -->
  <div class="breackout_body">
    <form novalidate #form="ngForm" (submit)="onSave(form)" autocomplete="off">

      <div class="field-row">
        <div class="select_field profile_outer">

          <div class="cover-image">
            <img *ngIf="service.formData?.UserInfo?.ProfileFileInfo?.CroppedContentString"
              [src]="service.formData.UserInfo.ProfileFileInfo.CroppedContentString" alt=""
              class="profile_background_cover">

            <img *ngIf="!service.formData?.UserInfo?.ProfileFileInfo?.CroppedContentString"
              [src]="service.formData?.UserInfo?.CroppedProfileFilepath" alt="" class="profile_background_cover">

            <span class="cover_img_icon bi bi-pencil-square" data-bs-toggle="tooltip" title="Upload Cover Image"
              (click)="editCoverImage()"></span>
          </div>

          <div class="profile_image">
            <img *ngIf="service.formData?.UserInfo?.FileInfo?.CroppedContentString"
              [src]="service.formData.UserInfo.FileInfo.CroppedContentString" alt="">

            <img *ngIf="!service.formData?.UserInfo?.FileInfo?.CroppedContentString"
              [src]="service.formData?.UserInfo?.CroppedFilepath" alt="">

            <span class="pr_img_edit_icon bi bi-pencil" data-bs-toggle="tooltip" title="Edit Profile Image"
              (click)="editPic()"></span>
          </div>

        </div>

        <div class="select_field">
          <div class="field-row" *ngIf="service.formData.UserInfo.UserId <= 0">
            <div class="select_field">
              <label>{{'UserId' | translate}}</label>
              <input class="field-control" placeholder="{{'UserId' | translate}}" name="UserLogin" #UserLogin="ngModel"
                [(ngModel)]="service.formData.UserInfo.UserLogin">
            </div>

            <div class="select_field">
              <label>{{'Password' | translate}}</label>
              <input class="field-control" type="password" placeholder="********" name="Password" #Password="ngModel"
                [(ngModel)]="service.formData.UserInfo.Password" required
                class.invalid="Password.invalid && Password.touched">
            </div>
          </div>
          <div class="field-row">
            <div class="select_field">
              <label>{{'School' | translate}}</label>
              <select class="field-control appearance-auto" name="SchoolId" #SchoolId="ngModel"
                [(ngModel)]="service.formData.UserInfo.SchoolId" [disabled]="true">
                <option [value]="0">Select School</option>
                <option *ngFor="let school of schoollist" [value]="school.Id">{{school.Name}}</option>
              </select>
            </div>
            <div class="select_field">
              <label>{{'Branch' | translate}}</label>
              <select class="field-control appearance-auto" name="BranchId" #BranchId="ngModel"
                [(ngModel)]="service.formData.UserInfo.BranchId" [disabled]="true">
                <option [value]="0">Select Branch</option>
                <option *ngFor="let branch of branchlist" [value]="branch.Id">{{branch.Name}}</option>
              </select>
            </div>
          </div>
          <div class="field-row">
            <div class="select_field">
              <label>{{'EnrollmentId' | translate}}</label>
              <input class="field-control" type="text" placeholder="{{'EnrollmentId' | translate}}" name="EnrollmentId"
                #EnrollmentId="ngModel" [(ngModel)]="service.formData.UserInfo.EnrollmentId" required minlength="8"
                class.invalid="EnrollmentId.invalid && EnrollmentId.touched" [disabled]="true">
            </div>
            <div class="select_field" *ngIf="service.formData.UserInfo.UserId > 0">
              <label>{{'UserId' | translate}} - {{service.formData.UserInfo.UserId}}</label>
              <input class="field-control" placeholder="{{'UserId' | translate}}" name="UserLogin" #UserLogin="ngModel"
                [(ngModel)]="service.formData.UserInfo.UserLogin" [readonly]="service.formData.UserInfo.UserId > 0"
                [disabled]="true">
            </div>
          </div>
        </div>
      </div>
      <div class="field-row">
        <div class="select_field">
          <label>{{'FirstName' | translate}}</label>
          <input class="field-control" placeholder="{{'FirstName' | translate}}" name="FirstName" #FirstName="ngModel"
            [(ngModel)]="service.formData.UserInfo.FirstName" required
            class.invalid="FirstName.invalid && FirstName.touched" [disabled]="true">
        </div>
        <div class="select_field">
          <label>{{'MidName' | translate}}</label>
          <input class="field-control" placeholder="{{'MiddleName' | translate}}" name="MiddleName"
            #MiddleName="ngModel" [(ngModel)]="service.formData.UserInfo.MiddleName" required
            class.invalid="MiddleName.invalid && MiddleName.touched" [disabled]="true">
        </div>
        <div class="select_field">
          <label>{{'Surname' | translate}}</label>
          <input class="field-control" placeholder="{{'Surname' | translate}}" name="Surname" #Surname="ngModel"
            [(ngModel)]="service.formData.UserInfo.Surname" required class.invalid="Surname.invalid && Surname.touched"
            [disabled]="true">
        </div>
      </div>
      <div class="field-row">

        <div class="select_field">
          <label>{{'Gender' | translate}}</label>
          <select class="field-control appearance-auto" name="GenderId" #GenderId="ngModel"
            [(ngModel)]="service.formData.UserInfo.GenderId" [disabled]="true">
            <option [value]="0">Select Gender</option>
            <option *ngFor="let gender of genderlist" [value]="gender.Id">{{gender.Name}}</option>
          </select>
        </div>
        <div class="select_field">
          <label>{{'DateOfBirth' | translate}}</label>

          <input type="text" class="field-control" placeholder="{{'DateOfBirth' | translate}}" name="DateOfBirth"
            #DateOfBirth="ngModel" [(ngModel)]="service.formData.UserInfo.DateOfBirth" [bsConfig]="datepickerConfig"
            bsDatepicker [disabled]="true">

        </div>
        <div class="select_field">
          <label>{{'Role' | translate}}</label>
          <select class="field-control appearance-auto" name="RoleId" #RoleId="ngModel"
            [(ngModel)]="service.formData.UserInfo.RoleId" [disabled]="true">
            <option [value]="0">Select Role</option>
            <option *ngFor="let role of rolelist" [value]="role.Id">{{role.Name}}</option>
          </select>
        </div>
      </div>
      <div class="field-row">
        <div class="select_field">
          <label>{{'Mobile' | translate}}</label>
          <input class="field-control appearance-none" type="number" placeholder="{{'Mobile' | translate}}"
            name="Mobile" #Mobile="ngModel" [(ngModel)]="service.formData.UserInfo.Mobile" required minlength="8"
            class.invalid="Mobile.invalid && Mobile.touched">
        </div>
        <div class="select_field">
          <label>{{'SMobile' | translate}}</label>
          <input class="field-control appearance-none" type="number" placeholder="{{'SMobile' | translate}}"
            name="SMobile" #SMobile="ngModel" [(ngModel)]="service.formData.UserInfo.SMobile">
        </div>
        <div class="select_field">
          <label>{{'Email' | translate}}</label>
          <input class="field-control" type="email" placeholder="{{'Email' | translate}}" name="Email" #Email="ngModel"
            [(ngModel)]="service.formData.UserInfo.Email" required class.invalid="Email.invalid && Email.touched">
        </div>
      </div>
      <div class="field-row">
        <div class="select_field">
          <label>{{'Address' | translate}}</label>
          <input class="field-control" type="text" placeholder="{{'Address' | translate}}" name="Address"
            #Address="ngModel" [(ngModel)]="service.formData.UserInfo.Address" [disabled]="true" required
            class.invalid="Address.invalid && Address.touched" [disabled]="true">
        </div>
      </div>
      <div class="field-row">
        <div class="select_field">
          <label>{{'Street' | translate}}</label>
          <input class="field-control" type="text" placeholder="{{'Street' | translate}}" name="Street"
            #Street="ngModel" [(ngModel)]="service.formData.UserInfo.Street" [disabled]="true">
        </div>

        <div class="select_field">
          <label>{{'PostalNo' | translate}}</label>
          <input class="field-control" type="text" placeholder="{{'PostalNo' | translate}}" name="PostalNo"
            #PostalNo="ngModel" [(ngModel)]="service.formData.UserInfo.PostalNo" required [disabled]="true"
            class.invalid="PostalNo.invalid && PostalNo.touched">
        </div>
        <div class="select_field">
          <label>{{'City' | translate}}</label>
          <input class="field-control" type="text" placeholder="{{'City' | translate}}" name="City" #City="ngModel"
            [(ngModel)]="service.formData.UserInfo.City" required [disabled]="true"
            class.invalid="City.invalid && City.touched">
        </div>
        <div class="select_field">
          <label>{{'State' | translate}} - {{role}}</label>
          <input class="field-control" type="text" placeholder="{{'State' | translate}}" name="State" #State="ngModel"
            [(ngModel)]="service.formData.UserInfo.State" required [disabled]="true"
            class.invalid="State.invalid && State.touched">
        </div>
      </div>


      <div class="field-row" *ngIf=" role == 'Parent'">
        <div class="select_field">
          <!-- <label>{{'Academics' | translate}}</label> -->
          <mat-checkbox class="example-margin" name="IsAcademics" #IsAcademics="ngModel"
            [(ngModel)]="service.formData.ParentInfo.IsAcademics">{{'Academics' | translate}}</mat-checkbox>
        </div>
        <div class="select_field">
          <!-- <label>{{'HumanityLife' | translate}}</label> -->
          <mat-checkbox class="example-margin" name="IsHumanityLife" #IsHumanityLife="ngModel"
            [(ngModel)]="service.formData.ParentInfo.IsHumanityLife">{{'HumanityLife' | translate}}</mat-checkbox>

        </div>
        <div class="select_field">
          <!-- <label>{{'Skills' | translate}}</label> -->
          <mat-checkbox class="example-margin" name="IsSkills" #IsSkills="ngModel"
            [(ngModel)]="service.formData.ParentInfo.IsSkills">{{'Skills' | translate}}</mat-checkbox>
        </div>

        <div class="select_field">
          <!-- <label>{{'LearnOutOfBox' | translate}}</label> -->
          <mat-checkbox class="example-margin" name="IsLearnOutOfBox" #IsLearnOutOfBox="ngModel"
            [(ngModel)]="service.formData.ParentInfo.IsLearnOutOfBox">{{'LearnOutOfBox' |
            translate}}</mat-checkbox>
        </div>
      </div>
      <div class="field-row" *ngIf=" role == 'Parent'">
        <!-- <div class="select_field">
          <label>{{'Children' | translate}}</label>
          <ng-multiselect-dropdown [placeholder]="'Search'" [settings]="dropdownSettings" name="ChildrenList"
            [data]="childList" [(ngModel)]="service.formData.ParentInfo.ChildrenList"
            (onSelect)="onItemSelect($event,'Child')" (onDeSelect)="onItemSelect($event,'Child')">
          </ng-multiselect-dropdown>

        </div> -->

        <div class="select_field">
          <label>{{'ExpiryDate' | translate}}</label>

          <input type="text" class="field-control" placeholder="{{'ExpiryDate' | translate}}" name="ExpiryDate"
            #ExpiryDate="ngModel" [(ngModel)]="service.formData.ParentInfo.ExpiryDate" [bsConfig]="datepickerConfig"
            bsDatepicker>

        </div>

      </div>

      <div class="field-row">
        <div class="select_field">
          <label>{{'About' | translate}} {{role}} <span
              class="d-inline-block float-right">{{currentLength}}/{{maxLength}}</span></label>
          <textarea class="field-control appearance-auto resizebele-false m-0" name="About" #About="ngModel"
            [(ngModel)]="service.formData.UserInfo.About" rows="5" maxlength="2000"
            (keyup)="lenghtCounter()"></textarea>
        </div>
      </div>

    </form>

    <div class="modal-footer">
      <!-- <button type="button" class="btn btn-primary" (click)="userLogHistory()" *ngIf="this.role==='Parent'||this.role==='Student'">User Log</button> -->
      <button type="button" class="custom-primary-btn"
        (click)="userAcademicYearLogInfo(service.formData.UserInfo.UserId)">Academic Year Log</button>
      <button type="button" class="custom-primary-btn" (click)="resetPassword(service.formData.UserInfo.UserId)">Reset
        Password</button>
      <button type="button" class="custom-primary-btn" (click)="onSave(form)">Save</button>
      <button type="button" class="btn btn-danger" (click)="modalService.hide(CUSTOMCONST.USER_EDIT_MODAL)"
        aria-label="Close">Close</button>
    </div>
  </div>
</div>