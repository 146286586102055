<!-- <p>help works!</p> -->


<div class="modal-dialog  modal-xl m-0">
  <div class="modal-content">
    <div class="modal-header bg-theam p-1">
      <h3 class="modal-title h3 m-1">Student Fee Info</h3>
    </div>
    <div class="breackout_body p-2">
      <div>
        <table class="border-left border-right border-secondary">
          <thead>
            <tr class="border-bottom border-top border-secondary">
              <th class="border-right border-secondary">Name</th>
              <th class="border-right border-secondary">Description</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr class="border-bottom border-secondary" *ngFor="let feeinfo of feeInfo">
              <td class="border-right border-secondary">{{feeinfo.Text}}</td>
              <td class="border-right border-secondary">{{feeinfo.Description}}</td>
              <td>{{feeinfo.Amount}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal-footer p-1">
      <button type="button" class="btn btn-success" aria-label="Close">Pay Now</button>
      <button type="button" class="btn btn-danger" (click)="modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL)"
        aria-label="Close">Cancel</button>
    </div>
  </div>
</div>