import { Component, ViewChild, AfterViewInit, ElementRef } from '@angular/core';

// import { CanvasWhiteboardModule } from 'ng2-canvas-whiteboard';
import { FormsModule } from "@angular/forms";
declare const initCanvasDesigner: any;

@Component({
  selector: 'app-drawing-tool',
  standalone: true,
  imports: [FormsModule,],
  templateUrl: './drawing-tool.component.html',
  styleUrl: './drawing-tool.component.scss'
})
export class DrawingToolComponent {
  ngOnInit() {
    initCanvasDesigner("canvas-designer")
  }
}
// implements AfterViewInit {
//   @ViewChild('canvas') canvasRef!: ElementRef<HTMLCanvasElement>;
//   @ViewChild('videoStream') videoRef!: ElementRef<HTMLVideoElement>;

//   private ctx!: CanvasRenderingContext2D;
//   private isDrawing = false;
//   private startX = 0;
//   private startY = 0;

//   shapes: any[] = [];
//   selectedColor = '#00FF00';
//   lineWidth = 5;
//   selectedShape = 'line';

//   ngAfterViewInit(): void {
//     this.ctx = this.canvasRef.nativeElement.getContext('2d')!;
//     this.initCanvasEvents();

//     // Stream the canvas to the video element
//     const stream = this.canvasRef.nativeElement.captureStream(30); // 30 FPS
//     this.videoRef.nativeElement.srcObject = stream;
//     this.videoRef.nativeElement.play();
//   }

//   // Initialize canvas event listeners
//   private initCanvasEvents(): void {
//     const canvas = this.canvasRef.nativeElement;

//     canvas.onmousedown = (e) => this.startDrawing(e);
//     canvas.onmousemove = (e) => this.draw(e);
//     canvas.onmouseup = () => this.stopDrawing();
//   }

//   // Start drawing
//   startDrawing(event: MouseEvent): void {
//     this.isDrawing = true;
//     this.startX = event.offsetX;
//     this.startY = event.offsetY;

//     this.ctx.strokeStyle = this.selectedColor;
//     this.ctx.lineWidth = this.lineWidth;
//     this.ctx.beginPath();

//     if (this.selectedShape === 'pencil' || this.selectedShape === 'brush') {
//       this.ctx.moveTo(this.startX, this.startY);
//     }
//   }

//   // Drawing on canvas
//   draw(event: MouseEvent): void {
//     if (!this.isDrawing) return;

//     const currentX = event.offsetX;
//     const currentY = event.offsetY;

//     this.clearCanvas();

//     if (this.selectedShape === 'line') {
//       this.drawLine(this.startX, this.startY, currentX, currentY);
//     } else if (this.selectedShape === 'rectangle') {
//       this.drawRect(this.startX, this.startY, currentX - this.startX, currentY - this.startY);
//     } else if (this.selectedShape === 'circle') {
//       this.drawCircle(this.startX, this.startY, currentX, currentY);
//     } else if (this.selectedShape === 'pencil' || this.selectedShape === 'brush') {
//       this.ctx.lineTo(currentX, currentY);
//       this.ctx.stroke();
//     } else if (this.selectedShape === 'eraser') {
//       this.ctx.strokeStyle = 'white'; // Eraser color
//       this.ctx.lineWidth = 20; // Eraser size
//       this.ctx.lineTo(currentX, currentY);
//       this.ctx.stroke();
//     }
//   }

//   // Stop drawing
//   stopDrawing(): void {
//     this.isDrawing = false;
//     this.ctx.closePath();

//     if (this.selectedShape !== 'pencil' && this.selectedShape !== 'brush' && this.selectedShape !== 'eraser') {
//       this.shapes.push({
//         shape: this.selectedShape,
//         startX: this.startX,
//         startY: this.startY,
//         endX: this.startX,
//         endY: this.startY,
//         color: this.selectedColor,
//         lineWidth: this.lineWidth,
//       });
//     }

//     this.clearCanvas();
//     this.drawShapes(); // Redraw all shapes
//   }

//   // Draw the shapes stored in the shapes array
//   drawShapes(): void {
//     this.shapes.forEach((shape) => {
//       this.ctx.strokeStyle = shape.color;
//       this.ctx.lineWidth = shape.lineWidth;
//       this.ctx.beginPath();

//       if (shape.shape === 'line') {
//         this.drawLine(shape.startX, shape.startY, shape.endX, shape.endY);
//       } else if (shape.shape === 'rectangle') {
//         this.drawRect(shape.startX, shape.startY, shape.endX - shape.startX, shape.endY - shape.startY);
//       } else if (shape.shape === 'circle') {
//         this.drawCircle(shape.startX, shape.startY, shape.endX, shape.endY);
//       }

//       this.ctx.closePath();
//       this.ctx.stroke();
//     });
//   }

//   // Clear the canvas
//   clearCanvas(): void {
//     this.ctx.clearRect(0, 0, this.canvasRef.nativeElement.width, this.canvasRef.nativeElement.height);
//   }

//   // Draw line
//   private drawLine(x1: number, y1: number, x2: number, y2: number): void {
//     this.ctx.moveTo(x1, y1);
//     this.ctx.lineTo(x2, y2);
//   }

//   // Draw rectangle
//   private drawRect(x: number, y: number, width: number, height: number): void {
//     this.ctx.rect(x, y, width, height);
//   }

//   // Draw circle
//   private drawCircle(x1: number, y1: number, x2: number, y2: number): void {
//     const radius = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
//     this.ctx.arc(x1, y1, radius, 0, Math.PI * 2);
//   }

//   // Undo the last shape
//   undo(): void {
//     this.shapes.pop();
//     this.clearCanvas();
//     this.drawShapes();
//   }
// }

