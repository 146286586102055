<!-- <ul class="list-sidebar bg-defoult clear-both mt-3"> -->
<li routerLink="/dashboard/" routerLinkActive="active">
  <i class="bi bi-home"></i>
  <span>Dashboard</span>
</li>
<li data-bs-toggle="collapse" data-bs-target="#configDropdown"
  [ngClass]="{'active': (ILActive.isActive || GDActive.isActive || teachersActive.isActive || StudentsActive.isActive || classesActive.isActive  || subjectsActive.isActive || TTActive.isActive || SDActive.isActive ),'collapsed':!(ILActive.isActive || GDActive.isActive || teachersActive.isActive || StudentsActive.isActive || classesActive.isActive  || subjectsActive.isActive || TTActive.isActive || SDActive.isActive ) }">
  <i class="fa fa-cogs"></i>
  <span>Configurations</span>
  <!-- <span class="fa fa-chevron-left"></span> -->

  <ul class="sub-menu collapse" id="configDropdown"
    [ngClass]="{'show': (ILActive.isActive || GDActive.isActive || teachersActive.isActive || StudentsActive.isActive || classesActive.isActive  || subjectsActive.isActive || TTActive.isActive || SDActive.isActive ) }">
    <li class="active"><a routerLink="/institute-logo/" routerLinkActive="active" #ILActive="routerLinkActive">Institute
        Logo</a></li>
    <li><a routerLink="/general-details/" routerLinkActive="active" #GDActive="routerLinkActive">General Details</a>
    </li>
    <li><a routerLink="/teacher-list/" routerLinkActive="active" #teachersActive="routerLinkActive">Teacher
        Profiles</a></li>
    <li><a routerLink="/student-list/" routerLinkActive="active" #StudentsActive="routerLinkActive">Student
        Profiles</a></li>
    <li><a routerLink="/class-list/" routerLinkActive="active" #classesActive="routerLinkActive">Classes</a></li>
    <li><a routerLink="/subject-list/" routerLinkActive="active" #subjectsActive="routerLinkActive">Subjects</a></li>
    <li><a routerLink="/time-tables/" routerLinkActive="active" #TTActive="routerLinkActive">Timetable</a></li>
    <li><a routerLink="/getdairy/" routerLinkActive="active" #SDActive="routerLinkActive">Student Diary </a></li>
    <li><a routerLink="/school-seting/" routerLinkActive="active" #schoolsetingActive="routerLinkActive">School
        Seting</a></li>
  </ul>
</li>
<li data-bs-toggle="collapse" data-bs-target="#paymentsDropdown"
  [ngClass]="{'active': (termsActive.isActive || paymentsActive.isActive ),'collapsed':!(termsActive.isActive || paymentsActive.isActive ) }">
  <i class=""></i>
  <span>Fee Management</span>


  <ul class="sub-menu collapse" id="paymentsDropdown"
    [ngClass]="{'show': termsActive.isActive || paymentsActive.isActive }">
    <li class="active"><a routerLink="/terms/" routerLinkActive="active" #termsActive="routerLinkActive">Fees
        Configuration</a></li>
    <li><a routerLink="/payments/" routerLinkActive="active" #paymentsActive="routerLinkActive">Fee Payments</a></li>
  </ul>
</li>
<li routerLink="/event/" routerLinkActive="active">
  <fa-icon [icon]="liveClassIcon"></fa-icon>
  <span>Interactive Live Classes</span>
</li>
<li routerLink="/assignment/" routerLinkActive="active"> <fa-icon [icon]="tasksIcon"></fa-icon> <span>Assignments</span>
</li>
<li routerLink="/question/" routerLinkActive="active"> <fa-icon [icon]="tasksIcon"></fa-icon> <span>Assessments</span>
</li>
<li routerLink="/survey/" routerLinkActive="active"><fa-icon [icon]="serveyIcon"></fa-icon> <span>Survey</span></li>
<li routerLink="/analysis/" routerLinkActive="active"><fa-icon [icon]="analyticsIcon"></fa-icon> <span>Analysis</span>
</li>

<li routerLink="/qrcode/" routerLinkActive="active"><fa-icon [icon]="qrcodeIcon"></fa-icon> <span>QRCode Lisr</span>
</li>
<li routerLink="/badge-list/" routerLinkActive="active"> <span>Badge</span></li>

<!-- <li>
  <a href="#" data-bs-toggle="collapse" data-bs-target="#functionsDropdown" [ngClass]="{'active': (questionActive.isActive || analysisActive.isActive || surveyActive.isActive || assignmentActive.isActive || eventActive.isActive ),'collapsed':!(questionActive.isActive || analysisActive.isActive || surveyActive.isActive || assignmentActive.isActive || eventActive.isActive ) }">
    <i class="fa fa-sliders-h"></i> <span class="nav-label">Notifications</span> 
  </a>
  <ul class="sub-menu collapse" id="functionsDropdown" [ngClass]="{'show': (questionActive.isActive || analysisActive.isActive || surveyActive.isActive || assignmentActive.isActive || eventActive.isActive ) }">
    <li class="active"><a routerLink="/question/" routerLinkActive="active" #questionActive="routerLinkActive">Assessments</a></li>
    <li><a routerLink="/analysis/" routerLinkActive="active" #analysisActive="routerLinkActive">Analysis</a></li>
    <li><a routerLink="/survey/" routerLinkActive="active" #surveyActive="routerLinkActive">Survey</a></li>
    <li><a routerLink="/assignment/" routerLinkActive="active" #assignmentActive="routerLinkActive">Assignments</a></li>
    <li><a  routerLink="/event/" routerLinkActive="active" #eventActive="routerLinkActive">Interactive Live Classes</a></li>
  </ul>
  </li> -->
<li routerLink="/user/" routerLinkActive="active"><i class="fa fa-users"></i> <span>User</span></li>
<li routerLink="/chat/" routerLinkActive="active"> <i class="fa fa-comments"></i> <span>Chat</span></li>
<li data-bs-toggle="collapse" data-bs-target="#notificationsDropdown"
  [ngClass]="{'active': (NTCActive.isActive || NSCActive.isActive || NPCActive.isActive  ),'collapsed':!(NTCActive.isActive || NSCActive.isActive || NPCActive.isActive  ) }">
  <i class="fa fa-bell"></i> <span>Notifications</span>
  <ul class="sub-menu collapse" id="notificationsDropdown"
    [ngClass]="{'show': (NTCActive.isActive || NSCActive.isActive || NPCActive.isActive) }">
    <li class="active"><a routerLink="/NTCirculars/" routerLinkActive="active" #NTCActive="routerLinkActive">Teachers
        Circulars</a></li>
    <li><a routerLink="/NSCirculars/" routerLinkActive="active" #NSCActive="routerLinkActive">Students Circulars</a>
    </li>
    <li><a routerLink="/NPCirculars/" routerLinkActive="active" #NPCActive="routerLinkActive">Parents Circulars</a>
    </li>
  </ul>
</li>
<li data-bs-toggle="collapse" data-bs-target="#ECircularDropdown"
  [ngClass]="{'active': (ECTCActive.isActive || ECSCActive.isActive || ECPCActive.isActive  ),'collapsed':!(ECTCActive.isActive || ECSCActive.isActive || ECPCActive.isActive  ) }">
  <i class="fa fa-circle"></i> <span>E-Circular</span>
  <ul class="sub-menu collapse" id="ECircularDropdown"
    [ngClass]="{'show': (ECTCActive.isActive || ECSCActive.isActive || ECPCActive.isActive) }">
    <li class="active"><a routerLink="/ECTCirculars/" routerLinkActive="active" #ECTCActive="routerLinkActive">Teachers
        Circulars</a></li>
    <li><a routerLink="/ECSCirculars/" routerLinkActive="active" #ECSCActive="routerLinkActive">Students Circulars</a>
    </li>
    <li><a routerLink="/ECPCirculars/" routerLinkActive="active" #ECPCActive="routerLinkActive">Parents Circulars</a>
    </li>
  </ul>
</li>
<li data-bs-toggle="collapse" data-bs-target="#LMDropdown"
  [ngClass]="{'active': (SLeavesActive.isActive || TLeavesActive.isActive),'collapsed':!(SLeavesActive.isActive || TLeavesActive.isActive) }">
  <i class="fa fa-outdent"></i> <span>Leave Management</span>
  <ul class="sub-menu collapse" id="LMDropdown"
    [ngClass]="{'show': (SLeavesActive.isActive || TLeavesActive.isActive) }">
    <li class="active"><a routerLink="/getteacher-leave/" routerLinkActive="active"
        #TLeavesActive="routerLinkActive">Teachers Leaves</a></li>
    <li><a routerLink="/getstudent-leave/" routerLinkActive="active" #SLeavesActive="routerLinkActive">Students
        Leaves</a></li>
  </ul>
</li>
<li data-bs-toggle="collapse" data-bs-target="#ReportsDropdown"
  [ngClass]="{'active': (SReportActive.isActive || TReportActive.isActive || CReportActive.isActive || MAReportActive.isActive),'collapsed':!(SReportActive.isActive || TReportActive.isActive || CReportActive.isActive || MAReportActive.isActive) }">

  <i class="fa fa-sticky-note"></i> <span>Reports</span>

  <ul class="sub-menu collapse" id="ReportsDropdown"
    [ngClass]="{'show': (SReportActive.isActive || TReportActive.isActive || CReportActive.isActive || MAReportActive.isActive) }">
    <li class="active"><a routerLink="/teacher-report/" routerLinkActive="active"
        #TReportActive="routerLinkActive">Teachers</a></li>
    <li><a routerLink="/student-report/" routerLinkActive="active" #SReportActive="routerLinkActive">Students</a></li>
    <li><a routerLink="/class-report/" routerLinkActive="active" #CReportActive="routerLinkActive">class </a></li>
    <li><a routerLink="/monthly-attendance-report/" routerLinkActive="active" #MAReportActive="routerLinkActive">Monthly
        Attendance</a></li>
  </ul>
</li>
<li data-bs-toggle="collapse" data-bs-target="#SLTrackingDropdown"
  [ngClass]="{'active': (SAttendanceActive.isActive || SAssignmentsActive.isActive || SAssessmentsActive.isActive || SProgressCardActive.isActive || SNotificationActive.isActive || SAnnouncementsActive.isActive || SFeeActive.isActive || SPRActive.isActive),'collapsed':!(SAttendanceActive.isActive || SAssignmentsActive.isActive || SAssessmentsActive.isActive || SProgressCardActive.isActive || SNotificationActive.isActive || SAnnouncementsActive.isActive || SFeeActive.isActive || SPRActive.isActive) }">

  <fa-icon [icon]="trackingIcon"></fa-icon> <span>Student Level Tracking</span>
  <ul class="sub-menu collapse" id="SLTrackingDropdown"
    [ngClass]="{'show': (SAttendanceActive.isActive || SAssignmentsActive.isActive || SAssessmentsActive.isActive || SProgressCardActive.isActive || SNotificationActive.isActive || SAnnouncementsActive.isActive || SFeeActive.isActive || SPRActive.isActive) }">
    <li class="active"><a routerLink="/student-attendance/" routerLinkActive="active"
        #SAttendanceActive="routerLinkActive">Attendance</a></li>
    <li><a routerLink="/student-assignments/" routerLinkActive="active"
        #SAssignmentsActive="routerLinkActive">Assignments</a></li>
    <li><a routerLink="/student-assessments/" routerLinkActive="active"
        #SAssessmentsActive="routerLinkActive">Assessments </a></li>
    <li><a routerLink="/student-progress-card/" routerLinkActive="active"
        #SProgressCardActive="routerLinkActive">Progress card</a></li>
    <li><a routerLink="/student-notification/" routerLinkActive="active"
        #SNotificationActive="routerLinkActive">Notification</a></li>
    <li><a routerLink="/student-announcements/" routerLinkActive="active"
        #SAnnouncementsActive="routerLinkActive">Announcements</a></li>
    <li><a routerLink="/student-fee/" routerLinkActive="active" #SFeeActive="routerLinkActive">Fee</a></li>
    <li><a routerLink="/student-payment-receipts/" routerLinkActive="active" #SPRActive="routerLinkActive">Payment
        Receipts</a></li>
  </ul>
</li>
<li data-bs-toggle="collapse" data-bs-target="#BillingDropdown"
  [ngClass]="{'active': (UsageActive.isActive || StorageActive.isActive || NOStudentsActive.isActive || InvoiceActive.isActive),'collapsed':!(UsageActive.isActive || StorageActive.isActive || NOStudentsActive.isActive || InvoiceActive.isActive) }">
  <fa-icon [icon]="billingIcon"></fa-icon> <span>Billing</span>
  <ul class="sub-menu collapse" id="BillingDropdown"
    [ngClass]="{'show': (UsageActive.isActive || StorageActive.isActive || NOStudentsActive.isActive || InvoiceActive.isActive) }">
    <li><a routerLink="/usege/" routerLinkActive="active" #UsageActive="routerLinkActive">Usage Metrics</a></li>
    <li><a routerLink="/storage/" routerLinkActive="active" #StorageActive="routerLinkActive">Storage</a></li>
    <li><a routerLink="/number-of-student/" routerLinkActive="active" #NOStudentsActive="routerLinkActive">Number of
        Students </a></li>
    <li><a routerLink="/invoice/" routerLinkActive="active" #InvoiceActive="routerLinkActive">Bills and Invoice</a>
    </li>
  </ul>
</li>
<li routerLink="/attendence/" routerLinkActive="active"><i class="fa fa-clock"></i> <span>Attendance</span></li>


<li data-bs-toggle="collapse" data-bs-target="#filesDropdown"
  [ngClass]="{'active': (unusedfilesActive.isActive || filemanagementActive.isActive || owndocumentsActive.isActive|| parentsharedocActive.isActive),'collapsed':!(unusedfilesActive.isActive || filemanagementActive.isActive || owndocumentsActive.isActive|| parentsharedocActive.isActive) }">
  <i class="fa fa-folder-open"></i> <span>Files</span>
  <ul class="sub-menu collapse" id="filesDropdown" data-target="#filesDropdown"
    [ngClass]="{'show': unusedfilesActive.isActive || filemanagementActive.isActive || owndocumentsActive.isActive || parentsharedocActive.isActive}">
    <li class="active"><a routerLink="/unusedfiles/" routerLinkActive="active"
        #unusedfilesActive="routerLinkActive">Unused Files - {{unusedfilesActive.isActive ||
        filemanagementActive.isActive || owndocumentsActive.isActive || parentsharedocActive.isActive}}</a></li>
    <li><a routerLink="/file-management/" routerLinkActive="active" #filemanagementActive="routerLinkActive">File
        Management</a></li>
    <li><a routerLink="/own-documents/" routerLinkActive="active" #owndocumentsActive="routerLinkActive">Own
        Documents</a></li>
    <li><a routerLink="/parent-share-doc/" routerLinkActive="active" #parentsharedocActive="routerLinkActive">Parent
        Share Doc</a></li>

  </ul>
</li>
<!-- </ul> -->