import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { EventService } from 'src/app/shared/event.service';

declare const getActiveParticipants: any;


@Component({
  selector: 'app-student-list-2',
  standalone: true,
  imports: [ CommonModule],
  templateUrl: './student-list.component.html',
  styleUrl: './student-list.component.scss'
})
export class StudentListComponent2 {
  activeList: any[] = [];
  StudentList: any[] = [];

  constructor(
    public eventService: EventService,
  ) {}
  ngOnInit() {
    this.getStudentList();
  }
  getStudentList() {
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    this.eventService
      .getStudentByEventId(eventData.EventId)
      .subscribe((res: any) => {
        // console.log(res.StudentList);
        const StudentList = res.StudentList;
        localStorage.setItem("StudentList", JSON.stringify(StudentList));
        this.StudentList = StudentList;
      });
  }
  isActive(item: any) {
    this.getStudentList();
    this.activeList = getActiveParticipants();
    var idx = this.activeList.findIndex(itm=>item.Id===itm)
    // console.log(idx,item,this.activeList);
    
    return (
      idx == -1
    );
    // return false;
  }
}
