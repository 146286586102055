import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ForumViewData,Forum,ForumActionData } from './forum.model';
import CONFIG from './Const';

@Injectable({
  providedIn: 'root'
})
export class ForumService {

  constructor(private http:HttpClient) { 
    this.formData.ForumInfo = new Forum();
  }
  readonly baseUrl = CONFIG.BASE_URL+'api/Forum';
  formData:ForumViewData = new ForumViewData();

 
  getQuestions(config){
    return this.http.get(this.baseUrl+'/getForumQuestionList',config)  
  }
  getQuestion(questionId){
    const params = new HttpParams().set('questionId', questionId);
    return this.http.get(this.baseUrl+'/getForumQuestionInfo',{params})  
  }
  postQuestion(actionData:any,subject:string){
    const params = new HttpParams().set('subject', subject);
    return this.http.post(this.baseUrl+'/uploadForumQuestionInfo',actionData,{params});
  }
  getAnswers(questionId){
    const params = new HttpParams().set('questionId', questionId);
    return this.http.get(this.baseUrl+'/getForumAnswerList',{params})  
  }
  getAnswer(){
    return this.http.get(this.baseUrl+'/getattendancelist')  
  }
  postAnswer(actionData:any,questionId:string){
    const params = new HttpParams().set('questionId', questionId);
    return this.http.post(this.baseUrl+'/uploadForumAnswerInfo',actionData,{params});
  }

}
