<!-- For demo purpose -->

<div class="dashboardInnerPage">

    <div class="teacherDashboardData">
        <div class="dashboard-col student-dashboard-colOuter">
            <div class="student-dashboard-col">
                <div class="title">
                    <h3>Self-Assessment <span (click)="openHelpTooltip('Score')"
                            class="bi bi-exclamation-circle-fill"></span>
                    </h3>
                </div>

                <div class="scores_outer">
                    <div class="scores_col">
                        <div class="scores_icon">
                            <span>{{daypoints}}</span>
                        </div>
                        <h3>Daily Score</h3>
                    </div>
                    <div class="scores_col">
                        <div class="scores_icon">
                            <span>{{weekpoints}}</span>
                        </div>
                        <h3>Weekly Score</h3>
                    </div>
                    <div class="scores_col">
                        <div class="scores_icon">
                            <span>{{monthpoints}}</span>
                        </div>
                        <h3>Monthly Score</h3>
                    </div>
                    <div class="scores_col">
                        <div class="scores_icon">
                            <span>{{points}}</span>
                        </div>
                        <h3>Total Score</h3>
                    </div>
                </div>
            </div>
            <div class="student-dashboard-col">
                <div class="title">
                    <h3>Learning Time <span (click)="openHelpTooltip('LearningTime')"
                            class="bi bi-exclamation-circle-fill"></span>
                    </h3>
                </div>

                <div #gradeCharGraph *ngIf="isShowLearningTime">

                    <div class="student_chart" *ngIf="studentsByGradeCharGraph?.length > 0">

                        <google-chart [title]="studentsByGradeCharGraphTitle" [type]="studentsByGradeCharGraphType"
                            [data]="studentsByGradeCharGraph" [columns]="studentsByGradeCharGraphCol"
                            [options]="studentsByGradeCharGraphOption" [width]="graphWidth" [height]="graphHeight">
                        </google-chart>


                    </div>
                </div>
                <div class="student_chart" *ngIf="!isShowLearningTime">
                    <img src="../../../assets/images/default_images/learning_time.PNG" alt="">
                </div>
            </div>

            <mat-tab-group animationDuration="1000ms" (selectedTabChange)="activeTabChange($event)"
                [selectedIndex]="tabIndex" class="w-100">

                <mat-tab label="LiveClass">
                    <ng-template mat-tab-label>
                        <label>Live Class <span (click)="openHelpTooltip('SBAssignment')"
                                class="ms-2 bi bi-exclamation-circle-fill"></span></label>
                    </ng-template>
                    <div class="assingment-box-outer">
                        <div class="assingment-heading">
                            <h3>Today Live Classes</h3>
                        </div>
                        <div class="teacher-class mb-3">
                            <div *ngFor="let todayClass of todayliveClass" class="class-col-outer">
                                <div class="class-date-time">
                                    <span class="bi bi-calendar-week"> {{todayClass.EventDate}}</span>
                                    <span class="bi bi-clock"> {{todayClass.StartTimeISO | customDate:'shortTime'}} ~
                                        {{todayClass.EndTimeISO | customDate:'shortTime'}}</span>
                                </div>
                                <div class="classes-col">
                                    <div class="class-icon">{{getIcon(todayClass.Description)}}</div>
                                    <div class="class-data">
                                        <h3>{{todayClass.Description | titlecase }}</h3>
                                        <span>Class By {{todayClass.UserName}}</span>
                                        <div class="calling-btn action-links">
                                            <span class="bi bi-camera-video" data-bs-toggle="tooltip"
                                                data-bs-placement="bottom" title="Join Call"
                                                (click)="startClass(todayClass)"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="class-col-outer w-100 text-center" *ngIf="todayliveClass?.length == 0">
                                <img src="../../../assets/images/default_images/no_live_class.png"
                                    alt="no_live_class.png">
                                <p class="text-center text-danger">You do not
                                    have any assigned live class for today</p>
                            </div>
                        </div>
                        <div class="assingment-heading">
                            <h3>Tomorrow Live Classes</h3>
                        </div>
                        <div class="teacher-class">
                            <div *ngFor="let todayClass of tomorrowliveClass" class="class-col-outer">
                                <div class="class-date-time">
                                    <span class="bi bi-calendar-week"> {{todayClass.EventDate}}</span>
                                    <span class="bi bi-clock"> {{todayClass.StartTimeISO | customDate:'shortTime'}} ~
                                        {{todayClass.EndTimeISO | customDate:'shortTime'}}</span>
                                </div>
                                <div class="classes-col">
                                    <div class="class-icon">{{getIcon(todayClass.Description)}}</div>
                                    <div class="class-data">
                                        <h3>{{todayClass.Description | titlecase }}</h3>
                                        <span>Class By {{todayClass.UserName}}</span>
                                        <!-- <div class="calling-btn action-links">
                                            <span class="bi bi-camera-video" data-bs-toggle="tooltip"
                                                data-bs-placement="bottom" title="Join Call"
                                                (click)="startClass(todayClass)"></span>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="class-col-outer w-100 text-center" *ngIf="tomorrowliveClass?.length == 0">
                                <img src="../../../assets/images/default_images/no_live_class.png"
                                    alt="no_live_class.png">
                                <p class="text-center text-danger">You do not
                                    have any assigned live class for today</p>
                            </div>
                        </div>
                        <!-- <div class="tab-content p-0">
                            <div class="">
                                <div class="bg-white rounded tab-content">
                                    <div class="today-live-class">
                                        <p class="m-0 pt-2">Tomorrow Live Classes</p>
                                        <div class="live-item d-flex justify-content-between pb-1 border-bottom pt-3 "
                                            *ngFor="let todayClass of tomorrowliveClass">
                                            <div class="lang-icon me-2">
                                                <label class="p-2 mb-0">
                                                    <img src="../../../assets/images/icons/svg/language_green.svg"
                                                        alt="">
                                                </label>
                                            </div>
                                            <div class="item-group w-100">
                                                <h3 class="m-0 subject">
                                                    {{todayClass.Description | titlecase }}
                                                </h3>
                                                <p class="m-0 mt-1 teacher">Class By
                                                    {{todayClass.UserName}}</p>
                                                <div class="d-flex justify-content-between">
                                                    <p class="m-0 mt-1"><span class="lbl-time">Start Time
                                                        </span> <span class="time-value">
                                                            {{todayClass.StartTime}}</span>
                                                    </p>
                                                    <p class="m-0 mt-1"><span class="lbl-time">End Time
                                                        </span><span class="time-value">
                                                            {{todayClass.EndTime}}</span>
                                                    </p>
                                                    <p class="m-0 mt-1"><span class="lbl-started-in">Starting
                                                            in </span><span class="started-in-value">
                                                            {{todayClass.StartTime}}</span>
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="no-live-Class text-center" *ngIf="tomorrowliveClass?.length == 0">
                                            <img src="../../../assets/images/default_images/no_live_class.png"
                                                alt="no_live_class.png">
                                            <p class="text-center text-danger">You do not
                                                have any assigned live class for tomorrow
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div> -->
                    </div>
                </mat-tab>
                <mat-tab label="TimeTable">
                    <ng-template mat-tab-label>
                        <label>Time Table <span (click)="openHelpTooltip('SBAssignment')"
                                class="ms-2 bi bi-exclamation-circle-fill"></span></label>
                    </ng-template>
                    <div class="assingment-box-outer">
                        <div class="assingment-heading">
                            <h3>Time Table</h3>
                        </div>
                        <app-classtimetable [data]="timeTableData"></app-classtimetable>
                    </div>
                </mat-tab>
                <mat-tab label="SchoolDiary">
                    <ng-template mat-tab-label>
                        <label>School Diary <span (click)="openHelpTooltip('SBAssignment')"
                                class="ms-2 bi bi-exclamation-circle-fill"></span></label>
                    </ng-template>
                    <div class="assingment-box-outer">
                        <div class="assingment-heading">
                            <h3>School Diary</h3>
                        </div>
                        <app-student-dairy></app-student-dairy>
                        <div class="tab-content">
                            <div class="today-live-class">
                                <p class="m-0 pt-2"> </p>
                                <!-- diary component-->

                                <!--<div class="live-item d-flex justify-content-between pb-1 border-bottom pt-3 "*ngFor="let diary of diaryData">
                                                <div class="lang-icon me-2">
                                                    <label class="p-2 mb-0">
                                                        <img  src="../../../assets/images/icons/svg/language_green.svg" alt="">
                                                    </label>
                                                </div>
                                                <div class="item-group w-100">
                                                    <h3 class="m-0 subject">{{diary.Subject | titlecase }}</h3>
                                                    <p class="m-0 mt-1 diary-desc">Description : {{diary.DairyNotes}}</p>
                                                    <p class="m-0 mt-1 teacher">Class By {{diary.TeacherName}}</p>
                                                    <div class="d-flex justify-content-between">
                                                        <p class="m-0 mt-1"><span class="lbl-started-in">Status </span><span class="started-in-value">  {{diary.Result}}</span></p>
                                                    </div>
                                                </div>
                                                
                                            </div>-->
                            </div>

                        </div>
                    </div>
                </mat-tab>

            </mat-tab-group>
        </div>


    </div>
    <div class="teacher-sidebar">
        <bs-datepicker-inline class="inlinedate" name="inlineDate" [(ngModel)]="inlineDate" [bsValue]="inlineDate"
            [bsConfig]="datepickerConfig" (bsValueChange)="onDateChange($event)">
        </bs-datepicker-inline>
        <div class="sid-header">
            <h3>Upcoming Task</h3>
        </div>
        <div class="assingment-heading d-flex justify-content-between">
            <h3>Assignments</h3>
            <a [routerLink]="['/assignments']">View All</a>
        </div>
        <div *ngFor="let todayClass of assignmentData" class="class-col-outer w-100">
            <div class="class-date-time">
                <span class="bi bi-calendar-week"> {{todayClass.StartDate}}</span>
                <span class="bi bi-calendar-week"> {{todayClass.EndDate}}</span>
            </div>
            <div class="classes-col">
                <div class="class-icon">{{getIcon02(todayClass.Name)}}</div>
                <div class="class-data">
                    <h3>{{todayClass.Name | titlecase }}</h3>
                    <span>{{todayClass.Type}} Assingments</span>

                </div>
            </div>
        </div>
        <div class="assingment-heading d-flex justify-content-between mt-3">
            <h3>Assessments</h3>
            <a [routerLink]="['/assessments']">View All</a>
        </div>

        <div *ngFor="let todayClass of rowData" class="class-col-outer w-100">
            <div class="class-date-time">
                <span class="bi bi-calendar-week"> {{todayClass.StartDate}}</span>
                <span class="bi bi-calendar-week"> {{todayClass.EndDate}}</span>
            </div>
            <div class="classes-col">
                <div class="class-icon">{{getIcon02(todayClass.Name)}}</div>
                <div class="class-data">
                    <h3>{{todayClass.Name | titlecase }}</h3>
                    <span>{{todayClass.Type}} Assingments</span>

                </div>
            </div>
        </div>


    </div>




</div>