<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title h3 m-1" *ngIf="questionservice.formData.QuestionMasterInfo.QuestionMasterId<=0">
            {{'AddQuestion' | translate}}
            <label class="float-right m-0" *ngIf="IsStore">Question No. {{questionInfo.QuestionNo}}</label>
        </h3>
        <h3 class="modal-title h3 m-1" *ngIf="questionservice.formData.QuestionMasterInfo.QuestionMasterId>0">
            {{'EditQuestion' | translate}} <label class="float-right m-0" *ngIf="IsStore">Question No.
                {{questionInfo.QuestionNo}}</label></h3>
        <span class="bi bi-x" (click)="bsModalRef.hide()" title="Close Module" data-bs-toggle="tooltip"></span>
    </div>
    <form novalidate #form="ngForm" autocomplete="off">
        <div class="breackout_body">
            <div *ngIf="!IsStore">
                <div class="field-row">
                    <div class="select_field">
                        <label>{{'Name' | translate}}</label>
                        <input class="field-control" type="text" placeholder="{{'Name' | translate}}" name="Name"
                            Name="ngModel" [(ngModel)]="questionservice.formData.QuestionMasterInfo.Name" required
                            class.invalid="Name.invalid && Name.touched">
                    </div>
                    <div class="select_field">
                        <label>Academic year</label>
                        <select class="field-control appearance-auto" name="AcademicYearId" AcademicYearId="ngModel"
                            [(ngModel)]="questionservice.formData.QuestionMasterInfo.AcademicYearId">
                            <option [value]="0">Select</option>
                            <option *ngFor="let year of yearList" [value]="year.Id">{{year.Name}}</option>
                        </select>
                    </div>
                </div>
                <div class="field-row">
                    <div class="select_field">
                        <label>{{'Description' | translate}}</label>
                        <textarea class="field-control resizebele-false" type="text"
                            placeholder="{{'Description' | translate}}" name="Description" Description="ngModel"
                            [(ngModel)]="questionservice.formData.QuestionMasterInfo.Description"></textarea>
                    </div>
                </div>
                <div class="field-row">
                    <div class="select_field">
                        <label>{{'SelectClass' | translate}}</label>
                        <select class="field-control appearance-auto" name="ClassId" ClassId="ngModel"
                            [(ngModel)]="questionservice.formData.QuestionMasterInfo.ClassId" (change)="classChange()">
                            <option [value]="0">Select Class</option>
                            <option *ngFor="let class of classList" [value]="class.Id">{{class.Name}}</option>
                            <option [value]="-1" *ngIf="role == 'Admin' || role == 'SuperAdmin'">Add Class
                            </option>
                        </select>
                    </div>
                    <div class="select_field">
                        <label>{{'SelectSubject' | translate}}</label>
                        <select class="field-control appearance-auto" name="SubjectId" SubjectId="ngModel"
                            [(ngModel)]="questionservice.formData.QuestionMasterInfo.SubjectId"
                            (change)="sbjectChange()">
                            <option [value]="0">Select Subject</option>
                            <option *ngFor="let subject of subjectlist" [value]="subject.Id">{{subject.Name}}
                            </option>
                            <option [value]="-1" *ngIf="role == 'Admin' || role == 'SuperAdmin'">Add Subject
                            </option>
                        </select>
                    </div>
                    <div class="select_field">
                        <label>{{'SelectTopic' | translate}}</label>
                        <select class="field-control appearance-auto" name="TopicId" TopicId="ngModel"
                            [(ngModel)]="questionservice.formData.QuestionMasterInfo.TopicId" (change)="topicChange()">
                            <option [value]="0">Select Topic</option>
                            <option *ngFor="let topic of topiclist" [value]="topic.Id">{{topic.Name}}</option>
                            <option [value]="-1" *ngIf="role == 'Admin' || role == 'SuperAdmin'">Add Topic
                            </option>
                        </select>
                    </div>
                </div>
                <div class="field-row">
                    <div class="select_field">
                        <div class="field-row">
                            <div class="select_field">
                                <label>{{'StartDate' | translate}}</label>
                                <input type="text" class="field-control" placeholder="{{'StartDate' | translate}}"
                                    name="StartDate" StartDate="ngModel"
                                    [(ngModel)]="questionservice.formData.QuestionMasterInfo.StartDate"
                                    [bsConfig]="datepickerConfig" bsDatepicker [bsValue]="bsValue" [minDate]="minDate"
                                    timepicker required class.invalid="StartDate.invalid && StartDate.touched">
                            </div>
                            <div class="select_field">
                                <label>{{'Start Time' | translate}}</label>
                                <select [(ngModel)]="questionservice.formData.QuestionMasterInfo.StartTime"
                                    name="StartTime" class="field-control appearance-auto">
                                    <option *ngFor="let sTime of timeSlotList" [value]="sTime.Value">{{sTime.Value}}
                                    </option>
                                </select>
                            </div>
                        </div>

                    </div>
                    <div class="select_field">
                        <div class="field-row">
                            <div class="select_field">
                                <label>{{'End Date' | translate}}</label>
                                <input type="text" (click)="enddateSetting()" class="field-control"
                                    placeholder="{{'EndDate' | translate}}" name="EndDate" EndDate="ngModel"
                                    [(ngModel)]="questionservice.formData.QuestionMasterInfo.EndDate"
                                    [bsConfig]="datepickerConfig" bsDatepicker [bsValue]="bsValue" [minDate]="minDate"
                                    required class.invalid="EndDate.invalid && EndDate.touched" />
                            </div>
                            <div class="select_field">
                                <label>{{'End Time' | translate}}</label>
                                <select [(ngModel)]="questionservice.formData.QuestionMasterInfo.EndTime" name="EndTime"
                                    class="field-control appearance-auto">
                                    <option *ngFor="let eTime of timeSlotList" [value]="eTime.Value">{{eTime.Value}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="select_field">
                        <label>{{'TotalQuestion' | translate}}</label>
                        <input class="field-control" type="number" placeholder="{{'TotalQuestion' | translate}}"
                            name="NoOfQuestions" NoOfQuestions="ngModel"
                            [(ngModel)]="questionservice.formData.QuestionMasterInfo.NoOfQuestions">
                    </div>
                </div>

                <div class="field-row" [ngClass]="{'disabled':role != 'Admin' && role != 'SuperAdmin'}">
                    <div class="select_field">
                        <mat-checkbox class="example-margin" name="IsAcademics" IsAcademics="ngModel"
                            [(ngModel)]="questionservice.formData.QuestionMasterInfo.IsAcademics">{{'Academics' |
                            translate}}
                        </mat-checkbox>
                    </div>
                    <div class="select_field">
                        <mat-checkbox class="example-margin" name="IsHumanityLife" IsHumanityLife="ngModel"
                            [(ngModel)]="questionservice.formData.QuestionMasterInfo.IsHumanityLife">{{'HumanityLife'
                            |
                            translate}}</mat-checkbox>

                    </div>
                    <div class="select_field">
                        <mat-checkbox class="example-margin" name="IsSkills" IsSkills="ngModel"
                            [(ngModel)]="questionservice.formData.QuestionMasterInfo.IsSkills">{{'Skills' |
                            translate}}
                        </mat-checkbox>
                    </div>

                    <div class="select_field">
                        <mat-checkbox class="example-margin" name="IsLearnOutOfBox" IsLearnOutOfBox="ngModel"
                            [(ngModel)]="questionservice.formData.QuestionMasterInfo.IsLearnOutOfBox">{{'LearnOutOfBox'
                            |
                            translate}}</mat-checkbox>
                    </div>

                </div>

            </div>
            <div *ngIf="IsStore">
                <div class="field-row">
                    <div class="select_field">
                        <label>{{'QuestionType' | translate}}</label>
                        <select class="field-control appearance-auto" name="QuestionTypeId" QuestionTypeId="ngModel"
                            [(ngModel)]="questionInfo.QuestionTypeId" (change)="questionTypeChange()">
                            <option [value]="0">Select Type</option>
                            <option *ngFor="let questiontype of questiontypelist" [value]="questiontype.Id">
                                {{questiontype.Name}}</option>
                        </select>
                    </div>
                    <div class="select_field">
                        <label>{{'QuestionNo' | translate}}</label>
                        <select class="field-control appearance-auto" name="QuestionNo" QuestionNo="ngModel"
                            [(ngModel)]="questionInfo.QuestionNo" (change)="questionNoChange()">
                            <option *ngFor="let question of questionlist" [value]="question.QuestionNo">
                                {{question.QuestionNo}}</option>

                        </select>
                    </div>
                    <div class="select_field">
                        <label>{{'Marks' | translate}}</label>
                        <input class="field-control" type="text" placeholder="{{'Points' | translate}}" name="Points"
                            Points="ngModel" [(ngModel)]="questionInfo.Points" required
                            class.invalid="Points.invalid && Points.touched">
                    </div>
                </div>
                <div class="field-row"
                    *ngIf="(QuestionType == 'MC') || (QuestionType == 'MR') || (QuestionType == 'FIB')  || (QuestionType == 'Eassy')  || (QuestionType == 'TF') || (QuestionType == 'MF') || (QuestionType == 'MM')">
                    <div class="select_field">
                        <label><span>*</span>{{'Type Your Question' | translate}}</label>
                        <angular-editor class="resizebele-false" name="Question" #Question="ngModel"
                            [(ngModel)]="questionInfo.Question" [config]="config" required
                            class.invalid="Question.invalid && Question.touched">
                        </angular-editor>
                    </div>
                </div>
                <div *ngIf="QuestionType == 'MC'">
                    <div class="field-row">
                        <div class="select_field">
                            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                                name="OptionType" [(ngModel)]="questionInfo.OptionType">
                                <mat-radio-button class="example-radio-button mr-3"
                                    *ngFor="let optiontype of optionTypeList" [value]="optiontype">
                                    {{optiontype}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>

                    <div *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                        <div class="field-row" *ngIf="i==0">
                            <div class="select_field option_field">
                                <label>{{'Correct' | translate}}</label>
                            </div>
                            <div class="select_field">
                                <label>{{'QUESTION OPTION' | translate}}</label>
                            </div>
                            <div class="select_field option_field">
                                <button class="custom-primary-btn" type="submit" (click)='addNewRow("MC")'><span
                                        class="bi bi-plus-circle" title="Add New Row"></span></button>
                            </div>
                        </div>
                        <div class="field-row">
                            <div class="select_field option_field">
                                <label class="option-label">{{QindexLabel[i].text}}</label>
                                <mat-radio-group aria-label="Select an option" class="mat-radio-label-content-false"
                                    name="CorrectLabel-{{i}}" #CorrectLabel="ngModel" [(ngModel)]="qAnswer.CorrectLabel"
                                    (change)="changeMC(questionInfo.QAnswerList.length, i )">
                                    <mat-radio-button [value]="QindexLabel[i].text">
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="select_field" *ngIf="questionInfo.OptionType == 'Text'">
                                <textarea class="field-control appearance-auto resizebele-false" name="Answer{{i}}"
                                    #Answer="ngModel" [(ngModel)]="qAnswer.Answer" required
                                    class.invalid="Answer.invalid && Answer.touched"></textarea>
                            </div>
                            <div class="select_field" *ngIf="questionInfo.OptionType == 'Image'">
                                <mb-large-files-uploader (updatedAttachmentsEmitter)="largefileschange($event)"
                                    name="qAnswer.FileId" [(ngModel)]="qAnswer.FileId" #FileId="ngModel" required
                                    class.invalid="FileId.invalid && FileId.touched"
                                    (updatedResponseEmitter)="qAnswerFilesuploaded($event, i)"
                                    title="{{'Upload Image' | translate}}" canUpload="true" canSendData="true"
                                    saveButtonText="" saveButton="false">
                                </mb-large-files-uploader>

                                <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-100px">
                            </div>

                            <div class="select_field delete_option">
                                <mat-icon *ngIf="i>3" (click)="deleteRow(qAnswer)" class="text-danger cursor-pointer">
                                    delete_outline</mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="QuestionType == 'Eassy'">
                    <div *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                        <div class="field-row" *ngIf="i==0">
                            <div class="select_field">
                                <label>{{'Enter Answer' | translate}}</label>
                            </div>
                        </div>
                        <div class="field-row">

                            <div class="select_field">
                                <textarea class="field-control appearance-auto resizebele-false" rows="10" name="Answer"
                                    #Answer="ngModel" [(ngModel)]="qAnswer.Answer" required
                                    class.invalid="Answer.invalid && Answer.touched"></textarea>
                            </div>
                        </div>
                        <div class="field-row">
                            <div class="select_field">
                                <!-- <div class="form-group mb-0"> -->
                                <!-- <mb-large-files-uploader
                                                                (updatedAttachmentsEmitter)="largefileschange($event)"
                                                                (updatedResponseEmitter)="qAnswerFilesuploaded($event, 0)"
                                                                title="{{'Upload Image' | translate}}" canUpload="true"
                                                                canSendData="true" saveButtonText="">
                                                            </mb-large-files-uploader> -->
                                <!-- </div> -->
                                <img [src]="questionInfo.QAnswerList[0].FilePath" alt=""
                                    class="img img-fluid max-width-100px">
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="QuestionType == 'FIB'">
                    <div *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                        <div class="field-row" *ngIf="i==0">
                            <div class="select_field option_field">
                                <label>{{'Correct' | translate}}</label>
                            </div>
                            <div class="select_field">
                                <label>{{'QUESTION OPTION' | translate}}</label>
                            </div>
                            <div class="select_field">
                                <label>{{'Attachement' | translate}}</label>
                            </div>
                            <div class="select_field option_field">
                                <button class="custom-primary-btn" type="submit" (click)='addNewRow("FIB")'><span
                                        class="bi bi-plus-circle" title="Add New Row"></span></button>
                            </div>
                        </div>
                        <div class="field-row">
                            <div class="select_field option_field">
                                <label class="option-label"><span ngIF="i<9">0</span>{{i+1}}</label>
                            </div>
                            <div class="select_field">
                                <textarea class="field-control appearance-auto resizebele-false"
                                    name="qAnswer.Answer{{i}}" #Answer="ngModel" [(ngModel)]="qAnswer.Answer" required
                                    class.invalid="Answer.invalid && Answer.touched"></textarea>
                            </div>
                            <div class="select_field option_field">
                                <mb-large-files-uploader (updatedAttachmentsEmitter)="largefileschange($event)"
                                    name="qAnswer.FileId" [(ngModel)]="qAnswer.FileId"
                                    (updatedResponseEmitter)="qAnswerFilesuploaded($event, i)"
                                    title="{{'Upload Image' | translate}}" canUpload="true" canSendData="true"
                                    saveButton="false" saveButtonText="">
                                </mb-large-files-uploader>
                                <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-100px">

                            </div>
                            <div class="select_field delete_option">

                                <mat-icon *ngIf="i!=0" (click)="deleteRow(qAnswer)" class="text-danger cursor-pointer">
                                    delete_outline</mat-icon>

                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="QuestionType == 'MR'">
                    <div class="field-row">
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            name="OptionType" [(ngModel)]="questionInfo.OptionType">
                            <mat-radio-button class="example-radio-button mr-3"
                                *ngFor="let optiontype of optionTypeList" [value]="optiontype">
                                {{optiontype}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                        <div class="field-row" *ngIf="i==0">
                            <div class="select_field option_field">
                                <label>{{'Correct' | translate}}</label>
                            </div>
                            <div class="select_field">
                                <label>{{'QUESTION OPTION' | translate}}</label>
                            </div>
                            <div class="select_field option_field">
                                <button class="custom-primary-btn" type="submit" (click)='addNewRow("MR")'><span
                                        class="bi bi-plus-circle" title="Add New Row"></span></button>
                            </div>
                        </div>
                        <div class="field-row">
                            <div class="select_field option_field">
                                <label class="option-label">{{QindexLabel[i].text}}</label>
                                <mat-checkbox class="example-margin" name="qAnswer.IsCorrect{{i}}" #IsCorrect="ngModel"
                                    [(ngModel)]="qAnswer.IsCorrect" value="1">
                                </mat-checkbox>
                            </div>
                            <div class="select_field" *ngIf="questionInfo.OptionType == 'Text'">

                                <textarea name="" class="field-control appearance-auto resizebele-false"
                                    name="qAnswer.Answer{{i}}" #Answer="ngModel" [(ngModel)]="qAnswer.Answer" required
                                    class.invalid="Answer.invalid && Answer.touched"></textarea>

                            </div>
                            <div class="select_field" *ngIf="questionInfo.OptionType == 'Image'">

                                <mb-large-files-uploader (updatedAttachmentsEmitter)="largefileschange($event)"
                                    name="qAnswer.FileId" [(ngModel)]="qAnswer.FileId"
                                    (updatedResponseEmitter)="qAnswerFilesuploaded($event, i)"
                                    title="{{'Upload Image' | translate}}" canUpload="true" canSendData="true"
                                    saveButtonText="">
                                </mb-large-files-uploader>

                                <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-100px">
                            </div>
                            <div class="select_field delete_option">
                                <mat-icon *ngIf="i>3" (click)="deleteRow(qAnswer)" class="text-danger cursor-pointer">
                                    delete_outline</mat-icon>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="QuestionType == 'TF'">

                    <div class="field-row">
                        <div class="select_field">

                            <mat-radio-group aria-label="Select an option" name="IsCorrect" #IsCorrect="ngModel"
                                [(ngModel)]="questionInfo.QAnswerList[0].IsCorrect" (change)="changeTF()" required
                                class.invalid="IsCorrect.invalid && IsCorrect.touched">
                                <mat-radio-button class="mr-3"
                                    *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index;"
                                    [value]="QindexLabel[i].text">
                                    {{qAnswer.Answer}}

                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="select_field">
                            <!-- <mb-large-files-uploader
                                                            (updatedAttachmentsEmitter)="largefileschange($event)"
                                                            (updatedResponseEmitter)="qAnswerFilesuploaded($event, 0)"
                                                            title="{{'Upload Image' | translate}}" canUpload="true"
                                                            canSendData="true" saveButtonText="">
                                                        </mb-large-files-uploader> -->
                            <img [src]="questionInfo.QAnswerList[0].FilePath" alt=""
                                class="img img-fluid max-width-100px">
                        </div>
                    </div>

                </div>
                <div *ngIf="QuestionType == 'MF'">

                    <div class="field-row">
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            name="OptionType" [(ngModel)]="questionInfo.OptionType">
                            <mat-radio-button class="example-radio-button mr-3"
                                *ngFor="let optiontype of optionTypeList" [value]="optiontype">
                                {{optiontype}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div class="field-row" *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                        <div class="select_field">
                            <div class="field-row" *ngIf="i==0">
                                <div class="select_field">
                                    <label>{{'QUESTION OPTION' | translate}}</label>
                                </div>
                                <div class="select_field">
                                    <label>{{'QUESTION OPTION' | translate}}</label>
                                </div>
                                <div class="select_field option_field">
                                    <button class="custom-primary-btn" type="submit" (click)='addNewRow("MF")'><span
                                            class="bi bi-plus-circle" title="Add New Row"></span></button>
                                </div>
                            </div>

                            <div class="field-row">
                                <div class="select_field">
                                    <label class="option-label">{{qAnswer.GroupNo}}</label>

                                    <input name="" class="field-control appearance-auto resizebele-false"
                                        name="qAnswer.Answer{{i}}" #Answer="ngModel" [(ngModel)]="qAnswer.Answer"
                                        required class.invalid="Answer.invalid && Answer.touched">
                                </div>
                                <div class="select_field" *ngIf="questionInfo.OptionType == 'Text'">
                                    <label class="option-label">{{qAnswer.GroupNo1}}</label>

                                    <input name="" class="field-control appearance-auto resizebele-false"
                                        name="qAnswer.Answer1{{i}}" #Answer1="ngModel" [(ngModel)]="qAnswer.Answer1"
                                        required class.invalid="Answer1.invalid && Answer1.touched">
                                </div>
                                <div class="select_field" *ngIf="questionInfo.OptionType == 'Image'">
                                    <mb-large-files-uploader (updatedAttachmentsEmitter)="largefileschange($event)"
                                        name="qAnswer.FileId" [(ngModel)]="qAnswer.FileId"
                                        (updatedResponseEmitter)="qAnswerFilesuploaded($event, i)"
                                        title="{{'Upload Image' | translate}}" canUpload="true" canSendData="true"
                                        saveButtonText="">
                                    </mb-large-files-uploader>
                                    <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-100px">
                                </div>
                            </div>

                            <div class="field-row">
                                <div class="select_field">
                                    <div class="field-row" *ngIf="i==0">
                                        <div class="select_field">
                                            <label>{{'CORRECT ANSWER' | translate}}</label>
                                        </div>
                                    </div>

                                    <div class="field-row">
                                        <div class="select_field option_field"
                                            *ngFor="let qAnswer1 of questionInfo.QAnswerList; let j=index">
                                            <label class="option-label">{{qAnswer1.GroupNo1}}</label>
                                            <mat-radio-group aria-label="Select an option"
                                                class="mat-radio-label-content-false" name="{{i}}"
                                                #CorrectLabel="ngModel" [(ngModel)]="qAnswer.CorrectLabel" required
                                                class.invalid="CorrectLabel.invalid && CorrectLabel.touched">
                                                <mat-radio-button [checked]="qAnswer.CorrectLabel"
                                                    value="{{qAnswer1.GroupNo1}}">
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="select_field delete_option">
                                            <mat-icon *ngIf="i>3" (click)="deleteRow(qAnswer)"
                                                class="text-danger cursor-pointer">
                                                delete_outline</mat-icon>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="QuestionType == 'MM'">
                    <div class="field-row">
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            name="OptionType" [(ngModel)]="questionInfo.OptionType">
                            <mat-radio-button class="example-radio-button mr-3"
                                *ngFor="let optiontype of optionTypeList" [value]="optiontype">
                                {{optiontype}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="field-row" *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                        <div class="select_field">
                            <div class="field-row" *ngIf="i==0">
                                <div class="select_field">
                                    <label>{{'QUESTION OPTION' | translate}}</label>
                                </div>
                                <div class="select_field">
                                    <label>{{'QUESTION OPTION' | translate}}</label>
                                </div>
                                <div class="select_field option_field">
                                    <button class="custom-primary-btn" type="submit" (click)='addNewRow("MM")'><span
                                            class="bi bi-plus-circle" title="Add New Row"></span></button>
                                </div>
                            </div>

                            <div class="field-row">
                                <div class="select_field">
                                    <label class="option-label">{{qAnswer.GroupNo}}</label>

                                    <input name="" class="field-control appearance-auto resizebele-false"
                                        name="qAnswer.Answer{{i}}" #Answer="ngModel" [(ngModel)]="qAnswer.Answer"
                                        required class.invalid="Answer.invalid && Answer.touched">
                                </div>
                                <div class="select_field" *ngIf="questionInfo.OptionType == 'Text'">
                                    <label class="option-label">{{qAnswer.GroupNo1}}</label>

                                    <input name="" class="field-control appearance-auto resizebele-false"
                                        name="qAnswer.Answer1{{i}}" #Answer1="ngModel" [(ngModel)]="qAnswer.Answer1"
                                        required class.invalid="Answer1.invalid && Answer1.touched">
                                </div>
                                <div class="select_field" *ngIf="questionInfo.OptionType == 'Image'">
                                    <mb-large-files-uploader (updatedAttachmentsEmitter)="largefileschange($event)"
                                        name="qAnswer.FileId" [(ngModel)]="qAnswer.FileId"
                                        (updatedResponseEmitter)="qAnswerFilesuploaded($event, i)"
                                        title="{{'Upload Image' | translate}}" canUpload="true" canSendData="true"
                                        saveButtonText="">
                                    </mb-large-files-uploader>
                                    <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-100px">
                                </div>
                            </div>

                            <div class="field-row">
                                <div class="select_field">
                                    <div class="field-row" *ngIf="i==0">
                                        <div class="select_field">
                                            <label>{{'CORRECT ANSWER' | translate}}</label>
                                        </div>
                                    </div>

                                    <div class="field-row">
                                        <div class="select_field option_field"
                                            *ngFor="let qAnswer1 of questionInfo.QAnswerList; let j=index">
                                            <label class="option-label">{{qAnswer1.GroupNo1}}</label>
                                            <mat-checkbox class="example-margin" name="{{i}}{{j}}"
                                                #CorrectLabel="ngModel" [(ngModel)]="qAnswer.CorrectLabel"
                                                value="{{qAnswer1.GroupNo1}}" [checked]="qAnswer.CorrectLabel" required
                                                class.invalid="CorrectLabel.invalid && CorrectLabel.touched">
                                            </mat-checkbox>
                                        </div>
                                        <div class="select_field delete_option">
                                            <mat-icon *ngIf="i>3" (click)="deleteRow(qAnswer)"
                                                class="text-danger cursor-pointer">
                                                delete_outline</mat-icon>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button *ngIf="IsStore" type="button" class="custom-primary-btn" (click)="prevQuestion()">Back</button>
                <button *ngIf="IsStore && questionInfo.QuestionNo != noofQuestions" type="button"
                    (click)="addNewAnswer()" [disabled]="form.invalid  || questionInfo.QuestionTypeId<=0"
                    class="custom-primary-btn" type="button">Next</button>
                <button *ngIf="IsStore && (questionInfo.QuestionNo == noofQuestions || list[0].id > 0)" type="button"
                    (click)="saveQuestionInfo(form)" class="custom-primary-btn" type="button">Save</button>
                <button *ngIf="!IsStore" type="button" class="custom-primary-btn" (click)="questionInfoStore(form)"
                    type="button"
                    [disabled]="form.invalid || questionservice.formData.QuestionMasterInfo.ClassId <= 0 || questionservice.formData.QuestionMasterInfo.SubjectId <= 0 || questionservice.formData.QuestionMasterInfo.TopicId <= 0">Start</button>
                <button type="button" class="custom-primary-btn custom-danger" (click)="bsModalRef.hide()"
                    aria-label="Close">Cancel</button>
            </div>
        </div>
    </form>
</div>