<div class="select_field video-field" id="rec-on" #recOnDiv>
    <label for="">Record Your Question</label>
    <!-- Video Element to Display the Live Stream -->
    <video #videoElement autoplay></video>
    <div class="recording_button action-links mt-3">
      <!-- Recording Buttons -->
      <span (click)="startRecording()" class="rec-button bi bi-record-circle" title="Start Recording"></span>
      <span (click)="stopRecording()" class="rec-button bi bi-stop-circle" title="Stop Recording"></span>
    </div>
  </div>
  <div class="select_field video-field" id="rec-off" style="display: none;"  #recOffDiv>
    <label for="">Recorded Video</label>
    <video [src]="recordedBlobUrl" controls></video>
    <div class="recording_button action-links mt-3">
      <!-- Recording Buttons -->
      <a [href]="recordedBlobUrl" download="recording.webm" class="rec-button" title="Download Recording"><span
          class="bi bi-download"></span></a>
      <span (click)="discardRecording()" class="rec-button bi bi-x-circle" title="Discard Recording"></span>
    </div>
  </div>