<div class="profile-group">
  <img class="shadow" [src]="userData.UserInfo.CroppedFilepath?urlSafe:'../../../assets/images/user/default.png'">

  <p class="shadow">{{userData.UserInfo.FullName?userData.UserInfo.FullName:''}}</p>
</div>
<button mat-button [matMenuTriggerFor]="beforeMenu"><i class="fa fa-users" aria-hidden="true"></i>&nbsp; <i
    class="fa fa-caret-down"></i></button>
<mat-menu #beforeMenu="matMenu" xPosition="before">
  <button mat-menu-item *ngFor="let child of childrenList" (click)="selectChild(child.Id)">{{child.Name}}</button>
  <button mat-menu-item (click)="selectChild(-1)">Link a child to my account</button>
</mat-menu>
<div class="user-form d-flex align-items-center">
  <div class="container-fluid">
    <div class="row justify-content-c m-0">
      <div class="col-md-12 p-0">
        <div style="width: calc(100%);" class="bg-white shadow p-0 mb-0 mt-3 mb-3">



          <div class="modal-content">
            <div class="modal-header bg-theam p-1">
              <h3 class="modal-title h3 m-1">Home Work</h3>
            </div>
            <div class="breackout_body p-2">
              <div class="row form-row m-0">
                <div class="form-group col-12 p-0 pr-1 mb-0 border border-secondary ">
                  <!-- <div class="row form-row m-0 pt-2">

                        <div class="form-group col-4 mb-0">
                            <label>Selected Child</label>
                            <select class="form-control form-control-sm appearance-auto" name="selectedChild" [(ngModel)]="selectedChileId" (change)="changeChild()">
                              <option [value]="0">Select Child</option>
                              <option *ngFor="let child of childrenList" [value]="child.Id">{{child.Name}}</option>
                            </select>
                          </div>

                      </div> -->
                  <section class="mt-3 p-2">
                    <div class="row m-0 justify-content-c xs-flex-wrap">
                      <div class="p-2 shadow bg-white event-group">
                        <div class="mb-0 w-100">
                          <label>Assessments <a (click)="openHelpTooltip('Assessments')"
                              class="position-relative"><mat-icon>help_outline</mat-icon></a></label>
                          <ag-grid-angular #agGrid
                            style="width: 100%; height:248px;min-height: 200px;max-height: 248px;"
                            class="ag-theme-alpine" id="myGrid" [gridOptions]="gridOptions" [columnDefs]="columnDefs"
                            [defaultColDef]="defaultColDef" [rowSelection]="rowSelection" [rowData]="rowData"
                            (selectionChanged)="onSelectionChanged($event)" (gridReady)="onGridReady($event)"
                            [frameworkComponents]="frameworkComponents"></ag-grid-angular>
                        </div>
                      </div>

                      <div class="ml-15px p-0 inline-calander-group xs-ml-0px xs-mt-3">
                        <bs-datepicker-inline class="inlinedate" name="inlineDate" [(ngModel)]="inlineDate"
                          [bsValue]="inlineDate" [bsConfig]="datepickerConfig" (bsValueChange)="onDateChange($event)">
                        </bs-datepicker-inline>
                      </div>
                    </div>
                  </section>
                </div>

              </div>
            </div>
            <!-- <div class="modal-footer p-1">
                <button type="button" class="btn btn-success" (click)="onSave(form)">Save</button>

              </div> -->
          </div>

        </div>

      </div>
    </div>
  </div>

</div>