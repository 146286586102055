<div class="dashboardInnerPage inner-page-outer flex-column">
    <div class="title w-100">
        <span (click)="goBack()" title="Back" data-bs-toggle="tooltip" data-bs-placement="bottom"
            class="bi bi-arrow-left"></span>
        <h3>Subject</h3>
        <a class="add_button" routerLink="#" (click)="MenuEditClick(0,'')">Add Subject</a>
    </div>
    <div class="field-row filter-row ">
        <div class="select_field">
            <label>Select Class</label>


            <select class="field-control" name="classId" [(ngModel)]="classId" (change)="changeClass()" required>
                <option [value]="0">Select Class</option>
                <option *ngFor="let class of classList"  [value]="class.Id">
                {{class.Name}}
                </option>
            </select>

            <!-- <input [(ngModel)]="modelDate" autocomplete="off" class="form-control rounded-0" name="date" bsDatepicker
                        [bsConfig]="{ dateInputFormat: 'YYYY' }" (onShown)="onOpenCalendar($event)" /> -->
        </div>
        <div class="filter_option">
            <span (click)="serchSubject()" class="filter_button"><i class="bi bi-search"></i> Search</span>
            <span class="filter_button" data-bs-toggle="collapse" data-bs-target="#collapseExample"
                aria-expanded="false" aria-controls="collapseExample"><i class="bi bi-filter"></i> Filter</span>
        </div>
    </div>
    <div class="filter-form collapse" id="collapseExample">
        <div class="field-row">
            <div class="select_field">
                <label>Name</label>
                <input type="text" class="field-control" name="Name" (keyup)="filter($event)" />
            </div>
            <div class="select_field">
                <label>Description</label>
                <input type="text" class="field-control" name="Description" (keyup)="filter($event)" />
            </div>
        </div>
    </div>

    <div class="custom-table">
        <!-- <pre>{{ filteredrowData | json }}</pre> Debugging purpose -->

        <table class="table table-striped">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody *ngIf="filteredrowData.length === 0">
                <tr>
                    <td colspan="10">No data available</td>
                </tr>
            </tbody>
            <tbody *ngIf="filteredrowData.length > 0">
                <tr *ngFor="let data of filteredrowData">
                    <td>{{data.Name}}</td>
                    <td>{{data.Description}}</td>
                    <td>
                        <div class="table-action">
                            <span data-bs-toggle="tooltip" data-bs-placement="bottom" class=" bi bi-pencil-square"
                                title="Edit assignment" (click)="MenuEditClick(data.Id,data.Name)"></span>
                            <!-- <span data-bs-toggle="tooltip" data-bs-placement="bottom" class=" bi bi-list-ul"
                                title="Answer List" (click)="answerList(data.QuestionMasterId, this)"></span> -->
                            <span *ngIf=" this.commonServ.role != 'Teacher'" data-bs-toggle="tooltip"
                                data-bs-placement="bottom" class="bi bi-trash3" title="Delete"
                                (click)="deleteSubject(data.Id, this)"></span>

                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>



<!-- <div class="user-form d-flex align-items-center">
    <div class="container-fluid">
        <div class="row justify-content-c m-0">
            <div class="col-md-12 p-0">
                <div style="width: calc(100%);" class="bg-white shadow p-2 mb-0 mt-3 mb-3">
                    <div class="row border m-0 py-2 mb-2 justify-content-between">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                            <div class="form-group mb-0">
                                <label>Select Class</label>
                                
                                <select class="form-control form-control-sm appearance-auto" name="classId" [(ngModel)]="classId" (change)="changeClass()" required>
                                    <option [value]="0">Select Class</option>
                                    <option *ngFor="let class of classList"  [value]="class.Id">
                                    {{class.Name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                            <div class="form-group mb-0">
                                <label>&nbsp;</label>
                                <div class="form-control form-control-sm border-0 p-0">
                                    <button type="button" (click)="serchSubject()" class="btn btn-warning float-right btn-sm"
                                        type="button">Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ag-grid-angular 
                    #agGrid style="width: 100%; height:calc(100vh - 242px)" 
                    class="ag-theme-alpine"
                    id="myGrid" 
                    [gridOptions]="gridOptions" 
                    [columnDefs]="columnDefs" 
                    [defaultColDef]="defaultColDef"
                    [rowSelection]="rowSelection" 
                    [rowData]="rowData" 
                    (selectionChanged)="onSelectionChanged($event)"
                    (gridReady)="onGridReady($event)" 
                    [frameworkComponents]="frameworkComponents">
                </ag-grid-angular>
                <div class="row border-0 m-0 py-2 justify-content-between">
                        
                    <div class="col-12  px-2">
                        <div class="form-group mb-0">
                            <div class="form-control form-control-sm border-0 p-0">
                                <button type="button" (click)="openusermodal()" class="btn btn-warning float-right"
                                    type="button">Search Shared Id</button>
                            </div>
                        </div>
                    </div>
                </div> 
                </div>
                
            </div>
        </div>
    </div>
    
</div> -->