<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1">Survey</h3>
        </div>
        <form novalidate #form="ngForm" autocomplete="off">
            <div class="breackout_body p-2">
                <div>
                    <div class="row form-row m-0">
                        <div class="col-12">
                            <div class="form-group mb-0">
                                <label>{{'Description' | translate}}</label>
                                <textarea class="form-control form-control-sm resizebele-false" type="text"
                                    placeholder="{{'Description' | translate}}" name="Description"
                                    #Description="ngModel" [(ngModel)]="surveyServ.formData.Description"></textarea>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>{{'RatingType' | translate}}*</label>
                                <select class="form-control form-control-sm appearance-auto" name="RatingTypeId"
                                    #RatingTypeId="ngModel" [(ngModel)]="surveyServ.formData.RatingTypeId"
                                    class.invalid="RatingTypeId.invalid && RatingTypeId.touched">
                                    <option [value]="0">Select Rating Type</option>
                                    <option *ngFor="let ratingType of ratingTypeList" [value]="ratingType.Id">
                                        {{ratingType.Name}}</option>
                                </select>

                            </div>
                        </div>
                        <div class="col-6 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>{{'StartDate' | translate}}</label>
                                <input type="text" class="form-control form-control-sm"
                                    placeholder="{{'StartDate' | translate}}" name="StartDate" #StartDate="ngModel"
                                    [(ngModel)]="surveyServ.formData.StartDate" [bsConfig]="datepickerConfig"
                                    bsDatepicker timepicker>
                            </div>

                        </div>
                        <div class="col-6 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>{{'EndDate' | translate}}</label>
                                <input type="text" class="form-control form-control-sm"
                                    placeholder="{{'EndDate' | translate}}" name="EndDate" #EndDate="ngModel"
                                    [(ngModel)]="surveyServ.formData.EndDate" [bsConfig]="datepickerConfig" bsDatepicker
                                    timepicker>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer p-1">
                <button type="button" (click)="addSurveyInfo(form)" class="btn btn-success" type="button">Save</button>
                <button type="button" class="btn btn-danger" (click)="bsModalRef.hide()"
                    aria-label="Close">Close</button>
            </div>
        </form>
    </div>
</div>