import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { UsereditComponent } from "../user/useredit.component";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { NgFor } from "@angular/common";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
//import EventEmitter from 'node:events';
@Component({
  selector: "app-custommenu",
  templateUrl: "./custommenu.component.html",
  styleUrls: ["./custommenu.component.scss"],
  standalone: true,
  imports: [MatIconModule, MatMenuModule, NgFor],
})
export class CustommenuComponent implements OnInit {
  @Output() Editclick = new EventEmitter<string>();
  params: any;
  bsModalRef: BsModalRef;
  modalRef: BsModalRef;
  constructor(private modalService: BsModalService) {}
  MenuList: any;
  //SaveClick : any;

  ngOnInit(): void {}
  agInit(params: any) {
    this.params = params.value;
    this.MenuList = params.MenuList;
  }

  refresh(params: any) {
    return true;
  }
  openusermodal(userid) {
    const initialState = {
      list: [{ id: userid }],
    };
    this.bsModalRef = this.modalService.show(UsereditComponent, {
      initialState,
      class: "modal-dialog modal-dialog-centered",
      ignoreBackdropClick: true,
    });
  }

  edit() {
    //this.openusermodal(this.params);
    this.Editclick.emit(this.params);
  }
}
