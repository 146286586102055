import { Component, ViewChild, OnInit, ElementRef, Input, SimpleChanges } from "@angular/core";
import { MessageData, MessageMasterData } from "src/app/shared/chat.model";
import { ChatService } from "src/app/shared/chat.service";
import { CommonService } from "src/app/shared/common.service";
import { SignalrService } from "src/app/shared/signalr.service";
import { FormsModule } from "@angular/forms";
import { NgClass, NgIf, NgFor } from "@angular/common";
import {UploadMediaComponent} from "./upload-media/upload-media.component"
import $ from "jquery";

// import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
// import { faStar, faStarHalfAlt, faTimesCircle, faAngleUp } from '@fortawesome/free-solid-svg-icons';
// import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';

declare var MediaRecorder: any;

@Component({
  selector: "app-chatbox",
  templateUrl: "./chatbox.component.html",
  styleUrls: ["./chatbox.component.scss"],
  standalone: true,
  imports: [NgClass, NgIf, NgFor, FormsModule, UploadMediaComponent],
})
export class ChatboxComponent implements OnInit {
  @Input() isExpanded:boolean;
  list: any[] = [];
  isAdmin: boolean = false;
  joinedlist: Array<any>;
  messageInfo: MessageData;
  messageList: Array<MessageData>;
  masterMessageList: Array<MessageMasterData>;
  isChatExpend: boolean = false;

  videoElement: HTMLVideoElement;
  recordVideoElement: HTMLVideoElement;
  mediaVideoRecorder: any;
  videoRecordedBlobs: Blob[];
  isRecording: boolean = false;
  downloadVideoUrl: string;
  stream: MediaStream;
  @ViewChild("recordedVideo") recordVideoElementRef: ElementRef;
  @ViewChild("liveVideo") videoElementRef: ElementRef;
 
  // ChatExpend:any;
  constructor(
    public service: ChatService,
    public commonServ: CommonService,
    public signalRServ: SignalrService
  ) {
    // library.addIcons(faStar, faStarHalfAlt, farStar, faTimesCircle, faAngleUp);
    //this.commonServ.isChatting.next(false);
    this.messageInfo = new MessageData();
    // if(this.commonServ.messageMasterInfo.MessageMasterId && !this.commonServ.messageMasterInfo.messageMasterId){
    //   this.commonServ.messageMasterInfo.messageMasterId = this.commonServ.messageMasterInfo.MessageMasterId
    // }
    if (this.commonServ.messageMasterInfo) {
      this.messageInfo.MessageMasterId = this.commonServ.messageMasterInfo
        ? this.commonServ.messageMasterInfo.MessageMasterId
        : "";
      this.messageList = this.commonServ.MessageList;
      if (this.messageInfo)
        this.signalRServ.joinGroup(this.messageInfo.MessageMasterId.toString());
    }
    // this.signalRServ.chatStarted.subscribe(
    //   item => {
    //    // this.commonServ.isChatting.next(true);
    //     if(this.commonServ.messageMasterInfo.MessageMasterId != item.MessageMasterId){
    //       this.commonServ.messageMasterInfo = item;
    //    }

    //   }
    // );
    this.signalRServ.messageReceived.subscribe((item) => {
      this.messageList.push(item);
    });
    this.signalRServ.chatClosed.subscribe((item) => {
      this.messageList = [];
      this.signalRServ.UnSubscribe(
        this.commonServ.messageMasterInfo.MessageMasterId.toString()
      );
      this.commonServ.isChatting.next(false);
    });

    this.commonServ.sendMsg$.subscribe((message) => {
      this.messageList = message;
    });
    this.commonServ.loadMsg$.subscribe((messageMasterInfo) => {
      this.service
        .getMessageInfo(messageMasterInfo.MessageMasterId)
        .subscribe((res: any) => {
          this.messageList = res.MessageInfo;
        });
    });
  }
  ngOnInit(): void {
    // this.isChatExpend = false;
    // this.ChatExpend = "nvdshbvf";
    console.log(this.isExpanded);
    
    this.joinedlist = [];
    if (
      localStorage.getItem("UserRole") == "Admin" ||
      localStorage.getItem("UserRole") == "SuperAdmin" ||
      localStorage.getItem("UserRole") == "Teacher"
    )
      this.isAdmin = true;
      const AuthToken=localStorage.getItem("schoolAuth")
      const UserId=  localStorage.getItem("UserId")
      if(AuthToken)
      this.signalRServ.initiateSignalrConnection(AuthToken,UserId)
  
    this.service.getMessages().subscribe((res: any) => {
      console.log("chats" + res);
      this.masterMessageList = res;
      /* this.masterMessageList.forEach( function(val) {
        this.handleChatClick(val);
      }) */
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    this.closeChatBox()
  }
  closeChatBox() {
    $("#chatOuter").toggleClass("chatBoxOpen");
  }
    
  closeChat() {
    this.signalRServ.closeChat(this.commonServ.messageMasterInfo);
  }
  handleChatClick(masterMessage) {
    // this.messageList = [];
    this.commonServ.messageMasterInfo = masterMessage;
    if (this.commonServ.messageMasterInfo)
      this.messageInfo.MessageMasterId = this.commonServ.messageMasterInfo
        ? this.commonServ.messageMasterInfo.MessageMasterId
        : "";
    this.service
      .getMessageInfo(masterMessage.MessageMasterId)
      .subscribe((res: any) => {
        if (
          this.messageInfo //&&
          // !this.joinedlist.includes(this.messageInfo.MessageMasterId.toString())
        ) {
          console.log(res);
          
          this.joinedlist.push(this.messageInfo.MessageMasterId.toString());
          this.signalRServ.joinGroup(
            this.messageInfo.MessageMasterId.toString()
          );
          this.commonServ.SendMessageList(res.MessageInfo);
          this.signalRServ.startChat(res.MessageMasterInfo);
          this.toggleChat()
        }
      });
  }
  sendMessageAction(action) {
    this.messageInfo.Message = "%" + action + "%";
    this.sendMessage();
  }

  showCamera() {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          width: 150,
        },
      })
      .then((stream) => {
        this.videoElement = this.videoElementRef.nativeElement;
        this.recordVideoElement = this.recordVideoElementRef.nativeElement;
        this.stream = stream;
        this.videoElement.srcObject = this.stream;
      });
  }

  startVideoRecording() {
    // this.showCamera();
    // this.videoRecordedBlobs = [];
    // let options: any = {
    //   mimeType: "video/webm",
    // };
    // try {
    //   this.mediaVideoRecorder = new MediaRecorder(this.stream, options);
    // } catch (err) {
    //   console.log(err);
    // }
    // this.mediaVideoRecorder.start();
    this.isRecording = !this.isRecording;
    // this.onDataAvailableVideoEvent();
    // this.onStopVideoRecordingEvent();
  }
  stopVideoRecording() {
    // this.mediaVideoRecorder.stop();
    this.isRecording = !this.isRecording;
  }
  playRecording() {
    if (!this.videoRecordedBlobs || !this.videoRecordedBlobs.length) {
      return;
    }
    this.recordVideoElement.play();
  }
  onDataAvailableVideoEvent() {
    try {
      this.mediaVideoRecorder.ondataavailable = (event: any) => {
        if (event.data && event.data.size > 0) {
          this.videoRecordedBlobs.push(event.data);
        }
      };
    } catch (error) {
      console.log(error);
    }
  }
  onStopVideoRecordingEvent() {
    try {
      this.mediaVideoRecorder.onstop = (event: Event) => {
        const videoBuffer = new Blob(this.videoRecordedBlobs, {
          type: "video/webm",
        });
        this.downloadVideoUrl = window.URL.createObjectURL(videoBuffer);
        this.recordVideoElement.src = this.downloadVideoUrl;
      };
    } catch (error) {
      console.log(error);
    }
  }

  sendMessage() {
    //this.messageInfo.MessageMasterId = this.commonServ.messageMasterInfo.MessageMasterId;
    this.service
      .saveMessageInfo(this.messageInfo)
      .subscribe((res: MessageData) => {
        if (res.MessageId > 0) {
          this.signalRServ.sendMessage(res);
          this.messageInfo = new MessageData();
          this.messageInfo.MessageMasterId =
            this.commonServ.messageMasterInfo.MessageMasterId;
        } else {
        }
      });
  }
  textAreaAdjust(element) {
    const app = document.getElementsByClassName("js-auto-expand");

    // element.style.height = "1px";
    // element.style.height = (25+element.scrollHeight)+"px";
  }
  collapseToggle() {
    if (this.isChatExpend) {
      this.isChatExpend = false;
    } else {
      this.isChatExpend = true;
    }
  }
  toggleChat(){
    $("#message-body").toggleClass("message_active");
  }
}
