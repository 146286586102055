import {
  Component,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
  OnInit,
} from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { UsereditComponent } from "../user/useredit.component";
import { SchooleditComponent } from "../school/schooledit.component";
import { QuestioneditComponent } from "../question/questionedit.component";
import { EditassignmentComponent } from "../assignment/editassignment.component";
import { CommonService } from "../shared/common.service";
import { EditeventComponent } from "../event/editevent.component";
import { EditchatComponent } from "../chat/editchat.component";
import { EditsurveyComponent } from "../survey/editsurvey.component";
import { AnalysisComponent } from "../analysis/analysis.component";
import { CUSTOMCONST } from "../shared/Const";
import { EditPaymentsComponent } from "../payments/edit-payments.component";
import { EdittermComponent } from "../term/editterm.component";
import { EditTimeTableComponent } from "../timetable/edit-time-table/edit-time-table.component";
import { EditClassComponent } from "../class-list/edit-class.component";
import { AddmlinfoComponent } from "../question/addmlinfo/addmlinfo.component";
import { EditQRCodeComponent } from "../QRCode/edit-qrcode.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { NgIf } from "@angular/common";
import { take } from "rxjs/operators"; // Added this import for rxjs operators
// import { EditFeedbackComponent } from "../feedback/edit-feedback.component";
// import { AnalysisQuestionairComponent } from "../analysis/analysis-questionair.component";
// import { EditOwnDocsComponent } from "../fileManagement/edit-own-docs.component";
// import { LeaveRequestComponent } from "../leave/leave-request/leave-request.component";
// import { EditclassDairyComponent } from "../dairy/editclass-dairy/editclass-dairy.component";
// import { EditASSDocComponent } from "../fileManagement/edit-assdoc.component";
// import { EditApsdocComponent } from "../fileManagement/edit-apsdoc.component";
// import { EditBadgeComponent } from "../badge/edit-badge.component";
// import { EditsubjectComponent } from "../subject-list/editsubject/editsubject.component";

@Component({
  selector: "app-loading-overlay",
  template: `
    <div>
      <div
        *ngIf="params.enableMenu"
        #menuButton
        class="customHeaderMenuButton"
        (click)="onMenuClicked()"
      >
        <i class="fa {{ params.menuIcon }}"></i>
      </div>
      <div
        *ngIf="params.enableButton"
        class="customHeaderMenuButton"
        (click)="onMenuClicked()"
      >
        <button value="{{ params.button }}">{{ params.button }}</button>
      </div>
      <div *ngIf="params.enableText" class="customHeaderLabel">
        {{ params.displayName }}
      </div>
      <section
        *ngIf="params.checkboxToggle"
        class="costomHeaderCheckbox"
        (click)="params.Click(0, params.that)"
      >
        <mat-checkbox class="example-margin"></mat-checkbox>
      </section>
      <div
        *ngIf="params.enableSorting"
        (click)="onSortRequested('asc', $event)"
        class="customSortDownLabel"
      >
        <i class="fa fa-long-arrow-down"></i>
      </div>
      <div
        *ngIf="params.enableSorting"
        (click)="onSortRequested('desc', $event)"
        class="customSortUpLabel"
      >
        <i class="fa fa-long-arrow-up"></i>
      </div>
      <div
        *ngIf="params.enableSorting"
        (click)="onSortRequested('', $event)"
        class="customSortRemoveLabel"
      >
        <i class="fa fa-times"></i>
      </div>
    </div>
  `,
  styles: [
    `
      .customHeaderMenuButton,
      .customHeaderMenuIcon {
        margin-top: 7px;
        margin-left: 4px;
        float: left;
      }
      .costomHeaderCheckbox {
        margin-top: 7px;
      }
      .customHeaderLabel {
        margin-left: 5px;
        margin-top: 3px;
      }
      .customSortDownLabel,
      .customSortUpLabel,
      .customSortRemoveLabel {
        float: left;
        margin-left: 10px;
        margin-top: 5px;
      }
      .customSortUpLabel {
        margin-left: 3px;
        margin-top: 4px;
      }
      .customSortRemoveLabel {
        font-size: 11px;
        margin-left: 3px;
        margin-top: 6px;
      }
      .active {
        color: cornflowerblue;
      }
    `,
  ],
  standalone: true,
  imports: [NgIf, MatCheckboxModule, UsereditComponent],
})
export class CustomHeader implements OnInit {
  @Output() Editclick = new EventEmitter<string>();
  public params: any;
  public ascSort = "";
  public descSort = "";
  public noSort = "";
  public colId: string;
  public sortNumber = 0;
  public bsModalRef!: BsModalRef;
  CUSTOMCONST: any = CUSTOMCONST;

  @ViewChild("menuButton", { read: ElementRef }) public menuButton!: ElementRef;

  constructor(
    private modalService: BsModalService,
    public commonServ: CommonService
  ) {}

  ngOnInit(): void {
    console.log("Component Initialized");
  }

  agInit(params: any): void {
    this.params = params;
    params.column.addEventListener(
      "sortChanged",
      this.onSortChanged.bind(this)
    );
    this.onSortChanged();
    this.colId = this.params.column.getColId().replace(/\s/g, "");
  }

  onMenuClicked() {
    switch (this.params.displayName) {
      case "SchoolId":
        this.openschoolmodal();
        break;
      case "QuestionMasterId":
        this.openquestionmodal();
        break;
      case "AssignmentId":
        this.openAssignmentModal();
        break;
      case "SubjectId":
        this.openSubjectModal();
        break;
      case "EventId":
        this.openEventModal();
        break;
      case "FeedbackId":
        this.openFeedbackModal();
        break;
      case "SurveyId":
        this.openSurveyModal();
        break;
      case "MessageMasterId":
        this.openChatModal();
        break;
      case "AnalysisMasterId":
        this.openAnalysisModal();
        break;
      case "AnalysisQuestionId":
        this.openAnalysisQuestionairModal();
        break;
      case "TermId":
        this.openTermModal();
        break;
      case "PaymentDataId":
        this.openPaymentModal();
        break;
      case "UserDocumentId":
        this.openOwnDocumentModel();
        break;
      case "LeaveId":
        this.openLeaveRequestModel();
        break;
      case "ClassTTId":
        this.openTimeTableModel();
        break;
      case "ClassDairyMasterId":
        this.openClassDairyModel();
        break;
      case "shareDocId":
        this.openAdminSharedModel();
        break;
      case "ClassId":
        this.openClassModel();
        break;
      case "QRCodeId":
        this.openQRCodeModel();
        break;
      case "spaDocId":
        this.openSPADocModel();
        break;
      case "badgeId":
        this.openBadgeModel();
        break;
      default:
        this.openusermodal(0);
        break;
    }
  }
  openBadgeModel() {
    console.log(this.params);
  }
  openSPADocModel() {
    console.log(this.params);
  }
  openQRCodeModel() {
    const initialState = {
      list: [
        {
          id:0,
          action:'',
        }
      ]      
    };
    this.bsModalRef = this.modalService.show(EditQRCodeComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true}); 
  ;
  }
  openClassModel() {
    const initialState = {
      list: [
        {id:0},
        {Text:'ClassData'}
      ]
    };    
    this.bsModalRef = this.modalService.show(EditClassComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'second col-12 modal-dialog-centered model-sm',ignoreBackdropClick: true});
  }
  openAdminSharedModel() {
    console.log(this.params);
  }
  openClassDairyModel() {
    console.log(this.params);
  }
  openTimeTableModel() {
    const initialState = {
      list: [
        {id:0}
      ]      
    };
    this.bsModalRef = this.modalService.show(EditTimeTableComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});      
  }
  openLeaveRequestModel() {
    console.log(this.params);
  }
  openOwnDocumentModel() {
    console.log(this.params);
  }
  openPaymentModal() {
    const initialState = {
      list: [
        {id:0}
      ]
    };
    this.bsModalRef = this.modalService.show(EditPaymentsComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});
  }
  openTermModal() {
    const initialState = {
      list: [
        {id:0}
      ]
    };
    this.bsModalRef = this.modalService.show(EdittermComponent,{id: CUSTOMCONST.LABEL_ONE_MODAL,initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});

  }
  openAnalysisQuestionairModal() {
    console.log(this.params);
  }
  openAnalysisModal() {
    const initialState = {
      list: [{ id: 0 }],
    };
    this.bsModalRef = this.modalService.show(AnalysisComponent, {
      id: CUSTOMCONST.LABEL_ONE_MODAL,
      initialState,
      class: "modal-dialog modal-dialog-centered",
      ignoreBackdropClick: true,
    });
  }
  openChatModal() {
    const initialState = { list: [{ id: 0 }] };
    this.bsModalRef = this.modalService.show(EditchatComponent, {
      initialState,
      class: "modal-dialog modal-dialog-centered",
      ignoreBackdropClick: true,
    });
    this.bsModalRef.content.saved
      .pipe(take(1))
      .subscribe(this.addNewRecord.bind(this));
  }
  openSurveyModal() {
    const initialState = {
      list: [{ id: 0 }],
    };
    this.bsModalRef = this.modalService.show(EditsurveyComponent, {
      initialState,
      class: "modal-lg mb-0",
      ignoreBackdropClick: true,
    });
  }
  openFeedbackModal() {
    console.log(this.params);
  }
  openEventModal() {
    const initialState = {
      list: [
        {id:0}
      ]
    };
    this.bsModalRef = this.modalService.show(EditeventComponent,{initialState, class: 'modal-lg mb-0',ignoreBackdropClick: true});
  }
  openAssignmentModal() {
    const initialState = { list: [{ id: 0 }] };
    this.bsModalRef = this.modalService.show(EditassignmentComponent, {
      initialState,
      class: "modal-dialog modal-dialog-centered",
      ignoreBackdropClick: true,
    });
    this.bsModalRef.content.saved
      .pipe(take(1))
      .subscribe(this.addNewRecord.bind(this));
  }
  openSubjectModal() {
    const initialState = {
      list: [
        {id:0},
        {Text:"MLSubject"},
        {classId:localStorage.getItem("classId")},
        {subjectId:0}
      ]
  
    };
    console.log(this.modalService);
    
    this.bsModalRef = this.modalService.show(AddmlinfoComponent,{initialState, class: 'second col-12 modal-dialog-centered model-sm',ignoreBackdropClick: true});
  
  }

  openschoolmodal() {
    const initialState = { list: [{ id: 0 }] };
    this.bsModalRef = this.modalService.show(SchooleditComponent, {
      initialState,
      class: "modal-dialog modal-dialog-centered",
      ignoreBackdropClick: true,
    });
    this.bsModalRef.content.saved
      .pipe(take(1))
      .subscribe(this.addNewRecord.bind(this));
  }

  openquestionmodal() {
    const initialState = { list: [{ id: 0 }] };
    this.bsModalRef = this.modalService.show(QuestioneditComponent, {
      initialState,
      class: "modal-dialog modal-dialog-centered",
      ignoreBackdropClick: true,
    });
    this.bsModalRef.content.saved
      .pipe(take(1))
      .subscribe(this.addNewRecord.bind(this));
  }

  openusermodal(userid: number) {
    const initialState = { list: [{ id: userid }] };
    this.bsModalRef = this.modalService.show(UsereditComponent, {
      id: 1,
      initialState,
      class: "modal-dialog modal-dialog-centered",
      ignoreBackdropClick: true,
    });
    this.bsModalRef.content.saved
      .pipe(take(1))
      .subscribe(this.addNewRecord.bind(this));
  }

  addNewRecord(someData: any) {
    this.commonServ.CallOnRefreshGrid();
  }

  onSortChanged() {
    this.checkSortOrder();
    this.ascSort = this.descSort = this.noSort = "inactive";
    if (this.params.column.isSortAscending()) {
      this.ascSort = "active";
    } else if (this.params.column.isSortDescending()) {
      this.descSort = "active";
    } else {
      this.noSort = "active";
    }
  }

  checkSortOrder() {
    const sortingArray = this.params.api.getSortModel();
    if (sortingArray.length > 1) {
      setTimeout(() => {
        sortingArray.forEach((sort, index) => {
          this.sortNumber = index + 1;
          const sortingDom = <HTMLElement>(
            document.getElementById(
              "sortingOrder" + sort.colId.replace(/\s/g, "")
            )
          );
          if (sortingDom) sortingDom.innerHTML = String(index + 1);
        });
      }, 1000);
    }
  }

  onSortRequested(order: string, event: any) {
    this.params.setSort(order, event.shiftKey);
  }

  // Additional modal opening methods can go here...
}
