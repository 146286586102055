<!-- <canvas id="canvas" width="400" height="300"></canvas>
<div class="controls">
    <label for="colorPicker">Color:</label>
    <input type="color" id="colorPicker" value="#00FF00">
    
    <label for="lineWidth">Line Width:</label>
    <input type="range" id="lineWidth" min="1" max="50" value="5">
    
    <label for="shapeSelect">Shape:</label>
    <select id="shapeSelect">
        <option value="line">Line</option>
        <option value="rectangle">Rectangle</option>
        <option value="circle">Circle</option>
        <option value="pencil">Pencil</option>
        <option value="brush">Brush</option>
        <option value="eraser">Eraser</option>
    </select>

    <button id="undoButton">Undo</button>
</div>
<video id="videoStream" controls width="400"></video> -->

<div id="canvas-designer"></div>