import { Component, OnInit, EventEmitter } from "@angular/core";
import { NgForm, FormsModule } from "@angular/forms";
import {
  AngularEditorConfig,
  AngularEditorModule,
} from "@kolkov/angular-editor";
import {
  BsDatepickerConfig,
  BsDatepickerModule,
} from "ngx-bootstrap/datepicker";
import { BsModalRef } from "ngx-bootstrap/modal";
import {
  AssignamentFileInfo,
  AssignamentView,
} from "src/app/shared/assignemnt.model";
import { AssignemntService } from "src/app/shared/assignemnt.service";
import { CommonService } from "../shared/common.service";
import { NotificationService } from "../shared/notification.service";
import { QAnswerData, QuestionData } from "../shared/question.model";
import { EmbedVideoService } from "ngx-embed-video";
import { Ng7LargeFilesUploadLibComponent } from "ng7-large-files-upload-lib";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import CONFIG from "../shared/Const";
import { DateService } from "../shared/date.service";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { MatCardModule } from "@angular/material/card";
import { MatRadioModule } from "@angular/material/radio";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { NgIf, NgFor, NgClass } from "@angular/common";

@Component({
  selector: "app-editassignment",
  templateUrl: "./editassignment.component.html",
  styleUrls: ["./editassignment.component.scss"],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    FormsModule,
    BsDatepickerModule,
    MatCheckboxModule,
    MatRadioModule,
    AngularEditorModule,
    MatCardModule,
    MatIconModule,
    TranslateModule,
    // Ng7LargeFilesUploadLibComponent (Commented out if not used)
  ],
  providers: [
    AssignemntService,
    NotificationService,
    CommonService,
    DateService,
  ],
})
export class EditassignmentComponent implements OnInit {
  list: any[] = [];
  IsStart: boolean;
  stepNo: number;
  typeList: any;
  url: any;
  format: any;
  assignmentType: any;
  isQuestionir: boolean;
  isProject: boolean;
  tempVal: any;
  datepickerConfig: BsDatepickerConfig;
  bsValue = new Date();
  bsRangeValue: Date[];
  maxDate = new Date();
  minDate = new Date();
  time: Date = new Date();
  fileUploading: boolean;
  public startTime: string;
  public endTime: string;
  FileInfo: AssignamentFileInfo = new AssignamentFileInfo();
  public saved: EventEmitter<any> = new EventEmitter();
  gridOptions: any;
  enbedUrl: any;
  youtubeUrlId: string;
  urlSafe: SafeResourceUrl;
  fileType: any;
  readonly baseurl = CONFIG.UPLOAD_BASE_URL;
  public questionMasterInfo: any;
  public questionInfo: any;
  QindexLabel: any[] = [];
  QindexLabel2: any[] = [];
  questiontypelist: any = [];
  QuestionType: any;
  videoTypeList: string[] = ["Url", "File"];
  optionTypeList: string[] = ["Text", "Image"];
  role: string;
  yearList: any[] = [];
  timeSlotList: any[] = [];
  private embedService: EmbedVideoService;

  constructor(
    public service: AssignemntService,
    public bsModalRef: BsModalRef,
    private notificationServ: NotificationService,
    private commonService: CommonService,
    // private embedService: EmbedVideoService,
    public sanitizer: DomSanitizer,
    public _dateSer: DateService
  ) {
    this.datepickerConfig = Object.assign({
      dateInputFormat: "DD-MM-YYYY",
      showWeekNumbers: false,
      showTodayButton: true,
      todayPosition: "center",
    });
    this.minDate.setDate(this.minDate.getDate() - 0);
    this.maxDate.setDate(this.maxDate.getDate() + 30);
    this.bsRangeValue = [this.bsValue, this.maxDate];
    this.role = commonService.role;
    this.timeSlotList = this._dateSer.gettimeslotlist();
  }

  ngOnInit(): void {
    console.log("hhhhhhhhhhh");

    this.timeSlotList = this._dateSer.gettimeslotlist();
    this.service.getassignment(this.list[0].id).subscribe((res: any) => {
      this.service.formData = res;
      this.typeList = res.AssignmentTypeList;
      this.questiontypelist = res.QuestionTypeList;
      this.questionInfo = res.QuestionInfo;
      this.yearList = res.AcademicYearList;
      this.service.formData.AssignmentInfo.AssignmentId = this.list[0].id;
      this.service.formData.AssignmentInfo.UrlType =
        this.list[0].id == 0 ? "Url" : res.AssignmentInfo.UrlType;
      this.stepNo = 1;
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.service.formData.AssignmentInfo.Url
      );
      var ftList = this.service.formData.AssignmentInfo.Url.split(".");
      this.fileType = ftList[ftList.length - 1];

      if (this.service.formData.AssignmentInfo.UrlType == "Url") {
        console.log("jjjjjjjjjjjjjjjj");

        this.enbedUrl = this.embedService.embed(
          this.service.formData.AssignmentInfo.Url,
          {
            query: { portrait: 0, color: "333" },
            attr: { style: "width:100%;height:200px;" },
          }
        );
      }
    });

    this.url = "";
    this.isQuestionir = false;
    this.isProject = false;
    this.IsStart = false;
  }

  enddateSetting() {
    this.minDate = new Date(this.service.formData.AssignmentInfo.StartDate);
    this.maxDate = new Date(this.service.formData.AssignmentInfo.StartDate);
    this.minDate.setDate(this.minDate.getDate() + 0);
    this.maxDate.setDate(this.maxDate.getDate() + 15);
    this.bsRangeValue = [this.bsValue, this.maxDate];
  }
  radioChange(event) {
    this.service.formData.AssignmentInfo.UrlType = event.value;
    setTimeout(() => {
      for (var i = 0; i < document.getElementsByTagName("button").length; i++) {
        if (
          document.getElementsByTagName("button")[i].attributes["class"]
            .value == "ng-star-inserted"
        )
          document
            .getElementsByTagName("button")
            [i].setAttribute("style", "display:none");
      }
    }, 1000);
  }
  // optionTypeChange(event){

  //   this.questionInfo.OptionType = event.value;
  //   setTimeout(() => {
  //     for(var i =0; i < document.getElementsByTagName("button").length; i++ ){
  //       if(document.getElementsByTagName("button")[i].attributes["class"].value == "ng-star-inserted")
  //       document.getElementsByTagName("button")[i].setAttribute("style", "display:none")
  //       }

  //   }, 1000);

  // }
  AssignmentTypeChange() {
    this.assignmentType = "";
    if (this.service.formData.AssignmentInfo.TypeId > 0) {
      for (var i = 0; i < this.typeList.length; i++) {
        if (
          this.typeList[i].Id == this.service.formData.AssignmentInfo.TypeId
        ) {
          this.assignmentType = this.typeList[i].Name;
          break;
        }
      }
    }
  }
  largefileschange(event) {
    debugger;
  }

  filesuploaded(fileInfo) {
    debugger;
    if (fileInfo && fileInfo.IsSaved) {
      this.service.formData.AssignmentInfo.FileInfo = fileInfo;
    }
  }
  qAnswerFilesuploaded(fileInfo, index) {
    if (fileInfo && fileInfo.IsSaved) {
      this.questionInfo.QAnswerList[index].FileId = fileInfo.UserDocumentId;
      this.questionInfo.QAnswerList[index].FilePath =
        this.baseurl + "ReportFiles/" + fileInfo.FilePath;
    }
  }
  selectVideoFile(event) {
    const file = event.target.files && event.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      if (file.type.indexOf("video") > -1) {
        this.format = "video";
      }
      if (this.format == "video") {
        this.fileUploading = true;
        reader.onload = (event) => {
          this.url = (<FileReader>event.target).result;
          if (!this.service.formData.AssignmentInfo.FileInfo)
            this.service.formData.AssignmentInfo.FileInfo =
              new AssignamentFileInfo();
          this.service.formData.AssignmentInfo.FileInfo.FileName = file.name;
          this.service.formData.AssignmentInfo.FileInfo.OriginalContentString =
            this.url;
          this.service.formData.AssignmentInfo.FileInfo.FileSize = file.size;
          this.service.formData.AssignmentInfo.FileInfo.FileType = file.type;
          this.fileUploading = false;
        };
      } else {
        this.notificationServ.ShowError(
          "Invalid file format! Please select video file only"
        );
      }
    }
  }
  selectDocumentFile(event) {
    const file = event.target.files && event.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      if (file.type.indexOf("pdf") > -1) {
        this.format = "pdf";
        this.fileType = "";
      }
      if (file.type.indexOf("image") > -1) {
        this.format = "image";
      }
      if (this.format == "pdf" || this.format == "image") {
        this.fileUploading = true;
        reader.onload = (event) => {
          this.url = (<FileReader>event.target).result;
          if (!this.service.formData.AssignmentInfo.FileInfo)
            this.service.formData.AssignmentInfo.FileInfo =
              new AssignamentFileInfo();
          this.service.formData.AssignmentInfo.FileInfo.FileName = file.name;
          this.service.formData.AssignmentInfo.FileInfo.OriginalContentString =
            this.url;
          this.service.formData.AssignmentInfo.FileInfo.FileSize = file.size;
          this.service.formData.AssignmentInfo.FileInfo.FileType = file.type;
          this.fileUploading = false;
        };
      } else {
        this.notificationServ.ShowError(
          "Invalid file format! Please select PDF file only"
        );
      }
    }
  }
  selectAudioFile(event) {
    const file = event.target.files && event.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      if (file.type.indexOf("audio") > -1) {
        this.format = "audio";
      }
      if (this.format == "audio") {
        this.fileUploading = true;
        reader.onload = (event) => {
          this.url = (<FileReader>event.target).result;
          if (!this.service.formData.AssignmentInfo.FileInfo)
            this.service.formData.AssignmentInfo.FileInfo =
              new AssignamentFileInfo();
          this.service.formData.AssignmentInfo.FileInfo.FileName = file.name;
          this.service.formData.AssignmentInfo.FileInfo.OriginalContentString =
            this.url;
          this.service.formData.AssignmentInfo.FileInfo.FileSize = file.size;
          this.service.formData.AssignmentInfo.FileInfo.FileType = file.type;
          this.fileUploading = false;
        };
      } else {
        this.notificationServ.ShowError(
          "Invalid file format! Please select PDF file only"
        );
      }
    }
  }
  onSelectFile(event) {
    this.fileUploading = true;
    const file = event.target.files && event.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      if (file.type.indexOf("image") > -1) {
        this.format = "image";
      } else if (file.type.indexOf("video") > -1) {
        this.format = "video";
      }
      reader.onload = (event) => {
        this.url = (<FileReader>event.target).result;
        this.FileInfo.FileName = file.name;
        this.FileInfo.OriginalContentString = this.url;
        this.FileInfo.FileSize = file.size;
        this.FileInfo.FileType = file.type;
        this.fileUploading = false;
      };
    }
  }
  startAsignment(form) {
    this.stepNo = 2;
    this.isQuestionir = this.service.formData.AssignmentInfo.IsQuestionir;
    // alert(this.service.formData.AssignmentInfo.FileInfo.FileType);
    if (
      this.service.formData.AssignmentInfo.Name != null &&
      this.service.formData.AssignmentInfo.TypeId > 0
    ) {
      this.IsStart = true;

      if (this.list[0].id > 0) {
        this.assignmentType = this.service.formData.AssignmentInfo.Type;
        this.url = this.service.formData.AssignmentInfo.Url;
        this.format = this.assignmentType;
        this.questionInfo.QuestionNo = 0;
      } else {
        this.questionInfo = new QuestionData();
        this.questionInfo.QuestionNo = 0;
      }
    } else {
      this.notificationServ.ShowError("Pleae fill all requred fields");
    }
  }
  proceedNextStep(form) {
    this.stepNo = 3;
    this.isQuestionir = this.service.formData.AssignmentInfo.IsQuestionir;
    this.isProject = this.service.formData.AssignmentInfo.IsProject;
    if (this.isQuestionir) {
      if (
        this.list[0].id > 0 &&
        this.service.formData.QuestionInfo &&
        this.service.formData.QuestionInfo.length > 0
      ) {
        this.questionInfo = this.service.formData.QuestionInfo[0];
        this.questionTypeChange();
      } else {
        this.questionInfo = new QuestionData();
        this.questionInfo.QuestionNo = 1;
        this.questionInfo.QuestionTypeId = 0;

        this.questionMasterInfo.Name =
          this.service.formData.AssignmentInfo.Name;
        //this.questionMasterInfo.Description = this.service.formData.AssignmentInfo.Description;
        this.questionMasterInfo.StartDate =
          this.service.formData.AssignmentInfo.StartDate;
        this.questionMasterInfo.StartTime =
          this.service.formData.AssignmentInfo.StartTime;
        this.questionMasterInfo.EndDate =
          this.service.formData.AssignmentInfo.EndDate;
        this.questionMasterInfo.EndTime =
          this.service.formData.AssignmentInfo.EndTime;
        this.questionMasterInfo.IsHumanityLife =
          this.service.formData.AssignmentInfo.IsHumanityLife;
        this.questionMasterInfo.IsSkills =
          this.service.formData.AssignmentInfo.IsSkills;
        this.questionMasterInfo.IsAcademics =
          this.service.formData.AssignmentInfo.IsAcademics;
        this.questionMasterInfo.IsLearnOutOfBox =
          this.service.formData.AssignmentInfo.IsLearnOutOfBox;
      }
    }
  }
  prevQuestion() {
    if (this.list[0].id > 0) {
      //this.questionInfo;
      this.tempVal = this.questionInfo.QuestionNo - 1;
      if (this.tempVal > 0) {
        this.questionInfo =
          this.service.formData.QuestionInfo[this.tempVal - 1];
      }

      // this.questionInfo.QuestionNo=1;
      // this.questionInfo.QuestionTypeId =this.questionInfo[0].QuestionTypeId;

      this.questionTypeChange();
    } else {
      if (this.questionInfo.QuestionNo > 1) {
        var prvQ = this.service.formData.QuestionInfo.filter(
          (item) => item.QuestionNo == this.questionInfo.QuestionNo - 1
        );
        this.questionInfo = prvQ[0];
        this.QuestionType = this.questionInfo.QuestionType;
      } else {
        if (this.stepNo > 2) {
          this.stepNo = 2;
        } else {
          this.stepNo = 1;
        }
      }
    }
  }
  saveAssignment(form) {
    if (
      this.service.formData?.AssignmentInfo?.TypeId === 1 &&
      this.service.formData?.AssignmentInfo?.Duration <= 0
    ) {
      this.notificationServ.ShowInfo("Kindly Enter Duration");
      return;
    }
    if (this.questionInfo && this.questionInfo.QuestionType) {
      if (this.service.formData.QuestionInfo == null)
        this.service.formData.QuestionMasterInfo = this.questionMasterInfo;
      //this.service.formData.QuestionMasterInfo.push(this.questionMasterInfo);
      // this.service.formData.QuestionInfo = new Array<QuestionData>();

      if (this.service.formData.QuestionInfo == null)
        this.service.formData.QuestionInfo = new Array<QuestionData>();
      this.service.formData.QuestionInfo.push(this.questionInfo);
    }
    this.saved.emit("someData");
    this.service.saveassignment().subscribe((res: AssignamentView) => {
      if (res.ErrorMessage) {
        this.notificationServ.ShowError(res.ErrorMessage);
      } else if (res.IsSaved) {
        this.notificationServ.ShowInfo("Successfully saved!");
        this.commonService.CallOnRefreshGrid();
        this.bsModalRef.hide();
      }
    });
  }

  addNewRow(Type: string) {
    if (this.questionInfo.QuestionType == "FIB") {
      var answer1 = new QAnswerData();
      answer1.IsActive = true;
      answer1.Answer = "";
      answer1.AnswerNo = this.questionInfo.QAnswerList.length + 1;
      answer1.IsCorrect = true;
      answer1.FileId = 0;
      this.questionInfo.QAnswerList.push(answer1);
    } else if (
      this.questionInfo.QuestionType == "MC" ||
      this.questionInfo.QuestionType == "MR"
    ) {
      if (this.questionInfo.QAnswerList.length + 1 <= 8) {
        var answer1 = new QAnswerData();
        answer1.IsActive = true;
        answer1.Answer = "";
        answer1.AnswerNo = this.questionInfo.QAnswerList.length + 1;
        answer1.FileId = 0;
        this.questionInfo.QAnswerList.push(answer1);
        answer1.GroupNo =
          this.QindexLabel[this.questionInfo.QAnswerList.length].text;
      } else {
        this.notificationServ.ShowInfo("You can add maximam 8 rows only!");
      }
    } else if (
      this.questionInfo.QuestionType == "MF" ||
      this.questionInfo.QuestionType == "MM"
    ) {
      if (this.questionInfo.QAnswerList.length + 1 <= 8) {
        var answer1 = new QAnswerData();
        answer1.IsActive = true;
        answer1.Answer = "";
        answer1.Answer1 = "";
        answer1.FileId = 0;
        answer1.GroupNo =
          this.QindexLabel[this.questionInfo.QAnswerList.length].text;
        answer1.GroupNo1 =
          this.QindexLabel2[this.questionInfo.QAnswerList.length].text;
        answer1.AnswerNo = this.questionInfo.QAnswerList.length + 1;
        this.questionInfo.QAnswerList.push(answer1);
      } else {
        this.notificationServ.ShowInfo("You can add maximam 8 rows only!");
      }
    }
  }
  deleteRow(qAnswer: QAnswerData) {
    this.questionInfo.QAnswerList.forEach((element, index) => {
      if (element.AnswerNo == qAnswer.AnswerNo)
        this.questionInfo.QAnswerList.splice(index, 1);
    });
    for (var i = 0; i < this.questionInfo.QAnswerList.length; i++) {
      this.questionInfo.QAnswerList[i].AnswerNo = i + 1;
    }
  }

  addNewAnswer(form: NgForm) {
    var nextQuestion = [];
    if (
      this.service.formData.QuestionInfo &&
      this.service.formData.QuestionInfo.length > 0
    ) {
      nextQuestion = this.service.formData.QuestionInfo.filter(
        (item) => item.QuestionNo == this.questionInfo.QuestionNo + 1
      );
    }
    if (this.list[0].id > 0 && nextQuestion.length > 0) {
      if (
        this.questionInfo.QuestionNo < this.service.formData.QuestionInfo.length
      ) {
        this.questionInfo =
          this.service.formData.QuestionInfo[this.questionInfo.QuestionNo];

        this.questionTypeChange();
      }
    } else if (nextQuestion.length > 0) {
      //  var currentQ  =this.service.formData.QuestionInfo.filter(item => item.QuestionNo == this.questionInfo.QuestionNo);
      //  currentQ = this.questionInfo;
      this.questionInfo = nextQuestion[0];
      this.QuestionType = this.questionInfo.QuestionType;
      // this.questionInfo.OptionType = this.service.formData.QuestionInfo.OptionType;
    } else {
      // this.questionInfo.OptionType ="Text";
      if (this.service.formData.QuestionInfo == null)
        this.service.formData.QuestionInfo = new Array<QuestionData>();
      if (this.questionInfo.QuestionId <= 0)
        this.service.formData.QuestionInfo.push(this.questionInfo);
      this.questionInfo = new QuestionData();
      this.questionInfo.QuestionNo =
        this.service.formData.QuestionInfo.length + 1;
      // this.tempVal = this.questionInfo.QuestionNo;
      this.questionInfo.QuestionTypeId = 0;
      this.QuestionType = "";
    }
  }
  changeTF() {
    this.questionInfo.QAnswerList[1].IsCorrect =
      !this.questionInfo.QAnswerList[0].IsCorrect;
  }
  changeMC(length, index) {
    var i = 0;
    for (i; i < length; i++) {
      if (i != index) {
        this.questionInfo.QAnswerList[i].CorrectLabel = "";
      } else {
        this.questionInfo.QAnswerList[index].CorrectLabel =
          this.questionInfo.QAnswerList[index].CorrectLabel;
      }
    }
  }
  questionTypeChange() {
    // this.questionInfo.OptionType ="";
    if (this.list[0].id > 0 && this.questionInfo.QuestionId > 0) {
      this.QuestionType = "";
      // this.questionInfo.OptionType = this.service.formData.QuestionInfo.OptionType;
      if (this.questionInfo.QuestionTypeId > 0) {
        for (var i = 0; i < this.questiontypelist.length; i++) {
          if (this.questiontypelist[i].Id == this.questionInfo.QuestionTypeId) {
            this.QuestionType = this.questiontypelist[i].Code;
            break;
          }
        }
      }

      this.questionInfo.QuestionType = this.QuestionType;
      if (this.QuestionType == "MC" || this.QuestionType == "MR") {
        this.QindexLabel = [
          { text: "A" },
          { text: "B" },
          { text: "C" },
          { text: "D" },
          { text: "E" },
          { text: "F" },
          { text: "G" },
          { text: "H" },
        ];
      } else if (this.QuestionType == "TF") {
        this.QindexLabel = [{ text: true }, { text: false }];
      }
      // this.questionInfo.QAnswerList=this.questionInfo[0].QAnswerList;
    } else {
      this.questionInfo.OptionType = "Text";
      this.QuestionType = "";
      if (this.questionInfo.QuestionTypeId > 0) {
        for (var i = 0; i < this.questiontypelist.length; i++) {
          if (this.questiontypelist[i].Id == this.questionInfo.QuestionTypeId) {
            this.QuestionType = this.questiontypelist[i].Code;
            break;
          }
        }
      }
      this.questionInfo.QuestionType = this.QuestionType;
      // this.initItemRow(this.questiontypelist[i].Code);

      if (this.QuestionType == "TF") {
        this.QindexLabel = [{ text: true }, { text: false }];
        // this.questionInfo.QAnswerList[0].Answer= "True";
        // this.questionInfo.QAnswerList[1].Answer= "False";
        this.questionInfo.QAnswerList = new Array<QAnswerData>();
        var answer1 = new QAnswerData();
        answer1.AnswerNo = 1;
        answer1.IsActive = true;
        answer1.Answer = "True";
        answer1.IsCorrect = true;
        answer1.FileId = 0;
        this.questionInfo.QAnswerList.push(answer1);
        var answer2 = new QAnswerData();
        answer2.AnswerNo = 2;
        answer2.IsActive = true;
        answer2.Answer = "False";
        answer2.FileId = 0;
        this.questionInfo.QAnswerList.push(answer2);
      } else if (this.QuestionType == "FIB" || this.QuestionType == "Eassy") {
        this.questionInfo.QAnswerList = new Array<QAnswerData>();
        var answer1 = new QAnswerData();
        answer1.AnswerNo = 1;
        answer1.IsActive = true;
        answer1.IsCorrect = true;
        answer1.FileId = 0;
        this.questionInfo.QAnswerList.push(answer1);
      } else if (this.QuestionType == "MC" || this.QuestionType == "MR") {
        this.QindexLabel = [
          { text: "A" },
          { text: "B" },
          { text: "C" },
          { text: "D" },
          { text: "E" },
          { text: "F" },
          { text: "G" },
          { text: "H" },
        ];
        this.questionInfo.QAnswerList = new Array<QAnswerData>();

        var answer1 = new QAnswerData();
        answer1.AnswerNo = 1;
        answer1.IsActive = true;
        answer1.FileId = 0;
        this.questionInfo.QAnswerList.push(answer1);
        answer1.GroupNo = this.QindexLabel[0].text;

        var answer2 = new QAnswerData();
        answer2.AnswerNo = 2;
        answer2.IsActive = true;
        answer2.FileId = 0;
        this.questionInfo.QAnswerList.push(answer2);
        answer2.GroupNo = this.QindexLabel[1].text;

        var answer3 = new QAnswerData();
        answer3.AnswerNo = 3;
        answer3.IsActive = true;
        answer3.FileId = 0;
        this.questionInfo.QAnswerList.push(answer3);
        answer3.GroupNo = this.QindexLabel[2].text;

        var answer4 = new QAnswerData();
        answer4.AnswerNo = 4;
        answer4.IsActive = true;
        answer4.FileId = 0;
        this.questionInfo.QAnswerList.push(answer4);
        answer4.GroupNo = this.QindexLabel[3].text;
      } else if (this.QuestionType == "MF" || this.QuestionType == "MM") {
        this.QindexLabel = [
          { text: "A" },
          { text: "B" },
          { text: "C" },
          { text: "D" },
          { text: "E" },
          { text: "F" },
          { text: "G" },
          { text: "H" },
        ];
        this.QindexLabel2 = [
          { text: "P" },
          { text: "Q" },
          { text: "R" },
          { text: "S" },
          { text: "T" },
          { text: "U" },
          { text: "V" },
          { text: "W" },
        ];
        this.questionInfo.QAnswerList = new Array<QAnswerData>();
        var answer1 = new QAnswerData();

        answer1.Answer = "";
        answer1.Answer1 = "";
        answer1.GroupNo = this.QindexLabel[0].text;
        answer1.GroupNo1 = this.QindexLabel2[0].text;
        answer1.AnswerNo = 1;
        answer1.IsActive = true;
        answer1.FileId = 0;
        this.questionInfo.QAnswerList.push(answer1);

        var answer2 = new QAnswerData();
        answer2.Answer = "";
        answer2.Answer1 = "";
        answer2.GroupNo = this.QindexLabel[1].text;
        answer2.GroupNo1 = this.QindexLabel2[1].text;
        answer2.AnswerNo = 2;
        answer2.IsActive = true;
        answer2.FileId = 0;
        this.questionInfo.QAnswerList.push(answer2);

        var answer3 = new QAnswerData();
        answer3.Answer = "";
        answer3.Answer1 = "";
        answer3.GroupNo = this.QindexLabel[2].text;
        answer3.GroupNo1 = this.QindexLabel2[2].text;
        answer3.AnswerNo = 3;
        answer3.IsActive = true;
        answer3.FileId = 0;
        this.questionInfo.QAnswerList.push(answer3);

        var answer4 = new QAnswerData();
        answer4.Answer = "";
        answer4.Answer1 = "";
        answer4.GroupNo = this.QindexLabel[3].text;
        answer4.GroupNo1 = this.QindexLabel2[3].text;
        answer4.AnswerNo = 4;
        answer4.IsActive = true;
        answer4.FileId = 0;
        this.questionInfo.QAnswerList.push(answer4);
      }
    }
  }
  // getvideoType(){

  // }

  changeIsQuestionirStatus() {
    if (this.isQuestionir) {
      this.isQuestionir = false;
    } else {
      this.isQuestionir = true;
    }
  }
  // changeisProjectStatus(){
  //   if(this.isProject){
  //     this.isProject = false;
  //   }
  //   else{
  //     this.isProject = true;
  //   }

  // }
  htmlContent = "";

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: "Enter text here...",
    translate: "no",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [["bold"]],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };
}
