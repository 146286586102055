<div class="title w-100">
  <h3>Answers</h3>
</div>
<div class="question-col" *ngFor="let answer of answerList">
  <div class="question-header">
    <div class="question-title">
      <!-- <h3>{{question.subject}}</h3> -->
      <p><span class="bi bi-person"></span> {{answer.CreatedBy}}</p>
    </div>
  </div>
  <div class="ques-video">
    <video #videoElement [src]="answer.Url" controls></video>
  </div>
</div>