import { formatDate, NgFor, NgIf  ,Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  BsDatepickerConfig,
  BsDatepickerModule,
} from "ngx-bootstrap/datepicker";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CustommenuComponent } from "../custommenu/custommenu.component";
import { AttendanceService } from "../shared/attendance.service";
import { CommonService } from "../shared/common.service";
import { CUSTOMCONST } from "../shared/Const";
import { DateService } from "../shared/date.service";
import { EventService } from "../shared/event.service";
import { NotificationService } from "../shared/notification.service";
import { TakeAttendanceComponent } from "./take-attendance.component";
import { AgGridModule } from "ag-grid-angular";
import { FormsModule } from "@angular/forms";
import { MatTabsModule } from "@angular/material/tabs";

@Component({
  selector: "app-attendence",
  templateUrl: "./attendance.component.html",
  styleUrls: ["./attendance.component.scss"],
  standalone: true,
  imports: [
    MatTabsModule,
    BsDatepickerModule,
    FormsModule,
    NgFor,
    AgGridModule,
    NgIf,
  ],
})
export class AttendenceComponent implements OnInit {
  attendeceRowData: any[] = [];
  filteredattendecerowData: any[] = [];
  filterattendeceList: string[] = [];
  attendeceRowSelection;
  attendeceDefaultColDef;
  attendeceColumnDefs;
  attendeceGridOptions: any;
  gridApi;
  gridColumnApi;

  MAttendeceRowData: any[] = [];
  filteredrowData: any[] = [];
  filterList: string[] = [];
  MAttendeceRowSelection;
  MAttendeceDefaultColDef;
  MAttendeceColumnDefs;
  MAttendeceGridOptions: any;
  MAttendeceGridApi;
  MAttendeceGridColumnApi;

  currentyear: any;
  selectedYear: string;
  startDate: string;
  endDate: string;
  yearList: any;
  classList: any;
  selectedDate: string;
  selectedClassId: number = 1;
  activeTab: string;
  config = {
    params: {
      startDate: "",
      endDate: "",
    },
  };
  attandanceConfig = {
    params: {
      classId: 0,
      date: "",
    },
  };
  public bsModalRef: BsModalRef;
  datepickerConfig: BsDatepickerConfig;
  constructor(
    private modalService: BsModalService,
    public service: EventService,
    private notificationServ: NotificationService,
    public commonServ: CommonService,
    private attandanceServ: AttendanceService,
    public dateService: DateService,
    private location: Location
  ) {
    this.datepickerConfig = Object.assign({
      dateInputFormat: "DD-MM-YYYY",
      showWeekNumbers: false,
      showTodayButton: true,
      todayPosition: "center",
    });
    this.selectedDate = this.dateService.getStringDate(new Date());
    this.commonServ.RefreshGrid$.subscribe((item) => {
      this.getattendecelist();
    });
  }

  ngOnInit(): void {
    this.getClass();
    this.getmanualattendancelist();
    this.prepareAColumnDefs();

    this.prepareMAColumnDefs();

    this.commonServ.getMlListInfo("MLAcademicYear").subscribe((res: any) => {
      res = res.slice().reverse();
      this.yearList = res;
      this.selectedYear = res[0].Code;
      this.config = {
        params: {
          startDate: res[0].Code,
          endDate:
            "30-04-" +
            (parseInt(formatDate(new Date(res[0].Code), "yyyy", "en")) + 1),
        },
      };
    });
  }
  goBack(): void {
    this.location.back(); // Navigates back to the previous route
  }
  prepareAColumnDefs() {
    this.attendeceColumnDefs = [
      {
        headerName: "Class",
        field: "Key",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
      },
      {
        headerName: "Name",
        field: "Value",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
      },
      {
        headerName: "Date",
        field: "Result",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
      },
      // { headerName:"attendece",field: 'Result1', sortable: true, filter: 'agTextColumnFilter' ,suppressMenu: true,menuTabs: [],floatingFilterComponentParams: { suppressFilterButton: true, }, resizable: true, width: 120 },
    ];

    this.attendeceDefaultColDef = {
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      floatingFilter: true,
    };
  }

  prepareMAColumnDefs() {
    this.MAttendeceColumnDefs = [
      {
        headerName: "",
        field: "AppointmentId",
        cellRendererFramework: CustommenuComponent,
        cellRendererParams: {
          MenuList: [
            {
              Name: "View Appointment",
              Click: this.getStudentAttendanceViewInfo,
              that: this,
            },
          ],
        },

        filter: false,
        width: 60,
        maxWidth: 60,
        // headerComponent: 'customHeader',
        // headerComponentParams : { button : "click", enableMenu : true, menuIcon : "fa-plus", enableSorting:false}
      },
      {
        field: "CreatedBy",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
      },
      {
        field: "CreatedOn",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
      },
      {
        field: "Description",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
      },
      {
        field: "Text",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
      },
    ];
    // this.frameworkComponents = {
    //   customHeader: CustomHeader
    // };
    this.MAttendeceDefaultColDef = {
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      floatingFilter: true,
    };
  }
  getClass() {
    this.commonServ.getclasses().subscribe((res: any) => {
      this.classList = res;
    });
  }
  selectedDateChage() {
    this.selectedDate = this.dateService.getStringDate(this.selectedDate);
    // this.getmanualattendancelist();
  }
  // selectedClassChange(){
  //   this.getmanualattendancelist();
  // }
  activeTabChange(event) {
    this.activeTab = event.tab.textLabel;
    console.log(this.activeTab);
    if (this.activeTab == "Auto Generated Attendance") {
    } else if (this.activeTab == "Manual Attandance") {
    }
  }
  onAttendeceReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    var config = {
      params: {
        startDate: this.startDate,
        endDate: this.endDate,
      },
    };
    setTimeout(() => {
      this.getattendecelist();
    }, 500);
  }
  onMAttendeceReady(params) {
    this.MAttendeceGridApi = params.api;
    this.MAttendeceGridColumnApi = params.columnApi;

    setTimeout(() => {
      this.getmanualattendancelist();
    }, 500);
  }

  getattendecelist() {
    this.service.getattendecelistByParam(this.config).subscribe((res: any) => {
      this.attendeceRowData = res;
      this.filteredattendecerowData = res;
      const rowData = res;
      // this.gridApi.setRowData(rowData);
    });
  }
  onAttendeceReload(data) {
    this.attendeceRowData = data;
    this.filteredattendecerowData = data;
  }
  onMAttendeceReload(data) {
    this.MAttendeceRowData = data;
    this.filteredrowData = data;
  }
  serchAttendece() {
    this.getattendecelist();
  }
  serchMAttendece() {
    this.getmanualattendancelist();
  }

  changeYear() {
    this.config = {
      params: {
        startDate: this.selectedYear,
        endDate:
          "30-04-" +
          (parseInt(formatDate(new Date(this.selectedYear), "yyyy", "en")) + 1),
      },
    };
  }
  getmanualattendancelist() {
    this.attandanceConfig = {
      params: {
        classId: this.selectedClassId,
        date: this.selectedDate,
      },
    };
    this.attandanceServ
      .getmanualattendancelist(this.attandanceConfig)
      .subscribe((res: any) => {
        this.MAttendeceRowData = res;
        this.filteredrowData = res;
        const rowData = res;
        // this.MAttendeceGridApi.setRowData(rowData);
      });
  }
  getStudentAttendanceViewInfo(AppointmentId, that) {
    // that.attandanceServ.getStudentAttendanceViewInfo(that.selectedClassId, AppointmentId).subscribe((res : any) => {
    //   console.log(res);

    // });
    that.takeAttandance(AppointmentId);
  }

  takeAttandance(appointmentId: number) {
    const initialState = {
      list: [
        {
          AppointmentId: appointmentId,
          ClassId: this.selectedClassId,
          Date: this.selectedDate,
        },
      ],
    };
    this.bsModalRef = this.modalService.show(TakeAttendanceComponent, {
      id: CUSTOMCONST.LABEL_ONE_MODAL,
      initialState,
      class: "modal-lg mb-0",
      ignoreBackdropClick: true,
    });
  }
  filter(e: Event) {
    const inputElement = e.target as HTMLInputElement;
    const key = inputElement.name; // Get the name of the input field
    const text = inputElement.value; // Get the value of the input field
    this.filterList[key]=text
    var rowData=this.MAttendeceRowData 
    Object.keys(this.filterList).forEach(element => {
      rowData=rowData.filter((o) =>
        o[element].toLowerCase().includes(this.filterList[element].toLowerCase())
      );
    });
    
    this.filteredrowData = rowData;
  }
  filterattendece(e: Event) {
    const inputElement = e.target as HTMLInputElement;
    const key = inputElement.name; // Get the name of the input field
    const text = inputElement.value; // Get the value of the input field
    this.filterattendeceList[key]=text
    var rowData=this.attendeceRowData 
    Object.keys(this.filterattendeceList).forEach(element => {
      rowData=rowData.filter((o) =>
        o[element].toLowerCase().includes(this.filterattendeceList[element].toLowerCase())
      );
    });
    
    this.filteredattendecerowData = rowData;
  }
}
