<div id="showconsultationsidebar" class="video-container singleVideo twovideo">
  <!-- Chat Box -->
  <app-webrtc-chat-component />

  <!-- Patient Video Section -->
  <div class="video-camera-outer">
    <!-- <div class="local-camera"> -->
    <div id="local" class="student"></div>
    <!-- </div> -->

    <!-- Remote camera display -->

    <div id="remote" class="teacher">
      <button class="btn btn-primary" type="button" disabled id="remoteloaderbutton">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Please wait...
      </button>
    </div>
  </div>
  <div id="room-urls">
  </div>
  <app-student-list-2 *ngIf="showList" />


  <!-- Video Controls -->

  <div class="videos_controllers">
    <div style="display: none;" id="displayButtons">

      <div class="mute-video-btn-outer">
        <button data-bs-toggle="tooltip" data-bs-placement="top" class="control-btn" id="btnMute" title="Mute"><span
            class="bi bi-mic-fill"></span></button>
        <button data-bs-toggle="tooltip" data-bs-placement="top" class="control-btn" id="btnUnMute" title="Unmute"><span
            class="bi bi-mic-mute-fill"></span>
        </button>
        <button data-bs-toggle="tooltip" data-bs-placement="top" class="control-btn" id="off-cam"
          title="Camera Off"><span class="bi bi-camera-video-fill"></span></button>
        <button data-bs-toggle="tooltip" data-bs-placement="top" class="control-btn" id="start-cam"
          title="Camera On"><span class="bi bi-camera-video-off-fill"></span></button>
      </div>
      <div class="video-option-btn-outer">
        <button class="control-btn" id="screenShare" style="display: none;"><span class="bi bi-people-fill"></span>
          Share Screen</button>
        <button data-bs-toggle="tooltip" data-bs-placement="top" class="control-btn" style="display: none;"
          id="showchat" title="Show Chat" (click)="showchatbox()"><span class="bi bi-chat-square-dots"></span></button>
        <button class="control-btn" title="Record" style="display: none;"><span class="bi bi-record-circle"></span>
          Record</button>

        <!-- Show Student Icon -->
        <button data-bs-toggle="tooltip" data-bs-placement="top" id="studentshow" class="control-btn"
          title="Show Student List" (click)="switchShowList()"><span class="bi bi-people-fill"></span></button>
        <button data-bs-toggle="tooltip" data-bs-placement="top" class="control-btn" title="Hand Raise"
          id="hand-raise-btn" (click)="handRaise()"><span class="fa fa-hand-paper"></span></button>
        <button id="open-breakout-popup" (click)="openBreakOut()" style="display: none;"></button>
        <button id="handRaised-close" (click)="handRaised=false" style="display: none;"></button>
        <!-- <button class="control-btn" *ngIf="hidechatboxbutton" id="showchat" title="Chat" (click)="hidechatbox()"><span
              class="fa fa-comment-slash"></span></button> -->
        <a style="display: none;" data-bs-toggle="tooltip" data-bs-placement="top" title="Switch Class" class="btn-cut" id="switch-call"
          href="webrtc-group-call" class="control-btn"><span
            class="bi bi-arrow-left-right"></span></a>
      </div>



      <div class="end-call-btn-outer">

        <button data-bs-toggle="tooltip" data-bs-placement="top" class="btn-cut" id="btnLeave" (click)="endCall()"
          title="Exit Class">Exit Class</button>
      </div>



    </div>
  </div>

  <!-- <div class="videos_controllers">
    <div style="display: none;" id="displayButtons">
  
        <button *ngIf="showchatboxbutton" id="showchat" title="Chat" (click)="showchatbox()"><span
            class="fa fa-comment"></span></button>
        <button *ngIf="hidechatboxbutton" id="showchat" title="Chat" (click)="hidechatbox()"><span
            class="fa fa-comment-slash"></span></button>
  
        <button id="btnMute" title="Mute"><img src="../../assets/images/webrtc/mic.png"></button>
        <button id="btnUnMute" title="Unmute"><img src="../../assets/images/webrtc/mute.png"></button>
        <button id="off-cam" title="Camera Off"><img src="../../assets/images/webrtc/video-camera.png"></button>
        <button id="start-cam" title="Camera On"><img src="../../assets/images/webrtc/novideo.png"></button>
  
        <button class="btn-cut" id="btnLeave" (click)="endCall()" title="End"><img
            src="../../assets/images/webrtc/phone.png"></button>
      </div>
    </div> -->
</div>