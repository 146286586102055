import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventViewData,Event,EventAtionData } from './event.model';
import CONFIG from './Const';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private http:HttpClient) { 
    this.formData.EventInfo = new Event();
  }
  readonly baseUrl = CONFIG.BASE_URL+'api/Event';
  formData:EventViewData = new EventViewData();

  getEvents(){
    return this.http.get(this.baseUrl+'/getevents');
  }
  getEventsByParam(config){
    return this.http.get(this.baseUrl+'/getevents',config);
  }
  getEventsByDate(date:string){
    var config = {
      params: {
        EventDate: date,
      }
    };
    return this.http.get(this.baseUrl+'/geteventsbydate',config);
  }
  getEventsBySEDate(startDate:string, endDate:string){
    var config = {
      params: {
        EventDate: startDate,
      }
    };
    return this.http.get(this.baseUrl+'/geteventsbydate',config);
  }
  getEvent(EventId:number){
    var config = {
      params: {
        eventId: EventId,
      }
    };
    return this.http.get(this.baseUrl+'/getevent',config);
  }
  saveEvent(){
    return this.http.post(this.baseUrl+'/saveevent',this.formData);
  }
  getmultieventinfo(config){
    // var config = {
    //   params: {
    //     startDate: startDate,
    //     endDate: endDate,
    //   }
    // };
    return this.http.get(this.baseUrl+'/getmultieventinfo',config);
  }
  savemultievent(){ 
    return this.http.post(this.baseUrl+'/savemultievent',this.formData);
  }
  saveattendece(eventId:number){
    var config = {
      params: {
        eventId: eventId,
      }
    };
    return this.http.get(this.baseUrl+'/saveattendance',config);
  }
  getattendecelist(){
    return this.http.get(this.baseUrl+'/getattendancelist',undefined);
  }
  getattendecelistByParam(config){
    return this.http.get(this.baseUrl+'/getattendancelist',config);
  }
  getStudentByEventId(eventId:number){
    var config = {
      params: {
        eventId: eventId,
      }
    };
    return this.http.get(this.baseUrl+'/GetStudentListByEventId',config);
  }
  saveRoomStatusInfo(ationData:EventAtionData){
    localStorage.setItem("apiUrl",this.baseUrl)
    return this.http.post(this.baseUrl+'/saveRoomStatusInfo',ationData);
  }
  saveFeedbackInfo(ationData:any){
    return this.http.post(this.baseUrl+'/saveFeedbackInfo',ationData);
  }
 }
