<div class="profile-group">
  <img class="shadow"
    [src]="service.formData.UserInfo.CroppedFilepath?urlSafe:'../../../assets/images/user/default.png'">

  <p class="shadow">{{service.formData.UserInfo.FullName?service.formData.UserInfo.FullName:''}}</p>
</div>
<button mat-button [matMenuTriggerFor]="beforeMenu"><i class="fa fa-users" aria-hidden="true"></i>&nbsp; <i
    class="fa fa-caret-down"></i></button>
<mat-menu #beforeMenu="matMenu" xPosition="before">
  <button mat-menu-item *ngFor="let child of childrenList" (click)="selectChild(child.Id)">{{child.Name}}</button>
  <button mat-menu-item (click)="selectChild(-1)">Link a child to my account</button>
</mat-menu>

<div class="user-form d-flex align-items-center">
  <div class="container-fluid">
    <div class="row justify-content-c m-0">
      <div class="col-md-12 p-0">
        <div style="width: calc(100%);" class="bg-white shadow p-0 mb-0 mt-3 mb-3">



          <div class="modal-content">
            <div class="modal-header bg-theam p-1">
              <h3 class="modal-title h3 m-1" *ngIf="service.formData.UserInfo.UserId > 0">Edit Child</h3>
              <h3 class="modal-title h3 m-1" *ngIf="service.formData.UserInfo.UserId <= 0">Link a child in my account
              </h3>
            </div>
            <div class="breackout_body p-2">

              <form novalidate #form="ngForm" (submit)="onSave(form)" autocomplete="off">
                <div class="row form-row m-0">
                  <div class="form-group col-12 p-0 pr-1 mb-2 p-0 pr-1 mb-0 border border-secondary"
                    *ngIf="service.formData.UserInfo.UserId <= 0">
                    <div class="row form-row m-0 pt-0 justify-content-between">

                      <div class="form-group col-4 mb-0">
                        <label class="d-block">&nbsp;</label>
                        <mat-checkbox class="example-margin h-30px lh-30px mb-10px" name="IsSearchByUserShareId"
                          [(ngModel)]="IsSearchByUserShareId">I received a student ID from the school</mat-checkbox>
                      </div>
                      <div class="form-group col-4 mb-0"
                        *ngIf="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0">
                        <label>User Share Id</label>
                        <input class="form-control form-control-sm" autocomplete="off" placeholder="User Share Id"
                          name="userShareId" [(ngModel)]="userShareId" />
                      </div>

                      <div class="form-group col-4 mb-0"
                        *ngIf="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0">
                        <label>&nbsp;</label>
                        <div class="form-control form-control-sm border-0 p-0">
                          <button type="button" (click)="searchUserWithSharedId()"
                            class="btn btn-warning float-right btn-sm" type="button">Search</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-8 p-0 pr-1 mb-0 border border-secondary "
                    [ngClass]="{'readonly':service.formData.UserInfo.UserId > 0}">

                    <div class="row form-row m-0 pt-2">
                      <div class="form-group col-6 mb-0">
                        <label>{{'UserId' | translate}}</label>
                        <input class="form-control form-control-sm" placeholder="{{'UserId' | translate}}"
                          name="UserLogin" #UserLogin="ngModel" [(ngModel)]="service.formData.UserInfo.UserLogin"
                          [readonly]="(IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0) || service.formData.UserInfo.UserId <= 0">
                      </div>
                      <div class="form-group col-6 mb-0" *ngIf="service.formData.UserInfo.UserId <= 0">
                        <label>{{'Password' | translate}}</label>
                        <input class="form-control form-control-sm" type="password" autocomplete="off"
                          placeholder="********" name="Password" #Password="ngModel"
                          [(ngModel)]="service.formData.UserInfo.Password" required
                          class.invalid="Password.invalid && Password.touched"
                          [readonly]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0">
                      </div>
                      <div class="form-group col-6 mb-0">
                        <label>School Name</label>
                        <input class="form-control form-control-sm" type="text" name="SchoolName" #SchoolName="ngModel"
                          [(ngModel)]="service.formData.UserInfo.SchoolName" placeholder="School Name" required
                          class.invalid="SchoolName.invalid && SchoolName.touched"
                          [readonly]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0">

                      </div>
                      <div class="form-group col-6 mb-0">
                        <label>Grade</label>
                        <input class="form-control form-control-sm" type="text" name="Grade" #Grade="ngModel"
                          [(ngModel)]="service.formData.UserInfo.Grade" placeholder="Grade" required
                          class.invalid="Grade.invalid && Grade.touched"
                          [readonly]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0">

                      </div>
                      <div class="form-group col-6 mb-0">
                        <label>Syllabus</label>
                        <input class="form-control form-control-sm" type="text" name="Syllabus" #Syllabus="ngModel"
                          [(ngModel)]="service.formData.UserInfo.Syllabus" placeholder="Syllabus" required
                          class.invalid="Syllabus.invalid && Syllabus.touched"
                          [readonly]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0">

                      </div>
                      <div class="form-group col-6 mb-0">
                        <label>{{'Enrollment Id' | translate}}</label>
                        <input class="form-control form-control-sm" type="text"
                          placeholder="{{'Enrollment Id' | translate}}" name="EnrollmentId" #EnrollmentId="ngModel"
                          [(ngModel)]="service.formData.UserInfo.EnrollmentId" required minlength="8"
                          class.invalid="EnrollmentId.invalid && EnrollmentId.touched"
                          [readonly]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0">
                      </div>
                      <div class="form-group col-6 mb-0">
                        <label>&nbsp;</label>
                        <mat-card class="p-0 border-0 mb-3">
                          <mat-card-content>
                            <section class="example-section">
                              <mat-checkbox class="example-margin" name="IsAttendPrivateTution"
                                #IsAttendPrivateTution="ngModel"
                                [disabled]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0"
                                [(ngModel)]="service.formData.StudentInfo.IsAttendPrivateTution">My child attends a
                                private
                                institution</mat-checkbox>
                            </section>
                          </mat-card-content>
                        </mat-card>
                      </div>
                      <div class="form-group col-4 mb-0" *ngIf="service.formData.UserInfo.UserId > 0">
                        <label class="d-block">User Share Id - {{service.formData.UserInfo.UserShareId}}</label>
                        <mat-checkbox class="example-margin h-30px lh-30px" name="IsUserShare" #IsUserShare="ngModel"
                          [(ngModel)]="service.formData.UserInfo.IsUserShare"
                          [disabled]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0">Is User
                          Share</mat-checkbox>
                      </div>
                      <div class="form-group col-6 mb-0" *ngIf="service.formData.StudentInfo.IsAttendPrivateTution">
                        <label>Institute Name</label>
                        <input class="form-control form-control-sm" type="text" placeholder="Institute Name"
                          name="InstituteName" #InstituteName="ngModel"
                          [(ngModel)]="service.formData.StudentInfo.InstituteName" required
                          class.invalid="InstituteName.invalid && InstituteName.touched"
                          [readonly]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0">
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-4 mb-0 p-0 pl-1">
                    <div class="row form-row m-0 border border-secondary h-100 pt-2 position-relative">
                      <div class="cover-image position-relative mb-1">
                        <img src="{{service.formData.UserInfo.ProfileFileInfo.CroppedContentString}}" alt=""
                          class="w-100 img-fluid"
                          *ngIf="service.formData.UserInfo.ProfileFileInfo.CroppedContentString">
                        <img [src]="service.formData.UserInfo.CroppedProfileFilepath" alt="" class="w-100 img-fluid"
                          *ngIf="!service.formData.UserInfo.ProfileFileInfo.CroppedContentString">
                      </div>
                      <div class="form-group col-12 position-absolute mt-1 text-center">

                        <a class="addImageIcon cursor-pointer" (click)="editCoverImage()">
                          <mat-icon>add_a_photo</mat-icon>
                        </a>
                        <img src="{{service.formData.UserInfo.FileInfo.CroppedContentString}}" alt="" height="110"
                          width="110" class="img-circle border border-w-5 shadow-08" (click)="editPic()"
                          *ngIf="service.formData.UserInfo.FileInfo.CroppedContentString">
                        <img [src]="service.formData.UserInfo.CroppedFilepath" alt="" height="110" width="110"
                          class="border border-w-5 img-circle shadow-08" (click)="editPic()"
                          *ngIf="!service.formData.UserInfo.FileInfo.CroppedContentString">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row form-row m-0 mb-2">
                  <div class="form-group col-12 p-0 mb-0">
                    <div class="row form-row m-0 border border-secondary h-100 pt-2 mt-2 ">

                      <div class="form-group col-4 mb-0">
                        <label>First Name</label>
                        <input class="form-control form-control-sm" placeholder="{{'FirstName' | translate}}"
                          name="FirstName" #FirstName="ngModel" [(ngModel)]="service.formData.UserInfo.FirstName"
                          required class.invalid="FirstName.invalid && FirstName.touched"
                          [readonly]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0">
                      </div>
                      <div class="form-group col-4 mb-0">
                        <label>Mid Name</label>
                        <input class="form-control form-control-sm" placeholder="{{'MiddleName' | translate}}"
                          name="MiddleName" #MiddleName="ngModel" [(ngModel)]="service.formData.UserInfo.MiddleName"
                          required class.invalid="MiddleName.invalid && MiddleName.touched"
                          [readonly]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0">
                      </div>
                      <div class="form-group col-4 mb-0">
                        <label>Last Name</label>
                        <input class="form-control form-control-sm" placeholder="{{'Surname' | translate}}"
                          name="Surname" #Surname="ngModel" [(ngModel)]="service.formData.UserInfo.Surname" required
                          class.invalid="Surname.invalid && Surname.touched"
                          [readonly]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0">
                      </div>
                      <div class="form-group col-4 mb-0">
                        <label>Gender</label>
                        <select class="form-control form-control-sm appearance-auto" name="GenderId" #GenderId="ngModel"
                          [(ngModel)]="service.formData.UserInfo.GenderId"
                          [disabled]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0">
                          <option [value]="0">Select Gender</option>
                          <option *ngFor="let gender of genderlist" [value]="gender.Id">{{gender.Name}}</option>
                        </select>
                      </div>
                      <div class="form-group col-4 mb-0">
                        <label>Date Of Birth</label>
                        <input type="text" class="form-control form-control-sm"
                          placeholder="{{'DateOfBirth' | translate}}" name="DateOfBirth" #DateOfBirth="ngModel"
                          [(ngModel)]="service.formData.UserInfo.DateOfBirth" [bsConfig]="datepickerConfig" bsDatepicker
                          [readonly]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0">
                      </div>

                      <div class="form-group col-4 mb-0">
                        <label>Email</label>
                        <input class="form-control form-control-sm" autocomplete="off" type="email"
                          placeholder="{{'Email' | translate}}" name="Email" #Email="ngModel"
                          [(ngModel)]="service.formData.UserInfo.Email" required
                          class.invalid="Email.invalid && Email.touched" [readonly]="true">
                      </div>
                      <div class="form-group col-4 mb-0">
                        <label>{{'Mobile' | translate}}</label>
                        <input class="form-control form-control-sm appearance-none" type="number"
                          placeholder="{{'Mobile' | translate}}" name="Mobile" #Mobile="ngModel"
                          [(ngModel)]="service.formData.UserInfo.Mobile" required minlength="8"
                          class.invalid="Mobile.invalid && Mobile.touched"
                          [readonly]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0">
                      </div>
                      <div class="form-group col-8 mb-0">
                        <label>{{'Address' | translate}}</label>
                        <input class="form-control form-control-sm" type="text" placeholder="{{'Address' | translate}}"
                          name="Address" #Address="ngModel" [(ngModel)]="service.formData.UserInfo.Address" required
                          class.invalid="Address.invalid && Address.touched"
                          [readonly]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0">
                      </div>
                      <div class="form-group col-4 mb-0">
                        <label>{{'Street' | translate}}</label>
                        <input class="form-control form-control-sm" type="text" placeholder="{{'Street' | translate}}"
                          name="Street" #Street="ngModel" [(ngModel)]="service.formData.UserInfo.Street"
                          [readonly]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0">
                      </div>

                      <div class="form-group col-4 mb-0">
                        <label>{{'PostalNo' | translate}}</label>
                        <input class="form-control form-control-sm" type="text" placeholder="{{'PostalNo' | translate}}"
                          name="PostalNo" #PostalNo="ngModel" [(ngModel)]="service.formData.UserInfo.PostalNo" required
                          class.invalid="PostalNo.invalid && PostalNo.touched"
                          [readonly]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0">
                      </div>
                      <div class="form-group col-4 mb-0">
                        <label>{{'City' | translate}}</label>
                        <input class="form-control form-control-sm" type="text" placeholder="{{'City' | translate}}"
                          name="City" #City="ngModel" [(ngModel)]="service.formData.UserInfo.City" required
                          class.invalid="City.invalid && City.touched"
                          [readonly]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0">
                      </div>
                      <div class="form-group col-4 mb-0">
                        <label>{{'State' | translate}}</label>
                        <input class="form-control form-control-sm" type="text" placeholder="{{'State' | translate}}"
                          name="State" #State="ngModel" [(ngModel)]="service.formData.UserInfo.State" required
                          class.invalid="State.invalid && State.touched"
                          [readonly]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0">
                      </div>
                      <div class="form-group col-4 mb-0">
                        <label>Country</label>
                        <input class="form-control form-control-sm" type="text" placeholder="Country" name="Country"
                          #Country="ngModel" [(ngModel)]="service.formData.StudentInfo.Country" required
                          class.invalid="Country.invalid && Country.touched"
                          [readonly]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0">
                      </div>
                    </div>


                  </div>
                </div>

                <div class="row form-row m-0 mb-2">
                  <div class="form-group col-12 p-0 mb-0">
                    <div class="row form-row m-0 border border-secondary h-100 pt-2 mt-2 ">
                      <div class="form-group col-3 mb-0">
                        <mat-checkbox class="example-margin" name="IsAcademics" #IsAcademics="ngModel"
                          [(ngModel)]="service.formData.StudentInfo.IsAcademics"
                          [disabled]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0">{{'Academics' |
                          translate}}
                        </mat-checkbox>
                      </div>
                      <div class="form-group col-3 mb-0">
                        <mat-checkbox class="example-margin" name="IsHumanityLife" #IsHumanityLife="ngModel"
                          [(ngModel)]="service.formData.StudentInfo.IsHumanityLife"
                          [disabled]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0">{{'SEL' |
                          translate}}
                        </mat-checkbox>

                      </div>
                      <div class="form-group col-3 mb-0">
                        <mat-checkbox class="example-margin" name="IsSkills" #IsSkills="ngModel"
                          [(ngModel)]="service.formData.StudentInfo.IsSkills"
                          [disabled]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0">{{'Life Skills' |
                          translate}}</mat-checkbox>
                      </div>

                      <div class="form-group col-3 mb-0">
                        <mat-checkbox class="example-margin" name="IsLearnOutOfBox" #IsLearnOutOfBox="ngModel"
                          [(ngModel)]="service.formData.StudentInfo.IsLearnOutOfBox"
                          [disabled]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0">{{'Outside The
                          Box' | translate}}
                        </mat-checkbox>
                      </div>

                    </div>

                  </div>
                </div>

                <div class="row form-row m-0 mb-2">
                  <div class="form-group col-12 p-0 mb-0">
                    <div class="row form-row m-0 border border-secondary h-100 pt-2 mt-2 ">
                      <div class="form-group col-12 mb-0">
                        <label class="d-block">Your Child's Biography<span
                            class="d-inline-block float-right">{{currentLength}}/{{maxLength}}</span></label>
                        <textarea class="form-control form-control-sm appearance-auto resizebele-false m-0" name="About"
                          #About="ngModel" [(ngModel)]="service.formData.UserInfo.About" rows="5" maxlength="2000"
                          (keyup)="lenghtCounter()"
                          [readonly]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0"></textarea>
                        <p>Your Child's bio is chance to stand out: tell us about your child, what he/she do, and what
                          he/she passionate about.
                          Showcase your work by embedding pictures or videos.
                          Your child's bio will also be fully searchable within our directory for Free course offer,
                          scholarships, so we encourage you to use important keywords to make it easier for others to
                          find your profile.</p>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="row form-row m-0 mt-0">
                  <div class="form-group col-12 p-0 mb-0 mt-2">
                    <div class="row form-row m-0 border border-secondary h-100 pt-2 mt-2Ø">
                      <div class="form-group col-12 mb-2">
                        <p class="mb-1">What motivated you to become an Hybrid Learning Platform member?</p>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                          name="Motivation" [(ngModel)]="service.formData.StudentInfo.Motivation"
                          [disabled]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0">
                          <mat-radio-button class="example-radio-button mb-2 d-block"
                            *ngFor="let motivation of motivatedList" [value]="motivation.Name">
                            {{motivation.Name}}
                          </mat-radio-button>
                        </mat-radio-group>
                      </div>
                      <div class="form-group col-12 mb-0">
                        <p class="mb-1">How did you hear about Hybrid Learning Platform?</p>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                          name="Source" [(ngModel)]="service.formData.StudentInfo.Source"
                          [disabled]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0">
                          <mat-radio-button class="example-radio-button mb-2 d-block" *ngFor="let source of sourceList"
                            [value]="source.Name">
                            {{source.Name}}
                          </mat-radio-button>
                        </mat-radio-group>
                      </div>
                      <p>When you click save, and Once your membership is confirmed, you will receive an email with
                        instructions for using your account.
                        Please note that Hybrid Learning Platform staff reserve the right to review member sign-ups to
                        ensure eligibility and to suggest modifications accordingly.
                      </p>

                      <section class="example-section">
                        <mat-checkbox class="example-margin" name="IsAttendPrivateTution"
                          #IsAttendPrivateTution="ngModel"
                          [disabled]="IsSearchByUserShareId && service.formData.UserInfo.UserId <= 0"
                          [(ngModel)]="service.formData.StudentInfo.IsAttendPrivateTution">Don’t publish my profile in
                          the member directory.</mat-checkbox>
                      </section>
                    </div>
                  </div>

                </div>
              </form>
            </div>
            <div class="modal-footer p-1">
              <!-- <button type="button" class="btn btn-warning" (click)="searchUserWithSharedId()" *ngIf="service.formData.UserInfo.UserId <= 0">Search</button> -->
              <button type="button" class="btn btn-success" (click)="onSave(form)">Save</button>

            </div>
          </div>

        </div>

      </div>
    </div>
  </div>

</div>