<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1">Student Analytics Result</h3>
        </div>
        <div class="breackout_body p-0 model-max-window-h">
            <section class="pt-0">
                <div class="row justify-content-c m-0  bg-white shadow p-2">

                    <div class="col-md-12 p-0">
                        <ag-grid-angular #agGrid style="width: 100%; height:calc(100vh - 200px); min-height:200px"
                            class="ag-theme-alpine" id="myGrid" [gridOptions]="analysisStuGridOptions"
                            [columnDefs]="analysisStuColumnDefs" [defaultColDef]="analysisStuDefaultColDef"
                            [rowSelection]="analysisStuRowSelection" [rowData]="analysisStuRowData"
                            (gridReady)="onAnalyticGridReady($event)" [frameworkComponents]="frameworkComponents">
                        </ag-grid-angular>

                    </div>
                </div>
            </section>
        </div>
        <div class="modal-footer p-1">
            <button type="button" class="btn btn-danger" (click)="modalService.hide(CUSTOMCONST.LABEL_TWO_MODAL)"
                aria-label="Close">Close</button>
        </div>
    </div>
</div>