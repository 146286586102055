<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title h3 m-1" id="modalTitle">User Academic Year Log</h3>
        <span class="bi bi-x" (click)="modalService.hide('UserAcademicYearLog')" title="Close Module"
            data-bs-toggle="tooltip" aria-label="Close"></span>
    </div>
    <div class="breackout_body">
        <div class="custom-table">
            <!-- <pre>{{ filteredrowData | json }}</pre> Debugging purpose -->

            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Created By</th>
                        <th>Created On</th>
                        <th>New Academic Year</th>
                        <th>Old Academic Year</th>

                    </tr>
                </thead>
                <tbody *ngIf="RowData.length === 0">
                    <tr>
                        <td colspan="10">No data available</td>
                    </tr>
                </tbody>
                <tbody *ngIf="RowData.length > 0">
                    <tr *ngFor="let data of RowData">
                        <td>{{data.CreatedBy}}</td>
                        <td>{{data.CreatedOn}}</td>
                        <td>{{data.NewAcademicYear}}</td>
                        <td>{{data.OldAcademicYear}}</td>
                    </tr>
                </tbody>
            </table>

        </div>
        <!-- <section class="pt-0">
            <div class="row justify-content-center m-0 bg-white shadow p-2">

                <div class="col-md-12 p-0">
                    <ag-grid-angular #agGrid style="width: 100%; height: calc(100vh - 200px); min-height: 200px;"
                        class="ag-theme-alpine" id="myGrid" [columnDefs]="ColumnDefs" [defaultColDef]="DefaultColDef"
                        [rowSelection]="RowSelection" [rowData]="RowData"
                        (selectionChanged)="onSelectionChanged($event)" (gridReady)="onGridReady($event)">
                    </ag-grid-angular>
                </div>
            </div>
        </section> -->
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="modalService.hide('UserAcademicYearLog')"
                aria-label="Close">Close</button>
        </div>
    </div>
</div>