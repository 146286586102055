import { Component, OnInit } from "@angular/core";
import { NgForm, FormsModule } from "@angular/forms";
import { UserService } from "src/app/shared/user.service";
import { CommonService } from "src/app/shared/common.service";
import { ResetPassword, User, UserView } from "../shared/user.model";
import { UserLogin } from "../shared/user.model";
import { ActivatedRoute } from "@angular/router";
import { Title, Meta } from "@angular/platform-browser";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CUSTOMCONST } from "src/app/shared/Const";
import { NotificationService } from "../shared/notification.service";
import { TranslateModule } from "@ngx-translate/core";
@Component({
  selector: "app-password-reset",
  templateUrl: "./password-reset.component.html",
  styleUrls: ["./password-reset.component.scss"],
  standalone: true,
  imports: [FormsModule, TranslateModule],
})
export class PasswordResetComponent implements OnInit {
  list: any = [];
  CUSTOMCONST: any = CUSTOMCONST;
  constructor(
    public service: UserService,
    public commonServ: CommonService,
    private route: ActivatedRoute,
    private notificationServ: NotificationService,
    private titleService: Title,
    private metaService: Meta,
    public modalService: BsModalService,
    public bsModalRef: BsModalRef
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle("Welcome to New ERA Education");
    this.metaService.addTags([
      { name: "keywords", content: "Hybrid Learning" },
      { name: "description", content: "Hybrid Learning" },
      { name: "robots", content: "index, follow" },
    ]);

    if (typeof window != "undefined") window.scroll(0, 0);
    // this.service.formData =  new UserView();
    this.service.formData.ResetPasswordInfo = new ResetPassword();
  }

  resetPassword(form) {
    this.service.resetPassword().subscribe((res: any) => {
      if (res.ErrorMessage) {
        this.notificationServ.ShowError(res.ErrorMessage);
      }
    });
  }
}
