<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title h3 m-1" *ngIf="service.formData.EventInfo.EventId<=0">{{'AddEvent' | translate}}</h3>
        <h3 class="modal-title h3 m-1" *ngIf="service.formData.EventInfo.EventId>0">{{'EditEvent' | translate}}</h3>
        <span class="bi bi-x" (click)="bsModalRef.hide()" title="Close Module" data-bs-toggle="tooltip"></span>
    </div>

    <form novalidate #form="ngForm" autocomplete="off">
        <div class="breackout_body">
            <div class="field-row">
                <div class="select_field">
                    <label>Academic year</label>
                    <select class="field-control appearance-auto" name="AcademicYearId" #AcademicYearId="ngModel"
                        [(ngModel)]="service.formData.EventInfo.AcademicYearId">
                        <option [value]="0">Select</option>
                        @for (year of yearList; track year) {
                        <option [value]="year.Id">{{year.Name}}</option>
                        }
                    </select>
                </div>
                <div class="select_field">
                    <label>{{'Select Class' | translate}}</label>
                    <!-- <select class="form-control form-control-sm appearance-auto" name="ClassId" #ClassId="ngModel"
                            [(ngModel)]="service.formData.EventInfo.ClassId">
                              <option [value]="0">Select Class</option>
                              <option *ngFor="let class of classList" [value]="class.Id">{{class.Name}}</option>
                            </select> -->
                    <ng-multiselect-dropdown [placeholder]="'Search'" [settings]="dropdownSettings" name="StdClass"
                        [data]="classList" [(ngModel)]="service.formData.ClassMappingList"
                        (onSelect)="onItemSelect($event,'Class')" (onSelectAll)="onSelectAll($event,'Class')"
                        (onDeSelect)="onItemSelect($event,'Class')" (onSelectAll)="onSelectAll($event,'Class')"
                        class="ng_inputoption">
                    </ng-multiselect-dropdown>
                </div>
            </div>
            <div class="field-row">
                <div class="select_field">
                    <label>{{'Date' | translate}}</label>
                    <input type="text" class="field-control" placeholder="{{'Date' | translate}}" name="StartDate"
                        #StartDate="ngModel" [(ngModel)]="service.formData.EventInfo.EventDate"
                        [bsConfig]="datepickerConfig" [minDate]="minDate" bsDatepicker timepicker>
                </div>
                <div class="select_field">
                    <label>{{'StartTime' | translate}}</label>
                    <ng-template #popTemplate>
                        <timepicker [(ngModel)]="service.formData.EventInfo.StartTime" name="start Time"
                            [showMeridian]="true"></timepicker>
                    </ng-template>
                    <input [ngModel]="service.formData.EventInfo.StartTime| date:'shortTime'" class="field-control"
                        name="start Time" [popover]="popTemplate" [outsideClick]="true" />
                </div>
                <div class="select_field">
                    <label>{{'EndTime' | translate}}</label>
                    <ng-template #popTemplate1>
                        <timepicker [(ngModel)]="service.formData.EventInfo.EndTime" name="endTime"
                            [showMeridian]="true"></timepicker>
                    </ng-template>
                    <input [ngModel]="service.formData.EventInfo.EndTime | date:'shortTime'" class="field-control"
                        name="endTime" [popover]="popTemplate1" [outsideClick]="true" />
                </div>
            </div>
            <div class="field-row">
                <div class="select_field">
                    <label>{{'Description' | translate}}</label>
                    <textarea class="field-control resizebele-false" type="text"
                        placeholder="{{'Description' | translate}}" name="Description" #Description="ngModel"
                        [(ngModel)]="service.formData.EventInfo.Description"></textarea>
                </div>
            </div>


            <div class="field-row">
                <div class="select_field">
                    <mat-checkbox class="example-margin" name="IsMeeting" #IsMeeting="ngModel"
                        [(ngModel)]="service.formData.EventInfo.IsMeeting">{{'MeetingUrl' |
                        translate}}</mat-checkbox>
                </div>
            </div>
            <div class="field-row" [ngClass]="{'disabled':role != 'Admin' && role != 'SuperAdmin'}">

                <div class="select_field">
                    <mat-checkbox class="example-margin" name="IsAcademics" #IsAcademics="ngModel"
                        [(ngModel)]="service.formData.EventInfo.IsAcademics">{{'Academics' | translate}}
                    </mat-checkbox>
                </div>
                <div class="select_field">
                    <mat-checkbox class="example-margin" name="IsHumanityLife" #IsHumanityLife="ngModel"
                        [(ngModel)]="service.formData.EventInfo.IsHumanityLife">{{'HumanityLife' |
                        translate}}</mat-checkbox>

                </div>
                <div class="select_field">
                    <mat-checkbox class="example-margin" name="IsSkills" #IsSkills="ngModel"
                        [(ngModel)]="service.formData.EventInfo.IsSkills">{{'Skills' | translate}}
                    </mat-checkbox>
                </div>

                <div class="select_field">
                    <mat-checkbox class="example-margin" name="IsLearnOutOfBox" #IsLearnOutOfBox="ngModel"
                        [(ngModel)]="service.formData.EventInfo.IsLearnOutOfBox">{{'LearnOutOfBox' |
                        translate}}</mat-checkbox>
                </div>

            </div>


            <div class="modal_footer">
                <button type="button" (click)="addEventInfo(form)" class="custom-primary-btn"
                    type="button">Save</button>
                <button type="button" class="custom-primary-btn custom-danger" (click)="bsModalRef.hide()"
                    aria-label="Close">Cancel</button>
            </div>
        </div>
    </form>
</div>