<div class="modal-content">
  <div class="modal-header">
    <h3 class="modal-title h3 m-1" *ngIf="service.formData.UserInfo.UserId > 0">{{'Student Info' | translate}}</h3>
    <span class="bi bi-x" (click)="modalService.hide(CUSTOMCONST.USER_EDIT_MODAL)" title="Close Module"
      data-bs-toggle="tooltip"></span>
  </div>
  <div class="breackout_body">
    <form novalidate #form="ngForm" autocomplete="off">
      <div class="field-row">
        <div class="select_field">
          <label>{{'FirstName' | translate}}</label>
          <input class="field-control" placeholder="{{'FirstName' | translate}}" name="FirstName" #FirstName="ngModel"
            [(ngModel)]="service.formData.UserInfo.FirstName" required
            class.invalid="FirstName.invalid && FirstName.touched" [disabled]="true">
        </div>
        <div class="select_field">
          <label>{{'MidName' | translate}}</label>
          <input class="field-control" placeholder="{{'MiddleName' | translate}}" name="MiddleName"
            #MiddleName="ngModel" [(ngModel)]="service.formData.UserInfo.MiddleName" required
            class.invalid="MiddleName.invalid && MiddleName.touched" [disabled]="true">
        </div>
        <div class="select_field">
          <label>{{'Surname' | translate}}</label>
          <input class="field-control" placeholder="{{'Surname' | translate}}" name="Surname" #Surname="ngModel"
            [(ngModel)]="service.formData.UserInfo.Surname" required class.invalid="Surname.invalid && Surname.touched"
            [disabled]="true">
        </div>
      </div>
      <div class="field-row">
        <div class="select_field">
          <label>{{'Gender' | translate}}</label>
          <select class="field-control appearance-auto" name="GenderId" #GenderId="ngModel"
            [(ngModel)]="service.formData.UserInfo.GenderId" [disabled]="true">
            <option [value]="0">Select Gender</option>
            <option *ngFor="let gender of genderlist" [value]="gender.Id">{{gender.Name}}</option>
          </select>
        </div>
        <div class="select_field">
          <label>{{'DateOfBirth' | translate}}</label>
          <input type="text" class="field-control" placeholder="{{'DateOfBirth' | translate}}" name="DateOfBirth"
            #DateOfBirth="ngModel" [(ngModel)]="service.formData.UserInfo.DateOfBirth" [bsConfig]="datepickerConfig"
            bsDatepicker [disabled]="true">
        </div>
      </div>

      <div class="row form-row m-0">
        <div class="form-group col-12 p-0 mb-0">
          <div class="row form-row m-0 border border-secondary mt-2 py-2 mt-2 ">
            <div class="form-group col-3 mb-0">
              <label>Day Points ({{daypoints}})</label>
            </div>
            <div class="form-group col-3 mb-0">
              <label>Week Points ({{weekpoints}})</label>
            </div>
            <div class="form-group col-3 mb-0">
              <label>Month Points ({{monthpoints}})</label>
            </div>

            <div class="form-group col-3 mb-0">
              <label>Total Points ({{points}})</label>
            </div>

          </div>
        </div>
      </div>

      <div class="custom-table">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Given</th>
              <th>Feedback</th>
              <th>Created On</th>
              <th *ngIf="role == 'Admin'">Action</th>
            </tr>
          </thead>
          <tbody *ngIf="feedbackRowData.length === 0">
            <tr *ngIf="role != 'Admin'">
              <td colspan="3">No data available</td>
            </tr>
            <tr *ngIf="role == 'Admin'">
              <td colspan="4">No data available</td>
            </tr>
          </tbody>
          <tbody *ngIf="feedbackRowData.length > 0">
            <tr *ngFor="let data of feedbackRowData">
              <td>{{data.CreatedByName}}</td>
              <td>{{data.Feedback}}</td>
              <td>{{data.CreatedOn}}</td>
              <td *ngIf="role == 'Admin'">
                <div class="table-action">
                  <span data-bs-toggle="tooltip" data-bs-placement="bottom" class="bi bi-pencil-square" title="Edit"
                    (click)="editFeedback(data.feedbackId, this)"></span>
                  <span data-bs-toggle="tooltip" data-bs-placement="bottom" class=" bi bi-trash3" title="Delete"
                    (click)="deleteFeedback(data.feedbackId, this)"></span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- <div class="row form-row m-0 mb-0">
        <div class="col-12 p-0">
          <div style="width: calc(100%);" class="p-0 mb-0 mt-2 mb-0">
             <ag-grid-angular #agGrid style="width: 100%; height:calc(100vh - 330px);min-height: 200px;"
              class="ag-theme-alpine" id="feedbacktGrid" [gridOptions]="feedbackGridOptions"
              [columnDefs]="feedbackColumnDefs" [defaultColDef]="feedbackDefaultColDef"
              [rowSelection]="feedbackRowSelection" [rowData]="feedbackRowData"
              (selectionChanged)="feedbackRowSelectionChanged($event)" (gridReady)="onFeedbackReady($event)"
              [frameworkComponents]="frameworkComponents" (gridReload)="onFeedbackReload($event)"></ag-grid-angular>

          </div>
        </div>
      </div> -->
    </form>

    <div class="modal-footer">
      <!-- <button type="button" class="btn btn-primary" (click)="onSave(form)">Save</button> -->
      <button type="button" class="custom-primary-btn custom-danger"
        (click)="modalService.hide(CUSTOMCONST.USER_EDIT_MODAL)" aria-label="Close">Close</button>
    </div>
  </div>

</div>