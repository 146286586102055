<div class="dashboardInnerPage inner-page-outer flex-column">
    <div class="title w-100">
        <span (click)="goBack()" title="Back" data-bs-toggle="tooltip" data-bs-placement="bottom" class="bi bi-arrow-left"></span>
        <h3>Teacher Profile</h3>
        <!-- <a class="add_button" routerLink="#" (click)="openquestionmodal(0)">Add Assignments</a> -->
    </div>
    <mat-tab-group animationDuration="1000ms" (selectedTabChange)="activeTabChange($event)">
        <mat-tab label="Profile">
            <ng-template mat-tab-label>
                <label>Profile</label>
            </ng-template>
            <div class="assingment-box-outer">
                <div class="assingment-heading">
                    <h3>Profile</h3>
                </div>
                <!-- <div class="tab-content bg-white mt-3">
                    <div class="profile"> -->
                <div class="field-row">
                    <div class="select_field">
                        <div class="profile-dp"><img src="../../../assets/images/common/homework.jpg" alt="">
                        </div>
                        <div class="change-pic pt-1">
                            <img src="../../../assets/images/icons/svg/camera_green.svg" alt="">
                        </div>
                    </div>
                </div>
                <div class="field-row">
                    <div class="select_field">
                        <label for="">First Name</label>
                        <input type="text" class="field-control" [(ngModel)]="service.formData.UserInfo.FirstName">
                    </div>
                    <div class="select_field">
                        <label for="">Last Name</label>
                        <input type="text" class="field-control" [(ngModel)]="service.formData.UserInfo.Surname">
                    </div>
                </div>
                <div class="field-row">
                    <div class="select_field">
                        <label for="">Gender</label>
                        <select name="" id="" class="field-control" [(ngModel)]="service.formData.UserInfo.GenderId">
                            <option value="1">Male</option>
                            <option value="2">Female</option>
                        </select>
                    </div>
                    <div class="select_field">
                        <label for="">Date Of Birth(DOB)</label>
                        <input type="text" class="field-control" [(ngModel)]="service.formData.UserInfo.DateOfBirth">
                    </div>
                </div>
                <div class="field-row">
                    <div class="select_field">
                        <label for="">Email ID</label>
                        <!-- <div class="card">
                                            <div class="d-flex justify-content-between align-items-center"> -->
                        <input type="email" class="field-control" [(ngModel)]="service.formData.UserInfo.Email">
                        <!-- <span class="pr-1"><img
                                                src="../../../assets/images/icons/svg/plus_green.svg"></span> -->
                        <!-- </div>
                                        </div> -->
                    </div>
                    <div class="select_field">
                        <label for="">Mobile Number</label>
                        <!-- <input type="number">
                                                            <img src="../../../assets/images/icons/svg/plus_green.svg" alt=""> -->
                        <!-- <div class="card">
                                            <div class="d-flex justify-content-between align-items-center"> -->
                        <input type="text" class="field-control" [(ngModel)]="service.formData.UserInfo.Mobile">
                        <!-- <span class="pr-1"><img
                                                        src="../../../assets/images/icons/svg/plus_green.svg"></span>
                                            </div>
                                        </div> -->
                    </div>
                </div>
                <div class="field-row">
                    <div class="select_field">
                        <label for="">School</label>
                        <input type="text" class="field-control" [(ngModel)]="service.formData.UserInfo.SchoolId">
                    </div>
                    <div class="select_field">
                        <label for="">Branch</label>
                        <input type="text" class="field-control" [(ngModel)]="service.formData.UserInfo.BranchId">
                    </div>
                </div>
                <div class="field-row">
                    <div class="select_field">
                        <label for="">Address</label>
                        <textarea type="text" class="field-control"
                            [(ngModel)]="service.formData.UserInfo.Address"></textarea>
                    </div>
                </div>
                <div class="field-row">
                    <div class="select_field">
                        <label for="">State</label>
                        <input type="text" class="field-control" [(ngModel)]="service.formData.UserInfo.State">
                    </div>
                    <div class="select_field">
                        <label for="">City</label>
                        <input type="text" class="field-control" [(ngModel)]="service.formData.UserInfo.City">
                    </div>
                    <div class="select_field">
                        <label for="">Postal No</label>
                        <input type="text" class="field-control" [(ngModel)]="service.formData.UserInfo.PostalNo">
                    </div>

                </div>
                <div class="field-row">
                    <button type="button" [disabled]="!service.formData.UserInfo.IsEditAllowed"
                        class="custom-primary-btn" (click)="saveProfile()" type="button">Update</button>
                </div>
                <!-- </div>
                </div> -->
            </div>
        </mat-tab>
        <mat-tab label="Persona">
            <ng-template mat-tab-label>
                <label>Personal</label>
            </ng-template>
            <div class="assingment-box-outer">
                <div class="assingment-heading">
                    <h3>Personal</h3>
                </div>
                <div class="field-row">
                    <div class="select_field">
                        <label for="">Bio</label>
                        <textarea type="text" class="field-control"
                            value="{{this.service.formData.UserInfo.About}}"></textarea>
                    </div>
                </div>
                <div class="field-row field-rowSec">
                    <div class="select_field select_fieldSec" *ngFor="let profileType of profileTypeInfo">

                        <div class="personal-detail-card">
                            <div class="modal-header">
                                <h3 class="modal-title">{{profileType.Name}}</h3>
                                <span class="bi bi-plus" (click)="EditprofileProfile(profileType.Id, profileType.Name)"
                                    title="Add {{profileType.Name}}" data-bs-toggle="tooltip"></span>
                            </div>

                            <ul class="list-group list-group-flush">
                                <!-- <div class="card border-0"
                                    *ngFor="let studentProfileList of profileType.StudentProfileList">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div
                                            class="d-flex justify-content-between align-items-center w-100 m-1">
                                            <p type="text" class="field-control m-0">{{studentProfileList.Name}}
                                            </p>
                                            <span class="action-group">
                                                <fa-icon class="mr-2 text-primary" [icon]="editIcon"
                                                    (click)="Editprofile(studentProfileList.StudentProfileDataId, profileType.Name, studentProfileList)"></fa-icon>
                                                <fa-icon class="text-danger" [icon]="deleteIcon"
                                                    (click)="deleteProfile(studentProfileList.StudentProfileDataId, studentProfileList)"></fa-icon>
                                            </span>
                                        </div>
                                    </div>
                                </div> -->
                                <li class="list-group-item"
                                    *ngFor="let studentProfileList of profileType.StudentProfileList">

                                    <p>{{studentProfileList.Name}}</p>

                                    <div class="table-action">
                                        <span data-bs-toggle="tooltip" data-bs-placement="bottom"
                                            class=" bi bi-pencil-square" title="Edit"
                                            (click)="Editprofile(studentProfileList.StudentProfileDataId, profileType.Name, studentProfileList)"></span>
                                        <span data-bs-toggle="tooltip" data-bs-placement="bottom" class="bi bi-trash3"
                                            title="Delete"
                                            (click)="deleteProfile(studentProfileList.StudentProfileDataId, studentProfileList)"></span>
                                    </div>
                                </li>
                                <li class="list-group-item" *ngFor="let defaultvalue of profileType.Default">
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="filed-row">
                    <button type="button" class="custom-primary-btn" (click)="editPersona()">Edit</button>
                    <button class="custom-primary-btn" (click)="savePersona()" type="button">Update</button>
                </div>
            </div>
        </mat-tab>
        <!-- <mat-tab label="Empathy-Mapping">
            <ng-template mat-tab-label>
                <label>Empathy Mapping</label>
            </ng-template>
            <div class="rounded mt-4">
                <div class="tab-content">
                    <div class="empathy-mapping">
                        <div id="profileLayout" class="col-12 col-xl-12 col-md-12 col-sm-12 p-0">
                            <div class="d-md-block d-xs-none">
                                <div class="shadow">
                                    <div class="Row">
                                        <div>
                                            <span><img src="../../../assets/images/icons/svg/plus_green.svg"
                                                    (click)="AddEmpathy()"></span>
                                        </div>
                                        <div class="col-md-12 p-0">
                                            <ag-grid-angular #agGrid
                                                style="width: 100%; height:calc(100vh - 200px); min-height:200px"
                                                class="ag-theme-alpine" id="myGrid" [gridOptions]="GridOptions"
                                                [columnDefs]="ColumnDefs" [defaultColDef]="DefaultColDef"
                                                [rowSelection]="RowSelection" [rowData]="RowData"
                                                (selectionChanged)="onEmpathySelectionChanged($event)"
                                                (gridReady)="onGridReady($event)">
                                            </ag-grid-angular>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 mb-3">
                                            <div class="profile-group d-flex">
                                                <div class="Profile-background">
                                                    <img src="../../../assets/images/background/Profile-background1.svg"
                                                        alt="">
                                                </div>
                                                <div class="profile-pic">
                                                    <img src="'../../../assets/images/user/default.png" alt="">
                                                </div>
                                                <div class="profile-con-top">
                                                    <div class="w-50 text-center">
                                                        <div class="container-singlerow">
                                                            <mat-card class="empathy-card"
                                                                *ngFor="let EmpathyInfo of SelectedEmpathyInfo.Think_Feel.split('\n')">
                                                                <mat-card-content matTooltip={{EmpathyInfo}}>
                                                                    {{EmpathyInfo.length > 40 ?
                                                                    (EmpathyInfo | slice:0:40) :
                                                                    EmpathyInfo}}
                                                                </mat-card-content>
                                                            </mat-card>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="text-top white-font">
                                                    <h4>Think & Feel?</h4>
                                                </div>
                                                <div class="profile-con-bottom">
                                                    <div class="w-50 text-center">
                                                        <div class="container-singlerow">
                                                            <mat-card class="empathy-card"
                                                                *ngFor="let EmpathyInfo of SelectedEmpathyInfo.Say_Do.split('\n')">
                                                                <mat-card-content matTooltip={{EmpathyInfo}}>
                                                                    {{EmpathyInfo.length > 40 ?
                                                                    (EmpathyInfo | slice:0:40) :
                                                                    EmpathyInfo}}
                                                                </mat-card-content>
                                                            </mat-card>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="text-bottom white-font">
                                                    <h4>Say & Do</h4>
                                                </div>
                                                <div class="profile-con-left">
                                                    <div class="w-50 text-center">
                                                        <div class="container-singlerow">
                                                            <mat-card class="empathy-card"
                                                                *ngFor="let EmpathyInfo of SelectedEmpathyInfo.Hear.split('\n')">
                                                                <mat-card-content matTooltip={{EmpathyInfo}}>
                                                                    {{EmpathyInfo.length > 40 ?
                                                                    (EmpathyInfo | slice:0:40) :
                                                                    EmpathyInfo}}
                                                                </mat-card-content>
                                                            </mat-card>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="text-left white-font">
                                                    <h4>Hear</h4>
                                                </div>
                                                <div class="profile-con-right">
                                                    <div class="w-50 text-center">
                                                        <div class="container-singlerow">
                                                            <mat-card class="empathy-card"
                                                                *ngFor="let EmpathyInfo of SelectedEmpathyInfo.See.split('\n')">
                                                                <mat-card-content matTooltip={{EmpathyInfo}}>
                                                                    {{EmpathyInfo.length > 40 ?
                                                                    (EmpathyInfo | slice:0:40) :
                                                                    EmpathyInfo}}
                                                                </mat-card-content>
                                                            </mat-card>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="text-right white-font">
                                                    <h4>See</h4>
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-6 pe-0 pggroup">
                                            <div class=" text-center border p-4 point">
                                                <h4 class="text-center m-0 white-font">Pains</h4>
                                                <div class="container-singlerow">
                                                    <mat-card class="empathy-card"
                                                        *ngFor="let EmpathyInfo of SelectedEmpathyInfo.Pains.split('\n')">
                                                        <mat-card-content matTooltip={{EmpathyInfo}}>
                                                            {{EmpathyInfo.length > 40 ? (EmpathyInfo |
                                                            slice:0:40) : EmpathyInfo}}
                                                        </mat-card-content>
                                                    </mat-card>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 ps-0 pggroup">
                                            <div class="text-center border p-4 gain">
                                                <h4 class="text-center m-0 white-font">Gains</h4>
                                                <div class="container-singlerow">
                                                    <mat-card class="empathy-card"
                                                        *ngFor="let EmpathyInfo of SelectedEmpathyInfo.Gains.split('\n')">
                                                        <mat-card-content matTooltip={{EmpathyInfo}}>
                                                            {{EmpathyInfo.length > 40 ? (EmpathyInfo |
                                                            slice:0:40) : EmpathyInfo}}
                                                        </mat-card-content>
                                                    </mat-card>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="d-md-none d-sm-block mobile-view">
                                <div class="shadow">
                                    <div class="row">
                                        <div class="col-12 p-3">
                                            <div class="profile-pic-sm-md text-center border m-auto">
                                                <img src="'../../../assets/images/user/default.png" alt="">
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="text-center border">
                                                <div class="text-center border p-4 think_feel">
                                                    <h1 class="text-center m-0">Think and Feel</h1>
                                                    <div class="container-singlerow">
                                                        <mat-card class="empathy-card"
                                                            *ngFor="let EmpathyInfo of service.StudentEmpathyViewInfo.StudentEmpathyInfo">
                                                            <mat-card-content>
                                                                {{EmpathyInfo.Think_Feel}}
                                                            </mat-card-content>
                                                        </mat-card>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="text-center border">
                                                <div class="text-center border p-4 say_do">
                                                    <h1 class="text-center m-0">Say & Do</h1>
                                                    <div class="container-singlerow">
                                                        <mat-card class="empathy-card"
                                                            *ngFor="let EmpathyInfo of service.StudentEmpathyViewInfo.StudentEmpathyInfo">
                                                            <mat-card-content>
                                                                {{EmpathyInfo.Say_Do}}
                                                            </mat-card-content>
                                                        </mat-card>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="text-center border">
                                                <div class="text-center border p-4 hear">
                                                    <h1 class="text-center m-0">Hear</h1>
                                                    <div class="container-singlerow">
                                                        <mat-card class="empathy-card"
                                                            *ngFor="let EmpathyInfo of service.StudentEmpathyViewInfo.StudentEmpathyInfo">
                                                            <mat-card-content matTooltip="EmpathyInfo">
                                                                {{EmpathyInfo.Hear}}
                                                            </mat-card-content>
                                                        </mat-card>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="text-center border">
                                                <div class="text-center border p-4 see">
                                                    <h1 class="text-center m-0">See</h1>
                                                    <div class="container-singlerow">
                                                        <mat-card class="empathy-card"
                                                            *ngFor="let EmpathyInfo of service.StudentEmpathyViewInfo.StudentEmpathyInfo">
                                                            <mat-card-content>
                                                                {{EmpathyInfo.See}}
                                                            </mat-card-content>
                                                        </mat-card>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="text-center border">
                                                <div class="text-center border p-4 point">
                                                    <h1 class="text-center m-0">Point</h1>
                                                    <div class="container-singlerow">
                                                        <mat-card class="empathy-card"
                                                            *ngFor="let EmpathyInfo of service.StudentEmpathyViewInfo.StudentEmpathyInfo">
                                                            <mat-card-content>
                                                                {{EmpathyInfo.Pains}}
                                                            </mat-card-content>
                                                        </mat-card>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="text-center border">
                                                <div class="text-center border p-4 gain">
                                                    <h1 class="text-center m-0">Gains</h1>
                                                    <div class="container-singlerow">
                                                        <mat-card class="empathy-card"
                                                            *ngFor="let EmpathyInfo of service.StudentEmpathyViewInfo.StudentEmpathyInfo">
                                                            <mat-card-content>
                                                                {{EmpathyInfo.Gains}}
                                                            </mat-card-content>
                                                        </mat-card>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </mat-tab> -->
        <mat-tab label="Empathy-Mapping">
            <ng-template mat-tab-label>
                <div class="m-0"> 
                    <div class="d-flex align-items-center">                                                     
                        <label class="m-0">Empathy Mapping</label>                                                      
                       
                    </div>
                </div>
            </ng-template>   
            <div class="rounded mt-4">
                <div class="tab-content">
                    <div class="empathy-mapping">
                        <div id="profileLayout" class="col-12 col-xl-12 col-md-12 col-sm-12 p-0"> 
                            <div class="d-md-block d-xs-none">
                                <div class="shadow">
                                    <div class="row">
                                        <div class="col-12 mb-3">
                                            <div class="profile-group d-flex">
                                                <div class="Profile-background">
                                                    <img src="../../../assets/images/background/Profile-background1.svg" alt="">
                                                </div>
                                                <div class="profile-pic">
                                                    <img src="'../../../assets/images/user/default.png" alt="">
                                                </div>
                                                <div class="profile-con-top">
                                                    <div class="w-50 text-center">
                                                        <h1 class="m-0">Think and Feel</h1>
                                                        <span>{{studentEmpathyInfo?.Think_Feel!=null?studentEmpathyInfo?.Think_Feel:'What really matters \n Worries/Aspiration?'}}</span>
                                                    </div>
                                                </div>
                                                <div class="profile-con-bottom">
                                                    <div class="w-50 text-center">
                                                        <h1 class="text-center m-0">Say & Do</h1>
                                                        <span>{{studentEmpathyInfo?.Say_Do!=null?studentEmpathyInfo?.Say_Do:'Appearance \n Attitude in public'}}</span>
                                                    </div>
                                                </div>
                                                <div class="profile-con-left">
                                                    <div class="w-50 text-center">
                                                        <h1 class="text-center m-0">Hear</h1>
                                                        <span>{{studentEmpathyInfo?.Hear!=null?studentEmpathyInfo?.Hear:'What friends say \n What family say \n What bos/preers say \n What infuencers say'}}</span>
                                                    </div>
                                                </div>
                                                <div class="profile-con-right">
                                                    <div class="w-50 text-center">
                                                        <h1 class="m-0">See</h1>
                                                        <span>{{studentEmpathyInfo?.See!=null?studentEmpathyInfo?.See:'Envirnment \n Social Media \n Websites \n What the markets offers'}}</span>
                                                    </div>
                                                    
                                                </div>
                                                
                                                
                                            </div>
                                           
                                        </div> 
                                        <div class="col-6 pe-0 pggroup">
                                            <div class=" text-center border p-4 point">
                                                <h1 class="text-center m-0">Pains</h1>
                                                <span class="white-space-pre">{{studentEmpathyInfo?.Pains!=null?studentEmpathyInfo?.Pains:'Fears \n Frustrations \n Obstacles'}}</span>
                                            </div>
                                        </div>
                                        <div class="col-6 ps-0 pggroup">
                                            <div class="text-center border p-4 gain">
                                                <h1 class="text-center m-0">Gains</h1>
                                                <span class="white-space-pre">{{studentEmpathyInfo?.Gains!=null?studentEmpathyInfo?.Gains:'Wants/Needs \n Measure of success \n Goals'}}</span>
                                            </div>
                                        </div> 
                                        
                                    </div>
                                </div>
                            </div>             
                            
                            <div class="d-md-none d-sm-block mobile-view">
                                <div class="shadow">
                                    <div class="row">
                                        <div class="col-12 p-3">
                                            <div class="profile-pic-sm-md text-center border m-auto" >
                                                <img src="'../../../assets/images/user/default.png" alt="">
                                            </div>                                       
                                        </div>
                                        <div class="col-12">
                                            <div class="text-center border">
                                                <div class="text-center border p-4 think_feel">
                                                    <h1 class="text-center m-0">Think and Feel</h1>
                                                    <span>{{studentEmpathyInfo?.Think_Feel!=null?studentEmpathyInfo?.Think_Feel:'What really matters \n Worries/Aspiration?'}}</span>
                                                </div>
                                            </div>                                       
                                        </div>
                                        <div class="col-12">
                                            <div class="text-center border">
                                                <div class="text-center border p-4 say_do">
                                                    <h1 class="text-center m-0">Say & Do</h1>
                                                    <span>{{studentEmpathyInfo?.Say_Do!=null?studentEmpathyInfo?.Say_Do:'Appearance \n Attitude in public'}}</span>
                                                </div>
                                            </div>                                       
                                        </div>
                                        <div class="col-12">
                                            <div class="text-center border">
                                                <div class="text-center border p-4 hear">
                                                    <h1 class="text-center m-0">Hear</h1>
                                                    <span>{{studentEmpathyInfo?.Hear!=null?studentEmpathyInfo?.Hear:'What friends say \n What family say \n What bos/preers say \n What infuencers say'}}</span>
                                                </div>
                                            </div>                                       
                                        </div>
                                        <div class="col-12">
                                            <div class="text-center border">
                                                <div class="text-center border p-4 see">
                                                    <h1 class="text-center m-0">See</h1>
                                                    <span>{{studentEmpathyInfo?.See!=null?studentEmpathyInfo?.See:'Envirnment \n Social Media \n Websites \n What the markets offers'}}</span>
                                                </div>
                                            </div>                                       
                                        </div>
                                        <div class="col-12">
                                            <div class="text-center border">
                                                <div class="text-center border p-4 point">
                                                    <h1 class="text-center m-0">Point</h1>
                                                    <span class="white-space-pre">{{studentEmpathyInfo?.Pains!=null?studentEmpathyInfo?.Pains:'Fears \n Frustrations \n Obstacles'}}</span>
                                                </div>
                                            </div>                                       
                                        </div>
                                        <div class="col-12">
                                            <div class="text-center border">
                                                <div class="text-center border p-4 gain">
                                                    <h1 class="text-center m-0">Gains</h1>
                                                    <span class="white-space-pre">{{studentEmpathyInfo?.Gains!=null?studentEmpathyInfo?.Gains:'Wants/Needs \n Measure of success \n Goals'}}</span>
                                                </div>
                                            </div>                                       
                                        </div>
                    
                                    </div>
                                </div>
                            </div>  
                            
                        </div>
                                                             
                    </div>
            
                </div>
            </div>                                         
        </mat-tab> 
    </mat-tab-group>
    <!-- <div class="search-group">
                        <div class="d-flex align-items-center">
                            <span><img src="../../assets/images/icons/Search.png" alt=""></span>
                            <input type="text">
                        </div>
                    </div>  -->

</div>

<!-- <div class="container-fluid bg-light pb-2">
    <div class="row m-0 pt-2 pb-2">

        <div class="col-xl-2 col-md-2 col-sm-2 pr-0 profile-pic">
            <section class="">
                <div class="profile-dp"><img src="../../../assets/images/common/homework.jpg" alt=""></div>
                <div class="change-pic pt-1">
                    <img src="../../../assets/images/icons/svg/camera_green.svg" alt="">
                </div>
            </section>
        </div>

        <div class="col-xl-10 col-md-10 col-sm-10">
            <section class="profile-inpute-group">
                <div class="row">
                    <div class="col-6 mb-3">
                        <label for="">First Name</label>
                        <input type="text">
                    </div>
                    <div class="col-6 mb-3">
                        <label for="">Last Name</label>
                        <input type="text">
                    </div>
                    <div class="col-6 mb-3">
                        <label for="">Gender</label>
                        <select name="" id="">
                            <option value="">Male</option>
                            <option value="">Female</option>
                        </select>
                    </div>
                    <div class="col-6 mb-3">
                        <label for="">Date Of Birth(DOB)</label>
                        <input type="text">
                    </div>
                    <div class="col-6 mb-3">
                        <label for="">Email ID</label>
                        <div class="card">
                            <div class="d-flex justify-content-between align-items-center">
                                <input type="email" class="w-100">
                               <span class="pr-1"><img src="../../../assets/images/icons/svg/plus_green.svg"></span>
                            </div>
                    </div>
                    </div>
                    <div class="col-6 mb-3">
                        <label for="">Mobile Number</label>
                        <div class="card">
                                <div class="d-flex justify-content-between align-items-center">
                                    <input type="text" class="w-100">
                                   <span class="pr-1"><img src="../../../assets/images/icons/svg/plus_green.svg"></span>
                                </div>
                        </div>
                    </div>
                    <div class="col-6 mb-3">
                        <label for="">School</label>
                        <input type="text">
                    </div>
                    <div class="col-6 mb-3">
                        <label for="">Branch</label>
                        <input type="text">
                    </div>
                    <div class="col-12">
                        <label for="">Address</label>
                        <input type="text">
                    </div>
                </div>
            </section>
        </div>
    </div>
</div> -->