<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title">{{service.formData.MessageMasterInfo.Name}}</h3>
        <span class="bi bi-x" (click)="bsModalRef.hide()" title="Close Module" data-bs-toggle="tooltip"></span>
    </div>

    <div class="breackout_body p-2 chat-body bg-light">
        <ul class="meassgae_body_inner">
            <li class="message-text" *ngFor="let messagelist of messageList"
                [ngClass]="(messagelist.CreatedBy == commonServ.UserId)?'outgoing':'incoming'">
                <div *ngIf="messagelist.Message != '%PAUSE%' && messagelist.Message != '%RESUME%'">
                    <span class="sender-name"
                        *ngIf="messagelist.CreatedBy != commonServ.UserId">{{messagelist.CreatedByName}}
                    </span>
                    <pre>{{messagelist.Message}}</pre>



                    <span class="sender-date">
                        {{messagelist.CreatedOn}}
                    </span>
                </div>
            </li>
            
        </ul>
        
    </div>
</div>