import {
  CommonModule,
  formatDate,
  NgFor,
  NgIf,
  Location,
} from "@angular/common";
import { Component, OnInit, TemplateRef } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { UsereditComponent } from "./useredit.component";
import { UserService } from "src/app/shared/user.service";
import { CUSTOMCONST } from "src/app/shared/Const";
import { User } from "../shared/user.model";
import { CustommenuComponent } from "../custommenu/custommenu.component";
import { CustomHeader } from "../custommenu/custom-header.component";
import { CommonService } from "../shared/common.service";
import { EditFeedbackComponent } from "../feedback/edit-feedback.component";
import { StudentFeedbacklistComponent } from "../feedback/student-feedbacklist.component";
import { AgGridModule } from "ag-grid-angular";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "app-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.scss"],
  standalone: true,
  imports: [FormsModule, NgFor, AgGridModule, NgIf, CommonModule],
})
export class UserListComponent implements OnInit {
  gridApi;
  gridColumnApi;
  frameworkComponents;
  columnDefs;
  defaultColDef;
  rowSelection;
  rowData: User[] = [];
  filteredrowData: User[] = [];
  filterList : string[] = [];
  bsModalRef: BsModalRef;
  modalRef: BsModalRef;
  gridOptions: any;

  selectedYear: string;
  startDate: string;
  endDate: string;
  yearList: any;
  config = {
    params: {
      startDate: "",
      endDate: "",
    },
  };
  constructor(
    public service: UserService,
    private modalService: BsModalService,
    public commonServ: CommonService,
    private location: Location
  ) {
    var header_template =
      '<span class="text-danger" style="height:30px;">Some Value </span>';
    this.columnDefs = [
      {
        headerName: "Action",
        field: "UserId",
        filter: false,
        cellRendererFramework: CustommenuComponent,
        cellRendererParams: {
          MenuList: [
            { Name: "Student Info", Click: this.editUser, that: this },
            { Name: "Feedback", Click: this.createFeedback, that: this },
          ],
        },

        //headerComponent: 'customHeader',
        //  headerComponentParams: { button: "click", enableMenu: true, menuIcon: "fa-plus", enableSorting: false }
      },
      {
        field: "FullName",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
      },
      {
        field: "Address",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        maxWidth: 120,
        minWidth: 120,
      },
      {
        field: "Street",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        maxWidth: 120,
        minWidth: 120,
      },
      {
        field: "City",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        maxWidth: 120,
        minWidth: 120,
      },
      {
        field: "State",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        maxWidth: 120,
        minWidth: 120,
      },
      {
        field: "PostalNo",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        maxWidth: 120,
        minWidth: 120,
      },
      {
        field: "Mobile",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        maxWidth: 120,
        minWidth: 120,
      },
      {
        field: "SMobile",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        maxWidth: 120,
        minWidth: 120,
      },
      {
        field: "Role",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        maxWidth: 120,
        minWidth: 120,
      },
      {
        field: "Branch",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        maxWidth: 120,
        minWidth: 120,
      },
      {
        field: "Email",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 220,
      },
    ];
    this.frameworkComponents = {
      customHeader: CustomHeader,
    };
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      floatingFilter: true,
    };
    this.rowSelection = "single";
    // this.gridOptions = {
    //   context:{
    //     componentParent:this
    //   }
    // }

    this.commonServ.RefreshGrid$.subscribe((item) => {
      this.getUserList();
      // setTimeout(() => {
      //   this.service.getUserList().subscribe((res : User[]) => {
      //     this.rowData =  res;
      //   });
      // }, 2000);
    });
  }
  goBack(): void {
    this.location.back(); // Navigates back to the previous route
  }
  ngOnInit(): void {
    this.commonServ.getMlListInfo("MLAcademicYear").subscribe((res: any) => {
      res = res.slice().reverse();
      this.yearList = res;
      this.selectedYear = res[0].Code;
      this.config = {
        params: {
          startDate: res[0].Code,
          endDate:
            "30-04-" +
            (parseInt(formatDate(new Date(res[0].Code), "yyyy", "en")) + 1),
        },
      };
      this.getUserList();
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    setTimeout(() => {
      this.getUserList();
    }, 300);
  }
  getUserList() {
    this.service.getUserListByParams(this.config).subscribe((res: any) => {
      const rowData = res;
      // this.gridApi.setRowData(rowData);
      this.rowData = res;
      this.filteredrowData = res;
    });
  }
  createFeedback(UserId, that) {
    const initialState = {
      list: [{ id: UserId }],
    };
    that.bsModalRef = that.modalService.show(EditFeedbackComponent, {
      id: CUSTOMCONST.USER_EDIT_MODAL,
      initialState,
      class: "modal-dialog modal-dialog-centered",
      ignoreBackdropClick: true,
    });
    // that.bsModalRef.content.saved.take(1).subscribe(that.UserSaved.bind(that))
  }
  editUser(UserId, that) {
    const initialState = {
      list: [{ id: UserId }],
    };
    that.bsModalRef = that.modalService.show(StudentFeedbacklistComponent, {
      id: CUSTOMCONST.USER_EDIT_MODAL,
      initialState,
      class: "modal-dialog modal-dialog-centered",
      ignoreBackdropClick: true,
    });
    // that.bsModalRef.content.saved.take(1).subscribe(that.UserSaved.bind(that))
  }
  UserSaved(someData: any) {
    this.getUserList();
  }

  /*
  openusermodal(userid) {
    const initialState = {
      list: [
        {id:userid}
      ]
    };
    this.bsModalRef = this.modalService.show(UsereditComponent,{initialState, class: 'modal-dialog modal-dialog-centered'});
    this.bsModalRef.content.event.subscribe(res => {
    });

  }
  */
  onSelectionChanged($event) {
    var selectedRows = this.gridApi.getSelectedRows();
  }
  changeYear() {
    this.config = {
      params: {
        startDate: this.selectedYear,
        endDate:
          "30-04-" +
          (parseInt(formatDate(new Date(this.selectedYear), "yyyy", "en")) + 1),
      },
    };
  }
  serchStudent() {
    this.getUserList();
  }

  filter(e: Event) {
    const inputElement = e.target as HTMLInputElement;
    const key = inputElement.name; // Get the name of the input field
    const text = inputElement.value; // Get the value of the input field
    this.filterList[key]=text
    var rowData=this.rowData 
    Object.keys(this.filterList).forEach(element => {
      rowData=rowData.filter((o) =>
        o[element].toLowerCase().includes(this.filterList[element].toLowerCase())
      );
    });
    
    this.filteredrowData = rowData;
  }
}
