import { Component, OnInit} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CommonService } from "../../shared/common.service";
import { NotificationService } from "../../shared/notification.service";
import { CUSTOMCONST } from "../../shared/Const";
import { TranslateModule } from "@ngx-translate/core";
import { MasterListData } from "src/app/shared/contact.model";

@Component({
  selector: 'app-editsubject',
  standalone: true,
  templateUrl: './editsubject.component.html',
  styleUrl: './editsubject.component.scss',
  imports: [FormsModule, TranslateModule]
})
export class EditsubjectComponent  implements OnInit {
  list:any[];
  bsModalRef: BsModalRef;
  subject:any;
  Name:string="sasad";
  CUSTOMCONST:any=CUSTOMCONST;
  constructor(public commonServ:CommonService,public modalService: BsModalService,private notificationServ:NotificationService) { }

  ngOnInit(): void {
    this.Name=this.subject.Name
    console.log(this.Name);
    
  }
  addMLSubject(data){
    if(!this.subject.Id)
    {
      this.commonServ.addmlInfo("SubjectData",this.subject.Name,this.subject.Name).subscribe((res:MasterListData) =>{
        if(res.ErrorMessage){
          this.notificationServ.ShowError(res.ErrorMessage);
        }
        else if(res.IsSaved){
          //display added message.
          this.notificationServ.ShowInfo('Item saved successfully');
          this.commonServ.CallOnRefreshItem();
          this.modalService.hide();
        }
      });
    }else{
      this.commonServ.udpatemlinfo("SubjectData",this.subject.Id,this.Name).subscribe((res:any) =>{
        if(res.ErrorMessage){
          this.notificationServ.ShowError(res.ErrorMessage);
        }
        else if(res.IsSaved){
          //display added message.
          this.notificationServ.ShowInfo('Item update successfully');
          this.commonServ.CallOnRefreshGrid();
          this.modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL);
        }
      });
    }
  }
}

