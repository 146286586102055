<div class="dashboardInnerPage inner-page-outer flex-column">
    <div class="title w-100">
        <span (click)="goBack()" title="Back" data-bs-toggle="tooltip" data-bs-placement="bottom"
            class="bi bi-arrow-left"></span>
        <h3>Assessments</h3>
        <a class="add_button" routerLink="#" (click)="openquestionmodal(0)">Add Assessments</a>
    </div>
    <div class="field-row filter-row ">
        <div class="select_field">
            <label>Academic Year</label>

            <select class="field-control" name="selectedYear" [(ngModel)]="selectedYear" (change)="changeYear()"
                required>
                <option *ngFor="let year of yearList" [value]="year.Code">
                    {{year.Name}}
                </option>
            </select>

            <!-- <input [(ngModel)]="modelDate" autocomplete="off" class="form-control rounded-0" name="date" bsDatepicker
                        [bsConfig]="{ dateInputFormat: 'YYYY' }" (onShown)="onOpenCalendar($event)" /> -->
        </div>
        <div class="filter_option">
            <span (click)="serchQuestion()" class="filter_button"><i class="bi bi-search"></i> Search</span>
            <span class="filter_button" data-bs-toggle="collapse" data-bs-target="#collapseExample"
                aria-expanded="false" aria-controls="collapseExample"><i class="bi bi-filter"></i> Filter</span>
        </div>
    </div>
    <div class="filter-form collapse" id="collapseExample">
        <div class="field-row">
            <div class="select_field">
                <label>Name</label>
                <input type="text" class="field-control" name="Name" (keyup)="filter($event)" />
            </div>
            <div class="select_field">
                <label>Description</label>
                <input type="text" class="field-control" name="Description" (keyup)="filter($event)" />
            </div>
            <div class="select_field">
                <label>Subject</label>
                <input type="text" class="field-control" name="Subject" (keyup)="filter($event)" />
            </div>
            <div class="select_field">
                <label>Topic</label>
                <input type="text" class="field-control" name="Topic" (keyup)="filter($event)" />
            </div>
        </div>
        <div class="field-row">
            <div class="select_field">
                <label>No Of Questions</label>
                <input type="text" class="field-control" name="NoOfQuestions" (keyup)="filter($event)" />
            </div>
            <div class="select_field">
                <label>Start Date</label>
                <input type="text" class="field-control" name="StartDate" (keyup)="filter($event)" />
            </div>
            <div class="select_field">
                <label>End Date</label>
                <input type="text" class="field-control" name="EndDate" (keyup)="filter($event)" />
            </div>
            <div class="select_field">
                <label>Start Time</label>
                <input type="text" class="field-control" name="StartTime" (keyup)="filter($event)" />
            </div>
            <div class="select_field">
                <label>End Time</label>
                <input type="text" class="field-control" name="EndTime" (keyup)="filter($event)" />
            </div>
        </div>
    </div>

    <div class="custom-table">
        <!-- <pre>{{ filteredrowData | json }}</pre> Debugging purpose -->

        <table class="table table-striped">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Subject</th>
                    <th>Topic</th>
                    <th>No of Questions</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody *ngIf="filteredrowData.length === 0">
                <tr>
                    <td colspan="10">No data available</td>
                </tr>
            </tbody>
            <tbody *ngIf="filteredrowData.length > 0">
                <tr *ngFor="let data of filteredrowData">
                    <td>{{data.Name}}</td>
                    <td>{{data.Description}}</td>
                    <td>{{data.Subject}}</td>
                    <td>{{data.Topic}}</td>
                    <td>{{data.NoOfQuestions}}</td>
                    <td>{{data.StartDate}}</td>
                    <td>{{data.EndDate}}</td>
                    <td>{{data.StartTime}}</td>
                    <td>{{data.EndTime}}</td>
                    <td>
                        <div class="table-action">
                            <span data-bs-toggle="tooltip" data-bs-placement="bottom" class=" bi bi-pencil-square"
                                title="Edit assignment" (click)="editQuestion(data.QuestionMasterId, this)"></span>
                            <span data-bs-toggle="tooltip" data-bs-placement="bottom" class=" bi bi-list-ul"
                                title="Answer List" (click)="answerList(data.QuestionMasterId, this)"></span>
                            <span *ngIf=" this.commonServ.role != 'Teacher'" data-bs-toggle="tooltip"
                                data-bs-placement="bottom" class="bi bi-trash3" title="Delete"
                                (click)="deleteQuestion(data.QuestionMasterId, this)"></span>

                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<!-- <div class="row">
        <div class="col-12">
            <div style="width: calc(100%);" class="bg-white shadow p-2 mb-0 mt-3 mb-3">
                <div class="row border m-0 py-2 mb-2 justify-content-between">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                        <div class="form-group mb-0">
                            <label>Academic Year</label>

                            <select class="form-control form-control-sm appearance-auto" name="selectedYear"
                                [(ngModel)]="selectedYear" (change)="changeYear()" required>
                                <option *ngFor="let year of yearList" [value]="year.Code">
                                    {{year.Name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                        <button type="button" class="btn btn-warning float-right" type="button">Search</button>
                    </div>
                </div>
                <ag-grid-angular #agGrid style="width: 100%; height:calc(100vh - 150px);min-height: 200px;"
                    class="ag-theme-alpine" id="myGrid" [gridOptions]="gridOptions" [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef" [rowSelection]="rowSelection" [filteredrowData]="filteredrowData"
                    (selectionChanged)="onSelectionChanged($event)" (gridReady)="onGridReady($event)"
                    [frameworkComponents]="frameworkComponents">
                </ag-grid-angular>
            </div>
        </div>
    </div> -->