<div class="user-form d-flex align-items-center">
  <div class="container-fluid">
      <div class="row justify-content-c m-0">
          <div class="col-md-12 p-0">
              <div style="width: calc(100%);" class="bg-white shadow p-2 mb-0 mt-3 mb-3">
                  <!-- <img [src]="logo" alt=""> -->
                  <div class="row form-row m-0 mb-2">
                      <div class="form-group col-4 mb-0 p-0 pl-1">
                          <div class="row form-row m-0 border border-secondary h-100 pt-2 pb-2">
                            <div class="form-group col-12 text-center m-0">
                              <img [src]="service.formData.FileInfo.CroppedContentString" alt="" class="img-fluid edit-school-logo" *ngIf="service.formData.FileInfo && service.formData.FileInfo.CroppedContentString">
                              <img [src]="service.formData.Url" alt="" class="img-fluid edit-school-logo"  *ngIf="service.formData.FileInfo && !service.formData.FileInfo.CroppedContentString">
                              <a class="addImageIcon cursor-pointer" (click)="editSchoolLogo()">
                                <mat-icon>add_a_photo</mat-icon>
                              </a>
                            </div>
                          </div>
                        </div>
                  </div>
                  <div class="card-footer text-right p-2 mt-2" >
                      <button type="button" class="btn btn-primary" (click)="updateSchoolInfo()" type="button" >Update</button>
                  </div>
              </div>
              
          </div>
      </div>
  </div>
  
</div>