import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EditbannerComponent } from '../school/editbanner.component';
import { SchoolService } from '../shared/school.service';
import { UserService } from '../shared/user.service';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-institute-logo',
    templateUrl: './institute-logo.component.html',
    styleUrls: ['./institute-logo.component.scss'],
    standalone: true,
    imports: [NgIf, MatIconModule]
})
export class InstituteLogoComponent implements OnInit {
  schoolid:number;
  logo:string='';
  public bsModalRef: BsModalRef
    constructor(private userService:UserService,public modalService:BsModalService, public service:SchoolService) { }
  
    ngOnInit(): void {
      this.getUserInfo();
    }
  getUserInfo(){
    this.userService.getMyInfo().subscribe((res:any)=>{
      this.schoolid = res.UserInfo.SchoolId;
      this.getSchool();
    })
  }
  getSchool(){
    this.userService.getschool(this.schoolid).subscribe((res:any)=>{
      this.logo = res.Url;
      this.service.formData = res;
    })
  }
  openbranchmodal() {
      
    this.bsModalRef = this.modalService.show(EditbannerComponent,{class: 'modal-xl box-shadow',ignoreBackdropClick: true});
    this.bsModalRef.content.event.subscribe(res => {
       this.service.formData.FileInfo = res.data;
      //  this.service.formData.UserInfo.CroppedFileContent = res.data;
    });
  }
  editSchoolLogo()
  {
    this.openbranchmodal();
  }
  updateSchoolInfo(){
    this.userService.updateschool(this.service.formData).subscribe((res:any)=>{
      console.log(res);
      
    })
  }
  }
  
