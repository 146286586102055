import { NgIf, NgFor } from "@angular/common";
import { Component, EventEmitter, OnInit } from "@angular/core";
import { CommonService } from "../shared/common.service";
import { UserService } from "../shared/user.service";
import { CUSTOMCONST } from "src/app/shared/Const";
import { NgForm, FormsModule } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NotificationService } from "../shared/notification.service";
import { User } from "../shared/user.model";
import {
  BsDatepickerConfig,
  BsDatepickerModule,
} from "ngx-bootstrap/datepicker";
import { EditpicComponent } from "./editpic.component";
import {
  IDropdownSettings,
  // NgMultiSelectDropDownModule
} from "ng-multiselect-dropdown";
import { PasswordResetComponent } from "../login/password-reset.component";
import { UserAcademicYearLogComponent } from "../academic/user-academic-year-log.component";
import { UserLogInfoComponent } from "./user-log-info.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";

@Component({
  selector: "app-editprofile",
  templateUrl: "./editprofile.component.html",
  styleUrls: ["./editprofile.component.scss"],
  standalone: true,
  // imports: [NgIf, FormsModule, NgFor, MatIconModule, BsDatepickerModule, MatCheckboxModule, NgMultiSelectDropDownModule, TranslateModule]
  imports: [
    NgIf,
    NgFor,
    FormsModule,

    MatIconModule,
    BsDatepickerModule,
    MatCheckboxModule,
    TranslateModule,
  ],
})
export class EditprofileComponent implements OnInit {
  list: any = [];
  genderlist: any = [];
  CUSTOMCONST: any;
  maxLength: number;
  currentLength: number;
  role: string = "";
  datepickerConfig: BsDatepickerConfig;
  schoollist: any = [];
  rolelist: any = [];
  childList: any = [];
  branchlist: any = [];
  dropdownSettings: IDropdownSettings;
  public event: EventEmitter<any> = new EventEmitter();
  public saved: EventEmitter<any> = new EventEmitter();
  constructor(
    public service: UserService,
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    private notificationServ: NotificationService,
    private commonService: CommonService
  ) {
    this.datepickerConfig = Object.assign({
      dateInputFormat: "DD-MM-YYYY",
      showWeekNumbers: false,
      showTodayButton: false,
    });
    this.CUSTOMCONST = CUSTOMCONST;
    this.commonService.RefreshItemList$.subscribe((item) => {
      this.service.getUserInfo(this.list[0].id).subscribe((res: any) => {
        this.service.formData.StudentInfo.BatchId = 0;
        this.service.formData.StudentInfo.ClassId = 0;
        this.role = res.UserInfo.Role;
        // this.service.formData.TeacherInfo.IsHumanityLife;
      });
    });
  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: "Id",
      textField: "Name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 2,
      allowSearchFilter: false,
    };

    this.service.getUserInfo(this.list[0].id).subscribe((res: any) => {
      this.service.formData = res;
      this.genderlist = res.GenderList;
      this.schoollist = res.SchoolList;
      this.branchlist = res.BranchList;
      this.rolelist = res.RoleList;
      this.role = res.UserInfo.Role;
      var re = /\\/gi;
      if (res.UserInfo.CroppedProfileFilepath) {
        this.service.formData.UserInfo.CroppedProfileFilepath =
          res.UserInfo.CroppedProfileFilepath.replace(re, "/");
      }
      this.schoolChange();
    });
    this.maxLength = 2000;
    this.currentLength = 0;
  }

  onSave(form: NgForm) {
    this.service.formData.UserInfo.IsSaved = false;
    if (this.service.formData.UserInfo.UserId > 0) {
      this.service.saveUser().subscribe((res: User) => {
        if (res.ErrorMessage) {
          this.notificationServ.ShowError(res.ErrorMessage);
        } else if (res.IsSaved) {
          this.saved.emit("someData");
          this.notificationServ.ShowInfo("User saved successfully");
          // this.bModalRef.hide();
          this.modalService.hide(CUSTOMCONST.USER_EDIT_MODAL);
        } else this.saved.emit("someData");
      });
    } else {
      this.service.formData.UserInfo.IsActive = true;
      this.saved.emit("someData");
      this.service.saveUser().subscribe((res: User) => {
        if (res.ErrorMessage) {
          this.notificationServ.ShowError(res.ErrorMessage);
        } else if (res.IsSaved) {
          this.notificationServ.ShowInfo("User saved successfully");
          this.commonService.CallOnRefreshGrid();
          // this.bModalRef.hide();
          this.modalService.hide(CUSTOMCONST.USER_EDIT_MODAL);
        }
      });
    }
  }
  lenghtCounter() {
    this.currentLength = this.service.formData.UserInfo.About.length;
    // this.disabled = this.currentLength<this.maxLength?true;
  }
  openbranchmodal(data: string) {
    const initialState = {
      list: [{ editImageOf: data }],
    };
    this.bsModalRef = this.modalService.show(EditpicComponent, {
      id: CUSTOMCONST.LABEL_TWO_MODAL,
      initialState,
      class: "modal-md modal-dialog modal-dialog-centered",
      ignoreBackdropClick: true,
    });
    this.bsModalRef.content.event.subscribe((res) => {
      if (data === "profilePic") {
        this.service.formData.UserInfo.FileInfo = res.data;
      } else if (data === "coverImage") {
        this.service.formData.UserInfo.ProfileFileInfo = res.data;
      }
    });
  }
  editPic() {
    this.openbranchmodal("profilePic");
  }
  editCoverImage() {
    this.openbranchmodal("coverImage");
  }

  schoolChange() {
    if (this.service.formData.UserInfo.SchoolId > 0) {
      this.childList = [];
      this.service
        .getBasicStudentList(this.service.formData.UserInfo.SchoolId)
        .subscribe((res: any) => {
          this.childList = res;
        });
    } else {
      this.childList = [];
    }
  }
  onItemSelect(item: any, type: string) {
    // if (type == 'Child' && this.service.formData.ChildMappingList) {
    //   var ids = item.Id;
    //   for (var i = 0; i < this.service.formData.ChildMappingList.length; i++) {
    //     if (ids) {
    //       ids = ids + ',';
    //     }
    //     ids = ids + this.service.formData.ChildMappingList[i].Id;
    //   }
    // }
    // else {
    //   this.service.getBasicStudentList(item.Id).subscribe((res: any) => {
    //     this.childList = res;
    //   });
    // }
  }
  resetPassword(UserId) {
    const initialState = {
      list: [{ id: UserId }],
    };
    this.bsModalRef = this.modalService.show(PasswordResetComponent, {
      id: CUSTOMCONST.RESET_PWD_MODAL,
      initialState,
      class: "modal-md modal-dialog modal-dialog-centered",
      ignoreBackdropClick: true,
    });
  }
  userAcademicYearLogInfo(UserId: number) {
    const initialState = {
      list: [{ id: UserId }],
    };
    this.bsModalRef = this.modalService.show(UserAcademicYearLogComponent, {
      id: 'UserAcademicYearLog',
      initialState,
      class: "modal-md modal-dialog modal-dialog-centered",
      ignoreBackdropClick: true,
    });
  }
  // userLogHistory(){
  //   const initialState = {

  //     list: [
  //       {id:this.role==="Parent"?this.commonService.selectedChileId:this.list[0].id},
  //     ]

  //   };
  //   this.bsModalRef = this.modalService.show(UserLogInfoComponent, { id: CUSTOMCONST.LABEL_TWO_MODAL, initialState, class: 'modal-xl box-shadow', ignoreBackdropClick: true });

  // }
}
