<div class="field-row filter-row ">
  <div class="select_field">
    <label>Select Date</label>
    <input class="field-control" placeholder="{{'Date' | translate}}" name="selectedDate" [(ngModel)]="selectedDate"
      [bsConfig]="datepickerConfig" bsDatepicker timepicker>

    <!-- <input [(ngModel)]="modelDate" autocomplete="off" class="form-control rounded-0" name="date" bsDatepicker
                  [bsConfig]="{ dateInputFormat: 'YYYY' }" (onShown)="onOpenCalendar($event)" /> -->
  </div>
  <div class="filter_option">
    <span (click)="serchDairy()" class="filter_button"><i class="bi bi-search"></i> Search</span>
  </div>
  <div class="select_field selected_field">
    <span class="bi bi-chevron-left"></span>
    <label>{{commonService.formatdate(selectedDate)}}</label>
    <span class="bi bi-chevron-right"></span>
  </div>
</div>

<div class="custom-table">
  <!-- <pre>{{ filteredrowData | json }}</pre> Debugging purpose -->

  <table class="table table-striped">
    <thead>
      <tr>
        <th>Name</th>
        <th>Start Date</th>
        <th>End Date</th>
        <th>Class</th>

      </tr>
    </thead>
    <tbody *ngIf="filteredrowData.length === 0">
      <tr>
        <td colspan="5">No data available</td>
      </tr>
    </tbody>
    <tbody *ngIf="filteredrowData.length > 0">
      <tr *ngFor="let data of filteredrowData">
        <td>{{data.Subject}}</td>
        <td>{{data.Teacher}}</td>
        <td>{{data.DairyNotes}}</td>
        <td>{{data.Result}}</td>


      </tr>
    </tbody>
  </table>
</div>

<!-- <mat-table [dataSource]="dataSource" *ngIf="dataSource.length > 0" class="mat-elevation-z8 p-2">



  <ng-container matColumnDef="Subject">
    <mat-header-cell *matHeaderCellDef>Subject</mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.Subject}}</mat-cell>
  </ng-container>


  <ng-container matColumnDef="Teacher">
    <mat-header-cell *matHeaderCellDef> Teacher </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.TeacherName}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="Dairy Notes">
    <mat-header-cell *matHeaderCellDef> Dairy Notes </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.DairyNotes}}</mat-cell>
  </ng-container>


  <ng-container matColumnDef="Result">
    <mat-header-cell *matHeaderCellDef> Result </mat-header-cell>
    <mat-cell *matCellDef="let element" (click)="updateDairy(element.StudentDairyId)"
      [ngClass]="{'text-danger': element.Result=='Pending', 'text-success': element.Result=='Completed'}">{{element.Result}}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>


<mat-table [dataSource]="emptyDataSource" *ngIf="dataSource.length === 0" class="mat-elevation-z8 p-2">

  <ng-container matColumnDef="Subject">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Subject </mat-header-cell>
    <mat-cell class="d-flex justify-content-center min-h-25px" *matCellDef="let element"> No records found
    </mat-cell>
  </ng-container>


  <ng-container matColumnDef="Teacher">
    <mat-header-cell *matHeaderCellDef> Teacher </mat-header-cell>
    <mat-cell style="display: none;" *matCellDef="let element"></mat-cell>
  </ng-container>

  <ng-container matColumnDef="Dairy Notes">
    <mat-header-cell *matHeaderCellDef> Dairy Notes </mat-header-cell>
    <mat-cell style="display: none;" *matCellDef="let element"></mat-cell>
  </ng-container>


  <ng-container matColumnDef="Result">
    <mat-header-cell *matHeaderCellDef> Result </mat-header-cell>
    <mat-cell style="display: none;" *matCellDef="let element"></mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table> -->