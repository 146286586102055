<div class="dashboardInnerPage inner-page-outer flex-column">
  <!-- <div class="title w-100">
    <span (click)="goBack()" title="Back" data-bs-toggle="tooltip" data-bs-placement="bottom"
      class="bi bi-arrow-left"></span>
    <h3>{{questionservice.formData.QuestionMasterInfo.Name}}</h3>
    
  </div> -->
  <div *ngIf="!IsStart" class="exam_outer">
    <div class="exam_start_inner">
      <h3>{{questionservice.formData.QuestionMasterInfo.Name}}</h3>
      <p>{{questionservice.formData.QuestionMasterInfo.Description}}</p>

      <h5>Total Number of Uqestions: <strong>{{noofQuestions}}</strong></h5>
      <span>once you have click on start button until unless you can't go
        back.</span>

      <div class="modal_footer">
        <button class="custom-primary-btn" (click)="startQuiz()">Start</button>
        <button class="custom-primary-btn custom-danger" (click)="goBack()" aria-label="Close">Cancel</button>
      </div>
    </div>
  </div>

  <!-- <div *ngIf="!IsStart" class="exam-start text-center">
    <mat-card-header class="m-0 d-flex justify-content-center">
      <mat-card-title class="ml-0 ">{{questionservice.formData.QuestionMasterInfo.Name}}</mat-card-title>

    </mat-card-header>
    <mat-card-content>
      <p>
        {{questionservice.formData.QuestionMasterInfo.Description}}
      </p>
      <p>Totoal Number of Uqestions: <strong>{{noofQuestions}}</strong></p>
      <span class="text-muted">once you have click on start button untill unless you can't go
        back.</span>

      <div class="text-center pt-2">
        <button class="btn btn-danger text-white mlr-4px" (click)="goBack()" aria-label="Close">Cancel</button>
        <button class="btn btn-primary text-white mlr-4px" (click)="startQuiz()" *ngIf="!IsStart">Start</button>

      </div>
    </mat-card-content>
  </div> -->

  <form novalidate #form="ngForm" method="POST" *ngIf="IsStart">

    <div class="assessment_start_header">
      <div class="modal-header">
        <h3 class="modal-title h3 m-1">{{questionservice.formData.QuestionMasterInfo.Name}}</h3>
        <h3 class="modal-title h3 m-1">Max Marks: 100</h3>
      </div>
    </div>

    <div *ngFor="let question of questionList">
      <div *ngIf="question.QuestionType == 'Eassy'">
        <div class="field-row">
          <div class="select_field flex-row justify-content-between">
            <label>Q. {{question.QuestionNo}} {{question.Question}} </label>
            <label>Marks: {{question.Points}} </label>
          </div>
        </div>
        <div class="field-row">
          <div class="select_field">
            <textarea class="field-control appearance-auto resizebele-false" name="StrAnswer" #StrAnswer="ngModel"
              [(ngModel)]="question.QAnswerList[0].StrAnswer" rows="10" placeholder="Write Your Answer"></textarea>
          </div>
        </div>

        <div class="field-row">
          <img [src]="question.QAnswerList[0].FilePath" alt="" class="img img-fluid max-width-50px">
        </div>
      </div>

      <div *ngIf="question.QuestionType == 'TF'">
        <div class="field-row">
          <div class="select_field flex-row justify-content-between">
            <label>Q. {{question.QuestionNo}} {{question.Question}} </label>
            <label>Marks: {{question.Points}} </label>
          </div>
        </div>
        <mat-radio-group aria-label="Select an option" name="IsAnswer" #IsAnswer="ngModel"
          [(ngModel)]="question.QAnswerList[0].IsAnswer" (change)="changeTF()" required
          class.invalid="IsAnswer.invalid && IsAnswer.touched">
          <mat-radio-button class="mr-3" *ngFor="let qAnswer of question.QAnswerList; let i=index;"
            [value]="qAnswer.Answer">{{qAnswer.Answer}}</mat-radio-button>
        </mat-radio-group>

        <div class="field-row" *ngFor="let qAnswer of question.QAnswerList; let i=index">
          <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-50px">
        </div>
      </div>

      <div *ngIf="question.QuestionType == 'MC'">
        <h3>
          <span>Q. {{question.QuestionNo}} </span> <span class="float-right">Marks:
            {{question.Points}}</span>
          <div class="d-inline-block" [innerHTML]="question.Question"></div>
        </h3>
        <section *ngFor="let qAnswer of question.QAnswerList; let i=index">
          <div class="row form-row">
            <div class="col-6">
              <div class="form-group mb-0">
                <div class="appearance-auto mb-3">

                  <div class="custom-p-2">
                    <mat-radio-group aria-label="Select an option" class="" name="StrAnswer-{{i}}" #StrAnswer="ngModel"
                      [(ngModel)]="qAnswer.StrAnswer" (change)="changeMC(question.QAnswerList.length, i )">
                      <mat-radio-button [value]="qAnswer.GroupNo">{{qAnswer.Answer}}</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-50px">
            </div>
          </div>
        </section>

      </div>

      <div *ngIf="question.QuestionType == 'MR'" class="mb-3 p-2 border bdr-left">
        <h3>
          <span>Q. {{question.QuestionNo}} </span> <span class="float-right">Marks:
            {{question.Points}}</span>
          <div class="d-inline-block" [innerHTML]="question.Question"></div>
        </h3>
        <section *ngFor="let qAnswer of question.QAnswerList; let i=index">
          <div class="row form-row">
            <div class="col-6">
              <div class="form-group mb-0">
                <div class="form-control form-control-sm appearance-auto rounded-0 p-0 border-0">
                  <div class="custom-p-2 custom-mt-1">
                    <mat-checkbox class="" name="IsAnswer" #IsAnswer="ngModel"
                      [(ngModel)]="qAnswer.IsAnswer">{{qAnswer.Answer}} </mat-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-50px">
            </div>
          </div>
        </section>

      </div>
      <div *ngIf="question.QuestionType == 'FIB'" class="mb-3 p-2 border bdr-left">
        <h3>
          <span>Q. {{question.QuestionNo}} </span> <span class="float-right">Marks:
            {{question.Points}}</span>
          <div class="d-inline-block" [innerHTML]="question.Question"></div>
        </h3>
        <section *ngFor="let qAnswer of question.QAnswerList; let i=index">
          <div class="row form-row">
            <div class="col-6">
              <div class="form-group mb-0">
                <input type="text" class="m-0 form-control form-control-sm" name="StrAnswer" #StrAnswer="ngModel"
                  [(ngModel)]="qAnswer.StrAnswer">
              </div>
            </div>
            <div class="col-6">
              <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-50px">
            </div>
          </div>
        </section>

      </div>
      <div *ngIf="question.QuestionType == 'MF'" class="mb-3 p-2 border bdr-left">
        <h3>
          <span>Q. {{question.QuestionNo}} </span> <span class="float-right">Marks:
            {{question.Points}}</span>
          <div class="d-inline-block" [innerHTML]="question.Question"></div>
        </h3>

        <section>

          <div class="row form-row ">
            <div class="col-12 col-md-6">
              <div class="row m-0 mb-3 align-items-center" *ngFor="let qAnswer of question.QAnswerList; let i=index">
                <div class="col-6">
                  <div class="form-group mb-0">
                    <div class="appearance-auto mb-3">
                      <div class="custom-p-2">
                        <p>{{qAnswer.GroupNo}}. {{qAnswer.Answer}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6" *ngIf="question.OptionType == 'Text'">
                  <div class="form-group mb-0">
                    <div class="appearance-auto mb-3">
                      <div class="custom-p-2">
                        <p>{{qAnswer.GroupNo1}}. {{qAnswer.Answer1}}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-6" *ngIf="question.OptionType == 'Image'">
                  <div class="form-group mb-0">
                    <div class="appearance-auto mb-3">
                      <div class="custom-p-2">
                        {{qAnswer.GroupNo1}}
                        <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-50px">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="row m-0 mb-3" *ngFor="let qAnswer of question.QAnswerList; let i=index">
                <div class="col-12">
                  <div class="d-flex">
                    <div class="form-group mb-0 mr-1">
                      <div class="form-control form-control-sm appearance-auto rounded-0 p-0" style="height: 54px;">
                        <div class="bg-theam text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                          <label class="text-white p-2">{{qAnswer.GroupNo}}</label>
                        </div>
                      </div>
                    </div>
                    <div class="form-group mb-0 mr-1" *ngFor="let qAnswer1 of question.QAnswerList; let j=index">
                      <div class="form-control form-control-sm appearance-auto rounded-0 p-0" style="height: 54px;">
                        <div class="bg-theam text-center custom-p-1">
                          <label class="text-white">{{qAnswer1.GroupNo1}}</label>
                        </div>
                        <div class="text-center custom-p-2 custom-mt-1">
                          <mat-radio-group aria-label="Select an option" class="mat-radio-label-content-false"
                            name="{{i}}" #StrAnswer="ngModel" [(ngModel)]="qAnswer.StrAnswer">
                            <mat-radio-button value="{{qAnswer1.GroupNo1}}"></mat-radio-button>
                          </mat-radio-group>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
      <div *ngIf="question.QuestionType == 'MM'" class="mb-3 p-2 border">
        <h3>
          <span>Q. {{question.QuestionNo}} </span> <span class="float-right">Marks:
            {{question.Points}}</span>
          <div class="d-inline-block" [innerHTML]="question.Question"></div>
        </h3>
        <section *ngFor="let qAnswer of question.QAnswerList; let i=index">
          <div class="row form-row">

            <div class="col-3">
              <div class="form-group mb-0">
                <div class="appearance-auto mb-3">
                  <div class="custom-p-2">
                    <p>{{qAnswer.GroupNo}}. {{qAnswer.Answer}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-3" *ngIf="question.OptionType == 'Text'">
              <div class="form-group mb-0">
                <div class="appearance-auto mb-3">
                  <div class="custom-p-2">
                    <p>{{qAnswer.GroupNo1}}. {{qAnswer.Answer}}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-3" *ngIf="question.OptionType == 'Image'">
              <div class="form-group mb-0">
                <div class="appearance-auto mb-3">
                  <div class="custom-p-2">
                    {{qAnswer.GroupNo1}}. <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-50px">
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="d-flex">
                <div class="form-group mb-0 mr-1">
                  <div class="form-control form-control-sm appearance-auto rounded-0 p-0" style="height: 54px;">
                    <div class="bg-theam text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                      <label class="text-white p-2">{{qAnswer.GroupNo}}</label>
                    </div>
                  </div>
                </div>
                <div class="form-group mb-0 mr-1" *ngFor="let qAnswer1 of question.QAnswerList; let j=index">
                  <div class="form-control form-control-sm appearance-auto rounded-0 p-0" style="height: 54px;">
                    <div class="bg-theam text-center custom-p-1">
                      <label class="text-white">{{qAnswer1.GroupNo1}}</label>
                    </div>
                    <div class="text-center custom-p-2 custom-mt-1">

                      <mat-radio-group aria-label="Select an option" class="mat-radio-label-content-false" name="{{i}}"
                        #IsCorrect="ngModel" [(ngModel)]="qAnswer.StrAnswer">
                        <mat-radio-button value="{{qAnswer1.GroupNo1}}"></mat-radio-button>
                      </mat-radio-group>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


      </div>
    </div>




    <div class="modal-footer p-1" *ngIf="IsStart">

      <button class="btn btn-success text-white mlr-4px" (click)="addStudentAnswers()">Submit</button>
      <button class="btn btn-danger text-white mlr-4px" (click)="bsModalRef.hide()" aria-label="Close">Cancel</button>

    </div>
  </form>
</div>