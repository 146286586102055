import { formatDate, NgIf, NgClass, NgFor } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  BsDatepickerConfig,
  BsDatepickerModule,
} from "ngx-bootstrap/datepicker";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CustomGrideCelComponent } from "src/app/custommenu/custom-gride-cel/custom-gride-cel.component";
import { CustomHeader } from "src/app/custommenu/custom-header.component";
import { CustommenuComponent } from "src/app/custommenu/custommenu.component";
import { CommonService } from "src/app/shared/common.service";
import { DairyService } from "src/app/shared/dairy.service";
import { UserService } from "src/app/shared/user.service";
import { EditstudebtDairyComponent } from "../editstudebt-dairy/editstudebt-dairy.component";
import { CUSTOMCONST } from "src/app/shared/Const";
import { ConfirmComponent } from "src/app/common/confirm.component";
import { NotificationService } from "src/app/shared/notification.service";
import { TranslateModule } from "@ngx-translate/core";
import { MatTableModule } from "@angular/material/table";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "app-student-dairy",
  templateUrl: "./student-dairy.component.html",
  styleUrls: ["./student-dairy.component.scss"],
  standalone: true,
  imports: [
    BsDatepickerModule,
    FormsModule,
    NgIf,
    MatTableModule,
    NgClass,
    TranslateModule,
    NgFor,
  ],
})
export class StudentDairyComponent implements OnInit {
  public bsModalRef: BsModalRef;
  frameworkComponents;
  dairyRowData: any;
  dairyRowSelection;
  dairyDefaultColDef;
  dairyColumnDefs;
  dairyGridOptions: any;
  gridApi;
  gridColumnApi;
  filteredrowData: any[] = [];

  datepickerConfig: BsDatepickerConfig;
  classList: any[] = [];
  minDate = new Date();
  selectedDate: any = new Date();
  selectedClass: number = 1;
  config = {
    params: {
      studentId: 1,
      dairyDate: formatDate(new Date(), "dd-MM-yyyy", "en"),
    },
  };
  dairyUpdateData: any;
  studentId: number;
  dairyResultList: any[] = [];
  dataSource: any[] = [];
  emptyDataSource: any[] = [];

  constructor(
    public dairyServ: DairyService,
    public commonService: CommonService,
    public userService: UserService,
    public modalService: BsModalService,
    private notificationServ: NotificationService
  ) {
    this.commonService.OnConfirm$.subscribe((item) => {
      this.saveStudentAnswerInner();
    });
    this.commonService.RefreshGrid$.subscribe((item) => {
      this.getStudentDairy();
    });
  }

  ngOnInit(): void {
    // this.grigColReady();
    this.serchDairy();
    this.getClass();
    setTimeout(() => {
      this.getMyInfo();
    }, 300);
  }
  getMyInfo() {
    this.userService.getMyInfo().subscribe((res: any) => {
      this.userService.formData = res;
      console.log("getMyInfo");
      console.log(res.UserInfo.UserId);
      this.studentId = res.UserInfo.UserId;
      this.config.params.studentId = res.UserInfo.UserId;

      this.getStudentDairy();
    });
  }
  grigColReady() {
    this.dairyColumnDefs = [
      {
        headerName: "",
        field: "StudentDairyId",
        cellRendererFramework: CustommenuComponent,
        cellRendererParams: {
          MenuList: [
            { Name: "Mark as completed", Click: this.editDairy, that: this },
          ],
        },

        filter: false,
        width: 60,
        maxWidth: 60,
        minWidth: 60,
        // headerComponent: 'customHeader',
        // headerComponentParams : { button : "click", enableMenu : true, menuIcon : "fa-plus", enableSorting:false}
      },
      {
        field: "Subject",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
      },
      {
        headerName: "Teacher",
        field: "TeacherName",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
      },
      {
        field: "DairyNotes",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
      },
      {
        field: "Result",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
      },
    ];
    this.frameworkComponents = {
      customHeader: CustomHeader,
    };
    this.dairyDefaultColDef = {
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      floatingFilter: true,
    };
  }
  getStudentDairy() {
    this.dairyServ
      .getStudentDairyViewInfo(this.config)
      .subscribe((res: any) => {
        console.log(res);
        this.dairyRowData = res.StudentDairyInfo;
        this.dairyResultList = res.DairyResultList;
        this.dataSource = res.StudentDairyInfo;
        this.filteredrowData = res;
        this.emptyDataSource =
          this.dataSource.length > 0
            ? []
            : [{ DataNotFound: "No records found" }];
        console.log(this.dataSource);
      });
  }
  onDairyReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    setTimeout(() => {
      this.getMyInfo();
    }, 300);
  }
  onDairyReload(event) {}
  serchDairy() {
    this.config.params.dairyDate = formatDate(
      new Date(this.selectedDate),
      "dd-MM-yyyy",
      "en"
    );
    this.getStudentDairy();
  }
  changeClass() {
    this.config.params.studentId = this.selectedClass;
  }

  saveStudentAnswerInner() {
    this.dairyServ
      .saveStudentDairyViewInfo(this.dairyUpdateData)
      .subscribe((res: any) => {
        if (res.IsSaved) {
          this.notificationServ.ShowInfo("Successfully saved!");
          this.commonService.CallOnRefreshGrid();
          this.modalService.hide(CUSTOMCONST.STDASSIGNMENT_MODAL);
          // this.bsModalRef.hide()
        }
      });
  }
  editDairy(id, that) {
    that.dairyRowData;
    that.dairyRowData.forEach((element, index) => {
      if (element.StudentDairyId == id) {
        that.dairyUpdateData = element;
        that.dairyResultList.forEach((data, i) => {
          if (data.Code == "Completed") {
            that.dairyUpdateData.ResultId = data.Id;
          }
        });

        // that.dairyUpdateData.Result = "Completed";
        // that.dairyUpdateData.StudentId = that.studentId;
        console.log(that.dairyUpdateData);

        const initialState = {
          list: [
            { id: "confirm message id" },
            { message: "Are you sure want to submit as completed?" },
          ],
        };
        that.bsModalRef = that.modalService.show(ConfirmComponent, {
          id: CUSTOMCONST.CONFIRM_MODAL,
          initialState,
          class: "second col-12 modal-dialog-centered model-sm",
          ignoreBackdropClick: true,
        });
      }
    });

    // that.openStudentDairyModel();
  }
  getClass() {
    this.commonService.getclasses().subscribe((res: any) => {
      this.classList = res;
    });
  }

  displayedColumns: string[] = ["Subject", "Teacher", "Dairy Notes", "Result"];

  updateDairy(id) {
    this.dairyRowData;
    this.dairyRowData.forEach((element, index) => {
      if (element.StudentDairyId == id) {
        this.dairyUpdateData = element;
        this.dairyResultList.forEach((data, i) => {
          if (data.Code == "Completed") {
            this.dairyUpdateData.ResultId = data.Id;
          }
        });

        const initialState = {
          list: [
            { id: "confirm message id" },
            { message: "Are you sure want to submit as completed?" },
          ],
        };
        this.bsModalRef = this.modalService.show(ConfirmComponent, {
          id: CUSTOMCONST.CONFIRM_MODAL,
          initialState,
          class: "second col-12 modal-dialog-centered model-sm",
          ignoreBackdropClick: true,
        });
      }
    });

    // that.openStudentDairyModel();
  }
}
