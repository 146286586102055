<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1 w-100">QRCode View</h3>
        </div>
        <form novalidate #form="ngForm" autocomplete="off">
            <div class="breackout_body p-2">
                <div class="row justify-content-center">
                    <div class="col-6 text-center">
                        <img class="mx-w-300px img-fluid" [src]="QRCodeUrl" [alt]="QRCodeUrl">
                    </div>
                </div>
            </div>
            <div class="modal-footer p-1">
                <button type="button" class="btn btn-danger" (click)="bsModalRef.hide()"
                    aria-label="Close">Close</button>
            </div>
        </form>
    </div>
</div>