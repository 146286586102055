<div class="dashboardInnerPage inner-page-outer flex-column">
    <div class="title w-100">
        <span (click)="goBack()" title="Back" data-bs-toggle="tooltip" data-bs-placement="bottom" class="bi bi-arrow-left"></span>
        <h3>Leave Management</h3>
    </div>
    <div class="field-row filter-row ">

        <div class="select_field">
            <label>Academic Year</label>
            <select class="field-control" name="selectedYear" [(ngModel)]="selectedYear" (change)="changeYear()"
                required>
                <option *ngFor="let year of yearList" [value]="year.Code">
                    {{year.Name}}
                </option>
            </select>
        </div>
        <div class="filter_option">
            <span (click)="serchData()" class="filter_button"><i class="bi bi-search"></i> Search</span>
            <span class="filter_button" data-bs-toggle="collapse" data-bs-target="#collapseExample"
                aria-expanded="false" aria-controls="collapseExample"><i class="bi bi-filter"></i> Filter</span>

        </div>
    </div>
    <div class="field-row filter-form collapse" id="collapseExample">
        <div class="select_field">
            <label>User Name</label>
            <input type="text" class="field-control" name="UserName" (keyup)="filter($event)" />
        </div>
        <div class="select_field">
            <label>Leave Type</label>
            <input type="text" class="field-control" name="LeaveType" (keyup)="filter($event)" />
        </div>
        <div class="select_field">
            <label>Reason</label>
            <input type="text" class="field-control" name="Reason" (keyup)="filter($event)" />
        </div>
        <div class="select_field">
            <label>Start Date</label>
            <input type="text" class="field-control" name="StartDate" (keyup)="filter($event)" />
        </div>
        <div class="select_field">
            <label>End Date</label>
            <input type="text" class="field-control" name="EndDate" (keyup)="filter($event)" />
        </div>
        <div class="select_field">
            <label>Leave Status</label>
            <input type="text" class="field-control" name="LeaveStatus" (keyup)="filter($event)" />
        </div>
    </div>
    <div class="custom-table">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th>User Name</th>
                    <th>Leave Type</th>
                    <th>Reason</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Leave Status</th>
                    <th>Is Full Day Leave</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody *ngIf="filteredrowData.length === 0">
                <tr>
                    <td colspan="8">No data available</td>
                </tr>
            </tbody>
            <tbody *ngIf="filteredrowData.length > 0">
                <tr *ngFor="let data of filteredrowData">
                    <td>{{data.UserName}}</td>
                    <td>{{data.LeaveType}}</td>
                    <td>{{data.Reason}}</td>
                    <td>{{data.StartDate}}</td>
                    <td>{{data.EndDate}}</td>
                    <td>{{data.LeaveStatus}}</td>
                    <td>{{data.IsFullDayLeave}}</td>
                    <td>
                        <div class="table-action">
                            <span data-bs-toggle="tooltip" data-bs-placement="bottom" class=" bi bi-pencil-square"
                                title="Edit" (click)="editLeave(this)"></span>
                            <span data-bs-toggle="tooltip" data-bs-placement="bottom" class="bi bi-trash3"
                                title="Delete" (click)="deleteLeave(this)"></span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- <div class="row">
        <div class="col-12">
            <div style="width: calc(100%);" class="bg-white shadow p-2 mb-0 mt-3 mb-3">
                <div class="row border m-0 py-2 mb-2 justify-content-between">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                        <div class="form-group mb-0">
                            <label>Academic Year</label>

                            <select class="form-control form-control-sm appearance-auto" name="selectedYear"
                                [(ngModel)]="selectedYear" (change)="changeYear()" required>
                                <option *ngFor="let year of yearList" [value]="year.Code">
                                    {{year.Name}}
                                </option>
                            </select>

                        </div>

                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                        <button type="button" (click)="serchData()" class="btn btn-warning float-right"
                            type="button">Search</button>
                    </div>
                </div>
                <ag-grid-angular #agGrid style="width: 100%; height:calc(100vh - 200px);min-height: 200px;"
                    class="ag-theme-alpine" id="eventGrid" [gridOptions]="leaveGridOptions"
                    [columnDefs]="leaveColumnDefs" [defaultColDef]="leaveDefaultColDef"
                    [rowSelection]="leaveRowSelection" [rowData]="leaveRowData" (gridReady)="onLeaveReady($event)"
                    [frameworkComponents]="frameworkComponents" (gridReload)="onLeaveReload($event)"></ag-grid-angular>

            </div>
        </div>
    </div> -->
</div>