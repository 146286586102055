import { Component, ElementRef, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";
import $ from "jquery";
import { EventService } from "src/app/shared/event.service";
import { NotificationService } from "src/app/shared/notification.service";
import { FormsModule } from "@angular/forms";
import { WebrtcChatComponentComponent } from "../webrtc-chat-component/webrtc-chat-component.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { BreakOutRoomsComponent } from "../break-out-rooms/break-out-rooms.component";
import { StudentListComponent2 } from "./../student-list/student-list.component";

declare const oneToMany: any;
declare const oneToManyScreen: any;
declare const cameraOff: any;
declare const sendMessage: any;

@Component({
  selector: "app-webrtc-student-component",
  standalone: true,
  imports: [CommonModule, FormsModule, WebrtcChatComponentComponent,StudentListComponent2],
  templateUrl: "./webrtc-student-component.component.html",
  styleUrl: "./webrtc-student-component.component.scss",
})
export class WebrtcStudentComponentComponent {
  showchatboxbutton: boolean = true;
  hidechatboxbutton: boolean = false;
  roomId: string | null;
  showList: boolean = false;
  handRaised: boolean = false;
  studentList: any[] = [];
  public bsModalRef: BsModalRef;
  constructor(
    public eventService: EventService,
    public notificationService: NotificationService,
    private modalService: BsModalService
  ) {}
  ngOnInit() {
    localStorage.setItem("DuringConsultation", "true");

    $(document).ready(function () {
      $("#btnMute").show();
      $("#btnUnMute").hide();
      $("#off-cam").show();
      $("#start-cam").hide();
    });
    // calling function for getting consultation status
    var grouproomId = localStorage.getItem("grouproomId");
    if (grouproomId) {
      $("#switch-call").show();
    }
    this.roomId = localStorage.getItem("room_id");
    if (this.roomId) oneToMany(this.roomId, "openOrJoin", false);
    this.getStudentList();
    this.updateAttandance();
  }
  socketIo() {}
  switchShowList() {
    this.showList = !this.showList;
    $(document).ready(function () {
      $("#studentshow").toggleClass("active");
    });
  }
  getStudentList() {
    const eventData = JSON.parse(localStorage.getItem("eventData"));

    this.eventService
      .getStudentByEventId(eventData.EventId)
      .subscribe((res: any) => {
        const rowData = res.StudentList;
        this.studentList = rowData;
      });
  }
  updateAttandance() {
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    const actionData = {
      EventId: eventData.EventId,
      Action_Type: "Join",
    };

    this.eventService.saveRoomStatusInfo(actionData).subscribe((res: any) => {
      const rowData = res.StudentList;
      this.studentList = rowData;
    });
  }
  endCall() {
    // window.close();
  }
  // This function calls to send message

  // This function calls when doctor click on chat button
  showchatbox() {
    $(document).ready(function () {
      $("#showconsultationsidebar").toggleClass("chatBoxOpen");
      $("#showchat").toggleClass("active");
    });
  }
  // This function calls for hide chat box

  hidechatbox() {
    const showchatBtn = document.getElementById("showchat");
    showchatBtn.style.display = "flex";
    const hidechatBtn = document.getElementById("hidechat");
    hidechatBtn.style.display = "none";
    $(document).ready(function () {
      $("#showconsultationsidebar").removeClass("chatBoxOpen");
    });
  }
  handRaise() {
    this.handRaised=!this.handRaised
    const msg = JSON.stringify({
      type: this.handRaised?"handraise":"hand-down",
    });
    sendMessage(msg);
    const handraiseBtn = $("#hand-raise-btn");
    if (handraiseBtn) {
      handraiseBtn.toggleClass("hand-raise");
    }
  }
  openBreakOut() {
    const msg = JSON.stringify({
      type: "handraise",
    });
    const initialState = {};
    this.bsModalRef = this.modalService.show(BreakOutRoomsComponent, {
      initialState,
      class: "modal-dialog modal-dialog-centered breackoutroom_modal_student",
      ignoreBackdropClick: true,
    });
  }
}
