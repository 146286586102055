<div class="dashboardInnerPage">
    <div class="teacherDashboardData w-100">
        <div class="dashboard-col dashboard-learning-col">
            <div class="learning-col" (click)="changeLearningType('SchoolBL')"
                [ngClass]="{'active':activeLearningType=='SchoolBL'}">
                <div class="icon-col">
                    <img src="../../../assets/images/dashboard-icons/school-icon.png" alt="">
                </div>
                <h3>School Based<br>Learning</h3>
            </div>
            <div class="learning-col" (click)="changeLearningType('HomeBL')"
                [ngClass]="{'active':activeLearningType=='HomeBL'}">
                <div class="icon-col">
                    <img src="../../../assets/images/dashboard-icons/home-icon.png" alt="">
                </div>
                <h3>Home Based<br>Learning</h3>
            </div>
            <div class="learning-col" (click)="changeLearningType('CommunityBL')"
                [ngClass]="{'active':activeLearningType=='CommunityBL'}">
                <div class="icon-col">
                    <img src="../../../assets/images/dashboard-icons/community-icon.png" alt="">
                </div>
                <h3>Community Based<br>Learning</h3>
            </div>
            <div class="learning-col" (click)="changeLearningType('SocietyBL')"
                [ngClass]="{'active':activeLearningType=='SocietyBL'}">
                <div class="icon-col">
                    <img src="../../../assets/images/dashboard-icons/society-icon.png" alt="">
                </div>
                <h3>Society Based<br>Learning</h3>
            </div>
        </div>
        <div class="assingment-contentOuter">
            <div *ngIf="activeLearningType == 'SchoolBL'">
                <div class="content-heading">
                    <img src="../../../assets/images/dashboard-icons/school-icon.png" alt="">
                    <h3>School Based Learning</h3>
                    <div class="pending-assignment">
                        <span>Pending Assignments (0)</span>
                    </div>
                </div>
                <div class="details-content">
                    <p>Formal/School learning environment: Improving what we measure.</p>
                    <ul>
                        <li>1. Build Relationships and Provide support</li>
                        <li>2. Incorporate technology thoughtfully</li>
                        <li>3. Support additional Home Learning</li>
                        <li>4. Continue to engage and momentum</li>
                    </ul>

                    <p>A positive school climate is essential to supporting young people's academic success. Students'
                        motivation, behavior, and learning can be impacted by trusting relationships between teachers
                        and students.</p>
                    <p>The research has shown that a positive school climate is characterized by high-quality
                        interpersonal relationships among students, teachers, and peers.</p>
                </div>
            </div>

            <div *ngIf="activeLearningType == 'HomeBL'">
                <div class="content-heading">
                    <img src="../../../assets/images/dashboard-icons/home-icon.png" alt="">
                    <h3>Home Based Learning</h3>
                    <div class="pending-assignment">
                        <span>Pending Assignments (0)</span>
                    </div>
                </div>
                <div class="details-content">
                    <p>Home learning environment: There’s no place like home.</p>
                    <ul>
                        <li>1. Social Emotional Learning</li>
                        <li>2. Outside the Box Learning</li>
                        <li>3. Personalized Learning</li>
                        <li>4. Life Skills</li>
                    </ul>

                    <p>The home is a child’s first school. The character and features of the home
                        environment—including the quality and security of relationships and the availability of
                        various resources—create the foundations of healthy development,
                        learning, and ongoing levels of wellbeing and happiness. “Skills” are centrally important
                        for human capital development and workforce success. Key “SKILLS” that foster youth
                        workforce success: Toward a consensus across fields.</p>

                </div>
            </div>

            <div *ngIf="activeLearningType == 'CommunityBL'">
                <div class="content-heading">
                    <img src="../../../assets/images/dashboard-icons/community-icon.png" alt="">
                    <h3>Community Based Learning</h3>
                    <div class="pending-assignment">
                        <span>Pending Assignments (0)</span>
                    </div>
                </div>

                <div class="details-content">
                    <p>Community learning environment: More traditional learning environments</p>
                    <ul>
                        <li>1. Project Based Learning</li>
                        <li>2. Experiential Learning</li>
                        <li>3. Collaborative Learning</li>
                        <li>4. Cooperative Learning</li>
                    </ul>

                    <p>Community-based learning refers to a wide variety of instructional methods, programs that
                        educators use to connect what is being taught in schools to their surrounding.
                        In today’s fast-moving knowledge economy and automated labor market, students
                        experience uncertainty in planning their future. They must learn more about
                        themselves and the world of work and align their life and career decision with
                        their age, developmental stage, and life goals.</p>
                </div>
            </div>

            <div *ngIf="activeLearningType == 'SocietyBL'">
                <div class="content-heading">
                    <img src="../../../assets/images/dashboard-icons/community-icon.png" alt="">
                    <h3>Society Based Learning</h3>
                    <div class="pending-assignment">
                        <span>Pending Assignments (0)</span>
                    </div>
                </div>

                <div class="details-content">
                    <p>Society learning environment: More cultural learning environments</p>
                    <ul>
                        <li>1. Spiritual Wellness.</li>
                        <li>2. Observational Learning.</li>
                        <li>3. Reinforced Learning.</li>
                        <li>4. Behavioral Social Skills.</li>
                    </ul>

                    <p>Society plays a significant role in education. The values, morals, and principles of a
                        society will create an education system that upholds the same values, morals, and
                        principles./p>
                    <p>Education is a social concern. It is a social process. Its objective is to develop and
                        awaken in the child those physical,
                        intellectual and moral states which are acquired of the individual by his society as a whole
                        and the milieu for which he is specially destined.
                </div>
            </div>
        </div>
        <div class="assignment-content">


            <mat-tab-group animationDuration="1000ms" (selectedTabChange)="activeTabChange($event)">
                <mat-tab label="WatchAssingment">
                    <ng-template mat-tab-label>
                        <label>Watch Assignments</label>
                    </ng-template>

                    <div class="assingment-box-outer" *ngIf="watchList.length>0">

                        <div class="assingment-heading">
                            <h3><span>{{watchList.length}}</span> Watch Assignments</h3>
                        </div>
                        <!-- <div class="assingment-row">
                            <div class="assingment-col" *ngFor="let watchListData of watchList">
                                <div class="assingment-detail">
                                    <h4>{{watchListData.Name}}</h4>
                                    <p>{{watchListData.Description}}</p>
                                </div>

                                <div class="action-btn">
                                    <button [disabled]="watchListData.Status === 'Completed'"
                                        (click)="openModel(watchListData)"><i class="bi bi-camera-reels"></i>
                                        Watch</button>
                                        
                                </div>

                            </div>
                        </div> -->
                        <div class="col-3 px-2" *ngFor="let watchListData of watchList">
                            <div class="card">
                                <div class="assignments-video position-relative">
                                    <img src="../../assets/images/background/bg-home.jpg" class="card-img-top">
                                    <div class="video-button-group1 d-flex justify-content-between flex-wrap">
                                        <div class="d-flex flex-wrap">
                                            <div class="p-0 btn-group">
                                                <button class="btn btn-primary"
                                                    [disabled]="watchListData.Status === 'Completed'"
                                                    (click)="openModel(watchListData)">Watch</button>
                                            </div>
                                            <div class="p-0 btn-group" *ngIf="watchListData.QuestionMasterId >0">
                                                <button class="btn btn-success"
                                                    [disabled]="watchListData.Status === 'Completed' || !watchListData.IsAllowedToThink"
                                                    (click)="openThinkModel(watchListData)">Think</button>
                                            </div>
                                            <div class="p-0 btn-group" *ngIf="watchListData.QuestionMasterId >0">
                                                <button class="btn btn-primary"
                                                    [disabled]="watchListData.Status === 'Completed' || !watchListData.IsAllowedToExplore"
                                                    (click)="updateIsExplore()">Explore
                                                </button>
                                            </div>
                                            <div class="p-0 btn-group" *ngIf="watchListData.QuestionMasterId >0">
                                                <button class="btn btn-success"
                                                    [disabled]="watchListData.Status === 'Completed' || !watchListData.IsAllowedToSubmitProject">Present
                                                </button>
                                            </div>
                                            <div class="p-0 btn-group" *ngIf="watchListData.Status === 'Completed'">
                                                <button
                                                    class="btn btn-success">{{watchListData.Result==""?"Pending":watchListData.Result
                                                    }}</button>
                                            </div>
                                            <div class="p-0 btn-group" *ngIf="watchListData.Status === 'Completed'">
                                                <button class="btn btn-primary">Point:
                                                    {{watchListData.DiscussionPoints
                                                    <10?'0'+watchListData.DiscussionPoints:watchListData.DiscussionPoints}}
                                                        </button>
                                            </div>
                                            <div class="p-0 btn-group" *ngIf="watchListData.Status === 'Completed'">
                                                <!-- <button class="btn btn-success" [title]="watchListData.Comments">Feedback </button> -->
                                                <button class="btn btn-success btn-mat" mat-flat-button color="accent"
                                                    matTooltip="{{watchListData.Comments?watchListData.Comments:'No Feedback Available'}}"
                                                    [matTooltipPosition]="position">
                                                    Feedback
                                                </button>
                                            </div>
                                            <div class="p-0 btn-group" *ngIf="watchListData.Status === 'Completed'">
                                                <button class="btn btn-primary">SelfAssees</button>
                                            </div>
                                        </div>




                                    </div>
                                </div>
                                <div class="card-body p-0">
                                    <div class="assignments-heading">
                                        <p class="card-text">{{watchListData.Name}}</p>
                                    </div>
                                    <div class="assignments-date-time d-flex justify-content-between">
                                        <p class="card-text"><small class="text-muted">{{watchListData.StartDate
                                                | date:'MMMM d, y'}}</small></p>
                                        <p class="card-text"><small class="text-muted">{{watchListData.StartTime
                                                | date:'h:mm a'}}</small></p>
                                        <p class="card-text"><small class="text-muted">{{watchListData.EndTime
                                                | date:'h:mm a'}}</small></p>
                                    </div>
                                    <div class="assignments-disc">
                                        <div [innerHtml]="watchListData.Description">
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>



                    </div>
                    <div class="empty-Data" *ngIf="watchList.length == 0">
                        <div class="d-flex justify-content-center h-100 align-items-center">
                            <p>Data not found!</p>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="ListenAssingments">
                    <ng-template mat-tab-label>
                        <label>Listen Assingments</label>
                    </ng-template>

                    <div class="assingment-box-outer" *ngIf="listenList.length>0">
                        <div class="assingment-heading">
                            <h3><span>{{listenList.length}}</span> Listen Assingments</h3>
                        </div>

                        <div class="col-3 px-2" *ngFor="let listenListData of listenList">
                            <div class="card">
                                <div class="assignments-video">
                                    <img src="../../assets/images/background/bg-home.jpg" class="card-img-top">
                                </div>
                                <div class="card-body p-0">
                                    <div class="assignments-heading">
                                        <p class="card-text">{{listenListData.Name}}</p>
                                    </div>
                                    <div class="assignments-date-time d-flex justify-content-between">
                                        <p class="card-text"><small class="text-muted">August 5
                                                2023</small>
                                        </p>
                                        <p class="card-text"><small class="text-muted">9:40:Am</small></p>
                                        <p class="card-text"><small class="text-muted">1hr:30min</small></p>
                                    </div>
                                    <div class="assignments-disc">
                                        <div [innerHtml]="listenListData.Description">
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="empty-Data" *ngIf="listenList.length == 0">
                        <div class="d-flex justify-content-center h-100 align-items-center">
                            <p>Listen Assignment not found!</p>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="ReadAssingments">
                    <ng-template mat-tab-label>
                        <label>Read Assingments</label>
                    </ng-template>
                    <div class="assingment-box-outer" *ngIf="readList.length>0">

                        <div class="assingment-heading">
                            <h3><span>{{readList.length}}</span> Read Assingments</h3>
                        </div>
                        <div class="col-3 px-2" *ngFor="let readListData of readList">
                            <div class="card">
                                <div class="assignments-video">
                                    <img src="../../assets/images/background/bg-home.jpg" class="card-img-top">
                                </div>
                                <div class="card-body p-0">
                                    <div class="assignments-heading">
                                        <p class="card-text">{{readListData.Name}}</p>
                                    </div>
                                    <div class="assignments-date-time d-flex justify-content-between">
                                        <p class="card-text"><small class="text-muted">August 5
                                                2023</small>
                                        </p>
                                        <p class="card-text"><small class="text-muted">9:40:Am</small></p>
                                        <p class="card-text"><small class="text-muted">1hr:30min</small></p>
                                    </div>
                                    <div class="assignments-disc">
                                        <div [innerHtml]="readListData.Description">
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="empty-Data" *ngIf="readList.length == 0">
                        <div class="d-flex justify-content-center h-100 align-items-center">
                            <p>Read Assignment not found!</p>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="WriteAssingments">
                    <ng-template mat-tab-label>

                        <label>Write Assingments</label>


                    </ng-template>
                    <div class="assingment-box-outer" *ngIf="writeList.length>0">

                        <div class="assingment-heading">
                            <h3><span>{{writeList.length}}</span> Write Assingments</h3>
                        </div>
                        <div class="col-3 px-2" *ngFor="let writeListData of writeList">
                            <div class="card">
                                <div class="assignments-video">
                                    <img src="../../assets/images/background/bg-home.jpg" class="card-img-top">
                                </div>
                                <div class="card-body p-0">
                                    <div class="assignments-heading">
                                        <p class="card-text">{{writeListData.Name}}</p>
                                    </div>
                                    <div class="assignments-date-time d-flex justify-content-between">
                                        <p class="card-text"><small class="text-muted">August 5
                                                2023</small>
                                        </p>
                                        <p class="card-text"><small class="text-muted">9:40:Am</small></p>
                                        <p class="card-text"><small class="text-muted">1hr:30min</small></p>
                                    </div>
                                    <div class="assignments-disc">
                                        <div [innerHtml]="writeListData.Description">
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="empty-Data" *ngIf="writeList.length == 0">
                        <div class="d-flex justify-content-center h-100 align-items-center">
                            <p>Write Assignment not found!</p>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="DoAssingments">
                    <ng-template mat-tab-label>

                        <label>Do Assingments</label>


                    </ng-template>
                    <div class="assingment-box-outer" *ngIf="doList.length>0">

                        <div class="assingment-heading">
                            <h3><span>{{doList.length}}</span> Do Assingments</h3>
                        </div>
                        <div class="col-3 px-2" *ngFor="let doListData of doList">
                            <div class="card">
                                <div class="assignments-video">
                                    <img src="../../assets/images/background/bg-home.jpg" class="card-img-top">
                                </div>
                                <div class="card-body p-0">
                                    <div class="assignments-heading">
                                        <p class="card-text">{{doListData.Name}}</p>
                                    </div>
                                    <div class="assignments-date-time d-flex justify-content-between">
                                        <p class="card-text"><small class="text-muted">August 5
                                                2023</small>
                                        </p>
                                        <p class="card-text"><small class="text-muted">9:40:Am</small></p>
                                        <p class="card-text"><small class="text-muted">1hr:30min</small></p>
                                    </div>
                                    <div class="assignments-disc">
                                        <div [innerHtml]="doListData.Description"></div>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="empty-Data" *ngIf="doList.length == 0">
                        <div class="d-flex justify-content-center h-100 align-items-center">
                            <p>Do Assignment not found!</p>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Homework">
                    <ng-template mat-tab-label>

                        <label>Homework</label>



                    </ng-template>
                    <div class="assingment-box-outer" *ngIf="homeWorkList.length>0">

                        <div class="assingment-heading">
                            <h3><span>{{homeWorkList.length}}</span> Homework</h3>
                        </div>
                        <div class="col-3 px-2" *ngFor="let homeWorkListData of homeWorkList">
                            <div class="card">
                                <div class="assignments-video">
                                    <img src="../../assets/images/background/bg-home.jpg" class="card-img-top">
                                </div>
                                <div class="card-body p-0">
                                    <div class="assignments-heading">
                                        <p class="card-text">{{homeWorkListData.Name}}
                                        </p>
                                    </div>
                                    <div class="assignments-date-time d-flex justify-content-between">
                                        <p class="card-text"><small class="text-muted">August 5
                                                2023</small>
                                        </p>
                                        <p class="card-text"><small class="text-muted">9:40:Am</small></p>
                                        <p class="card-text"><small class="text-muted">1hr:30min</small></p>
                                    </div>
                                    <div class="assignments-disc">
                                        <div [innerHtml]="homeWorkListData.Description">
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="empty-Data" *ngIf="homeWorkList.length == 0">
                        <div class="d-flex justify-content-center h-100 align-items-center">
                            <p>Homework not found!</p>
                        </div>
                    </div>
                </mat-tab>

            </mat-tab-group>


        </div>



    </div>

</div>