<div class="profile-group">
    <img class="shadow" [src]="userData.UserInfo.CroppedFilepath?urlSafe:'../../../assets/images/user/default.png'">

    <p class="shadow">{{userData.UserInfo.FullName?userData.UserInfo.FullName:''}}</p>
</div>

<button mat-button [matMenuTriggerFor]="beforeMenu"><i class="fa fa-users" aria-hidden="true"></i>&nbsp; <i
        class="fa fa-caret-down"></i></button>
<mat-menu #beforeMenu="matMenu" xPosition="before">
    <button mat-menu-item *ngFor="let child of childrenList" (click)="selectChild(child.Id)">{{child.Name}}</button>
    <button mat-menu-item (click)="selectChild(-1)">Link a child to my account</button>
    <!-- <button mat-menu-item routerLink="/parent-setting/">Parent Setting</button> -->
</mat-menu>
<div class="user-form d-flex align-items-center">
    <div class="container-fluid">
        <div class="row justify-content-c m-0">
            <div class="col-md-12 p-0">
                <div style="width: calc(100%);" class="bg-white shadow p-0 mb-0 mt-3 mb-3">



                    <div class="modal-content">
                        <div class="modal-header bg-theam p-1">
                            <h3 class="modal-title h3 m-1">Result</h3>
                        </div>
                        <div class="breackout_body p-2">
                            <div class="row form-row m-0">
                                <!-- <div class="row form-row m-0 p-0">

                        <div class="form-group col-4 mb-0 pl-0">
                            <label>Selected Child</label>
                            <select class="form-control form-control-sm appearance-auto" name="selectedChild" [(ngModel)]="selectedChileId" (change)="changeChild()">
                              <option [value]="0">Select Child</option>
                              <option *ngFor="let child of childrenList" [value]="child.Id">{{child.Name}}</option>
                            </select>
                          </div>

                      </div> -->
                                <div class="form-group col-12 p-0 pr-1 mb-0 border border-secondary ">

                                    <section class="mt-0 p-0 ">
                                        <div class="row m-0 pt-0 row m-0 pt-0 justify-content-end">
                                            <div class="col-3 pt-2">
                                                <div class="form-group mb-0">
                                                    <select class="form-control form-control-sm appearance-auto"
                                                        name="selectedChileId" [(ngModel)]="viewName"
                                                        (change)="changeView()">
                                                        <option value="Day">Day</option>
                                                        <option value="Month">Month</option>
                                                        <option value="Year">Year</option>
                                                    </select>
                                                </div>

                                                <!-- <div class="row m-0 p-1 pt-0 bg-white">
                                    <div class="col-4 p-1">
                                        <div class="d-grid gap-2">
                                            <button type="button" class="btn btn-primary btn-sm rounded-0" [ngClass]="{'active': viewName=='Day'}" (click)="changeView('Day')"  type="button" >Day</button>
                                        </div>
                                    </div>
                                    <div class="col-4 p-1">
                                        <div class="d-grid gap-2">
                                            <button type="button" class="btn btn-primary btn-sm rounded-0" [ngClass]="{'active': viewName=='Month'}"  (click)="changeView('Month')" type="button" >Month</button>
                                        </div>
                                    </div>
                                    <div class="col-4 p-1">
                                        <div class="d-grid gap-2">
                                            <button type="button" class="btn btn-primary btn-sm rounded-0" [ngClass]="{'active': viewName=='Year'}"  (click)="changeView('Year')" type="button" >Year</button>
                                        </div>
                                    </div>
                                </div> -->
                                            </div>
                                        </div>
                                        <div class="row m-0 pt-3">
                                            <!-- <div class="col-4 pb-3" *ngIf="ILClassGraph.length > 0">
                                <div class="border">
                                    <div class="google-chart-group">
                                        <google-chart [title]="ILClassGraphTitle" [type]="ILClassGraphType" [data]="ILClassGraph"
                                            [columns]="ILClassGraphCol" [options]="ILClassGraphOption" [width]="graphWidth"
                                            [height]="graphHeight">
                                        </google-chart>
                                    </div>

                                </div>
                            </div>
                            <div class="col-4 pb-3" *ngIf="assessmentsGraph.length > 0">
                                <div class="border">
                                    <div class="google-chart-group">
                                        <div class="google-chart-group">
                                            <google-chart [title]="assessmentsGraphTitle" [type]="assessmentsGraphType"
                                                [data]="assessmentsGraph" [columns]="assessmentsGraphCol"
                                                [options]="assessmentsGraphOption" [width]="graphWidth" [height]="graphHeight">
                                            </google-chart>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4 pb-3" *ngIf="assignmentsGraph.length > 0">
                                <div class="border">
                                    <google-chart [title]="assignmentsGraphTitle" [type]="assignmentsGraphType"
                                        [data]="assignmentsGraph" [columns]="assignmentsGraphCol" [options]="assignmentsGraphOption"
                                        [width]="graphWidth" [height]="graphHeight">
                                    </google-chart>
                                </div>
                            </div>
                            <div class="col-4 pb-3" *ngFor="let stuanalysisGraph of stuanalysisgraph; let i=index">
                                <div class="border">
                                    <google-chart [title]="stuanalysisGraph.Key" [type]="stuAnalysisGraphType"
                                        [data]="graphdata[i]" [columns]="stuAnalysisGraphCol" [options]="stuAnalysisGraphOption"
                                        [width]="graphWidth" [height]="graphHeight">
                                    </google-chart>
                                </div>

                            </div> -->

                                            <div class="col-3 pb-3">
                                                <div class="border text-center">
                                                    <div class="chart">
                                                        <apx-chart [series]="chartOptionsHumanityLife.series"
                                                            [chart]="chartOptionsHumanityLife.chart"
                                                            [plotOptions]="chartOptionsHumanityLife.plotOptions"
                                                            [labels]="chartOptionsHumanityLife.labels"
                                                            [fill]="chartOptionsHumanityLife.fill"
                                                            [title]="chartOptionsHumanityLife.title"
                                                            [subtitle]="chartOptionsHumanityLife.subtitle"></apx-chart>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-3 pb-3">
                                                <div class="border text-center">
                                                    <div class="chart">
                                                        <apx-chart [series]="chartOptionsLifeSkills.series"
                                                            [chart]="chartOptionsLifeSkills.chart"
                                                            [plotOptions]="chartOptionsLifeSkills.plotOptions"
                                                            [labels]="chartOptionsLifeSkills.labels"
                                                            [fill]="chartOptionsLifeSkills.fill"
                                                            [title]="chartOptionsLifeSkills.title"
                                                            [subtitle]="chartOptionsLifeSkills.subtitle"></apx-chart>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-3 pb-3">
                                                <div class="border text-center">
                                                    <div class="chart">
                                                        <apx-chart [series]="chartOptionsAcademics.series"
                                                            [chart]="chartOptionsAcademics.chart"
                                                            [plotOptions]="chartOptionsAcademics.plotOptions"
                                                            [labels]="chartOptionsAcademics.labels"
                                                            [fill]="chartOptionsAcademics.fill"
                                                            [title]="chartOptionsAcademics.title"
                                                            [subtitle]="chartOptionsAcademics.subtitle"></apx-chart>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-3 pb-3">
                                                <div class="border text-center">

                                                    <div class="chart">
                                                        <apx-chart [series]="chartOptionsLearnOutOfBox.series"
                                                            [chart]="chartOptionsLearnOutOfBox.chart"
                                                            [plotOptions]="chartOptionsLearnOutOfBox.plotOptions"
                                                            [labels]="chartOptionsLearnOutOfBox.labels"
                                                            [fill]="chartOptionsLearnOutOfBox.fill"
                                                            [title]="chartOptionsLearnOutOfBox.title"
                                                            [subtitle]="chartOptionsLearnOutOfBox.subtitle"></apx-chart>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </section>
                                    <section class="pt-0">
                                        <div class="row m-0  bg-white">
                                            <div class="col-md-4">
                                                <div class="border">
                                                    <form novalidate #form="ngForm" (submit)="onSearch(form)"
                                                        method="POST">
                                                        <div class="row m-0 ">
                                                            <div
                                                                class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                                                                <div class="form-group mb-0">
                                                                    <label>Start Date</label>
                                                                    <input type="text"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="Start Date" name="StartDate"
                                                                        #StartDate="ngModel" [(ngModel)]="startDate"
                                                                        [bsConfig]="datepickerConfig" bsDatepicker
                                                                        [bsValue]="bsValue" required
                                                                        class.invalid="StartDate.invalid && StartDate.touched"
                                                                        autocomplete="off">
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                                                                <div class="form-group mb-0">
                                                                    <label>End Date</label>
                                                                    <input type="text"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="End Date" name="EndDate"
                                                                        #EndDate="ngModel" [(ngModel)]="endDate"
                                                                        [bsConfig]="datepickerConfig" bsDatepicker
                                                                        [bsValue]="bsValue" required
                                                                        class.invalid="EndDate.invalid && EndDate.touched"
                                                                        autocomplete="off">
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                                                                <div class="form-group mb-0">
                                                                    <label>&nbsp;</label>
                                                                    <div
                                                                        class="form-control form-control-sm p-0 border-0">
                                                                        <button
                                                                            class="btn btn-warning btn-sm float-right"
                                                                            type="submit"
                                                                            [disabled]="form.invalid">Search</button>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <ag-grid-angular #agGrid
                                                        style="width: 100%; height:calc(100vh - 263px);min-height: 200px;"
                                                        class="ag-theme-alpine px-2 pb-2" id="fileList"
                                                        [ensureDomOrder]="true" [enableCellTextSelection]="true"
                                                        [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
                                                        [rowSelection]="rowSelection" [rowData]="logListData"
                                                        (gridReady)="onGridReady($event)">
                                                    </ag-grid-angular>
                                                </div>
                                            </div>

                                            <div class="col-4" *ngIf="studentsByGradeCharGraph.length > 0">
                                                <div class="border">
                                                    <div class="google-chart-group">
                                                        <google-chart [title]="studentsByGradeCharGraphTitle"
                                                            [type]="studentsByGradeCharGraphType"
                                                            [data]="studentsByGradeCharGraph"
                                                            [columns]="studentsByGradeCharGraphCol"
                                                            [options]="studentsByGradeCharGraphOption"
                                                            [width]="graphWidth" [height]="graphHeight">
                                                        </google-chart>
                                                        <!-- <google-chart [title]="studentsByGradeCharGraphTitle" [type]="studentsByGradeCharGraphType" [data]="studentsByGradeCharGraph"
                                            [columns]="studentsByGradeCharGraphCol" [options]="studentsByGradeCharGraphOption" [width]="graphWidth"
                                            [height]="graphHeight">
                                        </google-chart> -->
                                                    </div>
                                                </div>
                                                <div class="border text-center mt-3">
                                                    <div class="chart">
                                                        <apx-chart [series]="academicAsignmentChartOptions.series1"
                                                            [chart]="academicAsignmentChartOptions.chart"
                                                            [xaxis]="academicAsignmentChartOptions.xaxis"
                                                            [stroke]="academicAsignmentChartOptions.stroke"
                                                            [dataLabels]="academicAsignmentChartOptions.dataLabels"
                                                            [yaxis]="academicAsignmentChartOptions.yaxis"
                                                            [labels]="academicAsignmentChartOptions.labels"
                                                            [legend]="academicAsignmentChartOptions.legend"
                                                            [title]="academicAsignmentChartOptions.title"
                                                            [subtitle]="academicAsignmentChartOptions.subtitle"></apx-chart>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-4">
                                                <div class="border">
                                                    <!-- <app-attendance-calendar></app-attendance-calendar> -->
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section>
                                        <div class="container-fluid bg-theam-body pb-5">
                                            <div class="row justify-content-center">
                                                <div class="col-10">
                                                    <div class="row mt-5 align-items-center">
                                                        <div class="col-12 mb-3">
                                                            <div class="section-header">
                                                                <h1
                                                                    class="d-block title pt-1 pb-1 text-center theam-color-8">
                                                                    Feedback <a (click)="openHelpTooltip('HomeWork')"
                                                                        class="position-relative"><mat-icon>help_outline</mat-icon></a>
                                                                </h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-center">
                                                        <div class="col-12 h-100">
                                                            <owl-carousel-o class="multy-item-carousel feedbackOptions "
                                                                [options]="mobileAppOptions">
                                                                <ng-container
                                                                    *ngFor="let feedbacks of feedbackList; let i=index">
                                                                    <ng-template carouselSlide>
                                                                        <div
                                                                            class="row justify-content-center pb-3 pt-3">
                                                                            <div class="col-5 mb-0">
                                                                                <div class="row">
                                                                                    <div class="col-3 pr-0">
                                                                                        <img [src]="feedbacks.CreatedByImageUrl?feedbacks.CreatedByImageUrl:'../../../assets/images/user/default.png'"
                                                                                            alt=""
                                                                                            class="rounded-circle border border-w-5 border-light">
                                                                                    </div>
                                                                                    <div class="col-9 pl-0 mt-2">
                                                                                        <span
                                                                                            class="d-block h6 font-weight-500 theam-color-333">{{feedbacks.CreatedByName}}</span>
                                                                                        <p class="theam-color-333"><span
                                                                                                class="mr-1">
                                                                                                <mat-icon>calendar_today</mat-icon>
                                                                                            </span>{{feedbacks.CreatedOn}}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <p class="theam-color-333">
                                                                                    {{feedbacks.Feedback}}
                                                                                </p>

                                                                            </div>
                                                                        </div>
                                                                    </ng-template>
                                                                </ng-container>
                                                            </owl-carousel-o>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section>
                                        <div class="container-fluid bg-light">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="mt-3 mb-3">
                                                        <div class="modal-header p-1 bg-theam">
                                                            <h3 class="modal-title h3 m-1">Parent Shared Documents</h3>
                                                        </div>

                                                        <div style="width: calc(100%);"
                                                            class="bg-white shadow p-2 mb-0">
                                                            <ag-grid-angular #agGrid
                                                                style="width: 100%; height:calc(100vh - 232px);min-height: 200px;"
                                                                class="ag-theme-alpine" id="PSDList"
                                                                [ensureDomOrder]="true" [enableCellTextSelection]="true"
                                                                [columnDefs]="columnDefsPSD"
                                                                [defaultColDef]="defaultColDef"
                                                                [rowSelection]="rowSelection" [rowData]="PSDListData"
                                                                (gridReady)="onGridReady($event)"
                                                                [frameworkComponents]="frameworkComponents">
                                                            </ag-grid-angular>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="container-fluid bg-theam-body pb-5">
                                            <div class="row justify-content-center">
                                                <div class="col-10">
                                                    <div class="row mt-5 align-items-center">
                                                        <div class="col-12 mb-3">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>

                            </div>
                        </div>
                        <!-- <div class="modal-footer p-1">
                <button type="button" class="btn btn-success" (click)="onSave(form)">Save</button>

              </div> -->
                    </div>

                </div>

            </div>
        </div>
    </div>

</div>