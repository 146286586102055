import { Component, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { SchooleditComponent } from "./schooledit.component";
import { BranchComponent } from "../branch/branch.component";
import { NgIf } from "@angular/common";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
@Component({
  selector: "app-schoolmenu",
  templateUrl: "./schoolmenu.component.html",
  styleUrls: ["./schoolmenu.component.scss"],
  standalone: true,
  imports: [MatIconModule, MatMenuModule, NgIf],
})
export class SchoolmenuComponent implements OnInit {
  IsBranch: boolean = false;
  params: any;
  bsModalRef: BsModalRef;
  modalRef: BsModalRef;
  constructor(private modalService: BsModalService) {}

  ngOnInit(): void {
    this.setBranchMenu();
  }
  agInit(params: any) {
    this.params = params;
    this.setBranchMenu();
  }
  setBranchMenu() {
    if (this.params.data.Type == "Branch") {
      this.IsBranch = true;
    } else {
      this.IsBranch = false;
    }
  }
  refresh(params: any) {
    this.setBranchMenu();
    return true;
  }
  openschoolmodal(schoolid) {
    const initialState = {
      list: [{ id: schoolid }],
    };
    this.bsModalRef = this.modalService.show(SchooleditComponent, {
      initialState,
      class: "modal-dialog modal-dialog-centered",
      ignoreBackdropClick: true,
    });
  }
  openbranchmodal(branchId, schoolId) {
    const initialState = {
      list: [{ id: branchId }, { schoolId: schoolId }],
    };
    this.bsModalRef = this.modalService.show(BranchComponent, {
      initialState,
      class: "modal-xl",
      ignoreBackdropClick: true,
    });
  }
  edit() {
    if (this.params.data.Type == "Branch") {
      this.openbranchmodal(this.params.data.Id, this.params.data.SchoolId);
    } else {
      this.openschoolmodal(this.params.data.SchoolId);
    }
  }
  addBranch() {
    this.openbranchmodal(0, this.params.data.SchoolId);
  }
}
