<div class="modal-content">
    <form novalidate #form="ngForm" (submit)="resetPassword(form)" autocomplete="off">
        <div class="modal-header">
            <h3 class="modal-title h3 m-1">{{'UserEdit' | translate}}</h3>
            <span class="bi bi-x" (click)="modalService.hide(CUSTOMCONST.RESET_PWD_MODAL)" title="Close Module"
                data-bs-toggle="tooltip" aria-label="Close"></span>
        </div>
        <div class="breackout_body">
            <div class="field-row">
                <div class="select_field">
                    <label>{{'CurrentPassword'| translate}}</label>
                    <input class="field-control" type="password" placeholder="********" name="Password"
                        #Password="ngModel" [(ngModel)]="service.formData.ResetPasswordInfo.Password" required
                        minlength="5" class.invalid="Password.invalid && Password.touched">
                </div>
                <div class="select_field">
                    <label>{{'NewPassword'| translate}}</label>
                    <input class="field-control" type="password" placeholder="********" name="Password"
                        #Password="ngModel" [(ngModel)]="service.formData.ResetPasswordInfo.NewPassword" required
                        minlength="5" class.invalid="Password.invalid && Password.touched">
                </div>
                <div class="select_field">
                    <label>{{'ConfirmPassword'| translate}}</label>
                    <input class="field-control" type="password" placeholder="********" name="Password"
                        #Password="ngModel" [(ngModel)]="service.formData.ResetPasswordInfo.ConfirmPassword" required
                        minlength="5" class.invalid="Password.invalid && Password.touched">
                </div>
            </div>

            <div class="modal-footer">
                <button type="submit" class="custom-primary-btn">Save</button>
                <button type="button" class="btn btn-danger" (click)="modalService.hide(CUSTOMCONST.RESET_PWD_MODAL)"
                    aria-label="Close">Close</button>
            </div>
        </div>
    </form>
</div>