import { Component, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CustomHeader } from "../custommenu/custom-header.component";
import { CustommenuComponent } from "../custommenu/custommenu.component";
import { CommonService } from "../shared/common.service";
import { AgGridModule } from "ag-grid-angular";
import { NgFor,Location, NgIf } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { EditsubjectComponent } from "./editsubject/editsubject.component";
import { CUSTOMCONST } from "../shared/Const";
import { AddmlinfoComponent } from "../question/addmlinfo/addmlinfo.component";

@Component({
  selector: "app-subject-list",
  templateUrl: "./subject-list.component.html",
  styleUrls: ["./subject-list.component.scss"],
  standalone: true,
  imports: [FormsModule, NgFor, AgGridModule,  NgIf],
})
export class SubjectListComponent implements OnInit {
  gridApi;
  gridColumnApi;
  frameworkComponents;
  columnDefs;
  defaultColDef;
  rowSelection;
  rowData: any[] = [];
  filteredrowData: any[] = [];
  filterList : string[] = [];
  bsModalRef: BsModalRef;
  gridOptions: any;
  classId: number = 0;
  classList: any;
  constructor(
    public commonServ: CommonService,
    private modalService: BsModalService,
    private location: Location
  ) {
    this.columnDefs = [
      {
        headerName: "SubjectId",
        field: "SubjectId",
        filter: false,
        cellRendererFramework: CustommenuComponent,
        cellRendererParams: {
          MenuList: [
            { Name: "Edit", Click: this.MenuEditClick, that: this },
            { Name: "Delete", Click: this.deleteSubject, that: this },
          ],
        },
        headerComponent: "customHeader",
        maxWidth: 50,
        minWidth: 50,
        headerComponentParams: {
          button: "click",
          enableMenu: true,
          menuIcon: "fa-plus",
          enableSorting: false,
        },
      },
      {
        field: "Name",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
      },
      {
        field: "Description",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        minWidth: 120,
      },
    ];
    this.frameworkComponents = {
      customHeader: CustomHeader,
    };
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      floatingFilter: true,
    };
    this.rowSelection = "single";
    this.commonServ.RefreshGrid$.subscribe((item) => {
      this.getsubjectsbyclassid(this.classId);
    });
  }

  ngOnInit(): void {
    this.getClass();
  }
  goBack(): void {
    this.location.back(); // Navigates back to the previous route
  }

  getsubjectsbyclassid(classId: number) {
    this.commonServ.getsubjectsbyclassid(classId).subscribe((res: any) => {
      this.rowData = res;
      this.filteredrowData = res;
    });
  }
  onSelectionChanged($event) {
    var selectedRows = this.gridApi.getSelectedRows();
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    setTimeout(() => {
      this.getsubjectsbyclassid(this.classId);
    }, 300);
  }

  deleteSubject(SubjectId, that) {}
  MenuEditClick(SubjectId,SubjectName) {
    const initialState = {
      subject: {  
        Id:SubjectId,
        Text:"MLSubject",
        classId:this.classId,
        subjectId:SubjectId,
        Name:SubjectName,
      }
    };
    this.bsModalRef = this.modalService.show(EditsubjectComponent,{initialState, class: 'second col-12 modal-dialog-centered model-sm',ignoreBackdropClick: true});
  }
  getClass() {
    this.commonServ.getclasses().subscribe((res: any) => {
      this.classList = res;
    });
  }
  changeClass() {
    localStorage.setItem("classId",this.classId.toString())
  }
  serchSubject() {
    this.getsubjectsbyclassid(this.classId);
  }
  filter(e: Event) {
    const inputElement = e.target as HTMLInputElement;
    const key = inputElement.name; // Get the name of the input field
    const text = inputElement.value; // Get the value of the input field
    this.filterList[key]=text
    var rowData=this.rowData 
    Object.keys(this.filterList).forEach(element => {
      rowData=rowData.filter((o) =>
        o[element].toLowerCase().includes(this.filterList[element].toLowerCase())
      );
    });
    
    this.filteredrowData = rowData;
  }
}
