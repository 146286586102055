<div class="modal-dialog modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header p-1 bg-theam">
      <h3 class="modal-title h3 m-1 w-100">{{fileType}}<span class="float-right"
          *ngIf="startAnswer && !ispreviewAnswer">Total Questions: {{noofQuestions}}</span></h3>
    </div>
    <div class="breackout_body model-min-window-h">
      <div *ngIf="(!startAnswer && ispreviewAnswer) || currentQuestionNo<=0">
        <div class="row" *ngIf="fileType == 'Watch'">
          <div class="col-12 min-height-150 m-auto" *ngIf="urlType != 'Url'">
            <div layout="column" flex>

              <video layout-fill width='100%' controls controlsList="nodownload">
                <source [src]="urlSafe" type="video/webm" />
              </video>
            </div>
          </div>
          <div class="col-12 min-height-150 m-auto" *ngIf="urlType == 'Url'">
            <div layout="column" flex>
              <div [innerHtml]="enbedUrl"></div>
            </div>
          </div>

        </div>
        <div class="row" *ngIf="fileType == 'Listen'">
          <div class="col-12 min-height-150 m-auto">

            <div class="h-100">
              <img src="../../../assets/media/audios/audio_player1.png"
                onClick='document.getElementById("audio1").play(); return false;' class="" width="100%" />
              <audio controls class="w-100 h-100" height="200px" id="audio1" style="height: 200px !important;
                                position: absolute;
                                bottom: 0;
                                left: 50%;
                                transform: translateX(-50%);
                                width:calc(100% - 30px) !important">
                <source [src]="urlSafe" type="audio/mp3" />
              </audio>
            </div>
          </div>

        </div>
        <div class="row m-0" *ngIf="fileType == 'Read' ">
          <div class="col-12 min-height-150 p-0"
            *ngIf="(fileExtension != 'doc' || fileExtension != 'docx') && (url != '')">

            <iframe [src]="urlSafe" width="100%" style="height: 450px;" type='application/pdf'></iframe>

          </div>

          <div class="col-12 min-height-150 p-0 mt-3"
            *ngIf="(fileExtension === 'doc' || fileExtension === 'docx') && (url != '')">

            <ngx-doc-viewer [url]="docUrl" viewer="google" style="width:100%;height:50vh;"></ngx-doc-viewer>

          </div>

        </div>
        <div class="row m-0" *ngIf="fileType == 'Do'">
          <div class="col-12 min-height-150 p-0 border">
            <div [innerHTML]="description"></div>
          </div>

        </div>
        <div class="row m-0" *ngIf="fileType == 'Home work'">
          <div class="col-12 ">
            <div [innerHTML]="description"></div>
          </div>
          <div class="col-12 min-height-150 p-0 mt-3"
            *ngIf="(fileExtension != 'doc' || fileExtension != 'docx') && (url != '')">

            <iframe [src]="urlSafe" width="100%" style="height: 450px;" type='application/pdf'></iframe>
          </div>
          <div class="col-12 min-height-150 p-0 mt-3"
            *ngIf="(fileExtension === 'doc' || fileExtension === 'docx') && (url != '')">

            <ngx-doc-viewer [url]="docUrl" viewer="google" style="width:100%;height:50vh;"></ngx-doc-viewer>

          </div>

        </div>
        <div class="mt-3" *ngIf="stdAnswerMasterInfo.ResultId > 0">

          <div class="dropdown-divider"></div>
          <div class="row form-row m-0">
            <div class="col-12">
              <div class="form-group mb-3">
                <label>{{'Result' | translate}} </label>
                <div [innerHtml]="stdAnswerMasterInfo.Result"></div>
              </div>
            </div>
          </div>
          <div class="row form-row m-0">
            <div class="col-12">
              <div class="form-group mb-0">
                <label>{{'Comments' | translate}}</label>
                <div [innerHtml]="stdAnswerMasterInfo.Comments"></div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div *ngIf="ispreviewAnswer">
        <div *ngFor="let questionInfo of service.formData.QuestionInfo; let Aindex=index">
          <div *ngIf="questionInfo.QuestionType == 'Eassy'">
            <h3>
              <span>Q.{{Aindex+1}} </span>
              <div class="d-inline-block" [innerHTML]="questionInfo.Question"></div>
            </h3>
            <div class="form-group mb-0">
              <label class="text-primary font-weight-500">Ans.</label>
              <p [innerHtml]="questionInfo.QAnswerList[0].StrAnswer"></p>
            </div>
            <div class="col-12">
              <img [src]="questionInfo.QAnswerList[0].FilePath" alt="" class="img img-fluid max-width-100px">
            </div>
          </div>
          <div *ngIf="questionInfo.QuestionType == 'TF'">
            <h3>
              <span>Q.{{Aindex+1}} </span>
              <div class="d-inline-block" [innerHTML]="questionInfo.Question"></div>
            </h3>
            <section *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
              <div class="row form-row">
                <div class="col-12">
                  <div class="form-group mb-0">
                    <div class="appearance-auto mb-3">
                      <div class="custom-p-2"
                        [ngClass]="qAnswer.IsCorrect?'correct-answer':qAnswer.IsCorrect != qAnswer.IsAnswer?'wrong-answer':''">
                        <mat-radio-group aria-label="Select an option" class="" name="IsAnswer{{Aindex}}">
                          <mat-radio-button [checked]="qAnswer.IsAnswer" value="qAnswer.IsAnswer"
                            [disabled]="true">{{qAnswer.Answer}}<mat-icon *ngIf="qAnswer.IsAnswer">{{(qAnswer.IsAnswer
                              == qAnswer.IsCorrect)?'done':'close'}}</mat-icon></mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-50px">
                </div>
              </div>
            </section>

          </div>
          <div *ngIf="questionInfo.QuestionType == 'MC'">
            <h3>
              <span>Q.{{Aindex+1}} </span>
              <div class="d-inline-block" [innerHTML]="questionInfo.Question"></div>
            </h3>
            <section *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
              <div class="row form-row">
                <div class="col-6">
                  <div class="form-group mb-0">
                    <div class="appearance-auto mb-3">
                      <div class="custom-p-2"
                        [ngClass]="qAnswer.CorrectLabel != ''?'correct-answer':((qAnswer.StrAnswer != '') && qAnswer.CorrectLabel != qAnswer.StrAnswer)?'wrong-answer':''">
                        <mat-radio-group aria-label="Select an option" class="" name="StrAnswer">
                          <mat-radio-button [checked]="qAnswer.StrAnswer != '' " [value]="qAnswer.StrAnswer"
                            [disabled]="true">
                            <span *ngIf="questionInfo.OptionType == 'Text'">{{qAnswer.Answer}}</span>
                            <mat-icon *ngIf="(qAnswer.StrAnswer != '')">{{(qAnswer.CorrectLabel !=
                              '')?'done':'close'}}</mat-icon>
                            <span *ngIf="questionInfo.OptionType == 'Image'"><img [src]="qAnswer.FilePath" alt=""
                                class="img img-fluid max-width-50px ml-3"></span>
                          </mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </section>

          </div>
          <div *ngIf="questionInfo.QuestionType == 'MR'">
            <h3>
              <span>Q.{{Aindex+1}} </span>
              <div class="d-inline-block" [innerHTML]="questionInfo.Question"></div>
            </h3>
            <section *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
              <div class="row form-row">
                <div class="col-6">
                  <div class="form-group mb-0">
                    <div class="appearance-auto rounded-0 p-0 border-0">
                      <div class="custom-p-2 custom-mt-1"
                        [ngClass]="qAnswer.IsCorrect?'correct-answer':qAnswer.IsCorrect != qAnswer.IsAnswer?'wrong-answer':''">
                        <mat-checkbox class="" [checked]="qAnswer.IsAnswer" [value]="qAnswer.IsAnswer"
                          [disabled]="true">
                          <span *ngIf="questionInfo.OptionType == 'Text'">{{qAnswer.Answer}}</span>
                          <mat-icon *ngIf="qAnswer.IsAnswer">{{(qAnswer.IsAnswer ==
                            qAnswer.IsCorrect)?'done':'close'}}</mat-icon>
                          <span *ngIf="questionInfo.OptionType == 'Image'"><img [src]="qAnswer.FilePath" alt=""
                              class="img img-fluid max-width-50px ml-3"></span></mat-checkbox>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </section>

          </div>
          <div *ngIf="questionInfo.QuestionType == 'FIB'">
            <h3>
              <span>Q.{{Aindex+1}} </span>
              <div class="d-inline-block" [innerHTML]="questionInfo.Question"></div>
            </h3>
            <section *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
              <div class="row form-row">
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label><span class="text-primary font-weight-500">Student Ans.</span> {{qAnswer.StrAnswer}}</label>
                    <label><span class="text-success font-weight-500">Correct Ans.</span> {{qAnswer.Answer}}</label>
                  </div>
                </div>
                <div class="col-12">
                  <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-50px">
                </div>
              </div>

            </section>

          </div>
          <div *ngIf="questionInfo.QuestionType == 'MF'">
            <h3>
              <span>Q.{{Aindex+1}} </span>
              <div class="d-inline-block" [innerHTML]="questionInfo.Question"></div>
            </h3>

            <section>
              <div class="row form-row">
                <div class="col-12 col-md-6">
                  <div class="row m-0 mb-3" *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                    <div class="col-6">
                      <div class="form-group mb-0">
                        <div class="appearance-auto mb-3">
                          <div class="custom-p-2">
                            <p>{{qAnswer.GroupNo}}. {{qAnswer.Answer}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6" *ngIf="questionInfo.OptionType == 'Text'">
                      <div class="form-group mb-0">
                        <div class="appearance-auto mb-3">
                          <div class="custom-p-2">
                            <p>{{qAnswer.GroupNo1}}. {{qAnswer.Answer1}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6" *ngIf="questionInfo.OptionType == 'Image'">
                      <div class="form-group mb-0">
                        <div class="appearance-auto mb-3">
                          <div class="custom-p-2">
                            {{qAnswer.GroupNo1}}
                            <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-50px">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="row m-0 mb-3" *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                    <div class="col-12">
                      <div class="d-flex">
                        <div class="form-group mb-0 mr-1">
                          <div class="form-control form-control-sm appearance-auto rounded-0 p-0" style="height: 54px;">
                            <div
                              class="bg-gray text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                              <label class="text-white p-2">{{qAnswer.GroupNo}}</label>
                            </div>
                          </div>
                        </div>
                        <div class="form-group mb-0 mr-1"
                          *ngFor="let qAnswer1 of questionInfo.QAnswerList; let j=index">
                          <div class="form-control form-control-sm appearance-auto rounded-0 p-0" style="height: 54px;"
                            [ngClass]="qAnswer1.GroupNo1 == qAnswer.CorrectLabel?'correct-answer border-success':qAnswer1.GroupNo1 == qAnswer.StrAnswer?'wrong-answer border-danger':''">
                            <div class="bg-gray text-center custom-p-1"
                              [ngClass]="qAnswer1.GroupNo1 == qAnswer.CorrectLabel?'bg-success':qAnswer1.GroupNo1 == qAnswer.StrAnswer?'bg-danger':'bg-gray'">
                              <label class="text-white">{{qAnswer1.GroupNo1}}</label>
                            </div>
                            <div class="text-center custom-p-2 custom-mt-1">
                              <mat-radio-group aria-label="Select an option" class="mat-radio-label-content-false"
                                name="{{i}}">
                                <mat-radio-button value="{{qAnswer1.GroupNo1}}" [disabled]="true"
                                  [checked]="qAnswer1.GroupNo1 == qAnswer.StrAnswer"></mat-radio-button>
                              </mat-radio-group>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

          </div>
          <div *ngIf="questionInfo.QuestionType == 'MM'">
            <h3>
              <span>Q.{{Aindex+1}} </span>
              <div class="d-inline-block" [innerHTML]="questionInfo.Question"></div>
            </h3>
            <section *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
              <div class="row form-row">

                <div class="col-3">
                  <div class="form-group mb-0">
                    <div class="appearance-auto mb-3">
                      <div class="custom-p-2">
                        <p>{{qAnswer.GroupNo}}. {{qAnswer.Answer}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-3" *ngIf="questionInfo.OptionType == 'Text'">
                  <div class="form-group mb-0">
                    <div class="appearance-auto mb-3">
                      <div class="custom-p-2">
                        <p>{{qAnswer.GroupNo1}}. {{qAnswer.Answer}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-3" *ngIf="questionInfo.OptionType == 'Image'">
                  <div class="form-group mb-0">
                    <div class="appearance-auto mb-3">
                      <div class="custom-p-2">
                        {{qAnswer.GroupNo1}}. <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-50px">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="d-flex">
                    <div class="form-group mb-0 mr-1">
                      <div class="form-control form-control-sm appearance-auto rounded-0 p-0" style="height: 54px;">
                        <div class="bg-theam text-center custom-p-1 h-100 justify-content-c align-items-center d-flex">
                          <label class="text-white p-2">{{qAnswer.GroupNo}}</label>
                        </div>
                      </div>
                    </div>
                    <div class="form-group mb-0 mr-1" *ngFor="let qAnswer1 of questionInfo.QAnswerList; let j=index">
                      <div class="form-control form-control-sm appearance-auto rounded-0 p-0" style="height: 54px;">
                        <div class="bg-theam text-center custom-p-1">
                          <label class="text-white">{{qAnswer1.GroupNo1}}</label>
                        </div>
                        <div class="text-center custom-p-2 custom-mt-1">
                          <mat-radio-group aria-label="Select an option" class="mat-radio-label-content-false"
                            name="{{i}}" #IsCorrect="ngModel" [(ngModel)]="qAnswer.StrAnswer">
                            <mat-radio-button value="{{qAnswer1.GroupNo1}}"></mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>


          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer p-1">
      <button type="button" *ngIf="status == 'Completed'" class="btn btn-success" (click)="viewResult()">View
        Result</button>
      <button type="button" class="btn btn-danger" (click)="bsModalRef.hide()" aria-label="Close">Close</button>
    </div>
  </div>

</div>