<div class="dashboardInnerPage inner-page-outer flex-column">
    <div class="title w-100">
        <span (click)="goBack()" title="Back" data-bs-toggle="tooltip" data-bs-placement="bottom" class="bi bi-arrow-left"></span>
        <h3>Attendance Management</h3>
    </div>
    <mat-tab-group animationDuration="1000ms" (selectedTabChange)="activeTabChange($event)">
        <mat-tab label="Manual Attandance">
            <ng-template mat-tab-label>
                <label>Manual Attandance</label>
            </ng-template>

            <div class="field-row filter-row ">
                <div class="select_field">
                    <label>Date</label>
                    <input class="field-control" name="Date" #StartDate="ngModel" [(ngModel)]="selectedDate"
                        [bsConfig]="datepickerConfig" bsDatepicker timepicker (ngModelChange)="selectedDateChage()" />
                </div>
                <div class="select_field">
                    <label>Select Class</label>
                    <select class="field-control" name="ClassId" #ClassId="ngModel" [(ngModel)]="selectedClassId">
                        <option [value]="0">Select Class</option>
                        <option *ngFor="let class of classList" [value]="class.Id">
                            {{class.Name}}</option>
                    </select>
                </div>
                <div class="filter_option">
                    <span (click)="serchMAttendece()" class="filter_button"><i class="bi bi-search"></i> Search</span>
                    <span class="filter_button" data-bs-toggle="collapse" data-bs-target="#collapseExample"
                        aria-expanded="false" aria-controls="collapseExample"><i class="bi bi-filter"></i> Filter</span>

                </div>
            </div>
            <div class="field-row filter-form collapse" id="collapseExample">
                <div class="select_field">
                    <label>Created By</label>
                    <input type="text" class="field-control" name="CreatedBy" (keyup)="filter($event)" />
                </div>
                <div class="select_field">
                    <label>CreatedOn</label>
                    <input type="text" class="field-control" name="CreatedOn" (keyup)="filter($event)" />
                </div>
                <div class="select_field">
                    <label>Description</label>
                    <input type="text" class="field-control" name="Description" (keyup)="filter($event)" />
                </div>
                <div class="select_field">
                    <label>Text</label>
                    <input type="text" class="field-control" name="Text" (keyup)="filter($event)" />
                </div>
            </div>
            <div class="custom-table">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Created By</th>
                            <th>Created On</th>
                            <th>Description</th>
                            <th>Text</th>

                        </tr>
                    </thead>
                    <tbody *ngIf="filteredrowData.length === 0">
                        <tr>
                            <td colspan="4">No data available</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="filteredrowData.length > 0">
                        <tr *ngFor="let data of filteredrowData">
                            <td>{{data.CreatedBy}}</td>
                            <td>{{data.CreatedOn}}</td>
                            <td>{{data.Description}}</td>
                            <td>{{data.Text}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="mt-2">
                <div class="tab-content px-0">
                    <div class="row border m-0 py-2 mb-2 justify-content-between">
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 px-2">
                            <div class="form-group mb-0">
                                <label>Date</label>
                                <input type="text" class="form-control form-control-sm m-0" name="Date"
                                    #StartDate="ngModel" [(ngModel)]="selectedDate" [bsConfig]="datepickerConfig"
                                    bsDatepicker timepicker (ngModelChange)="selectedDateChage()">
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 px-2">
                            <div class="form-group mb-0">
                                <label>Select Class</label>
                                <select class="form-control form-control-sm appearance-auto m-0" name="ClassId"
                                    #ClassId="ngModel" [(ngModel)]="selectedClassId">
                                    <option [value]="0">Select Class</option>
                                    <option *ngFor="let class of classList" [value]="class.Id">
                                        {{class.Name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 px-2">
                            <div class="form-group mb-0 d-flex flex-wrap justify-content-end">
                                <label class="d-block w-100">&nbsp;</label>
                                <button type="button" class="btn btn-warning btn-sm float-right"
                                    (click)="serchMAttendece()">Search</button>
                            </div>
                        </div>
                    </div>
                    <ag-grid-angular #agGrid style="width: 100%; height:calc(100vh - 200px);min-height: 200px;"
                        class="ag-theme-alpine" id="MAttendeceGrid" [gridOptions]="MAttendeceGridOptions"
                        [columnDefs]="MAttendeceColumnDefs" [defaultColDef]="MAttendeceDefaultColDef"
                        [rowSelection]="MAttendeceRowSelection" [rowData]="MAttendeceRowData"
                        (gridReady)="onMAttendeceReady($event)" (gridReload)="onMAttendeceReload($event)">
                    </ag-grid-angular>
                </div>
            </div> -->
        </mat-tab>
        <mat-tab label="Auto Generated Attendance">
            <ng-template mat-tab-label>
                <label>Auto Generated Attendance</label>
            </ng-template>
            <div class="field-row filter-row ">
                <div class="select_field">
                    <label>Academic Year</label>
                    <select class="field-control" name="selectedYear" [(ngModel)]="selectedYear" (change)="changeYear()"
                        required>
                        <option *ngFor="let year of yearList" [value]="year.Code">
                            {{year.Name}}
                        </option>
                    </select>
                </div>
                <div class="filter_option">
                    <span (click)="serchAttendece()" class="filter_button"><i class="bi bi-search"></i> Search</span>
                    <span class="filter_button" data-bs-toggle="collapse" data-bs-target="#collapseExample01"
                        aria-expanded="false" aria-controls="collapseExample01"><i class="bi bi-filter"></i>
                        Filter</span>

                </div>
            </div>
            <div class="field-row filter-form collapse" id="collapseExample01">
                <div class="select_field">
                    <label>Class</label>
                    <input type="text" class="field-control" name="Key" (keyup)="filterattendece($event)" />
                </div>
                <div class="select_field">
                    <label>Name</label>
                    <input type="text" class="field-control" name="Value" (keyup)="filterattendece($event)" />
                </div>
                <div class="select_field">
                    <label>Date</label>
                    <input type="text" class="field-control" name="Result" (keyup)="filterattendece($event)" />
                </div>
            </div>
            <div class="custom-table">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Class</th>
                            <th>Name</th>
                            <th>Date</th>


                        </tr>
                    </thead>
                    <tbody *ngIf="filteredattendecerowData.length === 0">
                        <tr>
                            <td colspan="3">No data available</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="filteredattendecerowData.length > 0">
                        <tr *ngFor="let data of filteredattendecerowData">
                            <td>{{data.Key}}</td>
                            <td>{{data.Value}}</td>
                            <td>{{data.Result}}</td>

                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="mt-2">
                <div class="tab-content px-0">
                    <div class="row border m-0 py-2 mb-2 justify-content-between">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                            <div class="form-group mb-0">
                                <label>Academic Year</label>

                                <select class="form-control form-control-sm appearance-auto m-0" name="selectedYear"
                                    [(ngModel)]="selectedYear" (change)="changeYear()" required>
                                    <option *ngFor="let year of yearList" [value]="year.Code">
                                        {{year.Name}}
                                    </option>
                                </select>

                            </div>

                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                            <div class="form-group mb-0 d-flex flex-wrap justify-content-end">
                                <label class="d-block w-100">&nbsp;</label>
                                <button (click)="serchAttendece()" type="button"
                                    class="btn btn-warning btn-sm float-right">Search</button>
                            </div>
                        </div>
                    </div>
                    <ag-grid-angular #agGrid style="width: 100%; height:calc(100vh - 200px);min-height: 200px;"
                        class="ag-theme-alpine" id="attendeceGrid" [gridOptions]="attendeceGridOptions"
                        [columnDefs]="attendeceColumnDefs" [defaultColDef]="attendeceDefaultColDef"
                        [rowSelection]="attendeceRowSelection" [rowData]="attendeceRowData"
                        (gridReady)="onAttendeceReady($event)" (gridReload)="onAttendeceReload($event)">
                    </ag-grid-angular>
                </div>
            </div> -->
        </mat-tab>

    </mat-tab-group>
</div>