<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1" *ngIf="service.formdata.AppointmentInfo.AppointmentId<=0">Add Appointment
            </h3>
            <h3 class="modal-title h3 m-1" *ngIf="service.formdata.AppointmentInfo.AppointmentId>0">Edit Appointment
            </h3>
        </div>
        <form novalidate #form="ngForm" autocomplete="off">
            <div class="breackout_body p-2">
                <div>
                    <div class="row form-row m-0">
                        <!-- <div class="form-group col-6 mb-0">
                            <label>Academic year</label>
                            <select class="form-control form-control-sm appearance-auto" name="AcademicYearId" #AcademicYearId="ngModel" 
                            [(ngModel)]="service.formdata.AppointmentInfo.AcademicYearId">
                              <option [value]="0">Select</option>
                              <option *ngFor="let year of yearList" [value]="year.Id">{{year.Name}}</option>
                            </select>
                          </div> -->
                        <div class="col-12">
                            <div class="form-group mb-0">
                                <label>{{'Description' | translate}}</label>
                                <textarea class="form-control form-control-sm resizebele-false" type="text"
                                    placeholder="{{'Description' | translate}}" name="Description"
                                    #Description="ngModel"
                                    [(ngModel)]="service.formdata.AppointmentInfo.Description"></textarea>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">

                            <div class="form-group mb-0">
                                <label>Text</label>
                                <input type="text" class="form-control form-control-sm" placeholder="Enter Etxt"
                                    name="Text" #Text="ngModel" [(ngModel)]="service.formdata.AppointmentInfo.Text">
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">

                            <div class="form-group mb-0">
                                <label>{{'Select Class' | translate}}</label>
                                <select class="form-control form-control-sm appearance-auto" name="ClassId"
                                    #ClassId="ngModel" [(ngModel)]="service.formdata.AppointmentInfo.ClassId">
                                    <option [value]="0">Select Class</option>
                                    <option *ngFor="let class of classList" [value]="class.Id">{{class.Name}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>{{'Date' | translate}}</label>
                                <input type="text" class="form-control form-control-sm"
                                    placeholder="{{'Date' | translate}}" name="Date" #StartDate="ngModel"
                                    [(ngModel)]="service.formdata.AppointmentInfo.Date" [bsConfig]="datepickerConfig"
                                    bsDatepicker (ngModelChange)="changeData()">
                            </div>

                        </div>
                        <div class="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>{{'StartTime' | translate}}</label>
                                <!-- <ng-template #popTemplate>
                                    <timepicker [(ngModel)]="service.formdata.AppointmentInfo.StartTime" name="StartTime" [showMeridian]="true"></timepicker>
                                </ng-template>
                                <input [ngModel]="service.formdata.AppointmentInfo.StartTime| date:'shortTime'" class="form-control form-control-sm" name="StartTime" [popover]="popTemplate" [outsideClick]="true" /> -->
                                <div class="input-group selectdiv">
                                    <select [(ngModel)]="service.formdata.AppointmentInfo.StartTime" name="StartTime"
                                        class="form-control form-control-sm appearance-auto">
                                        <option *ngFor="let sTime of timeSlotList" [value]="sTime.value">{{sTime.value}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>{{'EndTime' | translate}}</label>
                                <!-- <ng-template #popTemplate1>
                                    <timepicker [(ngModel)]="service.formdata.AppointmentInfo.EndTime" name="EndTime" [showMeridian]="true"></timepicker>
                                </ng-template>
                                <input [ngModel]="service.formdata.AppointmentInfo.EndTime | date:'shortTime'" class="form-control form-control-sm" name="EndTime" [popover]="popTemplate1" [outsideClick]="true" /> -->
                                <div class="input-group selectdiv">
                                    <select [(ngModel)]="service.formdata.AppointmentInfo.EndTime" name="EndTime"
                                        class="form-control form-control-sm appearance-auto" (change)="chkIsGreater()">
                                        <option *ngFor="let eTime of timeSlotList" [value]="eTime.value">{{eTime.value}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>

            </div>
            <div class="modal-footer p-1">
                <button type="button" (click)="addAppointmentInfo(form)" class="btn btn-success"
                    type="button">Save</button>
                <button type="button" class="btn btn-danger" (click)="modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL)"
                    aria-label="Close">Close</button>
            </div>
        </form>
    </div>
</div>