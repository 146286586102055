import { formatDate, NgFor } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import {
  BsDatepickerConfig,
  BsDatepickerModule,
} from "ngx-bootstrap/datepicker";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { StudentAnswerpreviewComponent } from "src/app/assignment/student-answerpreview.component";
import { HelpComponent } from "src/app/common/help.component";
import { CustomTooltipComponent } from "src/app/custom-tooltip/custom-tooltip.component";
import { CustomGrideCelComponent } from "src/app/custommenu/custom-gride-cel/custom-gride-cel.component";
import { CommonService } from "src/app/shared/common.service";
import { CUSTOMCONST } from "src/app/shared/Const";
import { QuestionService } from "src/app/shared/question.service";
import { UserService } from "src/app/shared/user.service";
import { FormsModule } from "@angular/forms";
import { AgGridModule } from "ag-grid-angular";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";

@Component({
  selector: "app-home-work",
  templateUrl: "./home-work.component.html",
  styleUrls: ["./home-work.component.scss"],
  standalone: true,
  imports: [
    MatButtonModule,
    MatMenuModule,
    NgFor,
    MatIconModule,
    AgGridModule,
    BsDatepickerModule,
    FormsModule,
  ],
})
export class HomeWorkComponent implements OnInit {
  rowData;
  columnDefs;
  frameworkComponents;
  defaultColDef;
  rowSelection;
  gridApi;
  gridColumnApi;
  gridOptions: any;
  attendance: any;
  childrenList: any;
  selectedChileId: number;
  tooltipShowDelay;
  datepickerConfig: BsDatepickerConfig;
  inlineDate: any;
  bsModalRef: BsModalRef;
  date: any;
  userData: any = [];
  urlSafe: SafeResourceUrl;
  constructor(
    public service: QuestionService,
    public commonService: CommonService,
    public userService: UserService,
    public modalService: BsModalService,
    public sanitizer: DomSanitizer,
    public router: Router
  ) {
    this.commonService.selectedChileId = this.commonService.selectedChileId
      ? this.commonService.selectedChileId
      : 0;
    this.selectedChileId = this.commonService.selectedChileId
      ? this.commonService.selectedChileId
      : 0;

    this.commonService.RefreshGrid$.subscribe((item) => {
      // this.getassignments();
      this.getAllStudentsQuestionsById();
    });
    this.datepickerConfig = Object.assign({
      dateInputFormat: "DD-MM-YYYY",
      showWeekNumbers: false,
      showTodayButton: false,
      todayPosition: "center",
      minMode: "day",
      maxDate: new Date(),
    });
    this.inlineDate = new Date();
    this.columnDefs = [
      {
        field: "Name",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        maxWidth: 200,
        width: 200,
      },
      {
        field: "Description",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        minWidth: 120,
      },
      {
        field: "StartDate",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        minWidth: 200,
      },
      {
        field: "StartTime",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
        maxWidth: 100,
      },
      {
        field: "NoOfQuestions",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        maxWidth: 200,
        minWidth: 100,
      },
      {
        field: "Result",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        maxWidth: 100,
        minWidth: 100,
        tooltipField: "Comments",
        tooltipComponentParams: { color: "#ffffff" },
      },

      {
        headerName: "Status",
        field: "QuestionMasterId",
        cellRendererFramework: CustomGrideCelComponent,
        maxWidth: 100,
        minWidth: 100,
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        cellRendererParams: {
          enableMeetingLink: false,
          enableButton: true,
          celName: [{ Name: "Join", Click: this.answerPreview, that: this }],
        },
      },
    ];
    this.tooltipShowDelay = 0;
    this.frameworkComponents = { customTooltip: CustomTooltipComponent };
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      filter: false,
      sortable: true,
      floatingFilter: false,
      tooltipComponent: "customTooltip",
    };
    this.rowSelection = "single";
  }

  ngOnInit(): void {
    this.getMyInfo();
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.getAllStudentsQuestionsById();
  }
  getAllStudentsQuestionsById() {
    this.service
      .getallstudentsquestionsbyId(this.selectedChileId)
      .subscribe((res: any) => {
        for (var i = 0; i < res.length; i++) {
          res[i].StartTime = this.commonService.timeFormate(res[i].StartTime);
          res[i].EndTime = this.commonService.timeFormate(res[i].EndTime);
        }
        this.rowData = res;
      });
  }
  getMyInfo() {
    this.userService.getMyInfo().subscribe((res: any) => {
      this.userService.formData = res;
      this.attendance = res.StudentInfo.LastAttendended;
      if (this.commonService.selectedChileId <= 0) {
        this.selectedChileId = res.ParentInfo.ChildrenList[0].Id;
        this.commonService.selectedChileId = res.ParentInfo.ChildrenList[0].Id;
      }
      this.childrenList = res.ParentInfo.ChildrenList;
      this.getAllStudentsQuestionsById();
      this.getuser();
    });
  }
  changeChild() {
    this.getAllStudentsQuestionsById();
  }
  answerPreview(StdQuestionMasterId, that) {
    const initialState = {
      list: [
        {
          id: StdQuestionMasterId,
          reqBy: "Parent",
        },
      ],
    };
    this.bsModalRef = that.modalService.show(StudentAnswerpreviewComponent, {
      initialState,
      class: "modal-dialog modal-dialog-centered",
      ignoreBackdropClick: true,
    });
  }
  openHelpTooltip(tooltipName: any) {
    const initialState = {
      list: [{ name: tooltipName }],
    };
    this.bsModalRef = this.modalService.show(HelpComponent, {
      id: CUSTOMCONST.USER_EDIT_MODAL,
      initialState,
      class: "modal-md col-12",
      ignoreBackdropClick: true,
    });
  }
  onDateChange(e: Event) {
    this.date = e;
    this.date = formatDate(this.date, "dd-MM-yyyy", "en");
    // this.getassignments();
  }
  onSelectionChanged($event) {}
  selectChild(id: any) {
    this.selectedChileId = id;
    this.commonService.selectedChileId = id;
    if (id > 0) {
      this.getAllStudentsQuestionsById();
      this.getuser();
    } else {
      this.router.navigateByUrl("/add-child");
    }
  }
  getuser() {
    var userid =
      this.commonService.selectedChileId > 0
        ? this.commonService.selectedChileId
        : 0;
    this.userService.getUserInfo(userid).subscribe((res: any) => {
      this.userData = res;
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
        res.UserInfo.CroppedFilepath
      );
    });
  }
}
