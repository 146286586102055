<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title h3 m-1">{{'AddMassage' | translate}}</h3>
        <span class="bi bi-x" (click)="bsModalRef.hide()" title="Close Module" data-bs-toggle="tooltip"></span>
    </div>
    <form novalidate #form="ngForm" autocomplete="off" enctype="multipart/form-data">
        <div class="breackout_body">
            <div class="field-row">
                <div class="select_field" *ngIf="service.formData.MessageMasterInfo">
                    <label>{{'Name' | translate}}*</label>
                    <input type="text" class="field-control" placeholder="{{'Name' | translate}}" name="Name"
                        #Name="ngModel" [(ngModel)]="service.formData.MessageMasterInfo.Name" required
                        class.invalid="Name.invalid && Name.touched">
                </div>
                <div class="select_field" *ngIf="service.formData">
                    <label>{{'Class' | translate}}*</label>
                    <ng-multiselect-dropdown [placeholder]="'Search'" [settings]="dropdownSettings" name="StdClass"
                        [data]="classList" [(ngModel)]="service.formData.ClassMappingList"
                        (onSelect)="onItemSelect($event,'Class')" (onSelectAll)="onSelectAll($event,'Class')"
                        (onDeSelect)="onItemSelect($event,'Class')" (onSelectAll)="onSelectAll($event,'Class')"
                        class="ng_inputoption">
                    </ng-multiselect-dropdown>
                </div>
                <div class="select_field" *ngIf="service.formData.MessageMasterInfo">
                    <label>{{'Student' | translate}}*</label>
                    <ng-multiselect-dropdown [placeholder]="'Search'" [settings]="dropdownSettings" name="students"
                        [data]="studentList" [(ngModel)]="service.formData.MessageMasterInfo.UserIdList"
                        (onSelect)="onStuentSelect($event,'Class')" (onSelectAll)="onStuentSelectAll($event,'Class')"
                        (onDeSelect)="onStuentSelect($event,'Class')" (onSelectAll)="onStuentSelectAll($event,'Class')"
                        class="ng_inputoption">
                    </ng-multiselect-dropdown>
                </div>
            </div>
            <div class="field-row">
                <div class="select_field" *ngIf="service.formData.MessageMasterInfo">
                    <label>{{'Description' | translate}}*</label>

                    <textarea class="field-control" type="text" placeholder="{{'Description' | translate}}"
                        name="Description" #Description="ngModel"
                        [(ngModel)]="service.formData.MessageMasterInfo.Description"></textarea>
                </div>
            </div>
            <!-- <div class="row form-row m-0"> -->
            <!-- <div class="col-4" *ngIf="service.formData.MessageMasterInfo">
                    <div class="form-group mb-0">
                        <label>{{'Name' | translate}}*</label>
                        <input class="form-control form-control-sm" type="text" placeholder="{{'Name' | translate}}"
                            name="Name" #Name="ngModel" [(ngModel)]="service.formData.MessageMasterInfo.Name" required
                            class.invalid="Name.invalid && Name.touched">
                    </div>
                </div> -->
            <!-- <div class="col-4" *ngIf="service.formData">
                    <div class="form-group mb-0">
                        <label>{{'Class' | translate}}*</label>
                        <ng-multiselect-dropdown placeholder="'Search'" [settings]="dropdownSettings" name="StdClass"
                            [data]="classList" [(ngModel)]="service.formData.ClassMappingList"
                            (onSelect)="onItemSelect($event,'Class')" (onSelectAll)="onSelectAll($event,'Class')"
                            (onDeSelect)="onItemSelect($event,'Class')" (onSelectAll)="onSelectAll($event,'Class')">
                        </ng-multiselect-dropdown>
                    </div>
                </div> -->
            <!-- <div class="col-4" *ngIf="service.formData.MessageMasterInfo">
                    <div class="form-group mb-0">
                        <label>{{'Student' | translate}}*</label>
                        <ng-multiselect-dropdown [placeholder]="'Search'" [settings]="dropdownSettings" name="students"
                            [data]="studentList" [(ngModel)]="service.formData.MessageMasterInfo.UserIdList"
                            (onSelect)="onStuentSelect($event,'Class')"
                            (onSelectAll)="onStuentSelectAll($event,'Class')"
                            (onDeSelect)="onStuentSelect($event,'Class')"
                            (onSelectAll)="onStuentSelectAll($event,'Class')">
                        </ng-multiselect-dropdown>
                    </div>
                </div> -->
            <!-- <div class="col-12" *ngIf="service.formData.MessageMasterInfo">
                    <div class="form-group mb-0">
                        <label>{{'Description' | translate}}*</label>
                        <input class="form-control form-control-sm" type="text"
                            placeholder="{{'Description' | translate}}" name="Description" #Description="ngModel"
                            [(ngModel)]="service.formData.MessageMasterInfo.Description">
                    </div>
                </div> -->
            <!-- </div> -->
            <div class="modal_footer">
                <button type="button" (click)="onStart()" class="custom-primary-btn" type="button">Start</button>
                <button type="button" class="custom-primary-btn custom-danger" (click)="bsModalRef.hide()"
                    aria-label="Close">Cancel</button>
            </div>
        </div>
    </form>
</div>