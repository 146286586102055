export class Forum {
    ForumId:number;
    UserId:number;
    ForumDate:string;
    subject:string;
}
export class ForumActionData {
    ForumId:number;
    Action_Type:string;
}
export class ForumViewData{
    ForumInfo: Forum;
    QuestionInfoList:Array<any>;
    IsSaved:boolean;
    ErrorMessage:string;
    ForumListInfo:Array<Forum>;
}
