<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title h3 m-1" *ngIf="service.formData.AssignmentInfo?.AssignmentId<=0">{{'AddAssignment' |
            translate}}</h3>
        <h3 class="modal-title h3 m-1" *ngIf="service.formData.AssignmentInfo?.AssignmentId>0">{{'EditAssignment' |
            translate}}</h3>
        <span class="bi bi-x" (click)="bsModalRef.hide()" title="Close Module" data-bs-toggle="tooltip"></span>
    </div>
    <form novalidate #form="ngForm" autocomplete="off" enctype="multipart/form-data">
        <div class="breackout_body">
            <div *ngIf="stepNo == 1">
                <div class="field-row">
                    <div class="select_field">
                        <label>{{'Name' | translate}}*</label>
                        <input class="field-control" type="text" placeholder="{{'Name' | translate}}" name="Name"
                            #Name="ngModel" [(ngModel)]="service.formData.AssignmentInfo.Name" required
                            class.invalid="Name.invalid && Name.touched">
                    </div>
                    <div class="select_field">

                        <label>{{'Type' | translate}}*</label>
                        <select class="field-control" name="TypeId" #TypeId="ngModel"
                            [(ngModel)]="service.formData.AssignmentInfo.TypeId" (change)="AssignmentTypeChange()"
                            required class.invalid="TypeId.invalid && TypeId.touched">
                            <option [value]="0">Select Type</option>
                            <option *ngFor="let assignType of typeList" [value]="assignType.Id">
                                {{assignType.Name}}</option>
                        </select>

                    </div>
                    <div class="select_field">
                        <label>Academic year</label>
                        <select class="field-control appearance-auto" name="AcademicYearId" #AcademicYearId="ngModel"
                            [(ngModel)]="service.formData.AssignmentInfo.AcademicYearId">
                            <option [value]="0">Select</option>
                            <option *ngFor="let year of yearList" [value]="year.Id">{{year.Name}}</option>
                        </select>
                    </div>
                </div>
                <div class="field-row">
                    <div class="select_field">
                        <label>{{'StartDate' | translate}}</label>
                        <input type="text" class="field-control" placeholder="{{'StartDate' | translate}}"
                            name="StartDate" #StartDate="ngModel"
                            [(ngModel)]="service.formData.AssignmentInfo.StartDate" [bsConfig]="datepickerConfig"
                            bsDatepicker [bsValue]="bsValue" [minDate]="minDate" timepicker required
                            class.invalid="StartDate.invalid && StartDate.touched">

                    </div>
                    <div class="select_field">
                        <label>{{'StartTime' | translate}}</label>
                        <!-- <ng-template #popTemplate>
                                    <timepicker [(ngModel)]="service.formData.AssignmentInfo.StartTime" name="StartTime"
                                        [showMeridian]="true"></timepicker>
                                </ng-template>
                                <input [ngModel]="service.formData.AssignmentInfo.StartTime | date:'shortTime'"
                                    class="form-control form-control-sm" name="StartTime" [popover]="popTemplate"
                                    [outsideClick]="true" #StartTime="ngModel" required
                                    class.invalid="StartTime.invalid && StartTime.touched" /> -->
                        <select [(ngModel)]="service.formData.AssignmentInfo.StartTime" name="StartTime"
                            class="field-control appearance-auto">
                            <option *ngFor="let sTime of timeSlotList">{{sTime.value}}</option>
                        </select>
                    </div>

                    <div class="select_field">
                        <label>{{'EndDate' | translate}}</label>
                        <input type="text" (click)="enddateSetting()" class="field-control"
                            placeholder="{{'EndDate' | translate}}" name="EndDate" #EndDate="ngModel"
                            [(ngModel)]="service.formData.AssignmentInfo.EndDate" [bsConfig]="datepickerConfig"
                            bsDatepicker [bsValue]="bsValue" [minDate]="minDate" required
                            class.invalid="EndDate.invalid && EndDate.touched">
                    </div>
                    <div class="select_field">
                        <label>{{'EndTime' | translate}}</label>
                        <!-- <ng-template #popTemplate1>
                                    <timepicker [(ngModel)]="service.formData.AssignmentInfo.EndTime" name="EndTime"
                                        [showMeridian]="true"></timepicker>
                                </ng-template>
                                <input [ngModel]="service.formData.AssignmentInfo.EndTime | date:'shortTime'"
                                    class="form-control form-control-sm" name="EndTime" [popover]="popTemplate1"
                                    [outsideClick]="true" #EndTime="ngModel" required
                                    class.invalid="EndTime.invalid && EndTime.touched" /> -->
                        <select [(ngModel)]="service.formData.AssignmentInfo.EndTime" name="EndTime"
                            class="field-control appearance-auto">
                            <option *ngFor="let sTime of timeSlotList">{{sTime.value}}</option>
                        </select>

                    </div>
                </div>
                <div class="field-row" [ngClass]="{'disabled':role != 'Admin' && role != 'SuperAdmin'}">
                    <div class="select_field">
                        <mat-checkbox class="example-margin" name="IsAcademics" #IsAcademics="ngModel"
                            [(ngModel)]="service.formData.AssignmentInfo.IsAcademics">{{'Academics' | translate}}
                        </mat-checkbox>
                    </div>
                    <div class="select_field">
                        <mat-checkbox class="example-margin" name="IsHumanityLife" #IsHumanityLife="ngModel"
                            [(ngModel)]="service.formData.AssignmentInfo.IsHumanityLife">{{'HumanityLife' |
                            translate}}</mat-checkbox>
                    </div>
                    <div class="select_field">
                        <mat-checkbox class="example-margin" name="IsSkills" #IsSkills="ngModel"
                            [(ngModel)]="service.formData.AssignmentInfo.IsSkills">{{'Skills' | translate}}
                        </mat-checkbox>
                    </div>

                    <div class="select_field">
                        <mat-checkbox class="example-margin" name="IsLearnOutOfBox" #IsLearnOutOfBox="ngModel"
                            [(ngModel)]="service.formData.AssignmentInfo.IsLearnOutOfBox">{{'LearnOutOfBox' |
                            translate}}</mat-checkbox>
                    </div>

                </div>
            </div>
            <div *ngIf="stepNo == 2">

                <div *ngIf="assignmentType == 'Watch'">
                    <div class="field-row">
                        <div class="select_field">
                            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                                name="UrlType" [(ngModel)]="service.formData.AssignmentInfo.UrlType">
                                <mat-radio-button class="example-radio-button mr-3" (change)="radioChange($event)"
                                    *ngFor="let videotype of videoTypeList" [value]="videotype">
                                    {{videotype}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>

                    </div>
                    <div class="field-row">
                        <div class="select_field" *ngIf="service.formData.AssignmentInfo.UrlType == 'File'">

                            <mb-large-files-uploader (updatedAttachmentsEmitter)="largefileschange($event)"
                                name="FileId" [(ngModel)]="service.formData.AssignmentInfo.FileId"
                                (updatedResponseEmitter)="filesuploaded($event)" title="{{'Upload video' | translate}}"
                                canUpload="true" canSendData="true" saveButtonText="" saveButton="false">
                            </mb-large-files-uploader>

                        </div>
                        <div class="select_field" *ngIf="service.formData.AssignmentInfo.UrlType == 'Url'">
                            <label>{{'Youtube Link' | translate}}</label>
                            <input class="field-control" name="Url" #Url="ngModel"
                                [(ngModel)]="service.formData.AssignmentInfo.Url" type='text' required
                                class.invalid="Url.invalid && Url.touched" />
                        </div>
                        <div class="select_field">
                            <video [src]="url"
                                *ngIf="(format == 'Watch' || format==='video') && url  && (service.formData.AssignmentInfo.UrlType =='File')"
                                height="200" controls></video>
                            <div [innerHtml]="enbedUrl" *ngIf="(service.formData.AssignmentInfo.UrlType =='Url')">

                                <img src="../../assets/images/loader/loader.gif" *ngIf="fileUploading" height="200">
                            </div>
                        </div>

                    </div>
                </div>
                <div *ngIf="assignmentType == 'Listen'">
                    <div class="field-row">
                        <div class="select_field">
                            <label>{{'Upload Audio' | translate}}</label>
                            <input class="field-control" type='file' (change)="selectAudioFile($event)" name="Url" />
                        </div>
                        <div class="select_field">
                            <img [src]="url" *ngIf="format==='image' && url" height="200">
                        </div>
                    </div>
                </div>
                <div *ngIf="assignmentType == 'Read'">
                    <div class="field-row">
                        <div class="select_field">
                            <label>{{'Upload PDF' | translate}}</label>
                            <input class="field-control" type='file' (change)="selectDocumentFile($event)" name="Url" />
                        </div>
                        <div class="select_field">
                            <img [src]="url"
                                *ngIf="(format==='image' || fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png') && url"
                                height="200">
                            <iframe [src]="urlSafe" *ngIf="fileType === 'pdf' && url" frameborder="0"
                                height="200"></iframe>
                        </div>
                    </div>
                </div>
                <div *ngIf="assignmentType == 'Do'">
                    <div class="field-row">
                        <div class="select_field">
                            <label><span>*</span>{{'Description' | translate}}</label>
                            <angular-editor class="resizebele-false" name="Description" #Description="ngModel"
                                [(ngModel)]="service.formData.AssignmentInfo.Description" [config]="config" required
                                class.invalid="Description.invalid && Description.touched">
                            </angular-editor>
                        </div>
                    </div>
                </div>
                <div *ngIf="assignmentType == 'Home work'">
                    <div class="field-row">
                        <div class="select_field">
                            <label><span>*</span>{{'Description' | translate}}</label>
                            <angular-editor class="resizebele-false" name="Description" #Description="ngModel"
                                [(ngModel)]="service.formData.AssignmentInfo.Description" [config]="config" required
                                class.invalid="Description.invalid && Description.touched">
                            </angular-editor>
                        </div>
                    </div>
                    <div class="field-row">
                        <div class="select_field">
                            <label>{{'Upload File' | translate}}</label>
                            <input class="field-control" type='file' (change)="selectDocumentFile($event)" name="Url" />
                        </div>
                        <div class="select_field">
                            <img [src]="url"
                                *ngIf="(format==='image' || fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png') && url"
                                height="200">
                            <iframe [src]="urlSafe" *ngIf="fileType === 'pdf' && url" frameborder="0"
                                height="200"></iframe>
                        </div>

                    </div>
                </div>
                <div
                    *ngIf="assignmentType == 'Watch' || assignmentType == 'Listen' || assignmentType == 'Read' || assignmentType == 'Do'">
                    <div class="field-row">
                        <div class="select_field">
                            <section class="example-section">
                                <mat-checkbox class="example-margin" name="IsQuestionir" #IsQuestionir="ngModel"
                                    [(ngModel)]="service.formData.AssignmentInfo.IsQuestionir"
                                    (click)="changeIsQuestionirStatus()"> Do you have any questionnaire?
                                </mat-checkbox>
                            </section>
                        </div>
                        <div class="select_field" *ngIf="assignmentType == 'Watch'">
                            <label>Duration</label>
                            <input class="field-control" name="Duration" #Duration="ngModel"
                                [(ngModel)]="service.formData.AssignmentInfo.Duration" type='text' required />
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="stepNo == 3">
                <div class="field-row" *ngIf="isProject">
                    <div class="select_field">
                        <label>{{'Description' | translate}}<span>*</span></label>
                        <angular-editor class="resizebele-false" name="Description" #Description="ngModel"
                            [(ngModel)]="service.formData.AssignmentInfo.Description" [config]="config" required
                            class.invalid="Description.invalid && Description.touched">
                        </angular-editor>
                    </div>
                </div>
                <div *ngIf="isQuestionir && questionInfo.QuestionNo>0">
                    <div class="field-row">
                        <div class="select_field">
                            <label>Question No. {{questionInfo.QuestionNo}}</label>
                        </div>
                    </div>
                    <div class="field-row">
                        <div class="select_field">
                            <label>{{'QuestionType' | translate}}</label>
                            <select class="field-control appearance-auto" name="QuestionTypeId"
                                #QuestionTypeId="ngModel" [(ngModel)]="questionInfo.QuestionTypeId"
                                (change)="questionTypeChange()" required
                                class.invalid="QuestionTypeId.invalid && QuestionTypeId.touched">
                                <option [value]="0">Select Type</option>
                                <option *ngFor="let questiontype of questiontypelist" [value]="questiontype.Id">
                                    {{questiontype.Name}}</option>
                            </select>
                        </div>

                    </div>

                    <!-- <mat-card class="example-card min-h-fitscreen1 p-1">
                        <mat-card-content class="mb-0"> -->
                    <div class="field-row"
                        *ngIf="(QuestionType == 'MC') || (QuestionType == 'MR') || (QuestionType == 'FIB')  || (QuestionType == 'Eassy')  || (QuestionType == 'TF') || (QuestionType == 'MF') || (QuestionType == 'MM')">
                        <div class="select_field">
                            <label><span>*</span>{{'Type Your Question' | translate}}</label>
                            <angular-editor class="resizebele-false" name="Question" #Question="ngModel"
                                [(ngModel)]="questionInfo.Question" [config]="config" required
                                class.invalid="Question.invalid && Question.touched">
                            </angular-editor>
                        </div>
                    </div>
                    <div *ngIf="QuestionType == 'MC'">
                        <div class="field-row">
                            <div class="select_field">
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                                    name="OptionType" [(ngModel)]="questionInfo.OptionType">
                                    <mat-radio-button class="example-radio-button mr-3"
                                        *ngFor="let optiontype of optionTypeList" [value]="optiontype">
                                        {{optiontype}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>

                        <div *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                            <div class="field-row" *ngIf="i==0">
                                <div class="select_field option_field">
                                    <label>{{'Correct' | translate}}</label>
                                </div>
                                <div class="select_field">
                                    <label>{{'QUESTION OPTION' | translate}}</label>
                                </div>
                                <div class="select_field option_field">
                                    <button class="custom-primary-btn" type="submit" (click)='addNewRow("MC")'><span
                                            class="bi bi-plus-circle" title="Add New Row"></span></button>
                                </div>
                            </div>
                            <div class="field-row">
                                <div class="select_field option_field">
                                    <label class="option-label">{{QindexLabel[i].text}}</label>
                                    <mat-radio-group aria-label="Select an option" class="mat-radio-label-content-false"
                                        name="CorrectLabel-{{i}}" #CorrectLabel="ngModel"
                                        [(ngModel)]="qAnswer.CorrectLabel"
                                        (change)="changeMC(questionInfo.QAnswerList.length, i )">
                                        <mat-radio-button [value]="QindexLabel[i].text">
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div class="select_field" *ngIf="questionInfo.OptionType == 'Text'">
                                    <textarea class="field-control appearance-auto resizebele-false" name="Answer{{i}}"
                                        #Answer="ngModel" [(ngModel)]="qAnswer.Answer" required
                                        class.invalid="Answer.invalid && Answer.touched"></textarea>
                                </div>
                                <div class="select_field" *ngIf="questionInfo.OptionType == 'Image'">
                                    <mb-large-files-uploader (updatedAttachmentsEmitter)="largefileschange($event)"
                                        name="qAnswer.FileId" [(ngModel)]="qAnswer.FileId" #FileId="ngModel" required
                                        class.invalid="FileId.invalid && FileId.touched"
                                        (updatedResponseEmitter)="qAnswerFilesuploaded($event, i)"
                                        title="{{'Upload Image' | translate}}" canUpload="true" canSendData="true"
                                        saveButtonText="" saveButton="false">
                                    </mb-large-files-uploader>

                                    <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-100px">
                                </div>

                                <div class="select_field delete_option">
                                    <mat-icon *ngIf="i>3" (click)="deleteRow(qAnswer)"
                                        class="text-danger cursor-pointer">
                                        delete_outline</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="QuestionType == 'Eassy'">
                        <div *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                            <div class="field-row" *ngIf="i==0">
                                <div class="select_field">
                                    <label>{{'Enter Answer' | translate}}</label>
                                </div>
                            </div>
                            <div class="field-row">

                                <div class="select_field">
                                    <textarea class="field-control appearance-auto resizebele-false" rows="10"
                                        name="Answer" #Answer="ngModel" [(ngModel)]="qAnswer.Answer" required
                                        class.invalid="Answer.invalid && Answer.touched"></textarea>
                                </div>
                            </div>
                            <div class="field-row">
                                <div class="select_field">
                                    <!-- <div class="form-group mb-0"> -->
                                    <!-- <mb-large-files-uploader
                                                                (updatedAttachmentsEmitter)="largefileschange($event)"
                                                                (updatedResponseEmitter)="qAnswerFilesuploaded($event, 0)"
                                                                title="{{'Upload Image' | translate}}" canUpload="true"
                                                                canSendData="true" saveButtonText="">
                                                            </mb-large-files-uploader> -->
                                    <!-- </div> -->
                                    <img [src]="questionInfo.QAnswerList[0].FilePath" alt=""
                                        class="img img-fluid max-width-100px">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="QuestionType == 'FIB'">
                        <div *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                            <div class="field-row" *ngIf="i==0">
                                <div class="select_field option_field">
                                    <label>{{'Correct' | translate}}</label>
                                </div>
                                <div class="select_field">
                                    <label>{{'QUESTION OPTION' | translate}}</label>
                                </div>
                                <div class="select_field">
                                    <label>{{'Attachement' | translate}}</label>
                                </div>
                                <div class="select_field option_field">
                                    <button class="custom-primary-btn" type="submit" (click)='addNewRow("FIB")'><span
                                            class="bi bi-plus-circle" title="Add New Row"></span></button>
                                </div>
                            </div>
                            <div class="field-row">
                                <div class="select_field option_field">
                                    <label class="option-label"><span ngIF="i<9">0</span>{{i+1}}</label>
                                </div>
                                <div class="select_field">
                                    <textarea class="field-control appearance-auto resizebele-false"
                                        name="qAnswer.Answer{{i}}" #Answer="ngModel" [(ngModel)]="qAnswer.Answer"
                                        required class.invalid="Answer.invalid && Answer.touched"></textarea>
                                </div>
                                <div class="select_field option_field">
                                    <mb-large-files-uploader (updatedAttachmentsEmitter)="largefileschange($event)"
                                        name="qAnswer.FileId" [(ngModel)]="qAnswer.FileId"
                                        (updatedResponseEmitter)="qAnswerFilesuploaded($event, i)"
                                        title="{{'Upload Image' | translate}}" canUpload="true" canSendData="true"
                                        saveButton="false" saveButtonText="">
                                    </mb-large-files-uploader>
                                    <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-100px">

                                </div>
                                <div class="select_field delete_option">

                                    <mat-icon *ngIf="i!=0" (click)="deleteRow(qAnswer)"
                                        class="text-danger cursor-pointer">
                                        delete_outline</mat-icon>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div *ngIf="QuestionType == 'MR'">
                        <div class="field-row">
                            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                                name="OptionType" [(ngModel)]="questionInfo.OptionType">
                                <mat-radio-button class="example-radio-button mr-3"
                                    *ngFor="let optiontype of optionTypeList" [value]="optiontype">
                                    {{optiontype}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                            <div class="field-row" *ngIf="i==0">
                                <div class="select_field option_field">
                                    <label>{{'Correct' | translate}}</label>
                                </div>
                                <div class="select_field">
                                    <label>{{'QUESTION OPTION' | translate}}</label>
                                </div>
                                <div class="select_field option_field">
                                    <button class="custom-primary-btn" type="submit" (click)='addNewRow("MR")'><span
                                            class="bi bi-plus-circle" title="Add New Row"></span></button>
                                </div>
                            </div>
                            <div class="field-row">
                                <div class="select_field option_field">
                                    <label class="option-label">{{QindexLabel[i].text}}</label>
                                    <mat-checkbox class="example-margin" name="qAnswer.IsCorrect{{i}}"
                                        #IsCorrect="ngModel" [(ngModel)]="qAnswer.IsCorrect" value="1">
                                    </mat-checkbox>
                                </div>
                                <div class="select_field" *ngIf="questionInfo.OptionType == 'Text'">

                                    <textarea name="" class="field-control appearance-auto resizebele-false"
                                        name="qAnswer.Answer{{i}}" #Answer="ngModel" [(ngModel)]="qAnswer.Answer"
                                        required class.invalid="Answer.invalid && Answer.touched"></textarea>

                                </div>
                                <div class="select_field" *ngIf="questionInfo.OptionType == 'Image'">

                                    <mb-large-files-uploader (updatedAttachmentsEmitter)="largefileschange($event)"
                                        name="qAnswer.FileId" [(ngModel)]="qAnswer.FileId"
                                        (updatedResponseEmitter)="qAnswerFilesuploaded($event, i)"
                                        title="{{'Upload Image' | translate}}" canUpload="true" canSendData="true"
                                        saveButtonText="">
                                    </mb-large-files-uploader>

                                    <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-100px">
                                </div>
                                <div class="select_field delete_option">
                                    <mat-icon *ngIf="i>3" (click)="deleteRow(qAnswer)"
                                        class="text-danger cursor-pointer">
                                        delete_outline</mat-icon>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div *ngIf="QuestionType == 'TF'">

                        <div class="field-row">
                            <div class="select_field">

                                <mat-radio-group aria-label="Select an option" name="IsCorrect" #IsCorrect="ngModel"
                                    [(ngModel)]="questionInfo.QAnswerList[0].IsCorrect" (change)="changeTF()" required
                                    class.invalid="IsCorrect.invalid && IsCorrect.touched">
                                    <mat-radio-button class="mr-3"
                                        *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index;"
                                        [value]="QindexLabel[i].text">
                                        {{qAnswer.Answer}}

                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="select_field">
                                <!-- <mb-large-files-uploader
                                                            (updatedAttachmentsEmitter)="largefileschange($event)"
                                                            (updatedResponseEmitter)="qAnswerFilesuploaded($event, 0)"
                                                            title="{{'Upload Image' | translate}}" canUpload="true"
                                                            canSendData="true" saveButtonText="">
                                                        </mb-large-files-uploader> -->
                                <img [src]="questionInfo.QAnswerList[0].FilePath" alt=""
                                    class="img img-fluid max-width-100px">
                            </div>
                        </div>

                    </div>
                    <div *ngIf="QuestionType == 'MF'">

                        <div class="field-row">
                            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                                name="OptionType" [(ngModel)]="questionInfo.OptionType">
                                <mat-radio-button class="example-radio-button mr-3"
                                    *ngFor="let optiontype of optionTypeList" [value]="optiontype">
                                    {{optiontype}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>

                        <div class="field-row" *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                            <div class="select_field">
                                <div class="field-row" *ngIf="i==0">
                                    <div class="select_field">
                                        <label>{{'QUESTION OPTION' | translate}}</label>
                                    </div>
                                    <div class="select_field">
                                        <label>{{'QUESTION OPTION' | translate}}</label>
                                    </div>
                                    <div class="select_field option_field">
                                        <button class="custom-primary-btn" type="submit" (click)='addNewRow("MF")'><span
                                                class="bi bi-plus-circle" title="Add New Row"></span></button>
                                    </div>
                                </div>

                                <div class="field-row">
                                    <div class="select_field">
                                        <label class="option-label">{{qAnswer.GroupNo}}</label>

                                        <input name="" class="field-control appearance-auto resizebele-false"
                                            name="qAnswer.Answer{{i}}" #Answer="ngModel" [(ngModel)]="qAnswer.Answer"
                                            required class.invalid="Answer.invalid && Answer.touched">
                                    </div>
                                    <div class="select_field" *ngIf="questionInfo.OptionType == 'Text'">
                                        <label class="option-label">{{qAnswer.GroupNo1}}</label>

                                        <input name="" class="field-control appearance-auto resizebele-false"
                                            name="qAnswer.Answer1{{i}}" #Answer1="ngModel" [(ngModel)]="qAnswer.Answer1"
                                            required class.invalid="Answer1.invalid && Answer1.touched">
                                    </div>
                                    <div class="select_field" *ngIf="questionInfo.OptionType == 'Image'">
                                        <mb-large-files-uploader (updatedAttachmentsEmitter)="largefileschange($event)"
                                            name="qAnswer.FileId" [(ngModel)]="qAnswer.FileId"
                                            (updatedResponseEmitter)="qAnswerFilesuploaded($event, i)"
                                            title="{{'Upload Image' | translate}}" canUpload="true" canSendData="true"
                                            saveButtonText="">
                                        </mb-large-files-uploader>
                                        <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-100px">
                                    </div>
                                </div>

                                <div class="field-row">
                                    <div class="select_field">
                                        <div class="field-row" *ngIf="i==0">
                                            <div class="select_field">
                                                <label>{{'CORRECT ANSWER' | translate}}</label>
                                            </div>
                                        </div>

                                        <div class="field-row">
                                            <div class="select_field option_field"
                                                *ngFor="let qAnswer1 of questionInfo.QAnswerList; let j=index">
                                                <label class="option-label">{{qAnswer1.GroupNo1}}</label>
                                                <mat-radio-group aria-label="Select an option"
                                                    class="mat-radio-label-content-false" name="{{i}}"
                                                    #CorrectLabel="ngModel" [(ngModel)]="qAnswer.CorrectLabel" required
                                                    class.invalid="CorrectLabel.invalid && CorrectLabel.touched">
                                                    <mat-radio-button [checked]="qAnswer.CorrectLabel"
                                                        value="{{qAnswer1.GroupNo1}}">
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                            <div class="select_field delete_option">
                                                <mat-icon *ngIf="i>3" (click)="deleteRow(qAnswer)"
                                                    class="text-danger cursor-pointer">
                                                    delete_outline</mat-icon>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div *ngIf="QuestionType == 'MM'">
                        <div class="field-row">
                            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                                name="OptionType" [(ngModel)]="questionInfo.OptionType">
                                <mat-radio-button class="example-radio-button mr-3"
                                    *ngFor="let optiontype of optionTypeList" [value]="optiontype">
                                    {{optiontype}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="field-row" *ngFor="let qAnswer of questionInfo.QAnswerList; let i=index">
                            <div class="select_field">
                                <div class="field-row" *ngIf="i==0">
                                    <div class="select_field">
                                        <label>{{'QUESTION OPTION' | translate}}</label>
                                    </div>
                                    <div class="select_field">
                                        <label>{{'QUESTION OPTION' | translate}}</label>
                                    </div>
                                    <div class="select_field option_field">
                                        <button class="custom-primary-btn" type="submit" (click)='addNewRow("MM")'><span
                                                class="bi bi-plus-circle" title="Add New Row"></span></button>
                                    </div>
                                </div>

                                <div class="field-row">
                                    <div class="select_field">
                                        <label class="option-label">{{qAnswer.GroupNo}}</label>

                                        <input name="" class="field-control appearance-auto resizebele-false"
                                            name="qAnswer.Answer{{i}}" #Answer="ngModel" [(ngModel)]="qAnswer.Answer"
                                            required class.invalid="Answer.invalid && Answer.touched">
                                    </div>
                                    <div class="select_field" *ngIf="questionInfo.OptionType == 'Text'">
                                        <label class="option-label">{{qAnswer.GroupNo1}}</label>

                                        <input name="" class="field-control appearance-auto resizebele-false"
                                            name="qAnswer.Answer1{{i}}" #Answer1="ngModel" [(ngModel)]="qAnswer.Answer1"
                                            required class.invalid="Answer1.invalid && Answer1.touched">
                                    </div>
                                    <div class="select_field" *ngIf="questionInfo.OptionType == 'Image'">
                                        <mb-large-files-uploader (updatedAttachmentsEmitter)="largefileschange($event)"
                                            name="qAnswer.FileId" [(ngModel)]="qAnswer.FileId"
                                            (updatedResponseEmitter)="qAnswerFilesuploaded($event, i)"
                                            title="{{'Upload Image' | translate}}" canUpload="true" canSendData="true"
                                            saveButtonText="">
                                        </mb-large-files-uploader>
                                        <img [src]="qAnswer.FilePath" alt="" class="img img-fluid max-width-100px">
                                    </div>
                                </div>

                                <div class="field-row">
                                    <div class="select_field">
                                        <div class="field-row" *ngIf="i==0">
                                            <div class="select_field">
                                                <label>{{'CORRECT ANSWER' | translate}}</label>
                                            </div>
                                        </div>

                                        <div class="field-row">
                                            <div class="select_field option_field"
                                                *ngFor="let qAnswer1 of questionInfo.QAnswerList; let j=index">
                                                <label class="option-label">{{qAnswer1.GroupNo1}}</label>
                                                <mat-checkbox class="example-margin" name="{{i}}{{j}}"
                                                    #CorrectLabel="ngModel" [(ngModel)]="qAnswer.CorrectLabel"
                                                    value="{{qAnswer1.GroupNo1}}" [checked]="qAnswer.CorrectLabel"
                                                    required
                                                    class.invalid="CorrectLabel.invalid && CorrectLabel.touched">
                                                </mat-checkbox>
                                            </div>
                                            <div class="select_field delete_option">
                                                <mat-icon *ngIf="i>3" (click)="deleteRow(qAnswer)"
                                                    class="text-danger cursor-pointer">
                                                    delete_outline</mat-icon>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- </mat-card-content>
                    </mat-card> -->
                </div>
            </div>

            <div class="modal_footer">

                <button *ngIf="stepNo > 1" type="button" class="custom-primary-btn"
                    (click)="prevQuestion()">Back</button>
                <button *ngIf="stepNo > 1 && questionInfo.QuestionNo <= 0 && (isQuestionir || isProject)" type="button"
                    (click)="proceedNextStep(form)" [disabled]="form.invalid || fileUploading"
                    class="custom-primary-btn" type="button">Next</button>
                <button *ngIf="stepNo > 1 && questionInfo.QuestionNo > 0 && (isQuestionir || isProject)" type="button"
                    (click)="addNewAnswer(form)"
                    [disabled]="form.invalid || fileUploading || (questionInfo.QuestionTypeId<=0)"
                    class="custom-primary-btn" type="button">Next</button>
                <button *ngIf="stepNo > 1" type="button" (click)="saveAssignment(form)" class="custom-primary-btn"
                    type="button"
                    [disabled]="form.invalid || fileUploading || (questionInfo.QuestionTypeId<=0)">Save</button>
                <button *ngIf="stepNo == 1" type="button" class="custom-primary-btn" (click)="startAsignment(form)"
                    type="button"
                    [disabled]="form.invalid || service.formData.AssignmentInfo.TypeId <= 0">Start</button>

                <button type="button" class="custom-primary-btn custom-danger" (click)="bsModalRef.hide()"
                    aria-label="Close">Cancel</button>
            </div>
        </div>
    </form>
</div>