import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { ForumService } from 'src/app/shared/forum.service';
@Component({
  selector: 'app-answer-list-component',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './answer-list-component.component.html',
  styleUrl: './answer-list-component.component.scss'
})
export class AnswerListComponentComponent {
  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
  @Input() questionId: string = '';
  @Input() reloadAnswer: number;
  answerList: any[] = [];
  constructor(public service:ForumService) {}
  ngOnChanges(changes: SimpleChanges) {
    this.getAnswers()
  }
  ngOnInit(): void {
      this.getAnswers()
  }
  getAnswers(){
    this.service.getAnswers(this.questionId).subscribe(res=>{
      this.answerList=  res["AnswerInfoList"]
    })
  }
}
