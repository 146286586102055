import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { CustomControlsComponent } from "src/app/custommenu/custom-controls/custom-controls.component";
import { CustomHeader } from "src/app/custommenu/custom-header.component";
import { ClassData } from "src/app/shared/question.model";
import { QuestionService } from "src/app/shared/question.service";
import { AssignemntService } from "src/app/shared/assignemnt.service";
import { NotificationService } from "src/app/shared/notification.service";
import { CommonService } from "src/app/shared/common.service";
import { TranslateModule } from "@ngx-translate/core";
import { AgGridModule } from "ag-grid-angular";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { NgFor } from "@angular/common";
// import { ClassData } from '../shared/question.model';
@Component({
  selector: "app-assign-classes",
  templateUrl: "./assign-classes.component.html",
  styleUrls: ["./assign-classes.component.scss"],
  standalone: true,
  imports: [AgGridModule, TranslateModule, MatCheckboxModule, NgFor],
})
export class AssignClassesComponent implements OnInit {
  gridApi;
  toggleSelection: boolean;
  getRowNodeId;
  gridColumnApi;
  frameworkComponents;
  columnDefs;
  defaultColDef;
  rowSelection;
  rowData: ClassData[];
  filteredrowData: ClassData[];
  filterList: string[] = [];

  gridOptions: any;
  list: any[] = [];
  constructor(
    public questionservice: QuestionService,
    public bsModalRef: BsModalRef,
    public assignService: AssignemntService,
    private notificationServ: NotificationService,
    private commonService: CommonService
  ) {
    this.toggleSelection = false;
    this.columnDefs = [
      {
        headerName: "ClassId",
        field: "IdActive",
        cellRendererFramework: CustomControlsComponent,
        cellRendererParams: {
          enableCheckbox: true,
          controlList: [
            {
              Name: "checkbox",
              Click: (params) => this.selectUnselect(params),
              that: this,
            },
          ],
        },
        filter: false,
        width: 60,
        maxWidth: 60,
        headerComponent: "customHeader",
        headerComponentParams: {
          button: "click",
          checkboxToggle: true,
          menuIcon: "far fa-check-square",
          enableSorting: false,
          Click: this.selectUnselectAll,
          that: this,
        },
      },
      {
        field: "Name",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
      },
      {
        field: "Description",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
      },
    ];
    this.frameworkComponents = {
      customHeader: CustomHeader,
    };
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      floatingFilter: true,
    };
    this.rowSelection = "single";
    this.getRowNodeId = (params) => {
      return params.ClassId;
    };
  }

  ngOnInit(): void {
    this.commonService.getclasses().subscribe((res: ClassData[]) => {
      this.rowData = res;
      this.rowData.filter((i) => {
        i.IsActive = false;
        i.IdActive = i.Id + "_" + i.IsActive;
      });
      if (
        this.list.length > 1 &&
        this.list[2].classidlist &&
        this.list[2].classidlist.length > 0
      ) {
        for (var i = 0; i < this.list[2].classidlist.split(",").length; i++) {
          var row = this.rowData.filter(
            (item) => item.Id == this.list[2].classidlist.split(",")[i]
          );
          row[0].IsActive = true;
          row[0].IdActive = row[0].Id + "_" + row[0].IsActive;
        }
      }
      this.filteredrowData = this.rowData;
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.commonService.getclasses().subscribe((res: ClassData[]) => {
      this.rowData = res;
      this.rowData.filter((i) => {
        i.IsActive = false;
        i.IdActive = i.Id + "_" + i.IsActive;
      });
      if (
        this.list.length > 1 &&
        this.list[2].classidlist &&
        this.list[2].classidlist.length > 0
      ) {
        for (var i = 0; i < this.list[2].classidlist.split(",").length; i++) {
          var row = this.rowData.filter(
            (item) => item.Id == this.list[2].classidlist.split(",")[i]
          );
          row[0].IsActive = true;
          row[0].IdActive = row[0].Id + "_" + row[0].IsActive;
        }
      }
      this.gridApi.setRowData(this.rowData);
    });
  }
  assignCass() {
    // Step 1: Initialize idList
    const idList: number[] = [];

    // Step 2: Loop through filteredrowData and collect the active IDs
    this.filteredrowData.forEach((data: any) => {
      if (data.IsActive) {
        idList.push(data.Id); // Collect selected class IDs
      }
    });

    // Step 3: Check if any rows were selected
    if (idList.length === 0) {
      this.notificationServ.ShowError("No classes selected.");
      return;
    }

    // Step 4: Check if `this.list` is valid and contains the necessary data
    if (!this.list || this.list.length < 1) {
      this.notificationServ.ShowError("Invalid list data.");
      return;
    }

    // Step 5: Prepare the assignment object based on the type (Assignment or QuestionMaster)
    let assObj: any;
    if (this.list.length > 1 && this.list[1].type === "Assignment") {
      assObj = {
        Id: this.list[0].id,
        IdList: idList,
      };

      // Assign classes using the assignService
      this.assignService.assignToClass(assObj).subscribe((res: any) => {
        if (res.ErrorMessage) {
          this.notificationServ.ShowError(res.ErrorMessage);
        } else if (res.IsSaved) {
          this.notificationServ.ShowInfo("Classes assigned successfully");
          this.commonService.CallOnRefreshGrid();
          this.bsModalRef.hide();
        }
      });
    } else {
      assObj = {
        QuestionMasterId: this.list[0].id,
        IdList: idList,
      };

      // Assign classes using the questionservice
      this.questionservice.assignToClass(assObj).subscribe((res: any) => {
        if (res.ErrorMessage) {
          this.notificationServ.ShowError(res.ErrorMessage);
        } else if (res.IsSaved) {
          this.notificationServ.ShowInfo("Classes assigned successfully");
          this.bsModalRef.hide();
        }
      });
    }
  }

  selectUnselect(idactive: any) {
    // Convert idactive to a string if it's a number or handle it as a string
    let idactiveStr =
      typeof idactive === "number" ? idactive.toString() : idactive;

    // If idactive doesn't contain an underscore, assume it's just an ID
    if (!idactiveStr.includes("_")) {
      idactiveStr = idactiveStr + "_true"; // Default to 'true' if no '_', assuming active
    }

    if (idactiveStr.includes("_")) {
      const id = idactiveStr.split("_")[0]; // Extract the ID part before the underscore
      const item = this.rowData.find((item) => item.Id.toString() === id); // Ensure comparison as strings

      if (item) {
        item.IsActive = !item.IsActive; // Toggle IsActive
        item.IdActive = item.Id + "_" + item.IsActive; // Update IdActive
      } else {
        console.warn("Item not found for id:", id); // Log a warning if no matching item is found
      }
    } else {
      console.error("Invalid idactive value:", idactiveStr); // Log an error if idactive is invalid
    }
  }

  selectUnselectAll() {
    this.toggleSelection = !this.toggleSelection;
    if (this.rowData) {
      this.rowData.filter((i) => {
        i.IsActive = this.toggleSelection;
        i.IdActive = i.Id + "_" + i.IsActive;
      });
    }

    // this.gridApi.setRowData(this.rowData);

    // var params = {
    //   force: true,
    //   suppressFlash: true,
    //   rowNodes: that.rowData,
    // };
    // that.gridApi.redrawRows();
  }
  filter(e: Event) {
    const inputElement = e.target as HTMLInputElement;
    const key = inputElement.name; // Get the name of the input field
    const text = inputElement.value; // Get the value of the input field
    this.filterList[key] = text;
    var rowData = this.rowData;
    Object.keys(this.filterList).forEach((element) => {
      rowData = rowData.filter((o) =>
        o[element]
          .toLowerCase()
          .includes(this.filterList[element].toLowerCase())
      );
    });

    this.filteredrowData = rowData;
  }
}
