<div class="field-row">
  <div class="select_field">
    <label for="">Select Week</label>
    <select [(ngModel)]="selectedWeek" class="field-control">
      <option [ngValue]="availweek" *ngFor="let availweek of AvailWeeks">
        {{availweek}}
      </option>
    </select>
  </div>
  <div class="select_field selected_field">
    <span (click)="PrevWeek()" [ngClass]="{'disabled-icon': isDisabledPrevWeek}" class="bi bi-chevron-left"></span>
    <label>{{selectedWeek}}</label>
    <span (click)="NextWeek()" [ngClass]="{'disabled-icon': isDisabledNextWeek}" class="bi bi-chevron-right"></span>
  </div>
</div>
<div class="custom-table">
  <table class="table table-bordered">
    <thead>
      <tr>
        <th scope="col">Time</th>
        <th scope="col" class="timetable-header">Monday</th>
        <th scope="col" class="timetable-header">Tuesday</th>
        <th scope="col" class="timetable-header">Wednesday</th>
        <th scope="col" class="timetable-header">Thursday</th>
        <th scope="col" class="timetable-header">Friday</th>
        <th scope="col" class="timetable-header">Saturday</th>
        <th scope="col" class="timetable-header">Sunday</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let offset of Weeklydata[selectedWeek]">
        <td *ngFor="let weekdata of offset; let i =index" class="timetable-content"
          [ngClass]="{'timetable-header' : i === 0}">
          <span *ngIf="i === 0">{{weekdata}}</span>
          <div *ngIf="i !== 0" class="font-bold font-title">{{weekdata.heading}}</div>
          <div *ngIf="i !== 0" class="font-subtitle">{{weekdata.subheading}}</div>
        </td>
      </tr>
    </tbody>
  </table>
</div>