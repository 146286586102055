import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
declare const sendMessage: any;
declare const receivecMessage: any;
declare const shreFiles: any;
// import $ from 'jquery';
import { FormsModule } from '@angular/forms';
// import { SocketService } from 'src/app/shared/socket.service';
// import { NotificationService } from 'src/app/shared/notification.service';

@Component({
  selector: 'app-webrtc-chat-component',
  standalone: true,
  imports: [CommonModule,FormsModule],
  templateUrl: './webrtc-chat-component.component.html',
  styleUrl: './webrtc-chat-component.component.scss'
})
export class WebrtcChatComponentComponent {
  message: any;
  messages: any[] = [];
  @Input() title:string;
  receivedData: any;
  @ViewChild('chatContainer') chatContainer!: ElementRef;
  constructor(){}
  ngOnInit() {
    console.log(this.title);
    
    // this.receivedMessage();
    receivecMessage();
  }
  sendMessage(): void {
    this.scrollToBottom();
    const msg = JSON.stringify({
      type:"message",
      text:this.message.trim()
    })
    console.log(msg);
    
    sendMessage(msg);
    this.message = '';
  }
  scrollToBottom(): void {
    try {
      this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }
  sendFiles(): void {
    try {
      shreFiles();
    } catch (err) { }
  }
}
