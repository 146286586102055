<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title h3 m-1">{{'Assign' | translate}}</h3>
        <span class="bi bi-x" (click)="bsModalRef.hide()" title="Close Module" data-bs-toggle="tooltip"></span>
    </div>
    <div class="breackout_body">

        <div class="field-row filter-form">
            <div class="select_field">
                <label>Search Class Name</label>
                <input type="text" class="field-control" name="Name" (keyup)="filter($event)" />
            </div>
        </div>
        <div class="custom-table">
            <!-- <pre>{{ filteredrowData | json }}</pre> Debugging purpose -->

            <table class="table table-striped">
                <thead>
                    <tr>
                        <th><mat-checkbox class="example-margin" required (change)="selectUnselectAll()"
                                class.invalid="CorrectLabel.invalid && CorrectLabel.touched">
                            </mat-checkbox></th>
                        <th>Class Name</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of filteredrowData">
                        <td><mat-checkbox class="example-margin" required [checked]="data.IsActive"
                                (change)="selectUnselect(data.Id)"
                                class.invalid="CorrectLabel.invalid && CorrectLabel.touched">
                            </mat-checkbox></td>
                        <td>{{data.Name}}</td>
                        <td>{{data.Description}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- <div class="row justify-content-c m-0">
            <div class="col-md-12 p-0">
                <ag-grid-angular #agGrid style="width: 100%; height:calc(90vh - 80px)" class="ag-theme-alpine"
                    id="myGrid" [getRowNodeId]="getRowNodeId" [gridOptions]="gridOptions" [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef" [rowSelection]="rowSelection" [filteredrowData]="filteredrowData"
                    (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents">
                </ag-grid-angular>
            </div>
        </div> -->

        <div class="modal_footer">
            <button class="custom-primary-btn" (click)="assignCass()">Save</button>
            <button class="custom-primary-btn custom-danger" (click)="bsModalRef.hide()"
                aria-label="Close">Cancel</button>
        </div>
    </div>

</div>