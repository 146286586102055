import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import {DrawingToolComponent} from '../drawing-tool/drawing-tool.component'
declare const whiteboard: any;
// declare const sendMessage: any;
// declare const canvas: any;
// declare const captureStream: any;
declare const initCanvasDesigner: any;

@Component({
  selector: "app-white-board",
  standalone: true,
  imports: [TranslateModule,DrawingToolComponent],
  templateUrl: "./white-board.component.html",
  styleUrl: "./white-board.component.scss",
})
export class WhiteBoardComponent {
  @Output() closeWhiteboard = new EventEmitter<string>();
  constructor(public bsModalRef: BsModalRef) {}
  roomId: string | null;
  ngOnInit() {
    this.roomId = localStorage.getItem("room_id");
    initCanvasDesigner("canvas-designer")
    // whiteboard(this.roomId);
    
    // canvas()
    // captureStream()
    this.closeWhiteboard.emit();
  }
}
