import { formatDate, NgFor, NgIf ,Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { CustomHeader } from "src/app/custommenu/custom-header.component";
import { CustommenuComponent } from "src/app/custommenu/custommenu.component";
import { CommonService } from "src/app/shared/common.service";
import { LeaveService } from "src/app/shared/leave.service";
import { NotificationService } from "src/app/shared/notification.service";
import { UserService } from "src/app/shared/user.service";
import { AgGridModule } from "ag-grid-angular";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "app-teacher-leave",
  templateUrl: "./teacher-leave.component.html",
  styleUrls: ["./teacher-leave.component.scss"],
  standalone: true,
  imports: [FormsModule, NgFor, AgGridModule, NgIf],
})
export class TeacherLeaveComponent implements OnInit {
  frameworkComponents;
  leaveRowData: any[] = [];
  filteredrowData: any[] = [];
  filterList: string[] = [];
  leaveRowSelection;
  leaveDefaultColDef;
  leaveColumnDefs;
  leaveGridOptions: any;
  gridApi;
  gridColumnApi;

  config: any = {
    params: {
      userId: 0,
      startDate: "",
      endDate: "",
    },
  };
  yearList: any[] = [];
  selectedYear: string = "";
  selectedChileId: number = 0;
  childrenList: any = [];
  userData: any = [];
  constructor(
    public userService: UserService,
    public commonService: CommonService,
    private leaveServ: LeaveService,
    private notificationServ: NotificationService,
    private location: Location
  ) {
    this.commonService.selectedChileId = this.commonService.selectedChileId
      ? this.commonService.selectedChileId
      : 0;
    this.selectedChileId = this.commonService.selectedChileId
      ? this.commonService.selectedChileId
      : 0;
    this.greadReady();
    this.commonService.RefreshGrid$.subscribe((item) => {
      this.getuserleaveinfo();
    });
  }
  goBack(): void {
    this.location.back(); // Navigates back to the previous route
  }
  ngOnInit(): void {
    this.getStartAndEndDate();
    // this.getMyInfo();
  }
  greadReady() {
    this.leaveColumnDefs = [
      {
        headerName: "LeaveId",
        field: "LeaveId",
        cellRendererFramework: CustommenuComponent,
        cellRendererParams: {
          MenuList: [
            { Name: "Edit", Click: this.editLeave, that: this },
            { Name: "Delete", Click: this.deleteLeave, that: this },
            { Name: "Cancel Request", Click: this.cancelRequest, that: this },
          ],
        },

        filter: false,
        width: 70,
        maxWidth: 70,
        minWidth: 70,
        headerComponent: "customHeader",
        headerComponentParams: {
          button: "click",
          enableMenu: true,
          menuIcon: "fa-plus",
          enableSorting: false,
        },
      },
      {
        field: "UserName",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
      },
      {
        field: "LeaveType",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
      },
      {
        field: "Reason",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
      },
      {
        headerName: "StartDate",
        field: "StartDate",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
        maxWidth: 120,
      },
      {
        field: "EndDate",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
        maxWidth: 100,
      },
      {
        field: "LeaveStatus",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
        maxWidth: 100,
      },
      {
        field: "IsFullDayLeave",
        sortable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        menuTabs: [],
        floatingFilterComponentParams: { suppressFilterButton: true },
        resizable: true,
        width: 120,
        maxWidth: 100,
      },
    ];
    this.frameworkComponents = {
      customHeader: CustomHeader,
    };
    this.leaveDefaultColDef = {
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      floatingFilter: true,
    };
  }
  getMyInfo() {
    this.userService.getMyInfo().subscribe((res: any) => {
      this.config.params.userId = res.UserInfo.UserId;
      this.getuserleaveinfo();
    });
  }

  getStartAndEndDate() {
    this.commonService.getMlListInfo("MLAcademicYear").subscribe((res: any) => {
      res = res.slice().reverse();
      this.yearList = res;
      this.selectedYear = res[0].Code;
      this.config.params.startDate = res[0].Code;
      this.config.params.endDate =
        "30-04-" +
        (parseInt(formatDate(new Date(res[0].Code), "yyyy", "en")) + 1);
    });
  }
  // getalluserleaveinfo(){
  //   this.leaveServ.getalluserleaveinfo(this.config).subscribe((res:any)=>{

  //   })
  // }
  serchData() {
    this.getuserleaveinfo();
  }
  changeYear() {
    this.config.params.startDate = this.selectedYear;
    this.config.params.endDate =
      "30-04-" +
      (parseInt(formatDate(new Date(this.selectedYear), "yyyy", "en")) + 1);
  }
  getuserleaveinfo() {
    this.leaveServ.getuserleaveinfo(this.config).subscribe((res: any) => {
      this.leaveRowData = res.LeaveListInfo;
      this.filteredrowData = res.LeaveListInfo;
      console.log(res);
    });
  }

  onLeaveReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    setTimeout(() => {
      this.getMyInfo();
    }, 300);
  }

  onLeaveReload(data) {
    this.leaveRowData = data;
    this.filteredrowData = data;
  }
  deleteLeave(that) {}
  editLeave(that) {}
  cancelRequest(leaveId, that) {
    that.leaveServ.leaveStatusInfo.LeaveId = leaveId;
    that.leaveServ.leaveStatusInfo.IsSaved = true;
    that.leaveServ.leaveStatusInfo.Leavestatus = "Cancelled";
    that.saveLeaveStatus();
  }
  saveLeaveStatus() {
    this.leaveServ.saveleavestatusinfo().subscribe((res: any) => {
      this.leaveRowData = res.LeaveListInfo;
      this.filteredrowData = res.LeaveListInfo;
      console.log(res);
      if (res.IsSaved) {
        this.notificationServ.ShowInfo("Successfully saved!");
        this.commonService.CallOnRefreshGrid();
      }
    });
  }
  filter(e: Event) {
    const inputElement = e.target as HTMLInputElement;
    const key = inputElement.name; // Get the name of the input field
    const text = inputElement.value; // Get the value of the input field
    this.filterList[key]=text
    var rowData=this.leaveRowData 
    Object.keys(this.filterList).forEach(element => {
      rowData=rowData.filter((o) =>
        o[element].toLowerCase().includes(this.filterList[element].toLowerCase())
      );
    });
    
    this.filteredrowData = rowData;
  }
}
