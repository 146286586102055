<div class="profile-group">
  <img class="shadow"
    [src]="service.formData.UserInfo.CroppedFilepath?urlSafe:'../../../assets/images/user/default.png'">

  <p class="shadow">{{service.formData.UserInfo.FullName?service.formData.UserInfo.FullName:''}}</p>
</div>
<button mat-button [matMenuTriggerFor]="beforeMenu"><i class="fa fa-users" aria-hidden="true"></i>&nbsp; <i
    class="fa fa-caret-down"></i></button>
<mat-menu #beforeMenu="matMenu" xPosition="before">
  <button mat-menu-item *ngFor="let child of childrenList" (click)="selectChild(child.Id)">{{child.Name}}</button>
  <button mat-menu-item (click)="selectChild(-1)">Link a child to my account</button>
</mat-menu>

<div class="share-form d-flex align-items-center">
  <div class="container-fluid">
    <div class="row justify-content-c m-0">
      <div class="col-md-6 p-0">
        <div style="width: calc(100%);" class="bg-white shadow p-0 mb-0 mt-3 mb-3">

          <div class="modal-content">
            <div class="modal-header bg-theam p-1">
              <h3 class="modal-title h3 m-1">Refer and earn credits to redeem for upcoming Workshops and Courses ! Let's
                get started ! Enter details below to start inviting friends/relatives and earning credits.</h3>


            </div>
            <div class="breackout_body p-2">

              <form novalidate #form="ngForm" (submit)="onRegister(form)" autocomplete="off">
                <div class="row form-row m-0">
                  <div class="form-group col-12 p-0 px-1 mb-2 border border-secondary">
                    <div class="row form-row m-0 pt-0 justify-content-between">
                      <div class="col-12 mt-2 mb-2 d-flex">
                        <label class="d-block w-88px">I want to refer a/an</label>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                          name="Role" [(ngModel)]="commonServ.userFormData.UserInfo.Role" #Role="ngModel" required
                          class.invalid="Role.invalid && Role.touched">
                          <mat-radio-button class="example-radio-button ml-3" *ngFor="let role of SignupRoleList"
                            [value]="role.Code">
                            {{role.Name}}
                          </mat-radio-button>
                        </mat-radio-group>
                      </div>
                      <div class="col-6 mb-0">
                        <label>First Name</label>
                        <input class="form-control form-control-sm" placeholder="First Name" name="FirstName"
                          #FirstName="ngModel" [(ngModel)]="commonServ.userFormData.UserInfo.FirstName" required
                          class.invalid="FirstName.invalid && FirstName.touched" maxlength="100">
                      </div>
                      <div class="col-6 mb-0">
                        <label>Last Name</label>
                        <input class="form-control form-control-sm" placeholder="Last Name" name="Surname"
                          #Surname="ngModel" maxlength="100" [(ngModel)]="commonServ.userFormData.UserInfo.Surname">
                      </div>
                      <div class="col-6 mb-0">
                        <label>Email</label>
                        <input class="form-control form-control-sm" type="email" placeholder="Email" name="Email"
                          #Email="ngModel" [(ngModel)]="commonServ.userFormData.UserInfo.Email" required maxlength="50"
                          class.invalid="Email.invalid && Email.touched">
                      </div>
                      <div class="col-6 mb-0">
                        <label>Mobile No</label>
                        <input class="form-control form-control-sm" type="number" placeholder="Mobile No"
                          name="MobileNo" #MobileNo="ngModel" [(ngModel)]="commonServ.userFormData.UserInfo.MobileNo"
                          required maxlength="10" class.invalid="MobileNo.invalid && MobileNo.touched">
                      </div>
                      <div class="col-12 mb-0">
                        <label>Description</label>
                        <textarea class="form-control form-control-sm" placeholder="Description" name="Description"
                          [(ngModel)]="commonServ.userFormData.UserInfo.Description" cols="10" rows="2"></textarea>
                      </div>
                      <!-- <div class="form-group col-12 mb-0">
                            <mat-checkbox class="example-margin h-30px mb-10px"  name="IsUserReferred"
                            [(ngModel)]="commonServ.userFormData.UserInfo.IsUserReferred" 
                            #IsUserReferred="ngModel" required class.invalid="IsUserReferred.invalid && IsUserReferred.touched">Refering to my friend</mat-checkbox>
                          </div> -->
                      <div class="form-group col-12 mb-0">
                        <mat-checkbox class="example-margin mb-10px w-100" name="IsUserReferred"
                          [(ngModel)]="commonServ.userFormData.UserInfo.IsUserReferred" #IsUserReferred="ngModel"
                          required class.invalid="IsUserReferred.invalid && IsUserReferred.touched">
                          I have read and agree to the Hybrid Learning Platform Refer-A-Friend rules and consent to
                          receive Hybrid Learning Platform emails specific to my participation in the program, and
                          information that may be of interest to me about Hybrid Learning Platform services.
                        </mat-checkbox>
                      </div>

                    </div>
                  </div>

                </div>

              </form>
            </div>
            <div class="modal-footer p-1">
              <button type="button" class="btn btn-success" [disabled]="form.invalid"
                (click)="onRegister(form)">Submit</button>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>

</div>