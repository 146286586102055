import { Component, OnInit, EventEmitter } from "@angular/core";
import { NgForm, FormsModule } from "@angular/forms";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { UserService } from "src/app/shared/user.service";
import {
  BsDatepickerConfig,
  BsDatepickerModule,
} from "ngx-bootstrap/datepicker";
import { User, UserView } from "../shared/user.model";
import { NotificationService } from "../shared/notification.service";
import { CommonService } from "../shared/common.service";
import contactPageData from "../schoolConst";
import { Router } from "@angular/router";
import { EditpicComponent } from "../user/editpic.component";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { TranslateModule } from "@ngx-translate/core";
import { MatRadioModule } from "@angular/material/radio";
import { MatIconModule } from "@angular/material/icon";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { NgFor, NgIf, NgClass } from "@angular/common";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";

@Component({
  selector: "app-edit-child",
  templateUrl: "./edit-child.component.html",
  styleUrls: ["./edit-child.component.scss"],
  standalone: true,
  imports: [
    MatButtonModule,
    MatMenuModule,
    NgFor,
    NgIf,
    FormsModule,
    MatCheckboxModule,
    NgClass,
    MatCardModule,
    MatIconModule,
    BsDatepickerModule,
    MatRadioModule,
    TranslateModule,
  ],
})
export class EditChildComponent implements OnInit {
  timepickerVisible = false;
  public time: Date = new Date();
  public mytime: string;
  maxLength: number;
  currentLength: number;
  datepickerConfig: BsDatepickerConfig;
  public saved: EventEmitter<any> = new EventEmitter();
  CUSTOMCONST: any;
  bsModalRef2: BsModalRef;
  bsModalRef3: BsModalRef;
  childList: any = [];
  genderlist: any = [];
  schoollist: any = [];
  rolelist: any = [];
  list: any[] = [];
  role: string;
  childrenList: any = [];
  selectedChileId: number = 0;
  public bsModalRef: BsModalRef;
  sourceList: any = contactPageData.contact.hearSourceList;
  motivatedList: any = contactPageData.contact.motivatedList;

  userShareId: string = "";
  urlSafe: SafeResourceUrl;
  IsSearchByUserShareId: boolean = false;
  constructor(
    public service: UserService,
    public modalService: BsModalService,
    public router: Router,
    private notificationServ: NotificationService,
    private commonService: CommonService,
    public sanitizer: DomSanitizer
  ) {
    this.datepickerConfig = Object.assign({
      dateInputFormat: "DD-MM-YYYY",
      showWeekNumbers: false,
      showTodayButton: false,
    });
    // this.CUSTOMCONST = CUSTOMCONST;
    this.commonService.selectedChileId = this.commonService.selectedChileId
      ? this.commonService.selectedChileId
      : 0;
    this.selectedChileId = this.commonService.selectedChileId
      ? this.commonService.selectedChileId
      : 0;
  }
  ngOnInit(): void {
    // this.service.formData = new UserView();
    // this.service.formData.UserInfo.UserId = 0;

    // this.service.formData.UserInfo.IsSearchByUserShareId = false;
    if (this.commonService.selectedChileId) {
      this.selectedChileId = this.commonService.selectedChileId;
    }
    this.getMyInfo();
    this.getuser();
  }
  getuser() {
    var userid =
      this.commonService.selectedChileId > 0
        ? this.commonService.selectedChileId
        : 0;
    this.service.getUserInfo(userid).subscribe((res: any) => {
      this.service.formData = res;
      this.genderlist = res.GenderList;
      this.schoollist = res.SchoolList;
      this.rolelist = res.RoleList;
      this.role = this.service.formData.UserInfo.Role;
      this.maxLength = 2000;
      this.currentLength = 0;
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
        res.UserInfo.CroppedFilepath
      );
    });
  }
  getMyInfo() {
    this.service.getMyInfo().subscribe((res: any) => {
      this.childrenList = res.ParentInfo.ChildrenList;
    });
  }
  schoolChange() {
    // if(this.service.formData.UserInfo.SchoolId > 0){
    //   this.service.getBasicStudentList(this.service.formData.UserInfo.SchoolId).subscribe((res: any) => {
    //     this.childList = res;
    //   });
    // }
    // else{
    //   this.childList =  [];
    // }
  }

  getTime(time) {
    return "s";
  }
  onSave(form: NgForm) {
    this.service.formData.UserInfo.IsSearchByUserShareId =
      this.IsSearchByUserShareId;
    this.service.formData.UserInfo.RoleId = this.rolelist.filter(
      (item) => item.Code == "Student"
    )[0].Id;
    // this.service.formData.UserInfo.Role = "Student";
    this.service.formData.UserInfo.IsSaved = false;
    if (this.service.formData.UserInfo.UserId > 0) {
      this.service.saveUser().subscribe((res: User) => {
        if (res.ErrorMessage) {
          this.notificationServ.ShowError(res.ErrorMessage);
        } else if (res.IsSaved) {
          this.saved.emit("someData");
          this.notificationServ.ShowInfo("User saved successfully");
          this.commonService.CallOnRefreshGrid();
          this.router.navigateByUrl("/parentdashboard");
        } else this.saved.emit("someData");
      });
    } else {
      this.service.formData.UserInfo.IsActive = true;
      this.saved.emit("someData");
      this.service.saveUser().subscribe((res: User) => {
        if (res.ErrorMessage) {
          this.notificationServ.ShowError(res.ErrorMessage);
        } else if (res.IsSaved) {
          this.notificationServ.ShowInfo("User saved successfully");
          this.commonService.CallOnRefreshGrid();
          // this.bModalRef.hide();
          this.router.navigateByUrl("/parentdashboard");
        }
      });
    }
  }

  lenghtCounter() {
    this.currentLength = this.service.formData.UserInfo.About.length;
    // this.disabled = this.currentLength<this.maxLength?true;
  }

  // studentOffer(studentId:number){
  //   const initialState = {
  //     list: [
  //       { id: studentId }
  //     ]

  //   };
  //   this.bModalRef = this.modalService.show(StuOfferComponent, { id: CUSTOMCONST.LABEL_ONE_MODAL, initialState, class: 'modal-dialog modal-dialog-centered', ignoreBackdropClick: true });
  // }
  editCoverImage() {
    this.openbranchmodal("coverImage");
  }
  editPic() {
    this.openbranchmodal("profilePic");
  }
  openbranchmodal(data: string) {
    const initialState = {
      list: [{ editImageOf: data }],
    };
    this.bsModalRef = this.modalService.show(EditpicComponent, {
      initialState,
      class: "modal-xl box-shadow",
      ignoreBackdropClick: true,
    });
    this.bsModalRef.content.event.subscribe((res) => {
      if (data === "profilePic") {
        this.service.formData.UserInfo.FileInfo = res.data;
      } else if (data === "coverImage") {
        this.service.formData.UserInfo.ProfileFileInfo = res.data;
      }
    });
  }
  selectChild(id: any) {
    this.selectedChileId = id;
    this.commonService.selectedChileId = id;
    this.IsSearchByUserShareId = false;
    this.userShareId = "";
    this.getuser();
  }
  searchUserWithSharedId() {
    this.service
      .searchuserwithsharedid(this.userShareId)
      .subscribe((res: any) => {
        if (res.ErrorMessage) {
          this.notificationServ.ShowError(res.ErrorMessage);
        } else {
          this.service.formData = res;
          this.genderlist = res.GenderList;
          this.rolelist = res.RoleList;
          this.schoollist = res.SchoolList;
          this.role = this.service.formData.UserInfo.Role;
          this.maxLength = 2000;
          this.currentLength = 0;
        }
      });
  }

  changeChild(data: any) {
    console.log(data);

    var id = data.Userinfo.UserId;
    this.commonService.selectedChileId = id;

    this.service.formData = data;
    this.genderlist = data.GenderList;
    this.schoollist = data.SchoolList;
    this.rolelist = data.RoleList;
    this.role = this.service.formData.UserInfo.Role;
    this.maxLength = 2000;
    this.currentLength = 0;
  }
}
