<div class="chatbox-outer chatBoxOpen" id="chatOuter">
    <div class="message_user_list">
        <div class="message-header">
            <h3>Contacts</h3>
            <span class="bi bi-x chat-action closeChat" title="Chat Close" data-bs-placement="bottom"
                (click)="closeChatBox()"></span>
        </div>
        <ul class="message_user_list_inner">
            <li *ngFor="let masterMessage of masterMessageList" class="msg-user-name">
                <a (click)="handleChatClick(masterMessage)"
                    [ngClass]="{'activeuser':(commonServ.messageMasterInfo && commonServ.messageMasterInfo.MessageMasterId===masterMessage.MessageMasterId)}"
                    href="Javascript:void(0);"><span class="msg-user-img">DT</span>{{masterMessage.Name}}</a>
            </li>
        </ul>
    </div>

    <div class="meassge_body" id="message-body">
        <div class="message-header">
            <span class="bi bi-arrow-left-short chat-action back-action" title="Back" (click)="toggleChat()"></span>
            <h3>{{commonServ.messageMasterInfo && commonServ.messageMasterInfo &&
                commonServ.messageMasterInfo.Name || 'Messages'}}</h3>
            <span class="bi bi-x chat-action closeChat" title="Chat Close" data-bs-placement="bottom"
                (click)="closeChatBox()"></span>
        </div>
        <ul class="meassgae_body_inner">
            <li class="message-text" *ngFor="let messagelist of messageList"
                [ngClass]="(messagelist.CreatedBy == commonServ.UserId)?'outgoing':'incoming'">
                <div *ngIf="messagelist.Message != '%PAUSE%' && messagelist.Message != '%RESUME%'">
                    <span class="sender-name"
                        *ngIf="messagelist.CreatedBy != commonServ.UserId">{{messagelist.CreatedByName}}
                    </span>
                    <pre>{{messagelist.Message}}</pre>



                    <span class="sender-date">
                        {{messagelist.CreatedOn}}
                    </span>
                </div>
            </li>
            <li *ngIf="isAdmin && messageList && messageList.length > 1">

                <button type="button" class="btn btn-danger"
                    (click)="sendMessageAction(messageList[messageList?.length-1].Message == '%PAUSE%' ? 'RESUME' : 'PAUSE')"
                    aria-label="Close">{{messageList[messageList?.length-1].Message == '%PAUSE%' ? 'RESUME'
                    : 'PAUSE'}}</button>


            </li>
            <app-upload-media *ngIf="isRecording"></app-upload-media>
        </ul>
        <div class="message-type" *ngIf="messageList">
            <textarea
                [disabled]="!isAdmin && messageList[messageList?.length-1] && messageList[messageList?.length-1].Message == '%PAUSE%'"
                [disabled]="!isAdmin && messageList[messageList?.length-1] && messageList[messageList?.length-1].Message == '%PAUSE%'"
                autofocus type="text" class="chat-type"
                (keyup)="textAreaAdjust(this)" name="Message" #Message="ngModel" [(ngModel)]="messageInfo.Message"
                placeholder="Type Message"></textarea>
            <button
                [disabled]="!isAdmin && messageList[messageList?.length-1] && messageList[messageList?.length-1].Message == '%PAUSE%'"
                [disabled]="!isAdmin && messageList[messageList?.length-1] && messageList[messageList?.length-1].Message == '%PAUSE%'"
                class="chat-icons" (click)="sendMessage()" title="Send"><i class="bi bi-send"></i></button>
            <button class="chat-icons" *ngIf="!isRecording" (click)="startVideoRecording()" title="Start Recording"><i
                    class="bi bi-mic"></i></button>
            <button class="chat-icons" *ngIf="isRecording" (click)="stopVideoRecording()" title="Cancel"><i
                    class="bi bi-x"></i></button>
            <!-- <div class="chart-form position-relative">
                <div> -->
            <!-- <h1>Video Record</h1> -->
            <!-- <button (click)="showCamera()">Record</button> -->
            <!-- <video class="video" #liveVideo autoplay controls></video>
                    <video class="video" controls #recordedVideo></video> -->


            <!-- </div> -->
            <!-- </div> -->
        </div>
    </div>
</div>