<div class="dashboardInnerPage inner-page-outer flex-column">
    <div class="title w-100">
        <span (click)="goBack()" title="Back" data-bs-toggle="tooltip" data-bs-placement="bottom"
            class="bi bi-arrow-left"></span>
        <h3>Student List</h3>
        <!-- <a class="add_button" routerLink="#" (click)="openquestionmodal(0)">Add Assessments</a> -->
    </div>
    <div class="field-row filter-row ">
        <div class="select_field">
            <label>Academic Year</label>

            <select class="field-control appearance-auto" name="selectedYear" [(ngModel)]="selectedYear"
                (change)="changeYear()" required>
                <option *ngFor="let year of yearList" [value]="year.Code">
                    {{year.Name}}
                </option>
            </select>

            <!-- <input [(ngModel)]="modelDate" autocomplete="off" class="form-control rounded-0" name="date" bsDatepicker
                        [bsConfig]="{ dateInputFormat: 'YYYY' }" (onShown)="onOpenCalendar($event)" /> -->
        </div>
        <div class="filter_option">
            <span (click)="serchStudent()" class="filter_button"><i class="bi bi-search"></i> Search</span>
            <span class="filter_button" data-bs-toggle="collapse" data-bs-target="#collapseExample"
                aria-expanded="false" aria-controls="collapseExample"><i class="bi bi-filter"></i> Filter</span>
        </div>
    </div>
    <div class="filter-form collapse" id="collapseExample">
        <div class="field-row">
            <div class="select_field">
                <label>Full Name</label>
                <input type="text" class="field-control" name="FullName" (keyup)="filter($event)" />
            </div>
            <div class="select_field">
                <label>Address</label>
                <input type="text" class="field-control" name="Address" (keyup)="filter($event)" />
            </div>
            <div class="select_field">
                <label>Street</label>
                <input type="text" class="field-control" name="Street" (keyup)="filter($event)" />
            </div>
            <div class="select_field">
                <label>City</label>
                <input type="text" class="field-control" name="City" (keyup)="filter($event)" />
            </div>
        </div>
        <div class="field-row">
            <div class="select_field">
                <label>State</label>
                <input type="text" class="field-control" name="State" (keyup)="filter($event)" />
            </div>
            <div class="select_field">
                <label>Postal No</label>
                <input type="text" class="field-control" name="PostalNo" (keyup)="filter($event)" />
            </div>
            <div class="select_field">
                <label>Mobile</label>
                <input type="text" class="field-control" name="Mobile" (keyup)="filter($event)" />
            </div>
            <div class="select_field">
                <label>S Mobile</label>
                <input type="text" class="field-control" name="SMobile" (keyup)="filter($event)" />
            </div>
            <div class="select_field">
                <label>Role</label>
                <input type="text" class="field-control" name="Role" (keyup)="filter($event)" />
            </div>
            <div class="select_field">
                <label>Branch</label>
                <input type="text" class="field-control" name="Branch" (keyup)="filter($event)" />
            </div>
            <div class="select_field">
                <label>Email</label>
                <input type="text" class="field-control" name="Email" (keyup)="filter($event)" />
            </div>
        </div>
    </div>

    <div class="custom-table">
        <!-- <pre>{{ filteredrowData | json }}</pre> Debugging purpose -->

        <table class="table table-striped">
            <thead>
                <tr>
                    <th>Full Name</th>
                    <th>Address</th>
                    <th>Street</th>
                    <th>City</th>
                    <th>State</th>
                    <th>Postal No</th>
                    <th>Mobile</th>
                    <th>S Mobile</th>
                    <th>Role</th>
                    <th>Branch</th>
                    <th>Email</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody *ngIf="filteredrowData.length === 0">
                <tr>
                    <td colspan="12">No data available</td>
                </tr>
            </tbody>
            <tbody *ngIf="filteredrowData.length > 0">
                <tr *ngFor="let data of filteredrowData">
                    <td>{{data.FullName}}</td>
                    <td>{{data.Address}}</td>
                    <td>{{data.Street}}</td>
                    <td>{{data.City}}</td>
                    <td>{{data.State}}</td>
                    <td>{{data.PostalNo}}</td>
                    <td>{{data.Mobile}}</td>
                    <td>{{data.SMobile}}</td>
                    <td>{{data.Role}}</td>
                    <td>{{data.Branch}}</td>
                    <td>{{data.Email}}</td>
                    <td>
                        <div class="table-action">
                            <span data-bs-toggle="tooltip" data-bs-placement="bottom" class=" bi bi-info-lg"
                                title="Student Info" (click)="editUser(data.UserId, this)"></span>
                            <span data-bs-toggle="tooltip" data-bs-placement="bottom" class=" bi bi-chat-right-quote"
                                title="Feedback" (click)="createFeedback(data.UserId, this)"></span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- <ag-grid-angular #agGrid style="width: 100%; height:calc(100vh - 80px)" class="ag-theme-alpine"
                        id="myGrid" [gridOptions]="gridOptions" [columnDefs]="columnDefs"
                        [defaultColDef]="defaultColDef" [rowSelection]="rowSelection" [filteredrowData]="filteredrowData"
                        (selectionChanged)="onSelectionChanged($event)" (gridReady)="onGridReady($event)"
                        [frameworkComponents]="frameworkComponents">
                    </ag-grid-angular> -->
    </div>
</div>