import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-upload-media',
  standalone: true,
  imports: [],
  templateUrl: './upload-media.component.html',
  styleUrl: './upload-media.component.scss'
})
export class UploadMediaComponent {
  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
  @ViewChild('recOnDiv') recOnDiv!: ElementRef<HTMLDivElement>;
  @ViewChild('recOffDiv') recOffDiv!: ElementRef<HTMLDivElement>;
  mediaRecorder!: MediaRecorder;
  recordedChunks: Blob[] = [];
  recordedBlobUrl: string | null = null;
  isRecording = false;
  isRecorded = false;

  stream!: MediaStream;
  submitted = false;
  selectedFile: File | null = null;
  fileUploadError: string = '';
  responseData: any;
  blob: any;
  act
  ngOnInit(): void {
    this.initMediaStream();
  }
    // Initialize the media stream from camera and microphone
    async initMediaStream() {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true
        });
  
        // Set the stream to the video element
        this.videoElement.nativeElement.srcObject = this.stream;
      } catch (error) {
        console.error('Error accessing media devices:', error);
      }
    }
  
    // Start recording video and audio
    startRecording() {
      this.recordedChunks = [];
      this.isRecording = true;
  
      // Initialize MediaRecorder with the media stream
      this.mediaRecorder = new MediaRecorder(this.stream);
  
      // Event triggered when data is available (i.e., recording in progress)
      this.mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          this.recordedChunks.push(event.data);
        }
      };
  
      // Start recording
      this.mediaRecorder.start();
    }
  
    // Stop recording and create a blob from the recorded data
    stopRecording() {
      this.isRecording = false;
      this.isRecorded = true;
  
      this.mediaRecorder.stop();
  
      this.mediaRecorder.onstop = () => {
        // Create a Blob from the recorded chunks
        this.blob = new Blob(this.recordedChunks, { type: 'video/webm' });
      
        // Create a URL for the blob
        this.recordedBlobUrl = URL.createObjectURL(this.blob);
        let stream = this.mediaRecorder.stream; // Get the media stream
        let tracks = stream.getTracks(); // Get all tracks from the stream
    
        // Stop each track (video/audio)
        tracks.forEach(track => track.stop());
        this.toggleView()
      };

    }
    discardRecording() {
      // this.recordedChunks = [];
      this.recordedBlobUrl = null;
      this.isRecording = false;
      this.isRecorded = false;
      this.toggleView()
      this.initMediaStream();
    }

    onSubmit(): void {
      console.log("here")
      this.submitted = true;
    
      // Validate form fields
        if (!this.blob) {
          this.fileUploadError = 'File is required.';
        }
        return;
      }
      toggleView(){
        console.log(this.isRecorded);
        
        if(this.isRecorded){
          this.recOnDiv.nativeElement.style.display = 'none';
          this.recOffDiv.nativeElement.style.display = 'block';
        }else{
          this.recOffDiv.nativeElement.style.display = 'none';
          this.recOnDiv.nativeElement.style.display = 'block';
        }
      }
}
