import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { Location } from '@angular/common';
import { ForumService } from 'src/app/shared/forum.service';

@Component({
  selector: 'app-general-forum-component',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    RouterLink
  ],
  templateUrl: './general-forum-component.component.html',
  styleUrl: './general-forum-component.component.scss'
})
export class GeneralForumComponentComponent {
  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;

  list: any[] = [];
  constructor(public service:ForumService,private location: Location) {}

  ngOnInit(): void {
    const config={}
    this.service.getQuestions(config).subscribe(res=>{
      console.log(res["QuestionInfoList"]);
      this.list=  res["QuestionInfoList"]
    })
    // this.list=[
    //   {
    //     subject:"test subject",
    //     video:"https://hybridlearning.in/95/File/192d6206-4099-4678-a0b1-ea191bad1081recording.webm",
    //     userName:"test",
    //     questionId:"1"
    //   },
    //   {
    //     subject:"test subject",
    //     video:"https://hybridlearning.in/95/File/192d6206-4099-4678-a0b1-ea191bad1081recording.webm",
    //     userName:"test",
    //     questionId:"2"
    //   },
    //   {
    //     subject:"test subject",
    //     video:"https://hybridlearning.in/95/File/192d6206-4099-4678-a0b1-ea191bad1081recording.webm",
    //     userName:"test",
    //     questionId:"3"
    //   },
    //   {
    //     subject:"test subject",
    //     video:"https://hybridlearning.in/95/File/192d6206-4099-4678-a0b1-ea191bad1081recording.webm",
    //     userName:"test",
    //     questionId:"4"
    //   },
    //   {
    //     subject:"test subject",
    //     video:"https://hybridlearning.in/95/File/192d6206-4099-4678-a0b1-ea191bad1081recording.webm",
    //     userName:"test",
    //     questionId:"5"
    //   },
    //   {
    //     subject:"test subject",
    //     video:"https://hybridlearning.in/95/File/192d6206-4099-4678-a0b1-ea191bad1081recording.webm",
    //     userName:"test",
    //     questionId:"6"
    //   },
    //   {
    //     subject:"test subject",
    //     video:"https://hybridlearning.in/95/File/192d6206-4099-4678-a0b1-ea191bad1081recording.webm",
    //     userName:"test",
    //     questionId:"7"
    //   }]
  }
  goBack(): void {
    this.location.back(); // Navigates back to the previous route
  }

}
