<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1">Edit Own Document</h3>
        </div>
        <div class="breackout_body p-0 model-max-window-h">
            <section class="pt-0">
                <div class="row justify-content-c m-0  bg-white p-2">
                    <div class="row border m-0 py-2 mb-2 justify-content-between p-0">
                        <div class="col-6">
                            <div class="form-group mb-0">
                                <img [src]="url"
                                    *ngIf="(extension === 'jpg' || extension === 'jpeg' || extension === 'png') && url"
                                    height="200">
                                <iframe [src]="urlSafe" *ngIf="extension === 'pdf' && url" frameborder="0"
                                    height="500"></iframe>
                                <iframe *ngIf="(extension === 'docs' || extension === 'doc') && url" [src]="urlSafe"
                                    height="500"></iframe>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group mb-0">
                                <label>ExternalShareId</label>
                                <label
                                    class="form-control form-control-sm readonly">{{service.formData.ExternalShareId}}</label>
                            </div>
                            <div class="form-group mb-0">
                                <label>&nbsp;</label>
                                <div class="custom-p-2 custom-mt-1">
                                    <mat-checkbox class="example-margin" name="IsExternalShare"
                                        #IsExternalShare="ngModel" [(ngModel)]="service.formData.IsExternalShare">Is
                                        External Share</mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
        <div class="modal-footer p-1">
            <button type="button" (click)="generateshareddocumentid()" class="btn btn-success float-right"
                type="button">Save</button>
            <button type="button" class="btn btn-danger" (click)="modalService.hide(CUSTOMCONST.LABEL_ONE_MODAL)"
                aria-label="Close">Close</button>
        </div>
    </div>
</div>