<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1">{{'Survey' | translate}}</h3>
        </div>
        <form novalidate #form="ngForm" autocomplete="off">
            <div class="breackout_body p-2">
                <div>
                    <div class="row form-row m-0">
                        <div class="col-12">
                            <p>{{Description}}</p>
                            <bar-rating [rate]="rate" (rateChange)="onFaoRate($event)" [max]="5"
                                *ngIf="RatingType === '5 Star'">
                                <ng-template ratingInactive>
                                    <fa-icon [icon]="['far', 'star']" [fixedWidth]="true" size="lg"
                                        style="color: #d2d2d2"></fa-icon>
                                </ng-template>
                                <ng-template ratingActive>
                                    <fa-icon [icon]="['fas', 'star']" [fixedWidth]="true" size="lg"
                                        style="color: #50e3c2"></fa-icon>
                                </ng-template>
                                <ng-template ratingFraction>
                                    <fa-icon [icon]="['fas', 'star-half-alt']" [fixedWidth]="true" size="lg"
                                        style="color: #50e3c2"></fa-icon>
                                </ng-template>
                            </bar-rating>

                            <div *ngIf="RatingType !== '5 Star'">
                                <mat-radio-group aria-label="Select an option" name="Result"
                                    [(ngModel)]="surveyServ.formData.Result" required>
                                    <mat-radio-button class="mr-3" value="Yes"> Yes </mat-radio-button>
                                    <mat-radio-button class="mr-3" value="No"> No </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div class="modal-footer p-1">
                <button type="button" (click)="addSurveyResultInfo(form)" class="btn btn-success"
                    type="button">Save</button>
            </div>
        </form>
    </div>
</div>