<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title">{{'AddFeedback' | translate}}</h3>
        <span class="bi bi-x" (click)="bsModalRef.hide()" title="Close Module" data-bs-toggle="tooltip"></span>
    </div>
    <form novalidate #form="ngForm" autocomplete="off">
        <div class="breackout_body">
            <div class="field-row">
                <div class="select_field">
                    <label>{{'Feedback' | translate}}</label>
                    <textarea class="field-control resizebele-false" type="text"
                        placeholder="{{'Feedback' | translate}}" name="Feedback" #Feedback="ngModel"
                        [(ngModel)]="feedbackServ.formData.Feedback"></textarea>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="addFeedbackInfo(form)" class="custom-primary-btn"
                    type="button">Save</button>
                <button type="button" class="custom-primary-btn custom-danger" (click)="bsModalRef.hide()"
                    aria-label="Close">Close</button>
            </div>
        </div>

    </form>
</div>