<div class="modal-dialog modal-xl m-0">
    <div class="modal-content">
        <div class="modal-header p-1 bg-theam">
            <h3 class="modal-title h3 m-1">My Hobby practicing</h3>
        </div>
        <form novalidate #form="ngForm" autocomplete="off">
            <div class="breackout_body p-2">
                <div>
                    <div class="row form-row m-0">
                        <div class="col-4 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>{{'Hobby Name' | translate}}*</label>
                                <input class="form-control form-control-sm" type="text"
                                    placeholder="{{'Hobby Name' | translate}}" name="HobbyName" #HobbyName="ngModel"
                                    [(ngModel)]="service.formDataHobby.StudentHobbyInfo.Name" required
                                    class.invalid="HobbyName.invalid && HobbyName.touched">

                            </div>
                        </div>
                        <div class="col-4 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="form-group mb-0">
                                <label>{{'Source of Learning ' | translate}}*</label>
                                <select class="form-control form-control-sm appearance-auto" name="learningsource"
                                    #learningsource="ngModel"
                                    [(ngModel)]="service.formDataHobby.StudentHobbyInfo.SourceId" required
                                    class.invalid="learningsource.invalid && learningsource.touched">
                                    <option *ngFor="let learningsource of service.formDataHobby.HobbySourceInfo"
                                        [value]="learningsource.Id">{{learningsource.Name}}</option>
                                </select>

                            </div>
                        </div>

                        <div class="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>{{'StartDate' | translate}}</label>
                                <input type="text" class="form-control form-control-sm"
                                    placeholder="{{'StartDate' | translate}}" name="StartDate" #StartDate="ngModel"
                                    [(ngModel)]="service.formDataHobby.StudentHobbyInfo.StartDate"
                                    [bsConfig]="datepickerConfig" bsDatepicker [bsValue]="bsValue" timepicker required
                                    class.invalid="StartDate.invalid && StartDate.touched">
                            </div>

                        </div>

                        <div class="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <div class="form-group mb-0">
                                <label>{{'EndDate' | translate}}</label>
                                <input type="text" (click)="enddateSetting()" class="form-control form-control-sm"
                                    placeholder="{{'EndDate' | translate}}" name="EndDate" #EndDate="ngModel"
                                    [(ngModel)]="service.formDataHobby.StudentHobbyInfo.EndDate"
                                    [bsConfig]="datepickerConfig" bsDatepicker [bsValue]="bsValue" [minDate]="minDate"
                                    required class.invalid="EndDate.invalid && EndDate.touched">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row form-row m-0">
                    <div class="row form-row m-0 p-0">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="form-group mb-0">
                                <label>{{'Outcomes ' | translate}}*</label>
                                <textarea class="form-control form-control-sm appearance-auto resizebele-false"
                                    name="Outcomes " #Outcomes="ngModel"
                                    [(ngModel)]="service.formDataHobby.StudentHobbyInfo.Outcome" required
                                    class.invalid="Outcomes.invalid && Outcomes.touched"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-c m-0  bg-white p-2">
                        <label class="m-0 p-0">{{'Hobby Pictures ' | translate}}*</label>
                        <div class="row border m-0 py-2 mb-2 justify-content-between p-0">

                            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-2">
                                <div class="form-group mb-0">
                                    <mb-large-files-uploader (updatedAttachmentsEmitter)="largefileschange($event)"
                                        name="FileInfoList"
                                        [(ngModel)]="service.formDataHobby.StudentHobbyInfo.FileInfoList"
                                        (updatedResponseEmitter)="filesuploaded($event)"
                                        title="{{'Upload File' | translate}}" canUpload="true" canSendData="true"
                                        saveButtonText="" class="inner-btn-none">
                                    </mb-large-files-uploader>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>


            <div class="modal-footer p-1">
                <button type="button" class="btn btn-success" (click)="saveDocument()" type="button">Save</button>
                <button type="button" class="btn btn-danger" (click)="closeModel()" aria-label="Close">Close</button>
            </div>
        </form>
    </div>
</div>