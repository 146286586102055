import { NgIf, NgFor } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CommonService } from "../shared/common.service";
import { CUSTOMCONST } from "../shared/Const";
import { UserService } from "../shared/user.service";
import { AgGridModule } from "ag-grid-angular";

@Component({
  selector: "app-user-academic-year-log",
  templateUrl: "./user-academic-year-log.component.html",
  styleUrls: ["./user-academic-year-log.component.scss"],
  standalone: true,
  imports: [AgGridModule, NgIf, NgFor],
})
export class UserAcademicYearLogComponent implements OnInit {
  list: any[] = []; // Initialize to avoid undefined errors
  gridApi: any;
  gridColumnApi: any;
  ColumnDefs: any[];
  DefaultColDef: any;
  RowData: any[] = [];
  RowSelection: string = "single"; // Default to single selection
  CUSTOMCONST: any = CUSTOMCONST;

  constructor(
    public service: UserService,
    public commonServ: CommonService,
    public bsModalRef: BsModalRef,
    public modalService: BsModalService
  ) {
    this.ColumnDefs = [
      {
        field: "CreatedBy",
        sortable: true,
        filter: "agTextColumnFilter",
        width: 200,
      },
      {
        field: "CreatedOn",
        sortable: true,
        filter: "agTextColumnFilter",
        width: 120,
      },
      {
        field: "NewAcademicYear",
        sortable: true,
        filter: "agTextColumnFilter",
        width: 200,
      },
      {
        field: "OldAcademicYear",
        sortable: true,
        filter: "agTextColumnFilter",
        width: 200,
      },
    ];

    // Default column definitions can be set here
    this.DefaultColDef = {
      resizable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
    };
  }

  ngOnInit(): void {
    // Fetch the user list or set it before calling the log info function
    this.fetchUserList(); // Example method to fetch the user list
  }

  fetchUserList() {
    // Fetch or initialize this.list here
    this.service
      .getuseracademicyearloginfo(this.list[0].id)
      .subscribe((res: any) => {
        this.RowData = res;
      });
  }

  getuseracademicyearloginfo() {
    if (this.list.length > 0) {
      this.service.getuseracademicyearloginfo(this.list[0].id).subscribe(
        (res: any) => {
          this.RowData = res;
        },
        (error) => {
          console.error("Error fetching academic year log info:", error);
        }
      );
    } else {
      console.warn("User list is empty. Cannot fetch academic year log info.");
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.getuseracademicyearloginfo(); // Call the data fetch here without timeout
  }

  onSelectionChanged(event: any) {
    // Handle selection change
  }
}
